import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"
// import checklist from "./../../../images/checklist/checklist.svg"
import { ChecklistCard } from "./../ChecklistTab/ChecklistCard"

function ChecklistCreator() {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  const [toggleStar, setToggleStar] = useState(false)
  const [toggleCheck, setToggleCheck] = useState(false)
  const [checkListItems, setCheckListItems] = useState("")

  const [syncRequired, setSyncRequired] = useState(false)

  const addChecklistItem = () => {
    dispatch({ type: "addChecklistItem" })
  }

  const onSyncRequired = e => {}
  const showChecklistItems = () => {
    state.currentJobInfo.tripChecklist.map((item, index) => {
      return <ChecklistCard date={item} onSyncRequired={onSyncRequired} />
    })
  }

  useEffect(() => {}, [state.currentJobInfo.tripChecklist])

  useEffect(() => {
    try {
      console.log("change to sync  flag")
      if (state.currentJobInfo.tripChecklistSyncRequired) {
        //get all the date between the trip start and end dates.
        console.log("updating DB")
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${authContext.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({ tripChecklist: state.currentJobInfo.tripChecklist })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => console.log(e))
        }

        dispatch({ type: "tripChecklistSyncRequired", data: false })
      }
    } catch (error) {
      console.log(error)
    }
  }, [state.currentJobInfo.tripChecklistSyncRequired])

  const onRemove = e => {
    dispatch({
      type: "deleteChecklistItem",
      index: e.currentTarget.id,
    })
    dispatch({ type: "tripChecklistSyncRequired", data: true })
  }

  return (
    <div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
        className=""
      >
        <p
          // style={{
          //   fontSize: "22px",
          //   fontWeight: "800",
          //   color: "rgba(74,74,74,0.8)",
          //   margin: "20px auto",
          //   lineHeight: "1.4",
          //   textAlign: "center",
          // }}
          className="tab-header"
        >
          Tasks<span style={{ color: "#fec600" }}>.</span>
        </p>
        <div
          style={{
            margin: "30px",
          }}
        >
          <a
            style={{
              textAlign: "center",
              fontWeight: "600",
              textDecorationColor: "#fec600",
              color: "rgba(74, 74, 74, 0.8)",
              textDecoration: "none",
            }}
            onClick={addChecklistItem}
          >
            <i className="far fa-plus" style={{ marginRight: "5px" }}></i> Add
            an item
          </a>

          {/* <button className="button is-warning" onClick={addChecklistItem}>
            <span className="icon is-small">
              <i className="fal fa-plus"></i>
            </span>
            <span>Add a new checklist item</span>
          </button> */}
        </div>
        {state.currentJobInfo.tripChecklist.map((item, index) => {
          console.log({ item })
          if (item != undefined)
            return (
              <ChecklistCard
                key={index}
                data={item}
                onRemove={onRemove}
                canEdit={true}
              />
            )
        })}
        <div style={{ zIndex: "-5", position: "absolute", top: "50px" }}>
          {/* <img
            src={checklist}
            style={{ maxWidth: "400px", marginTop: "80px" }}
          ></img> */}
        </div>
      </div>
    </div>
  )
}

export default ChecklistCreator

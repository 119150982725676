import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"
import TripRecommendationsComponent from "./TripRecommendationsComponent"

const TripRecommendationsContainer = ({ canEdit }) => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  useEffect(() => {
    dispatch({ type: "initializeTempRecommendations" })
  }, [])
  const beginWork = e => {
    //Update the required state and db values
    try {
      //get all the date between the trip start and end dates.

      if (dbContext) {
        dbContext
          .firestore()
          .collection("jobs")
          .doc(`${authContext.uid}`)
          .collection("active-jobs")
          .doc(`${state.currentJobInfo.jobUid}`)
          .update({
            recommendationsStatus: "in-progress",
            recommendations: {},
          })
          .then(() => {
            console.log("document update successful")
            //update local state now
            dispatch({ type: "recommendationsInProgress" })
            // dispatch({
            //   type: "initializeDesignerWorkspaceForItineraries",
            //   data: a,
            // })

            //
          })
          .catch(e => console.log(e))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renderRightContent = () => {
    if (state.currentJobInfo.recommendationsStatus === "not-started")
      return (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <button className="button is-warning" onClick={beginWork}>
              Start trip recommendations
            </button>
          </div>
          {/* <div>
        <img
          src={checklist}
          style={{ maxWidth: "500px", marginTop: "80px" }}
        ></img>
      </div> */}
        </div>
      )
    // return (
    //   <TripRecommendationsComponent
    //     canEdit={canEdit}
    //     launchInEditMode={true}
    //   />
    // )
    else {
      if (state.currentJobInfo.recommendationsStatus === "in-progress")
        return (
          <TripRecommendationsComponent
            canEdit={canEdit}
            launchInEditMode={false}
          />
        )
      else {
        if (state.currentJobInfo.recommendationsStatus === "complete")
          return <TripRecommendationsComponent canEdit={canEdit} />
        else {
          return (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <button className="button is-warning" onClick={beginWork}>
                  Start trip recommendations
                </button>
              </div>
              {/* <div>
              <img
                src={checklist}
                style={{ maxWidth: "500px", marginTop: "80px" }}
              ></img>
            </div> */}
            </div>
          )
        }
      }
    }
  }
  return (
    <div>
      <div className="columns">
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-10-desktop is-10-tablet is-12-mobile">
          {" "}
          {renderRightContent()}
        </div>
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default TripRecommendationsContainer

import React, { useState, useEffect, useContext, useMemo } from "react"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"

import { DebounceInput } from "react-debounce-input"
import {
  useProfileStateStore,
  ProfileStateProvider,
} from "../../State/profile-state/profile-state"
import { ToastProvider, useToasts } from "react-toast-notifications"
import "./SearchDesigner.scss"

import Map from "../../components/Map/Map"

import { getCodes } from "../../utility/general"
import InstantSearchComponent from "./InstantSearchComponent"
import InstantSearchComponentSimple from "./InstantSearchComponentSimple"

const SearchDesignersBase = React.memo(
  ({ onSelect, destination, skillsAffinityMatrix }) => {
    const [state, dispatch] = useProfileStateStore()
    const [loading, setLoading] = useState(true)
    // const [toggleFilters, setToggleFilters] = useState(false)
    const { addToast } = useToasts()

    useEffect(() => {
      console.log("reloading")
    }, [])
    useEffect(() => {
      console.log({ state })
    }, [state])

    return (
      <div>
        <div className="columns is-mobile is-multiline">
          <div className="column is-12-desktop is-12-tablet is-12-mobile">
            {/* <InstantSearchComponent
              onSelect={onSelect}
              destination={destination}
              skillsAffinityMatrix={skillsAffinityMatrix}
            /> */}
            <InstantSearchComponentSimple
              onSelect={onSelect}
              destination={destination}
              skillsAffinityMatrix={skillsAffinityMatrix}
            />
          </div>
        </div>
      </div>
    )
  }
)

const SearchDesigners = ({ onSelect, destination, skillsAffinityMatrix }) => {
  let newDestination =
    destination === "United States of America" ? "USA" : destination
  return (
    <ToastProvider>
      <ProfileStateProvider>
        <div style={{ margin: "20px 0px" }}>
          {/* <div className="columns is-mobile">
            <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
            <div className="column is-8-desktop is-10-tablet is-12-mobile">
              {" "} */}
          <SearchDesignersBase
            onSelect={onSelect}
            destination={newDestination}
            skillsAffinityMatrix={skillsAffinityMatrix}
          />
          {/* </div>
            <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
          </div> */}
        </div>
      </ProfileStateProvider>
    </ToastProvider>
  )
}

export default SearchDesigners

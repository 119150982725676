import React, { useState, useEffect } from "react"
import AvatarEditor from "react-avatar-editor"
import "./AvatarEditorWrapper.scss"
export default function AvatarEditorWrapper({
  defaultImage,
  onCancel,
  onSave,
}) {
  const [sliderValue, setSliderValue] = useState(1.5)
  const [editor, setEditor] = useState()
  const [selectedImage, setSelectedImage] = useState("")

  const supportedAvatarFileType = [
    "image/jpg",
    "image/png",
    "image/jpeg",
    "image/gif",
  ]

  const onClickSave = () => {
    if (editor) {
      onSave(editor.getImageScaledToCanvas().toDataURL())
    }
  }
  let setEditorRef = editor => setEditor(editor)

  useEffect(() => {
    setSelectedImage(defaultImage)
    return () => {}
  }, [defaultImage])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AvatarEditor
        image={selectedImage}
        width={250}
        height={250}
        border={20}
        ref={setEditorRef}
        color={[255, 198, 0, 0.4]} // RGBA
        scale={sliderValue}
        rotate={0}
        borderRadius={5}
        crossOrigin="anonymous"
      />
      <div className="slidecontainer" style={{ width: "290px" }}>
        <input
          type="range"
          min="0.5"
          max="2.5"
          step="0.1"
          value={sliderValue}
          className="slider"
          id="myRange"
          onChange={e => {
            let value = e.target.value
            console.log(value)
            setSliderValue(value)
          }}
        />
      </div>

      <div className="fileSelector">
        <div align="center" className="field" style={{ margin: "15px" }}>
          <div className="file is-dark">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="resume"
                onChange={e => {
                  if (e) {
                    const file = e.target.files[0]
                    if (file) {
                      const { type } = file
                      console.log(type)
                      if (supportedAvatarFileType.includes(type)) {
                        setSelectedImage(file)
                      }
                    }
                  }
                }}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Choose File</span>
              </span>
            </label>
          </div>
        </div>
        {/* <input
          className=""
          type="file"
          //   name="resume"
          //accept="image/jpg, image/png, image/jpeg, image/gif"
          onChange={e => {
            if (e) {
              const file = e.target.files[0]
              if (file) {
                const { type } = file
                console.log(type)
                if (supportedAvatarFileType.includes(type)) {
                  setselectedImage(file)
                }
              }
            }
          }}
        /> */}

        <div
          className="field is-grouped"
          style={{
            width: "290px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "25px",
          }}
        >
          <div className="control ">
            <button className="button is-pulled-left" onClick={onCancel}>
              Cancel
            </button>
          </div>
          <div className="control">
            <button
              name=""
              className="button is-warning is-pulled-right"
              onClick={onClickSave}
            >
              Save profile photo
            </button>
          </div>
        </div>
      </div>

      {/* <div>{<img className="avatar" src={imageURL} alt="avatar" />}</div> */}
    </div>
  )
}

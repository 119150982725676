import React, { useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import AuthUserContext from "../../../components/session/context"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import Select from "react-select"
import {
  commuteMode,
  activityType,
  durationHours,
  durationMinutes,
} from "../../../constants/data/general"
import { titleCase } from "../../../utility/general"
import useRestrictCharacterLength from "../../../hooks/useRestrictCharacterLength"

export const NewCommuteForm = ({
  onCancel,
  activeTab,
  onCommuteCreated,
  inCommuteEditMode = false,
}) => {
  const [state, dispatch] = useDashboardStateStore()
  const dbContext = React.useContext(FirebaseContext)
  const authContext = React.useContext(AuthUserContext)
  const [isError, setIsError] = useState(false)
  const isCommuteTimeValid = params => {
    console.log(state.activityScratchPad.activityDurationHours)
    if (
      state.activityScratchPad.activityDurationHours === undefined &&
      state.activityScratchPad.activityDurationMinutes === undefined
    ) {
      return false
    } else return true
  }
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(200)

  return (
    <div className="columns">
      <div className="column  is-4-desktop is-2-tablet is-hidden-mobile "></div>
      <div className="column is-4-desktop is-8-tablet is-12-mobile">
        <div style={{ width: "100%" }} draggable="false">
          <h3
            align="center"
            style={{ marginTop: "26px" }}
            className="subtitle is-4"
          >
            Commute Details
          </h3>

          {/* Activity Type */}
          <div className="field">
            <label className="label">Commute Mode</label>
            <div className="control">
              <Select
                className="basic-single"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={{
                  value: `${state.activityScratchPad.commuteMode}`,
                  label: `${titleCase(state.activityScratchPad.commuteMode)}`,
                }}
                menuPortalTarget={document.body}
                classNamePrefix="select"
                isSearchable="true"
                isClearable="true"
                onChange={(value, actionMeta) => {
                  if (value)
                    dispatch({ type: "updateCommuteMode", data: value.value })
                  else dispatch({ type: "updateCommuteMode", data: "" })
                }}
                options={commuteMode}
              />
            </div>
          </div>

          {/* Activity How long? */}
          <label className="label">How long is the commute?</label>
          <div className="columns">
            <div className="column field is-half">
              <label className="label">Hours</label>
              <div className="control">
                <Select
                  className="basic-single"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  value={{
                    value: `${
                      state.activityScratchPad.activityDurationHours
                        ? state.activityScratchPad.activityDurationHours
                        : "0"
                    }`,
                    label: `${
                      state.activityScratchPad.activityDurationHours
                        ? state.activityScratchPad.activityDurationHours
                        : "0"
                    }`,
                  }}
                  menuPortalTarget={document.body}
                  classNamePrefix="select"
                  isSearchable="true"
                  isClearable="true"
                  onChange={(value, actionMeta) => {
                    setIsError(false)
                    dispatch({
                      type: "updateActivityDurationHours",
                      data: value ? value.value : 0,
                    })
                  }}
                  options={durationHours}
                />
                {isError && (
                  <p className="help is-danger">
                    Both Hours and Minutes cannot be zero
                  </p>
                )}
              </div>
            </div>
            <div className="column field is-half">
              <label className="label">Minutes</label>
              <div className="control">
                <Select
                  className="basic-single"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  value={{
                    value: `${
                      state.activityScratchPad.activityDurationMinutes
                        ? state.activityScratchPad.activityDurationMinutes
                        : "0"
                    }`,
                    label: `${
                      state.activityScratchPad.activityDurationMinutes
                        ? state.activityScratchPad.activityDurationMinutes
                        : "0"
                    }`,
                  }}
                  menuPortalTarget={document.body}
                  classNamePrefix="select"
                  isSearchable="true"
                  isClearable="true"
                  onChange={(value, actionMeta) => {
                    setIsError(false)
                    dispatch({
                      type: "updateActivityDurationMinutes",
                      data: value ? value.value : 0,
                    })
                  }}
                  options={durationMinutes}
                />
              </div>
            </div>
          </div>
          <div className="field" style={{ marginBottom: "20px" }}>
            <label className="label">Additional notes for this commute:</label>
            <div className="control is-expanded">
              <textarea
                onChange={e => {
                  if (check(e.currentTarget.value.length)) return
                  // console.log({ reachedMaxLength })
                  // if (reachedMaxLength) return

                  if (e.currentTarget.value) {
                    dispatch({
                      type: "updateCommuteAdditionalInfo",
                      data: e.currentTarget.value,
                    })
                  } else {
                    dispatch({
                      type: "updateCommuteAdditionalInfo",
                      data: "",
                    })
                  }
                }}
                value={state.activityScratchPad.commuteAdditionalInfo}
                className="textarea"
                // placeholder="Any additional info you would like to provi"
              ></textarea>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="help"></p>
                <p
                  className={`help is-pulled-right ${
                    reachedMaxLength ? "is-danger" : ""
                  }`}
                >
                  {/* Characters:{" "} */}
                  {state.activityScratchPad.commuteAdditionalInfo
                    ? state.activityScratchPad.commuteAdditionalInfo.length
                    : "0"}
                  /{maxCharactersAllowed}
                </p>
              </div>
              {/* {errors.accommodation ? (
                    <p className="help is-danger">
                      Please pick you accommodation preference
                    </p>
                  ) : (
                    <p className="help">pick all that apply</p>
                  )} */}
            </div>
          </div>

          {/* Activity buttons */}
          <div
            className="field is-grouped is-grouped-right"
            style={{ marginBottom: "40px" }}
          >
            <p className="control">
              <a
                className="button is-light"
                onClick={() => {
                  onCancel()
                }}
              >
                Cancel
              </a>
            </p>
            <p className="control">
              <a
                className="button is-warning"
                onClick={() => {
                  if (isCommuteTimeValid()) {
                    dispatch({
                      type: "updateActivity",
                      activeTab: activeTab,
                      activityUid: state.activityScratchPad.activityUid,
                    })
                    onCommuteCreated()
                    onCancel()
                  } else {
                    setIsError(true)
                  }
                }}
              >
                {inCommuteEditMode ? "Update Commute" : "Add Commute"}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="column is-4-desktop is-2-tablet is-hidden-mobile"></div>
    </div>
  )
}

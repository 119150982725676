import React, { useState, useEffect, useContext, useRef } from "react"
import "./TripCreation.scss"
import Details from "./Details"
import { useTripStateStore } from "../../State/trip-state/trip-state-store"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import swal from "sweetalert"
import EditDetails from "./EditDetails"
import { FirebaseContext } from "../firebase"
import AuthUserContext from "../session/context"
import { analytics } from "../firebase/firebase"

export function TravellerDetailsTableFormElements({
  state,
  dispatch,
  onNext,
  onPrevious,
}) {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [showAddCoTravelerForm, setShowAddCoTravelerForm] = useState(false)
  const [editTravelerForm, setEditTravelerForm] = useState(false)
  const [editTravelerIndex, setEditTravelerIndex] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(undefined)

  const [adultCount, setAdultCount] = useState(1)
  const [kidCount, setKidCount] = useState(0)
  const [additionalDetails, setAdditionalDetails] = useState("")
  const handleAddCoTraveler = e => {
    scrollToRef(editDetailsRef)
    setShowAddCoTravelerForm(!showAddCoTravelerForm)
  }
  const editDetailsRef = useRef(null)
  const handleEditTravelerForm = e => {
    setEditTravelerIndex(e.currentTarget.id)
    setEditTravelerForm(!editTravelerForm)
  }
  const setCancel = () => {
    window.scrollTo(0, 0)
    setShowAddCoTravelerForm(false)
  }
  const setEditCancel = () => {
    window.scrollTo(0, 0)
    setEditTravelerForm(false)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (!state.adultTravelerCount) {
      if (dispatch) {
        dispatch({
          type: "setAdultTravelerCount",
          data: 1,
        })
      }
    }
    if (!state.kidsTravelerCount) {
      if (dispatch) {
        dispatch({
          type: "setKidsTravelerCount",
          data: 0,
        })
      }
    }
  }, [])

  useEffect(() => {
    if (deleteIndex) {
      swal({
        title: "Are you sure?",
        //text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        //buttons: true,
        dangerMode: true,
        buttons: ["No, cancel it!", "Yes, I am sure!"],
      }).then(willDelete => {
        if (willDelete) {
          dispatch({
            type: "deleteCoTravelerEntry",
            data: Number(deleteIndex),
          })
          swal("Your entry has been deleted!", {
            icon: "success",
          })
        }
      })
    }
  }, [deleteIndex])

  const scrollToRef = ref => {
    try {
      window.scrollTo(0, ref.current.offsetTop)
    } catch (err) {
      console.log(err)
    }
  }
  const populateTable = () => {
    return state.travelerDetails.map(detail => (
      <Tr key={detail.index}>
        <Td className="" style={{ marginTop: "10px" }}>
          {detail.firstName + " " + detail.lastName}
        </Td>
        <Td className="">{detail.gender || "--"}</Td>
        <Td className="">{detail.age || "--"}</Td>
        <Td className="">{detail.diet || "--"}</Td>
        <Td className="">{detail.wheelchair || "--"}</Td>
        {/* <Td className="">{detail.additionalTravelerDetails || "--"}</Td> */}
        <Td id={detail.index} className="">
          <div
            className="link"
            id={detail.index}
            style={{
              textTransform: "uppercase",
              fontSize: "12px",
              // fontWeight: "600",
              letterSpacing: "0.5px",
            }}
            onClick={e =>
              swal({
                title: "Additional Details",
                text: `${detail.additionalTravelerDetails ||
                  "No additional details"}`,
                // icon: "info",
                button: "close",
              })
            }
          >
            View
          </div>
        </Td>
        <Td>
          <div className="field is-grouped is-grouped-right">
            {!detail.primary ? (
              <button
                id={detail.index}
                style={{
                  textTransform: "uppercase",
                  fontSize: "10px",
                  fontWeight: "600",
                  letterSpacing: "0.5px",
                  marginRight: "15px",
                }}
                className="button is-danger is-small is-light table-buttons"
                onClick={e => {
                  console.log(e.currentTarget.id)
                  setDeleteIndex(e.currentTarget.id)
                }}
              >
                Delete
              </button>
            ) : (
              ""
            )}
            {/* <p className="buttons">
              <a
                id={detail.index}
                className="button is-small"
                onClick={handleEditTravelerForm}
              >
                <span className="icon is-small">
                  <i className="fas fa-edit"></i>
                </span>
              </a>
            </p> */}

            <button
              id={detail.index}
              className="button is-dark is-small is-light table-buttons"
              // className="button is-text is-normal"
              onClick={handleEditTravelerForm}
              style={{
                textTransform: "uppercase",
                fontSize: "10px",
                fontWeight: "600",
                letterSpacing: "0.5px",
              }}
            >
              Edit Info
            </button>
          </div>
        </Td>
      </Tr>
    ))
  }
  return (
    <div className="columns is-multiline fade-in">
      <div className="column is-3-desktop is-1-tablet  is-hidden-mobile"></div>
      <div className="column is-6-desktop is-10-tablet is-12-mobile">
        <div style={{ textAlign: "left", marginBottom: "40px" }}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#fec600",
              letterSpacing: "0.5px",
              textAlign: "right",
            }}
          >
            3/3
          </p>
          <p
            style={{
              fontSize: "26px",
              fontWeight: "800",
              color: "#fec600",
              lineHeight: "30px",
              marginBottom: "15px",
            }}
          >
            Who'll be taking this trip with you?
          </p>
          <p
            style={{
              fontSize: "14px",
              marginBottom: "35px",
            }}
          >
            This will help your trip designer better curate your activities
          </p>
        </div>{" "}
        <label className="label">How many adults are going on the trip?</label>
        <div className="field has-addons">
          <div className="control">
            <a
              className="button is-warning"
              onClick={() => {
                if (state.adultTravelerCount <= 1) {
                  dispatch({
                    type: "setAdultTravelerCount",
                    data: 1,
                  })
                  return
                }
                dispatch({
                  type: "setAdultTravelerCount",
                  data: state.adultTravelerCount - 1,
                })
              }}
            >
              -
            </a>
          </div>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder=""
              value={state.adultTravelerCount}
              style={{ maxWidth: "40px", textAlign: "center" }}
            ></input>
          </div>
          <div className="control">
            <a
              className="button is-warning"
              onClick={() => {
                dispatch({
                  type: "setAdultTravelerCount",
                  data: state.adultTravelerCount + 1,
                })
              }}
            >
              +
            </a>
          </div>
        </div>
        <div className="field" style={{ marginTop: "20px" }}>
          <label className="label">How many kids are going on the trip?</label>
          <div className="field has-addons">
            <div className="control">
              <a
                className="button is-warning"
                onClick={() => {
                  if (state.kidsTravelerCount < 1) {
                    dispatch({
                      type: "setKidsTravelerCount",
                      data: 0,
                    })
                    return
                  }
                  dispatch({
                    type: "setKidsTravelerCount",
                    data: state.kidsTravelerCount - 1,
                  })
                }}
              >
                -
              </a>
            </div>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder=""
                value={state.kidsTravelerCount}
                style={{ maxWidth: "40px", textAlign: "center" }}
              ></input>
            </div>
            <div className="control">
              <a
                className="button is-warning"
                onClick={() => {
                  dispatch({
                    type: "setKidsTravelerCount",
                    data: state.kidsTravelerCount + 1,
                  })
                }}
              >
                +
              </a>
            </div>
          </div>
        </div>
        {/* <Table>
          <Thead>
            <Tr>
              <Th
                className="travelerDetails-Header"
                style={{ paddingBottom: "10px" }}
              >
                Name
              </Th>
              <Th
                className="travelerDetails-Header"
                style={{ paddingBottom: "10px" }}
              >
                Gender
              </Th>
              <Th
                className="travelerDetails-Header"
                style={{ paddingBottom: "10px" }}
              >
                Age
              </Th>
              <Th
                className="travelerDetails-Header"
                style={{ paddingBottom: "10px" }}
              >
                Diet
              </Th>
              <Th
                className="travelerDetails-Header"
                style={{ paddingBottom: "10px" }}
              >
                Wheelchair
              </Th>
              <Th
                className="travelerDetails-Header"
                style={{ paddingBottom: "10px" }}
              >
                Additional Details
              </Th>
            </Tr>
          </Thead>
          <Tbody>{populateTable()}</Tbody>
        </Table> */}
        {/* <hr className="hr-branded"></hr> */}
        {/* <div className="column is-12-desktop is-12-tablet"> */}
        <label className="label" style={{ marginTop: "40px" }}>
          Anything particular your designer should keep in mind about the
          travelers?
        </label>
        <textarea
          value={state.travelerAdditionalInfo}
          className="textarea"
          maxLength="750"
          rows="5"
          placeholder="Ages, special diets, handicapped access, kids nap times, etc."
          onChange={e => {
            dispatch({
              type: "setTravelerAdditionalInfo",
              data: e.currentTarget.value,
            })
          }}
        ></textarea>
        {/* </div> */}
        {/* {!showAddCoTravelerForm && !editTravelerForm && (
          <h3 align="center" className="subtitle is-3 align-center">
            <div className="control is-vertical-center">
              <button
                onClick={handleAddCoTraveler}
                className="button is-warning align-center"
                // disabled={editTravelerForm}
              >
                <span className="icon">
                  <i className="fas fa-user-plus"></i>
                </span>
                <span>Add a co-traveler</span>
              </button>
            </div>
          </h3>
        )} */}
        {showAddCoTravelerForm ? <Details setCancel={setCancel} /> : ""}
        {editTravelerForm ? (
          <div ref={editDetailsRef}>
            <EditDetails
              setEditCancel={setEditCancel}
              EditIndex={editTravelerIndex}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
      <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
      <div className="column is-6-desktop is-10-tablet is-12-mobile">
        {onNext && (
          <div className="columns is-mobile is-multiline">
            <div className="column is-12-desktop is-12-tablet is-12-mobile">
              <hr className="hr-branded"></hr>
            </div>
            <div className="column is-3-desktop is-4-tablet is-5-mobile">
              <button
                className="button"
                onClick={e => {
                  if (onPrevious) onPrevious()
                }}
              >
                <i
                  className="fas fa-angle-left"
                  style={{ marginRight: "5px" }}
                ></i>
                Preferences{" "}
              </button>
            </div>
            <div className="column is-6-desktop is-4-tablet is-2-mobile"></div>
            <div className="column is-3-desktop is-4-tablet is-5-mobile">
              <button
                className="button is-warning"
                style={{ float: "right" }}
                onClick={e => {
                  if (onNext) {
                    dbContext
                      .firestore()
                      .collection("trips")
                      .doc(`${state.uid}`)
                      .collection("active-trips")
                      .doc(`${state.tripUid}`)
                      .update(state)
                      .then(function() {
                        console.log("Trip successfully updated!")
                        onNext()
                      })
                      .catch(error => {
                        console.log("Error getting document:", error)
                      })
                    analytics.logEvent("wizard_trip_preferences", {
                      userId: `${authContext.uid}`,
                      tripId: `${state.tripUid}`,
                    })
                  }
                }}
              >
                Summary{" "}
                <i
                  className="fas fa-angle-right"
                  style={{ marginLeft: "5px" }}
                ></i>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
    </div>
  )
}

export default function TravellerDetailsTable({ onNext, onPrevious }) {
  const [state, dispatch] = useTripStateStore()

  return (
    <>
      <h3 align="center" className="subtitle is-3 align-center">
        {/* Traveler Details */}
      </h3>
      {/* <progress
        className="progress is-warning is-thin"
        value={state.wizardProgress}
        max="100"
      >
        25%
      </progress> */}

      <TravellerDetailsTableFormElements
        state={state}
        dispatch={dispatch}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </>
  )
}

import React from "react"
import { SortableElement } from "react-sortable-hoc"

export const CommuteCard = SortableElement(() => {
  return (
    <div className="activity-card-type-commute">
      <div className="top-connector"></div>
      <button className="button is-warning">Add Commute</button>
      <div className="bottom-connector"></div>
    </div>
  )
})

import {
  TripStateProvider,
  useTripStateStore,
} from "./trip-state/trip-state-store"
import {
  DashboardStateProvider,
  useDashboardStateStore,
} from "./dashboard-state/dashboard-state"
import React from "react"

export const TripDashboardCombinedProvider = ({ children }) => {
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripDispatch] = useTripStateStore()
  return (
    <TripStateProvider value={(tripState, tripDispatch)}>
      <DashboardStateProvider value={(state, dispatch)}>
        {children}
      </DashboardStateProvider>
    </TripStateProvider>
  )
}

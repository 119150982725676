import React, { useEffect, useState, useContext } from "react"
import { useTripStateStore } from "../../State/trip-state/trip-state-store"

const Confirm = () => {
  const [state, dispatch] = useTripStateStore()
  useEffect(() => {
    console.log({ state })
  }, [state])

  return (
    <div>
      <h3 align="center" className="subtitle is-3 align-center">
        Confirm
      </h3>
    </div>
  )
}

export default Confirm

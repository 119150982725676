import React from "react"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"

import { Tr, Td } from "react-super-responsive-table"
import moment from "moment"
function ReservationCard({ data, onEdit, canEdit }) {
  const [state, dispatch] = useDashboardStateStore()

  // // Returns date in this format 07-23-2020
  // const formatDate = () => {
  //   try {
  //     var reservationDate = data.dateTime.toDate()
  //     var yr = reservationDate.getFullYear()
  //     var d = reservationDate.getDate()
  //     var m = reservationDate.getMonth() + 1
  //     return `${m}-${d}-${yr}`
  //   } catch (error) {
  //     console.log(error)
  //     var reservationDate = new Date(data.dateTime)
  //     var yr = reservationDate.getFullYear()
  //     var d = reservationDate.getDate()
  //     var m = reservationDate.getMonth() + 1
  //     return `${m}-${d}-${yr}`
  //   }
  // }
  const formatDateAlt = () => {
    try {
      return typeof data.dateTime === "string"
        ? moment.utc(new Date(data.dateTime)).format("MM/DD/YYYY")
        : new Intl.DateTimeFormat("en-US", dateOptions).format(
            data.dateTime.toDate()
          )
    } catch (error) {
      console.log(error)
      return new Intl.DateTimeFormat("en-US", dateOptions).format(data.dateTime)
    }
  }
  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
  }
  // // Returns time in the this format 15:34
  // const formatTime = () => {
  //   try {
  //     var reservationDate = data.dateTime.toDate()
  //     var hr = reservationDate.getHours()
  //     var min = reservationDate.getMinutes()
  //     return `${hr}:${min}`
  //   } catch (error) {
  //     console.log(error)
  //     var reservationDate = new Date(data.dateTime)
  //     var hr = reservationDate.getHours()
  //     var min = reservationDate.getMinutes()
  //     return `${hr}:${min}`
  //   }
  // }
  const formatTimeAlt = () => {
    try {
      return typeof data.dateTime === "string"
        ? moment.utc(data.dateTime).format("HH:mm")
        : new Intl.DateTimeFormat("en-US", timeOptions).format(
            data.dateTime.toDate()
          )
    } catch (error) {
      console.log(error)
      return new Intl.DateTimeFormat("en-US", timeOptions).format(data.dateTime)
    }
  }
  return (
    <>
      <div className="reservation-card-container">
        <div style={{ padding: "5px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="reservation-date">
              <strong>Date:</strong> {formatDateAlt()} <br></br>{" "}
              <strong>Time:</strong> {formatTimeAlt()}
            </div>
            {canEdit ? (
              <div>
                <a
                  id={data.index}
                  style={{
                    paddingRight: "5px",
                    marginRight: "5px",
                    color: "#4a4a4a",
                    //bottom: "6px",
                  }}
                  className=""
                  onClick={onEdit}
                >
                  Edit
                </a>
                <a
                  className="is-small"
                  id={data.index}
                  onClick={e => {
                    dispatch({
                      type: "deleteReservation",
                      index: e.currentTarget.id,
                    })
                    dispatch({
                      type: "tripReservationSyncRequired",
                      data: true,
                    })
                  }}
                  style={{
                    // paddingRight: "2px",
                    // marginRight: "5px",
                    // top: "10px",
                    color: "#4a4a4a",
                  }}
                >
                  <i className="far fa-trash-alt delete-icon-color"></i>
                </a>{" "}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="reservation-primary-name">{data.name || "--"}</div>
          <div className="reservation-info">
            <span>{data.additionalNote || " "}</span>
          </div>
          <div className="reservation-confirmation">
            <strong style={{ fontSize: "14px" }}>Confirmation No: </strong>
            <span style={{ letterSpacing: "0.5px" }}>
              {data.confirmationNumber || "--"}
            </span>
          </div>
        </div>
      </div>
      {/* <Tr key={data.index}>
        <Td className="">{formatDateAlt()}</Td>
        <Td className="">{formatTimeAlt()}</Td>
        <Td className="">{data.confirmationNumber || "--"}</Td>
        <Td className="">{data.name || "--"}</Td>
        <Td className="">{data.additionalNote || "--"}</Td>
        {canEdit ? (
          <>
            <Td className="">
              <a
                id={data.index}
                style={{
                  paddingRight: "5px",
                  marginRight: "5px",
                  bottom: "6px",
                }}
                className=""
                onClick={onEdit}
              >
                Edit
              </a>
            </Td>

            <Td className="">
              <a
                className="is-small"
                id={data.index}
                onClick={e => {
                  dispatch({
                    type: "deleteReservation",
                    index: e.currentTarget.id,
                  })
                  dispatch({ type: "tripReservationSyncRequired", data: true })
                }}
                style={{
                  paddingRight: "2px",
                  marginRight: "5px",
                  top: "10px",
                }}
              >
                <i className="far fa-trash-alt delete-icon-color"></i>
              </a>
            </Td>
          </>
        ) : (
          ""
        )}
      </Tr> */}
    </>
  )
}

export default ReservationCard

export const dbUpdate = (dbContext, collection, uid, Obj) => {
  dbContext
    .firestore()
    .collection(collection)
    .doc(`${uid}`)
    .update(Obj)
    .then(function() {
      console.log("Document successfully updated!")
    })
    .catch(error => {
      console.log("Error getting document:", error)
    })
}
export const dbCreateNewTrip = (dbContext, collection, uid, Obj) => {
  dbContext
    .firestore()
    .collection("active-trips")
    .doc(`${uid}`)
    .collection(collection)
    .set(Obj)
    .then(function() {
      console.log("Document successfully updated!")
    })
    .catch(error => {
      console.log("Error getting document:", error)
    })
}

export const dbGet = (dbContext, collection, uid) => {
  return dbContext
    .firestore()
    .collection(collection)
    .doc(`${uid}`)
    .get()
}

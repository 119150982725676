import React, { useState, useEffect } from "react"
import "./GoTooTop.scss"
const GoToTop = ({ scrollStepInPx, delayInMs }) => {
  const [position, setPosition] = useState(false)
  const [interval, setInterval] = useState(0)
  useEffect(() => {
    document.addEventListener("scroll", positionSetter)
    window.scrollTo(0, 0)
    return () => window.removeEventListener("scroll", positionSetter)
  }, [])

  const positionSetter = e => {
    if (window.scrollY > 170) {
      setPosition(true)
    } else {
      setPosition(false)
    }
  }

  function onScrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(interval)
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx)
  }

  const scrollToTop = () => {
    // let intervalId = setTimeout(onScrollStep, delayInMs)
    // setTimeout(intervalId)
    window.scrollTo(0, 0)
  }

  const renderGoTopIcon = () => {
    if (position) {
      return ""
      // <div className="go-top" onClick={scrollToTop}>
      //   <i className="fas fa-angle-double-up"></i>
      // </div>
    }
  }
  return <>{renderGoTopIcon()}</>
}

export default GoToTop

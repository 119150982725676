import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import Textarea from "react-expanding-textarea"
import {
  useDashboardStateStore,
  DashboardStateProvider,
} from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"

import nochat from "./../../../images/no-chat.png"
import "./Messages.scss"
import { v4 as uuidv4 } from "uuid"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
import {
  ToastProvider,
  useToasts,
} from "react-toast-notifications/dist/ToastProvider"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import Carousel, { Modal, ModalGateway } from "react-images"
import { dottedText } from "../../../utility/general"

const MessageActivityComponentBase = ({ roomId, recipientUid }) => {
  const { addToast } = useToasts()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [chatText, setChatText] = useState("")
  const [reachedTop, setReachedTop] = useState(false)
  const [lastVisible, setLastVisible] = useState(0)
  const [receiverInfo, setReceiverInfo] = useState("")
  const [recipientPresence, setRecipientPresence] = useState("")
  const [progressValue, setProgressValue] = useState(0)
  const [isBusy, setIsBusy] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const [uploadedFilesList, setUploadedFilesList] = useState([])
  const [maxAttachmentCountReached, setMaxAttachmentCountReached] = useState(
    false
  )
  const [paddingValue, setPaddingValue] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const MAX_ATTACHMENTS_COUNT = 5
  useEffect(() => {
    //console.log({ roomId })
    dispatch({ type: "initChatMsgStore" })
    scroll()
    var element = document.getElementById("chat-scroll-view")
    element.addEventListener("scroll", function(event) {
      var element = event.target
      if (element.scrollTop === 0) {
        // Todo -> Show the "Load More..." button here.
        console.log("Scroll reached top")
        setReachedTop(true)
      } else {
        setReachedTop(false)
      }
    })
  }, [])

  useEffect(() => {
    console.log({ state })
  }, [state])

  const disableAttachments = () => {
    let b = document.getElementById("chat-attachment")
    if (b) b.classList.add("chat-send-disabled")
  }
  const enableAttachments = () => {
    let b = document.getElementById("chat-attachment")
    if (b) b.classList.remove("chat-send-disabled")
  }
  const disableChatSend = () => {
    let b = document.getElementById("chat-send")
    if (b) b.classList.add("chat-send-disabled")
  }
  const enableChatSend = () => {
    if (chatText !== "") {
      let b = document.getElementById("chat-send")
      if (b) b.classList.remove("chat-send-disabled")
    }
  }

  useEffect(() => {
    console.log({ uploadedFilesList })

    if (uploadedFilesList.length >= MAX_ATTACHMENTS_COUNT) {
      disableAttachments()
      setMaxAttachmentCountReached(true)
    } else {
      enableAttachments()
      setMaxAttachmentCountReached(false)
    }

    if (uploadedFilesList.length > 0) {
      let b = document.getElementById("chat-send")
      b.classList.remove("chat-send-disabled")
      // } else {
      //   if (b) b.classList.add("chat-send-disabled")
      // }
    } else {
      if (chatText === "") {
        let b = document.getElementById("chat-send")
        b.classList.add("chat-send-disabled")
      } else {
        let b = document.getElementById("chat-send")
        b.classList.remove("chat-send-disabled")
      }
    }
    // return uploadedFilesList.length * 30
    let a = document.getElementById("file-list")
    if (a) {
      let h = a.offsetHeight
      console.log({ h })
      setPaddingValue(h)
    } else setPaddingValue(1 * 40)
  }, [uploadedFilesList])

  useEffect(() => {
    try {
      let b = document.getElementById("chat-send")
      if (b) b.classList.add("chat-send-disabled")
    } catch (err) {}
  }, [])
  useEffect(() => {
    console.log({ globalState })
  }, [globalState])

  const pageSize = 20
  useEffect(() => {
    try {
      var lastMessageDate = ""
      console.log(roomId, recipientUid) // This variable is used to check the time stamp the of the chat messages and place them accordingly in the messageStore array.
      if (roomId !== undefined) {
        //Now fetch the messages from the actual messages location i.e.
        //  -> /chatRooms/{roomID}/messages/{msgId}/
        if (dbContext) {
          dbContext
            .database()
            .ref("/status/" + recipientUid)
            .on("value", snapshot => {
              // console.log(snapshot.val())
              if (snapshot.val()) {
                var a = snapshot.val().state || "offline"
                setRecipientPresence(a)
                // console.log({ a })
              } else setRecipientPresence("")
            })
        }
        if (dbContext) {
          var unsubscribe = dbContext
            .firestore()
            .collection("chatRooms")
            .doc(`${roomId}`)
            .collection("messages")
            .orderBy("sentAt", "desc")
            .limit(pageSize)
            // .get()
            // .then(function(querySnapshot) {
            //   querySnapshot.forEach(function(doc) {
            //     // doc.data() is never undefined for query doc snapshots
            //     console.log("#$%@#$%^#$%^")
            //     console.log(doc.id, " => ", doc.data())
            //   })
            // })
            .onSnapshot(function(snapshot) {
              setLastVisible(snapshot.docs[snapshot.docs.length - 1])
              snapshot.docChanges().forEach(function(change) {
                console.log(change.doc.data())
                if (change.type === "added") {
                  //This is to make sure the message direction is right. The initial messages during load have to be in one direction and later on types messages in another direction.
                  if (lastMessageDate === "") {
                    dispatch({
                      type: "updateChatMsgUnShift",
                      data: change.doc.data(),
                    })
                    lastMessageDate = change.doc.data().sentAt
                  } else {
                    //Pushing new messages here
                    if (change.doc.data().sentAt > lastMessageDate) {
                      dispatch({
                        type: "updateChatMsgPush",
                        data: change.doc.data(),
                      })
                      lastMessageDate = change.doc.data().sentAt
                    } else {
                      // Unshifting messages here.
                      dispatch({
                        type: "updateChatMsgUnShift",
                        data: change.doc.data(),
                      })
                      lastMessageDate = change.doc.data().sentAt
                    }
                  }
                }
              })
            })
        }
      }
    } catch (error) {
      console.log(error)
    }
    return () => {
      if (unsubscribe) unsubscribe()
      if (dbContext) {
        dbContext
          .database()
          .ref("/status/" + recipientUid)
          .off()
      }
    }
  }, [dbContext])

  const loadNext = params => {
    try {
      if (dbContext) {
        var unsubscribe = dbContext
          .firestore()
          .collection("chatRooms")
          .doc(`${roomId}`)
          .collection("messages")
          .orderBy("sentAt", "desc")
          .startAfter(lastVisible)
          .limit(pageSize)
          .get()
          .then(function(querySnapshot) {
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
            querySnapshot.forEach(function(doc) {
              // if (doc.data().sentAt > lastMessageDate) {
              // dispatch({
              //   type: "updateChatMsgPush",
              //   data: doc.data(),
              // })
              //lastMessageDate = doc.data().sentAt
              // } else {
              //   // Unshifting messages here.
              dispatch({
                type: "updateChatMsgUnShift",
                data: doc.data(),
              })
              //   lastMessageDate = doc.data().sentAt
              // }
            })
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    scroll()
  }, [state.messageStore])
  useEffect(() => {
    getRecipientName()
  }, [state.messageStore])

  const pushMessageToDB = messageObject => {
    if (roomId !== undefined) {
      if (dbContext) {
        const chatRoomRef = dbContext
          .firestore()
          .collection("chatRooms")
          .doc(`${roomId}`)

        const chatMessagesRef = dbContext
          .firestore()
          .collection("chatRooms")
          .doc(`${roomId}`)
          .collection("messages")
          .doc(`${messageObject.messageUid}`)

        const notificationRef = dbContext
          .firestore()
          .collection("users")
          .doc(`${recipientUid}`)
          .collection("publicNotifications")
          .doc(`${recipientUid}`)

        const batch = dbContext.firestore().batch()
        batch.set(
          chatRoomRef,
          { modifiedOn: new Date(), modifiedBy: messageObject.creatorInfo.uid },
          { merge: true }
        )
        batch.set(chatMessagesRef, messageObject)
        batch.set(notificationRef, { showNewMessage: true }, { merge: true })

        batch
          .commit()
          .then(() => {
            console.log("Message successfully written!")
          })
          .catch(error => {
            console.log(error)
          })
        // dbContext
        //   .firestore()
        //   .collection("chatRooms")
        //   .doc(`${roomId}`)
        //   .collection("messages")
        //   .doc(`${messageObject.messageUid}`)
        //   .set(messageObject)
        //   .then(function() {
        //     console.log("Document successfully written!")

        //     //set the notification flag on
        //     dbContext
        //       .firestore()
        //       .collection("users")
        //       .doc(`${recipientUid}`)
        //       .collection("publicNotifications")
        //       .doc(`${recipientUid}`)
        //       .update({ showNewMessage: true })
        //   })
        //   .catch(function(error) {
        //     console.error("Error writing document: ", error)
        //   })
      }
    }
  }

  // var Data = [
  //   {
  //     message:
  //       "  Hello there! how are you doing? Thanks for taking up this project. Very excited to see your plan!",
  //     sentAt: new Date(),
  //     creatorInfo: {
  //       name: "Vijay",
  //       Uid: "2345234520394587230498",
  //       profileUrl: "http:\\imagelocation.com",
  //     },
  //   },
  //   {
  //     message:
  //       " I am doing fine, how about you? Yes looking forward to plan your trip. Wish you an awesome vacation in advance. Feel free to message me if you need to update me with any further details!",
  //     sentAt: new Date(),
  //     creatorInfo: {
  //       name: "Kathy",
  //       Uid: "785678573451423463576567",
  //       profileUrl: "http:\\imagelocation.com",
  //     },
  //   },
  //   {
  //     message: "yay!!",
  //     sentAt: new Date(),
  //     creatorInfo: {
  //       name: "Vijay",
  //       Uid: "2345234520394587230498",
  //       profileUrl: "http:\\imagelocation.com",
  //     },
  //   },
  //   {
  //     message:
  //       "btw I love you destination choice! its going to be beautiful that time of the year!",
  //     sentAt: new Date(),
  //     creatorInfo: {
  //       name: "Kathy",
  //       Uid: "785678573451423463576567",
  //       profileUrl: "http:\\imagelocation.com",
  //     },
  //   },
  //   {
  //     message: "oh cool! thanks for the info!",
  //     sentAt: new Date(),
  //     creatorInfo: {
  //       name: "Vijay",
  //       Uid: "2345234520394587230498",
  //       profileUrl: "http:\\imagelocation.com",
  //     },
  //   },
  // ]

  const getThumbnailUrl = (fileUrl, fileName) => {
    try {
      if (fileUrl) {
        const regex = /\.[0-9a-z]+$/gm

        const str = fileUrl
        let m
        var ext = ""
        while ((m = regex.exec(str)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
            regex.lastIndex++
          }

          // The result can be accessed through the `m`-variable.
          m.forEach((match, groupIndex) => {
            console.log(`Found match: ${match}`)
            ext = match
          })
        }
        let a = fileName.replace(ext, "")
        var thumb_file_name = `${a}_200x200${ext}`
        let thumb_url = fileUrl.replace(fileName, thumb_file_name)
        console.log({ thumb_url })
        return thumb_url
      }
    } catch (err) {}
  }

  const renderMessages = () => {
    try {
      if (state.messageStore != undefined && authContext !== undefined) {
        if (state.messageStore.length === 0) {
          return (
            <div
              className="center"
              style={{
                width: "20%",
                minWidth: "200px",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <img src={nochat} alt="" />
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "300",
                  fontSize: "18px",
                }}
              >
                {/* - No messages yet - */}
              </p>
            </div>
          )
        } else
          return state.messageStore.map((item, index) => {
            if (item !== undefined) {
              if (item.creatorInfo.uid === authContext.uid)
                return (
                  <div
                    key={item.messageUid}
                    id={index}
                    className="message-container-self"
                  >
                    <div className="date-time-stamp">
                      {`You, ${new Intl.DateTimeFormat("en-US", options).format(
                        item.sentAt.toDate()
                      )}`}
                    </div>
                    <div className="chat-balloon-self">
                      <p
                        className="chat-message"
                        style={{ wordBreak: "break-word", marginBottom: "5px" }}
                      >
                        {item.message}
                      </p>
                      {item.messageAttachments
                        ? item.messageAttachments.map(attachment => {
                            return (
                              <>
                                {/* <div
                                  style={{ width: "100px", height: "100px" }}
                                >
                                  <img
                                    src={getThumbnailUrl(
                                      attachment.downloadPath,
                                      attachment.fileName
                                    )}
                                    alt=""
                                  />
                                </div> */}
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    cursor: "pointer",
                                    height: "auto",
                                    padding: "5px 7px",
                                  }}
                                  className="tag is-light"
                                  onClick={() => {
                                    setCurrentImage(attachment.downloadPath)
                                    setViewerIsOpen(true)
                                  }}
                                >
                                  <i
                                    className="fal fa-paperclip fa-lg"
                                    style={{
                                      color: "#4a4a4a",
                                      marginRight: "7px",
                                    }}
                                  ></i>
                                  <p style={{ whiteSpace: "initial" }}>
                                    {attachment.fileName}
                                  </p>

                                  {/* <a
                                    style={{
                                      color: "#4a4a4a",
                                      marginLeft: "7px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setCurrentImage(attachment.downloadPath)
                                      setViewerIsOpen(true)
                                    }}
                                  >
                                    view
                                  </a> */}
                                  {/* <a
                                  href={attachment.downloadPath}
                                  target="_blank"
                                  download
                                >
                                  <i
                                    className="fal fa-arrow-to-bottom"
                                    style={{
                                      color: "#4a4a4a",
                                      marginLeft: "7px",
                                      cursor: "pointer",
                                    }}
                                    // onClick={() => {
                                    //   downloadFileAttachment(
                                    //     attachment.downloadPath
                                    //   )
                                    //   // deleteUploadedFile(attachment.fileName)
                                    // }}
                                  ></i>
                                </a> */}
                                </div>
                              </>
                            )
                          })
                        : ""}
                    </div>
                    {/* <div className="avatar-self-edit">
                      <span style={{ fontSize: "10px" }}>Edit</span>
                    </div> */}

                    <div className="avatar-self">
                      <img src={item.creatorInfo.profileUrl} alt="" />
                    </div>
                  </div>
                )
              else
                return (
                  <div
                    key={item.messageUid}
                    id={index}
                    className="message-container-recipient"
                  >
                    <div className="date-time-stamp">
                      <p>{`${item.creatorInfo.name}, ${new Intl.DateTimeFormat(
                        "en-US",
                        options
                      ).format(item.sentAt.toDate())}`}</p>
                    </div>
                    <div className="chat-balloon-recipient">
                      <p
                        className="chat-message"
                        style={{ wordBreak: "break-word", marginBottom: "5px" }}
                      >
                        {item.message}
                      </p>
                      {item.messageAttachments
                        ? item.messageAttachments.map(attachment => {
                            return (
                              <>
                                {/* <div
                                  style={{ width: "100px", height: "100px" }}
                                >
                                  <img
                                    src={getThumbnailUrl(
                                      attachment.downloadPath,
                                      attachment.fileName
                                    )}
                                    alt=""
                                  />
                                </div> */}
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    cursor: "pointer",
                                    height: "auto",
                                    padding: "5px 7px",
                                  }}
                                  className="tag is-warning"
                                  onClick={() => {
                                    setCurrentImage(attachment.downloadPath)
                                    setViewerIsOpen(true)
                                  }}
                                >
                                  <i
                                    className="far fa-image"
                                    style={{
                                      color: "#4a4a4a",
                                      marginRight: "7px",
                                    }}
                                  ></i>
                                  <p style={{ whiteSpace: "initial" }}>
                                    {attachment.fileName}
                                  </p>

                                  {/* <a
                                    style={{
                                      color: "#4a4a4a",
                                      marginLeft: "7px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setCurrentImage(attachment.downloadPath)
                                      setViewerIsOpen(true)
                                    }}
                                  >
                                    view
                                  </a> */}
                                  {/* <a
                                  href={attachment.downloadPath}
                                  target="_blank"
                                  download
                                >
                                  <i
                                    className="fal fa-arrow-to-bottom"
                                    style={{
                                      color: "#4a4a4a",
                                      marginLeft: "7px",
                                      cursor: "pointer",
                                    }}
                                    // onClick={() => {
                                    //   downloadFileAttachment(
                                    //     attachment.downloadPath
                                    //   )
                                    //   // deleteUploadedFile(attachment.fileName)
                                    // }}
                                  ></i>
                                </a> */}
                                </div>
                              </>
                            )
                          })
                        : ""}
                    </div>
                    <div className="avatar-recipient">
                      <img src={item.creatorInfo.profileUrl} alt="" />
                    </div>
                  </div>
                )
            }
          })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const isInvalidText = str => {
    const regex = /(^ *$)/gm //check for spaces e,g, " ", "     " -> will be filtered out. "  sdfsdf" & "sdf   " -> are ok
    var BreakException = {}
    try {
      let m
      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }
        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          throw BreakException // Have to do this as from what I found out that there is no clean way to exit a forEach loop in JS
        })
      }
    } catch (e) {
      if (e === BreakException) return true
    }
    return false
  }
  const addAttachment = params => {
    console.log("add attachment")
  }

  const addChat = e => {
    // filter
    // if (isInvalidText(chatText)) {
    //   setChatText("")
    //   // scroll()
    //   return
    // }
    if (globalState && globalState.currentUserData) {
      var newMessageObject = {
        message: chatText,
        messageAttachments: uploadedFilesList,
        sentAt: new Date(),
        messageUid: uuidv4(),
        roomId: roomId,
        // creatorInfo: {
        //   name: authContext.displayName,
        //   uid: authContext.uid,
        //   profileUrl: authContext.photoURL,
        // },
        creatorInfo: {
          name:
            globalState.currentUserData.firstName === ""
              ? globalState.currentUserData.userName
              : globalState.currentUserData.firstName,
          uid: authContext.uid,
          profileUrl: globalState.currentUserData.userProfileImage,
        },
      }
      pushMessageToDB(newMessageObject)
      setChatText("")
      setUploadedFilesList([])
      scroll()
    }
  }

  //This is to handle "Enter"  key press on chat input window.
  const keyChanged = e => {
    try {
      switch (e.keyCode) {
        case 13: //ENTER
          addChat(e)
          break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }

  // Helps to scroll to the latest chat message.
  const scroll = params => {
    if (state.messageStore != undefined) {
      var element = document.getElementById(`${state.messageStore.length - 1}`)
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          alignToTop: true,
          block: "end",
          inline: "nearest",
        })
      }
    }
  }

  const getRecipientName = params => {
    setReceiverInfo("Recipient")
    if (state.messageStore != undefined && authContext !== undefined) {
      return state.messageStore.map((item, index) => {
        if (item !== undefined) {
          if (item.creatorInfo.uid !== authContext.uid) {
            item.creatorInfo.name
              ? setReceiverInfo(item.creatorInfo.name)
              : setReceiverInfo("Recipient")
          }
        }
      })
    } else setReceiverInfo("Recipient")
  }

  const deleteUploadedFile = fileName => {
    let foundIndex = uploadedFilesList.findIndex(
      item => item.fileName === fileName
    )
    if (foundIndex > -1) {
      let itemToBeDeleted = uploadedFilesList[foundIndex]
      let fileUrl = itemToBeDeleted.storageFilePath
      if (fileUrl) {
        const regex = /\.[0-9a-z]+$/gm
        var storageRef = dbContext.storage().ref()
        var fileRef = storageRef.child(fileUrl)
        const str = fileUrl
        let m
        var ext = ""
        while ((m = regex.exec(str)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
            regex.lastIndex++
          }

          // The result can be accessed through the `m`-variable.
          m.forEach((match, groupIndex) => {
            console.log(`Found match: ${match}`)
            ext = match
          })
        }
        var thumb_to_delete = fileUrl.replace(ext, `_200x200${ext}`)
        // Delete the file using the delete() method
        fileRef
          .delete()
          .then(function() {
            // File deleted successfully
            console.log("File Deleted")

            if (uploadedFilesList.length === 1) {
              setUploadedFilesList([])
            } else {
              let a = uploadedFilesList
              a.splice(foundIndex, 1)
              console.log({ a })
              setUploadedFilesList([...a])
            }
            var fileThumbnailRef = storageRef.child(thumb_to_delete)
            fileThumbnailRef
              .delete()
              .then(function() {
                console.log("Thumbnail Deleted")
              })
              .catch(function(error) {
                // Some Error occurred
              })
          })
          .catch(function(error) {
            // Some Error occurred
          })
      }
    }
  }

  const downloadFileAttachment = fileUrl => {
    try {
      if (fileUrl) {
        var a = document.createElement("a")

        a.href = fileUrl
        // a.download = "travel.pdf"
        document.body.appendChild(a)
        a.click()
        setTimeout(function() {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(fileUrl)
        }, 0)
      }
    } catch (error) {}
  }

  const onUpload = filesList => {
    var storageRef = dbContext.storage().ref()
    const regex = /\.[0-9a-z]+$/gm
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"]
    let m
    var ext = ""
    console.log({ filesList })
    disableAttachments()
    disableChatSend()
    if (filesList.length > 0)
      [...filesList].forEach(fileObject => {
        const str = fileObject.name
        if (!acceptedImageTypes.includes(fileObject.type)) {
          addToast("File type not supported. Only images are supported", {
            appearance: "error",
            autoDismiss: true,
          })
          setIsBusy(true)
          setShowProgress(false)
          setProgressValue(0)
          enableAttachments()
          enableChatSend()
          return
        }
        if (fileObject.size > 4194304) {
          addToast("File too big. Maximum file size allowed is 4Mb", {
            appearance: "error",
            autoDismiss: true,
          })
          setIsBusy(true)
          setShowProgress(false)
          setProgressValue(0)
          enableAttachments()
          enableChatSend()
          return
        }
        while ((m = regex.exec(str)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
            regex.lastIndex++
          }

          // The result can be accessed through the `m`-variable.
          m.forEach((match, groupIndex) => {
            console.log(`Found match: ${match}`)
            ext = match
          })
        }
        let filePrefix = uuidv4()
        var fileName = `${fileObject.name}`
        let size = fileObject.size
        console.log({ fileName })
        let storageFilePath = `chat-attachments/fileId_${filePrefix}*_*userId_${authContext.uid}*_*${fileName}`
        var uploadTask = storageRef
          .child(
            `chat-attachments/fileId_${filePrefix}*_*userId_${authContext.uid}*_*${fileName}`
          )
          .put(fileObject)

        uploadTask.on(
          dbContext.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log("Upload is " + progress + "% done")
            setShowProgress(true)
            setProgressValue(Number(progress))
            switch (snapshot.state) {
              case dbContext.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused")
                break
              case dbContext.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running")
                break
            }
          },
          function(error) {
            console.log(error)
            setShowProgress(false)
            setProgressValue(0)
            enableAttachments()
            enableChatSend()
            addToast("Error uploading the file, please try again in sometime", {
              appearance: "error",
              autoDismiss: true,
            })
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break
              case "storage/canceled":
                // User canceled the upload
                break
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break
            }
          },
          function() {
            // Upload completed successfully, now we can get the download URL

            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                console.log({ downloadURL })
                setIsBusy(true)
                setShowProgress(false)
                setProgressValue(0)
                var listRef = storageRef.child(
                  `user-data/${authContext.uid}/designerApplication/`
                )
                // updateFiles()
                setUploadedFilesList([
                  ...uploadedFilesList,
                  {
                    fileName: fileName,
                    size: size,
                    type: fileObject.type,
                    downloadPath: downloadURL,
                    storageFilePath: storageFilePath,
                  },
                ])
                enableAttachments()
                enableChatSend()
              })
          }
        )
      })
  }

  const statusTag = _status => {
    switch (_status) {
      case "offline":
        return (
          <span className="tag is-light" style={{ marginLeft: "10px" }}>
            Offline
          </span>
        )

      case "online":
        return (
          <span
            className="tag is-success is-light"
            style={{ marginLeft: "10px" }}
          >
            Online
          </span>
        )
      case "away":
        return (
          <span
            className="tag is-warning is-light"
            style={{ marginLeft: "10px" }}
          >
            Away
          </span>
        )
      default:
        break
    }
  }
  const getPaddingValue = () => {
    try {
      // return uploadedFilesList.length * 30
      let a = document.getElementById("file-list")
      if (a) {
        let h = a.offsetHeight
        console.log({ h })
        return h
      } else return 1 * 40
    } catch (error) {}
  }

  const showUploadFilesList = () => {
    try {
      if (uploadedFilesList && uploadedFilesList.length > 0) {
        return uploadedFilesList.map(item => {
          return (
            <div
              style={{ marginBottom: "5px", marginRight: "5px" }}
              className="tag is-small is-warning"
            >
              <i
                className="far fa-paperclip"
                style={{ color: "#4a4a4a", marginRight: "7px" }}
              ></i>
              {dottedText(item.fileName, 5)}
              <i
                className="fal fa-times"
                style={{
                  color: "#4a4a4a",
                  marginLeft: "7px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  deleteUploadedFile(item.fileName)
                }}
              ></i>
            </div>
          )
        })
      } else return ""
    } catch (err) {}
  }
  const closeLightbox = () => {
    setViewerIsOpen(false)
  }

  //Todo - Add support for loading more messages once this button is clicked. Only show the "load more" button when scroll reaches top!
  return (
    // <div className="columns">
    //   {/* <button className="button" onClick={scroll}>
    //     Scroll
    //   </button> */}
    //   <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
    //   <div className="column is-8-desktop is-10-tablet is-12-mobile">
    <>
      {/* {true && (
        <div className="modal">
          <div className="modal-background"></div>
          <div className="modal-content">
            <p className="image is-4by3">
              <img src={currentImage} alt="" />
            </p>
          </div>
          <button
            className="modal-close is-large"
            onClick={() => {
              setViewerIsOpen(false)
            }}
            aria-label="close"
          ></button>
        </div>
      )} */}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <>
              <img src={currentImage} alt="" />
              <button
                onClick={() => {
                  setViewerIsOpen(false)
                }}
                className="button"
                style={{
                  borderRadius: "50%",
                  position: "absolute",
                  right: "50px",
                  top: "50px",
                  border: "4px solid white",
                  width: "50px",
                  height: "50px",
                  background: "#fec600",
                  boxShadow: "0 3px 15px 0 rgba(0, 0, 0, 0.25) !important",
                }}
              >
                <i
                  className="fas fa-times fa-2x"
                  style={{ color: "white" }}
                ></i>
              </button>
            </>
          </Modal>
        ) : null}
      </ModalGateway>
      <div className="chat-container">
        {/* <div>
        <p>Send a message to your client</p>
      </div> */}
        <div style={{ display: "flex", margin: "1%" }}>
          <p>{receiverInfo}</p>
          {statusTag(recipientPresence)}
        </div>
        <div id="chat-scroll-view" className="chat-scroll-view">
          {reachedTop && (
            <button
              disabled={lastVisible ? false : true}
              style={{ width: "100%" }}
              className="button is-warning"
              onClick={() => {
                loadNext()
              }}
            >
              Load More...
            </button>
          )}
          {renderMessages()}
        </div>

        <div className="chat-write-container">
          <div className="field" style={{ position: "relative" }}>
            <div className="chat-input">
              {/* <input
              className="input"
              type="text"
              // rows="2"
              value={chatText}
              placeholder="Write a message..."
              onChange={e => {
                setChatText(e.currentTarget.value)
              }}
              // onKeyDown={keyChanged}
            /> */}
              {showProgress && (
                <div
                  style={{
                    left: "50%",
                    top: "50%",

                    transform: "translate(-50%, -50%)",
                    zIndex: "20",
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                  }}
                >
                  <CircularProgressbar
                    style={{ marginBottom: "2px", background: "white" }}
                    value={progressValue}
                    text={`${parseInt(progressValue)}%`}
                    max="100"
                    styles={buildStyles({
                      pathColor: `#fec600`,
                      textColor: "#4a4a4a",
                    })}
                  >
                    75%
                  </CircularProgressbar>
                </div>
              )}
              <div
                id="file-list"
                style={{
                  fontSize: "14px",
                  position: "absolute",
                  zIndex: "5",
                  paddingLeft: "12px",
                  paddingTop: "12px",
                  marginBottom: "15px",
                }}
              >
                {showUploadFilesList()}
              </div>

              <Textarea
                className="textarea"
                style={{
                  resize: "none",
                  maxHeight: "110px",
                  paddingRight: "110px",
                  paddingTop: `${paddingValue}px`,
                }}
                type="text"
                maxLength="3000"
                rows={4}
                value={chatText}
                placeholder="Write a message..."
                onChange={e => {
                  let b = document.getElementById("chat-send")
                  console.log(e.currentTarget.value)
                  let a = e.currentTarget.value
                  if (a && a.length > 0) {
                    if (b) b.classList.remove("chat-send-disabled")
                  } else {
                    if (b) b.classList.add("chat-send-disabled")
                  }
                  setChatText(e.currentTarget.value)
                }}

                // onKeyDown={keyChanged}
              />
              {maxAttachmentCountReached && (
                <p className="help is-pulled-right">
                  Maximum of 5 attachments allowed per message
                </p>
              )}
            </div>
            <label
              className="chat-attachment"
              id="chat-attachment"
              for="file-input"
              style={{
                position: "absolute",
                top: "50%",
                right: "60px",
                mstransform: "translate(0%, -50%)",
                transform: "translate(0%, -50%)",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <i
                className="fal fa-paperclip fa-2x"
                onClick={addAttachment}
                style={{ color: "#fec600" }}
              ></i>
              <span style={{ fontSize: "12px", marginTop: "3px" }}>attach</span>
              {/* <button
              className="button is-warning"
              style={{ borderRadius: "14px" }}
              onClick={addChat}
            >
              Send
            </button> */}
            </label>
            <input
              onChange={e => {
                var files = e.target.files
                console.log({ files })
                onUpload(files)
              }}
              type="file"
              id="file-input"
              style={{ display: "none" }}
            />
            <div
              className="chat-send"
              id="chat-send"
              style={{
                position: "absolute",
                top: "50%",
                right: "20px",
                mstransform: "translate(0%, -50%)",
                transform: "translate(0%, -50%)",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <i
                className="fas fa-paper-plane fa-2x"
                onClick={addChat}
                style={{ color: "#fec600" }}
              ></i>
              <span style={{ fontSize: "12px", marginTop: "3px" }}>send</span>
              {/* <button
              className="button is-warning"
              style={{ borderRadius: "14px" }}
              onClick={addChat}
            >
              Send
            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
    //   </div>
    //   <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
    // </div>
  )
}

const MessageActivityComponent = ({ roomId, recipientUid }) => {
  return (
    <ToastProvider>
      <DashboardStateProvider>
        <MessageActivityComponentBase
          roomId={roomId}
          recipientUid={recipientUid}
        />
      </DashboardStateProvider>
    </ToastProvider>
  )
}

export default MessageActivityComponent

import React, { useContext, useEffect, useState } from "react"
import { responsiveColumnWrapWide, dottedText } from "../../../utility/general"
import TimePicker from "react-time-picker"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"

import { ToastProvider, useToasts } from "react-toast-notifications"
import ReactHtmlParser from "react-html-parser"
import loadable from "@loadable/component"
import ChecklistViewer from "../ChecklistTab/ChecklistViewer"
import { ChecklistCard } from "../ChecklistTab/ChecklistCard"
const ReactQuill = loadable(() => import("react-quill"))
export const TripRecommendationsComponentBase = ({
  canEdit,
  launchInEditMode,
}) => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  const [inEditMode, setInEditMode] = useState(false)
  const [generalRecommendationsError, setGeneralRecommendationError] = useState(
    false
  )
  const [sunriseTimeError, setSunriseTimeError] = useState(false)
  const [sunsetTimeError, setSunsetTimeError] = useState(false)
  const [maxTempError, setMaxTempError] = useState(false)
  const [minTempError, setMinTempError] = useState(false)
  const [precipitationError, setPrecipitationError] = useState(false)
  const [tempMetric, setTempMetric] = useState("F")
  const [precipitationMetric, setPrecipitationMetric] = useState("In")
  const [precipitationType, setPrecipitationType] = useState("None")
  const [accommodationLinkCount, setAccommodationLinkCount] = useState(6)
  useEffect(() => {
    dispatch({ type: "initializeTempRecommendations" })
    if (launchInEditMode === true) {
      setInEditMode(true)
    }
  }, [])
  useEffect(() => {
    console.log({ state })
  }, [state])
  useEffect(() => {
    if (state.tempRecommendations) {
      if (state.tempRecommendations.tempLow)
        dispatch({
          type: "setLowTemp",
          data: {
            value: state.tempRecommendations.tempLow.value,
            metric: tempMetric,
          },
        })

      if (state.tempRecommendations.tempHigh)
        dispatch({
          type: "setHighTemp",
          data: {
            value: state.tempRecommendations.tempHigh.value,
            metric: tempMetric,
          },
        })
    }
  }, [tempMetric])

  useEffect(() => {
    if (state.tempRecommendations)
      if (state.tempRecommendations.precipitation)
        dispatch({
          type: "setPrecipitation",
          data: {
            value: state.tempRecommendations.precipitation.value,
            metric: state.tempRecommendations.precipitation.metric,
            type: precipitationType,
          },
        })
  }, [precipitationType])

  useEffect(() => {
    if (state.tempRecommendations)
      if (state.tempRecommendations.precipitation)
        dispatch({
          type: "setPrecipitation",
          data: {
            value: state.tempRecommendations.precipitation.value,
            metric: precipitationMetric,
            type: state.tempRecommendations.precipitation.type,
          },
        })
  }, [precipitationMetric])

  const { addToast } = useToasts()
  useEffect(() => {
    if (state.recommendationRequiresSync === true) {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("jobs")
          .doc(`${authContext.uid}`)
          .collection("active-jobs")
          .doc(`${state.currentJobInfo.jobUid}`)
          .update({ recommendations: state.tempRecommendations })
          .then(() => {
            dispatch({ type: "recommendationsSyncTemp" })
            dispatch({ type: "recommendationsDBSyncRequired", data: false })
          })
      }
    }
  }, [state.recommendationRequiresSync])

  const getSunriseTime = () => {
    try {
      return state.currentJobInfo.recommendations.sunriseTime
    } catch (error) {
      console.log(error)
      return "--"
    }
  }
  const getSunsetTime = () => {
    try {
      return state.currentJobInfo.recommendations.sunsetTime
    } catch (error) {
      console.log(error)
      return "--"
    }
  }
  const getTempHigh = () => {
    try {
      return `${state.currentJobInfo.recommendations.tempHigh.value} ${state.currentJobInfo.recommendations.tempHigh.metric}`
    } catch (error) {
      console.log(error)
      return "--"
    }
  }
  const getTempLow = () => {
    try {
      return `${state.currentJobInfo.recommendations.tempLow.value} ${state.currentJobInfo.recommendations.tempLow.metric}`
    } catch (error) {
      console.log(error)
      return "--"
    }
  }

  const getPrecipitation = () => {
    try {
      if (state.currentJobInfo.recommendations.precipitation.type === "None")
        return ""
      return `${state.currentJobInfo.recommendations.precipitation.value} ${state.currentJobInfo.recommendations.precipitation.metric}`
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  const getPrecipitationIcon = () => {
    try {
      switch (state.currentJobInfo.recommendations.precipitation.type) {
        case "None":
          return ""
          break
        case "Snowfall":
          return (
            <i
              className="fal fa-snowflakes fa-3x"
              style={{
                color: "#fec600",
              }}
            ></i>
          )
          break
        case "Rain":
          return (
            <i
              className="fal fa-cloud-showers fa-3x"
              style={{
                color: "#fec600",
              }}
            ></i>
          )
          break

        default:
          break
      }
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const showAccommodation = item => {
    try {
      if (item)
        return state.currentJobInfo.recommendations[`accommodation${item}`] ===
          undefined ||
          state.currentJobInfo.recommendations[`accommodation${item}`] ===
            "" ? (
          ""
        ) : (
          <div style={{ marginBottom: "20px" }}>
            <i
              className="far fa-hotel fa-lg"
              style={{ marginRight: "7px" }}
            ></i>
            <a
              style={{ marginRight: "20px", minWidth: "250px" }}
              href={
                state.currentJobInfo.recommendations[`accommodation${item}`]
              }
              target="_blank"
            >
              {dottedText(
                state.currentJobInfo.recommendations[`accommodation${item}`],
                30
              )}
            </a>
            <p style={{ marginTop: "7px", lineBreak: "anywhere" }}>
              {state.currentJobInfo.recommendations[
                `accommodationDetails${item}`
              ] === "" ||
              state.currentJobInfo.recommendations[
                `accommodationDetails${item}`
              ] === undefined ? (
                ""
              ) : (
                <>
                  <span
                    style={{
                      marginRight: "0px",
                      // fontWeight: "bold",
                    }}
                  >
                    Designer Note:{" "}
                  </span>
                  {
                    state.currentJobInfo.recommendations[
                      `accommodationDetails${item}`
                    ]
                  }
                </>
              )}
            </p>
          </div>
        )
    } catch (err) {
      console.log(err)
    }
  }

  const didValidationPass = () => {
    if (state.tempRecommendations === undefined) {
      setSunriseTimeError(true)
      setSunsetTimeError(true)
      setGeneralRecommendationError(true)
      setMaxTempError(true)
      setMinTempError(true)
      setPrecipitationError(true)
      return false
    }

    if (
      state.tempRecommendations.generalInfo === "" ||
      state.tempRecommendations.generalInfo === undefined
    ) {
      setGeneralRecommendationError(true)
      return false
    }

    if (
      state.tempRecommendations.sunriseTime === "" ||
      state.tempRecommendations.sunriseTime === undefined ||
      state.tempRecommendations.sunriseTime === null
    ) {
      setSunriseTimeError(true)
      return false
    }

    if (
      state.tempRecommendations.sunsetTime === "" ||
      state.tempRecommendations.sunsetTime === undefined ||
      state.tempRecommendations.sunsetTime === null
    ) {
      setSunsetTimeError(true)
      return false
    }
    if (
      state.tempRecommendations.tempHigh === "" ||
      state.tempRecommendations.tempHigh === undefined ||
      state.tempRecommendations.tempHigh === null
    ) {
      setMaxTempError(true)
      return false
    }
    if (
      state.tempRecommendations.tempLow === "" ||
      state.tempRecommendations.tempLow === undefined ||
      state.tempRecommendations.tempLow === null
    ) {
      setMinTempError(true)
      return false
    }
    if (
      state.tempRecommendations.precipitation === "" ||
      state.tempRecommendations.precipitation === undefined ||
      state.tempRecommendations.precipitation === null
    ) {
      setPrecipitationError(true)
      return false
    }

    return true
  }

  const showGeneralInfo = () => {
    {
      if (state.tempRecommendations) {
        return state.tempRecommendations.generalInfo === undefined
          ? " - Empty - "
          : ReactHtmlParser(state.tempRecommendations.generalInfo)
      }
    }
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const showHeading = () => {
    let place = ""
    if (state.tripDetails) {
      place =
        state.tripDetails.tripPrimaryDestination === undefined
          ? "--"
          : state.tripDetails.tripPrimaryDestination.place
    }
    try {
      return `${place} in ${
        months[
          typeof state.tripDetails.tripLogistics.travelDates.end === "string"
            ? new Date(
                state.tripDetails.tripLogistics.travelDates.end
              ).getMonth()
            : state.tripDetails.tripLogistics.travelDates.end
                .toDate()
                .getMonth()
        ]
      }`
    } catch (error) {
      console.log(error)
      return ` ${place} in ${
        months[state.tripDetails.tripLogistics.travelDates.end.getMonth()]
      } `
    }
  }

  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ script: "sub" }, { script: "super" }],
      [
        // { list: "ordered" },
        //{ list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      ["link"],
      // ["clean"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    //  "strike",
    "blockquote",
    "script",
    // "list",
    // "bullet",
    // "indent",
    "link",
    // "image",
  ]

  const getLength = index => {
    try {
      console.log({ index })
      if (state.tempRecommendations) {
        let v = state.tempRecommendations[`accommodationDetails${index + 1}`]

        console.log({ v })
        let l =
          state.tempRecommendations[`accommodationDetails${index + 1}`].length
        console.log({ l })
        return l
      } else return 0
    } catch (err) {
      return 0
    }
  }

  return (
    <div>
      {inEditMode ? (
        <>
          {responsiveColumnWrapWide(
            <div className="columns">
              <div className="column is-6">
                <label className="label">
                  Sunrise Time{" "}
                  {sunriseTimeError && (
                    <p
                      className="help is-danger"
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      <i
                        className="far fa-exclamation-triangle"
                        style={{ marginRight: "2px" }}
                      ></i>{" "}
                      This field is required
                    </p>
                  )}
                  <p
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "400",
                      float: "right",
                    }}
                  >
                    required
                  </p>
                </label>
                <div className="control">
                  {/* <input className="input" type="text" placeholder="times" /> */}
                  <TimePicker
                    onChange={time => {
                      setSunriseTimeError(false)
                      console.log({ time })
                      if (time) dispatch({ type: "setSunriseTime", data: time })
                    }}
                    value={
                      state.tempRecommendations !== undefined
                        ? state.tempRecommendations.sunriseTime
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="column is-6">
                <label className="label">
                  Sunset Time{" "}
                  {sunsetTimeError && (
                    <p
                      className="help is-danger"
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      <i
                        className="far fa-exclamation-triangle"
                        style={{ marginRight: "2px" }}
                      ></i>{" "}
                      This field is required
                    </p>
                  )}
                  <p
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "400",
                      float: "right",
                    }}
                  >
                    required
                  </p>
                </label>
                <div className="control">
                  <TimePicker
                    onChange={time => {
                      setSunsetTimeError(false)
                      console.log({ time })
                      if (time) dispatch({ type: "setSunsetTime", data: time })
                    }}
                    value={
                      state.tempRecommendations !== undefined
                        ? state.tempRecommendations.sunsetTime
                        : ""
                    }
                  />
                  {/* <input className="input" type="text" placeholder="times" /> */}
                </div>
              </div>
            </div>
          )}
          {responsiveColumnWrapWide(
            <div className="columns">
              <div className="column is-6-desktop is-12-tablet">
                <label className="label">
                  Min-Max Temp{" "}
                  {minTempError || maxTempError ? (
                    <p
                      className="help is-danger"
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      <i
                        className="far fa-exclamation-triangle"
                        style={{ marginRight: "2px" }}
                      ></i>{" "}
                      This field is required
                    </p>
                  ) : (
                    ""
                  )}
                  <p
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "400",
                      float: "right",
                    }}
                  >
                    required
                  </p>
                </label>
                <div className="field has-addons">
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="minimum"
                      value={
                        state.tempRecommendations.tempLow
                          ? state.tempRecommendations.tempLow.value
                          : ""
                      }
                      onChange={e => {
                        setMinTempError(false)
                        setMaxTempError(false)
                        dispatch({
                          type: "setLowTemp",
                          data: {
                            value: e.currentTarget.value,
                            metric: tempMetric,
                          },
                        })
                      }}
                    />
                  </div>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="maximum"
                      value={
                        state.tempRecommendations.tempHigh
                          ? state.tempRecommendations.tempHigh.value
                          : ""
                      }
                      onChange={e => {
                        dispatch({
                          type: "setHighTemp",
                          data: {
                            value: e.currentTarget.value,
                            metric: tempMetric,
                          },
                        })
                      }}
                    />
                  </div>
                  <div className="control">
                    <span className="select">
                      <select
                        value={
                          state.tempRecommendations.tempHigh
                            ? state.tempRecommendations.tempHigh.metric
                            : "F"
                        }
                        onChange={e => {
                          setTempMetric(e.currentTarget.value)
                        }}
                      >
                        <option>F</option>
                        <option>C</option>
                      </select>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="column is-4">
                <label className="label">
                  Max Temperature{" "}
                  {maxTempError && (
                    <p
                      className="help is-danger"
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      <i
                        className="far fa-exclamation-triangle"
                        style={{ marginRight: "2px" }}
                      ></i>{" "}
                      This field is required
                    </p>
                  )}
                  <p
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "400",
                      float: "right",
                    }}
                  >
                    required
                  </p>
                </label>
                <div className="field has-addons">
                  <div className="control">
                    <input className="input" type="text" placeholder="" />
                  </div>
                  <div className="control">
                    <span className="select">
                      <select>
                        <option>F</option>
                        <option>C</option>
                      </select>
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          )}
          {responsiveColumnWrapWide(
            <div className="columns">
              <div className="column is-6-desktop is-12-tablet">
                <label className="label">
                  Precipitation{" "}
                  {precipitationError && (
                    <p
                      className="help is-danger"
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      <i
                        className="far fa-exclamation-triangle"
                        style={{ marginRight: "2px" }}
                      ></i>{" "}
                      This field is required
                    </p>
                  )}
                  <p
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "400",
                      float: "right",
                    }}
                  >
                    required
                  </p>
                </label>
                <div className="field has-addons">
                  <div className="control is-expanded">
                    <input
                      className="input"
                      type="text"
                      placeholder=""
                      value={
                        state.tempRecommendations.precipitation
                          ? state.tempRecommendations.precipitation.value
                          : ""
                      }
                      onChange={e => {
                        dispatch({
                          type: "setPrecipitation",
                          data: {
                            value: e.currentTarget.value,
                            metric: precipitationMetric,
                            type: precipitationType,
                          },
                        })
                      }}
                    />
                  </div>
                  <div className="control">
                    <span className="select">
                      <select
                        value={
                          state.tempRecommendations.precipitation
                            ? state.tempRecommendations.precipitation.metric
                            : "In"
                        }
                        onChange={e => {
                          setPrecipitationMetric(e.currentTarget.value)
                        }}
                      >
                        <option>In</option>
                        <option>MM</option>
                      </select>
                    </span>
                  </div>
                  <div className="control">
                    <span className="select">
                      <select
                        value={
                          state.tempRecommendations.precipitation
                            ? state.tempRecommendations.precipitation.type
                            : "None"
                        }
                        onChange={e => {
                          setPrecipitationType(e.currentTarget.value)
                        }}
                      >
                        <option>None</option>
                        <option>Rain</option>
                        <option>Snowfall</option>
                      </select>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr className="hr-branded"></hr>
          {responsiveColumnWrapWide(
            <div className="columns">
              {/* <div className="column is-1"></div> */}

              <div className="column is-12">
                <div className="field">
                  <label className="label">
                    General location specific travel recommendations:
                    {generalRecommendationsError && (
                      <p
                        className="help is-danger"
                        style={{
                          display: "inline-block",
                          marginLeft: "5px",
                        }}
                      >
                        {" "}
                        <i
                          className="far fa-exclamation-triangle"
                          style={{ marginRight: "2px" }}
                        ></i>{" "}
                        This field is required
                      </p>
                    )}
                    <p
                      style={{
                        display: "inline-block",
                        fontSize: "14px",
                        fontWeight: "400",
                        float: "right",
                      }}
                    >
                      required
                    </p>
                  </label>
                  <div className="control">
                    <ReactQuill
                      theme="snow"
                      onChange={e => {
                        setGeneralRecommendationError(false)
                        dispatch({
                          type: "updateGeneralInfo",
                          data: e,
                        })
                      }}
                      value={
                        state.tempRecommendations !== undefined
                          ? state.tempRecommendations.generalInfo || ""
                          : " "
                      }
                      modules={modules}
                      formats={formats}
                      placeHolder="Hello there"
                    />
                    <p
                      className="help"
                      style={{
                        // display: "inline-block",
                        // marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      <strong>Suggested topics:</strong> Tipping policy{" "}
                      <span style={{ color: "#fec600", fontWeight: "bold" }}>
                        |
                      </span>{" "}
                      Currency Used{" "}
                      <span style={{ color: "#fec600", fontWeight: "bold" }}>
                        |
                      </span>{" "}
                      Must try local dishes{" "}
                      <span style={{ color: "#fec600", fontWeight: "bold" }}>
                        |
                      </span>{" "}
                      local drinks{" "}
                      <span style={{ color: "#fec600", fontWeight: "bold" }}>
                        |
                      </span>{" "}
                      Useful local services e.g. Nannybag.com{" "}
                      <span style={{ color: "#fec600", fontWeight: "bold" }}>
                        |
                      </span>{" "}
                      Transportation Info{" "}
                      <span style={{ color: "#fec600", fontWeight: "bold" }}>
                        |
                      </span>{" "}
                      Important Phrases{" "}
                      <span style={{ color: "#fec600", fontWeight: "bold" }}>
                        |
                      </span>{" "}
                    </p>
                    {/* <textarea
                      className="textarea"
                      rows="7"
                      onChange={e => {
                        setGeneralRecommendationError(false)
                        dispatch({
                          type: "updateGeneralInfo",
                          data: e.currentTarget.value,
                        })
                      }}
                      value={
                        state.tempRecommendations !== undefined
                          ? state.tempRecommendations.generalInfo
                          : " "
                      }
                      //placeholder="E.g. In January, winter is at the peak in Paris, and the presence of the cold winds result in low temperatures during the day and lower temperatures at night. With average temperatures between 3°C (37.4°F) and 7°C (44.6°F), the night temperatures seldom drop to 0°C (32°F) making the winter nights icy and chilly. Rainfall in January is moderately low, with an average of fourteen rainy days. Falling mostly in the afternoons, rains are accompanied by light floods and thunderstorms."
                      placeholder=""
                    ></textarea> */}
                  </div>
                </div>
              </div>
              {/* <div className="column is-1"></div> */}
            </div>
          )}
          <hr className="hr-branded"></hr>
          {responsiveColumnWrapWide(
            <div className="columns is-multiline">
              {/* <div className="column is-1"></div> */}

              <div className="column is-12">
                {" "}
                <label className="label">
                  Accommodation Recommendations{" "}
                  {/* <p
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "400",
                      float: "right",
                    }}
                  >
                    optional
                  </p> */}
                </label>
              </div>
              {/* <div
                className="column is-12"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0px auto 0px auto",
                }}
              >
                {accommodationLinkCount <= 1 ? (
                  ""
                ) : (
                  <div>
                    <button
                      style={{ margin: "10px" }}
                      className="button is-warning"
                      onClick={() => {
                        console.log({ accommodationLinkCount })
                        if (accommodationLinkCount <= 1) return
                        let i = accommodationLinkCount
                        setAccommodationLinkCount(i - 1)
                        dispatch({
                          type: `updateAccommodation${i}`,
                          data: "",
                        })
                        dispatch({
                          type: `updateAccommodationDetails`,
                          data: "",
                          index: i,
                        })
                      }}
                    >
                      <i
                        className="far fa-minus"
                        style={{ marginRight: "0px" }}
                      ></i>{" "}
                    </button>
                  </div>
                )}
                {accommodationLinkCount >= 6 ? (
                  ""
                ) : (
                  <div>
                    <button
                      style={{ margin: "10px" }}
                      className="button is-warning"
                      onClick={() => {
                        if (accommodationLinkCount >= 6) return
                        let i = accommodationLinkCount + 1
                        setAccommodationLinkCount(i)
                        dispatch({
                          type: `updateAccommodationCount`,
                          data: i,
                        })
                      }}
                    >
                      <i className="far fa-plus" style={{ marginRight: "0px" }}></i>
                    </button>
                  </div>
                )}
              </div> */}
              {[...Array(accommodationLinkCount)].map((item, index) => {
                return (
                  <>
                    <div className="column is-6">
                      <div className="field">
                        <label className="label">{`Accommodation URL ${index +
                          1}:`}</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder="Paste URL here"
                            id={`${index + 1}`}
                            onChange={e => {
                              dispatch({
                                type: `updateAccommodation${index + 1}`,
                                data: e.currentTarget.value,
                              })
                            }}
                            value={
                              state.tempRecommendations !== undefined
                                ? state.tempRecommendations[
                                    `accommodation${index + 1}`
                                  ]
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column is-6">
                      <div className="field">
                        <label className="label">Designer Note:</label>
                        <div className="control">
                          <textarea
                            onChange={e => {
                              dispatch({
                                type: `updateAccommodationDetails`,
                                data: e.currentTarget.value,
                                index: index + 1,
                              })
                            }}
                            value={
                              state.tempRecommendations !== undefined
                                ? state.tempRecommendations[
                                    `accommodationDetails${index + 1}`
                                  ]
                                : ""
                            }
                            maxLength={200}
                            className="textarea"
                            placeholder=""
                            rows="2"
                          ></textarea>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="help">
                              Details that you would like the traveler to know
                              about this recommendation.
                            </p>
                            <p
                              className={`help is-pulled-right ${
                                getLength(index) >= 200 ? "is-danger" : ""
                              }`}
                            >
                              {/* Characters:{" "} */}
                              {getLength(index)}/{200}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="column is-12">
                      <div
                        style={{
                          width: "200px",
                          margin: "0 auto",
                          borderBottom: "1px solid #ebebeb",
                        }}
                      ></div>
                    </div> */}
                  </>
                )
              })}

              {/* <div className="column is-1"></div> */}
            </div>
          )}

          {responsiveColumnWrapWide(
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="button is-light"
                style={{ width: "120px", marginRight: "15px" }}
                onClick={() => {
                  setInEditMode(false)
                }}
              >
                Cancel
              </button>

              <button
                className="button is-warning"
                style={{ width: "120px" }}
                onClick={() => {
                  if (didValidationPass()) {
                    dispatch({
                      type: "recommendationsDBSyncRequired",
                      data: true,
                    })
                    setInEditMode(false)
                  } else {
                    addToast("Please complete all the required fields.", {
                      appearance: "error",
                      autoDismiss: true,
                    })
                  }
                }}
              >
                Save
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          {/* {responsiveColumnWrapWide( */}
          <div className="columns is-multiline">
            <div className="column is-12">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {showHeading()}
              </p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    width: "100px",
                    // height: "75px",
                    //padding: "5px",
                    margin: "20px 0px",
                    //marginRight: "10px",
                    textAlign: "center",
                  }}
                >
                  {/* <img src={sunrise} alt="" />{" "} */}
                  <i
                    className="fal fa-sunrise fa-3x"
                    style={{ color: "#fec600" }}
                  ></i>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {getSunriseTime()}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      marginTop: "5px",
                    }}
                  >
                    Sunrise time
                  </p>
                </div>
                <div
                  style={{
                    width: "100px",
                    // height: "75px",
                    //padding: "5px",
                    margin: "20px 0px",
                    // marginRight: "20px",
                    textAlign: "center",
                  }}
                >
                  {/* <img src={sunset} alt="" />{" "} */}
                  <i
                    className="fal fa-sunset fa-3x"
                    style={{ color: "#fec600" }}
                  ></i>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {getSunsetTime()}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      marginTop: "5px",
                    }}
                  >
                    Sunset Time
                  </p>
                </div>
                <div
                  style={{
                    width: "100px",
                    // height: "75px",
                    //padding: "5px",
                    margin: "20px 0px",
                    // marginRight: "10px",
                    textAlign: "center",
                  }}
                >
                  {/* <img src={sunset} alt="" />{" "} */}
                  <i
                    className="fal fa-temperature-low fa-3x"
                    style={{ color: "#fec600", marginLeft: "17px" }}
                  ></i>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {getTempLow()}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      marginTop: "5px",
                    }}
                  >
                    Min Temp
                  </p>
                </div>
                <div
                  style={{
                    width: "100px",
                    // height: "75px",
                    //padding: "5px",
                    margin: "20px 0px",
                    //  marginRight: "20px",
                    textAlign: "center",
                  }}
                >
                  {/* <img src={sunset} alt="" />{" "} */}
                  <i
                    className="fal fa-temperature-high fa-3x"
                    style={{ color: "#fec600", marginLeft: "17px" }}
                  ></i>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {getTempHigh()}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      marginTop: "5px",
                    }}
                  >
                    Max Temp
                  </p>
                </div>
                <div
                  style={{
                    width: "100px",
                    // height: "75px",
                    //padding: "5px",
                    margin: "20px 0px",
                    textAlign: "center",
                  }}
                >
                  {/* <img src={sunset} alt="" />{" "} */}
                  {getPrecipitationIcon()}
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {getPrecipitation()}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      marginTop: "5px",
                    }}
                  >
                    Precipitation
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}

          {/* {responsiveColumnWrapWide( */}
          <div className="columns is-multiline">
            {/* <div className="column is-12 "></div> */}
            <div className="column is-12">
              <hr className="hr-branded"></hr>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                }}
              >
                Destination Information
              </p>
              {showGeneralInfo()}
            </div>
          </div>
          {/* )} */}

          {/* {responsiveColumnWrapWide( */}
          <div className="columns is-multiline">
            <div className="column is-12">
              <hr className="hr-branded"></hr>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                }}
              >
                Accommodation Recommendations
              </p>
              {[...Array(6)].map((item, index) => {
                console.log(index)
                return (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                      marginBottom: "5px",
                      flexWrap: "wrap",
                    }}
                  >
                    {showAccommodation(`${index + 1}`)}
                  </div>
                )
              })}
              {/* <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                {showAccommodation("1")}
              </p>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                {showAccommodation("2")}
              </p>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                {showAccommodation("3")}
              </p>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                {showAccommodation("4")}
              </p>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                {showAccommodation("5")}
              </p>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                {showAccommodation("6")}
              </p> */}
            </div>
          </div>
          {/* )} */}

          {canEdit
            ? responsiveColumnWrapWide(
                <div className="columns is-multiline">
                  <div className="column is-12">
                    <button
                      className="button is-warning"
                      style={{ float: "right", width: "200px" }}
                      onClick={() => {
                        setInEditMode(true)
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )
            : ""}
          <hr className="hr-branded"></hr>
          {!canEdit ? (
            <div className="columns is-multiline">
              <div className="column is-12">
                <p style={{ fontSize: "22px", fontWeight: "600px" }}>
                  Your Checklist
                </p>
                {state.currentJobInfo.tripChecklist.map((item, index) => {
                  console.log({ item })
                  if (item != undefined)
                    return (
                      <ChecklistCard
                        key={index}
                        data={item}
                        // onRemove={onRemove}
                        canEdit={false}
                      />
                    )
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          <hr className="hr-branded"></hr>
        </>
      )}

      {/* {responsiveColumnWrapWide(
        <div className="columns">
          <div className="column is-12">
            <div className="field">
              <label className="label">
                Whats the weather like in DESTINATION during TRAVEL_MONTH?
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  rows="3"
                  //placeholder="E.g. In January, winter is at the peak in Paris, and the presence of the cold winds result in low temperatures during the day and lower temperatures at night. With average temperatures between 3°C (37.4°F) and 7°C (44.6°F), the night temperatures seldom drop to 0°C (32°F) making the winter nights icy and chilly. Rainfall in January is moderately low, with an average of fourteen rainy days. Falling mostly in the afternoons, rains are accompanied by light floods and thunderstorms."
                  placeholder="Low temperature during the night : 3°C - 8°C (37°F - 46° F)
High temperature during the day: 10°C - 17°C (50°F - 62° F)"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}

const TripRecommendationsComponent = ({ canEdit, launchInEditMode }) => {
  return (
    <ToastProvider>
      <TripRecommendationsComponentBase
        canEdit={canEdit}
        launchInEditMode={launchInEditMode}
      />
    </ToastProvider>
  )
}
export default TripRecommendationsComponent

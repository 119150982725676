import algoliasearch from "algoliasearch/lite"
import { each, findIndex, range, sortBy, toPairs } from "lodash"
import React, { useContext, useEffect, useState } from "react"
import {
  Configure,
  connectCurrentRefinements,
  connectHits,
  connectHitsPerPage,
  connectRefinementList,
  InstantSearch,
} from "react-instantsearch-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Select from "react-select"
import { analytics } from "../../components/firebase/firebase"
import { AuthUserContext } from "../../components/session"
import DesignerCard from "../../components/TripCards/DesignerCard"
import DesignerCardNew from "../../components/TripCards/DesignerCardNew"
import { designerSkills } from "../../constants/data/general"
import notFound from "./../../images/not-found.svg"
import "./SearchDesigner.scss"

const searchClient = algoliasearch(
  `${process.env.ALGOLIA_SEARCH_APP_ID}`,
  `${process.env.ALGOLIA_SEARCH_API_KEY}`
)
const InstantSearchComponentSimple = ({
  onSelect,
  destination,
  skillsAffinityMatrix,
}) => {
  const [fetchingDesigner, setFetchingDesigner] = useState(true)
  const authContext = useContext(AuthUserContext)
  const [affinityLevel, setAffinityLevel] = useState(0)
  useEffect(() => {
    let ID = process.env.ALGOLIA_SEARCH_APP_ID
    let Key = process.env.ALGOLIA_SEARCH_API_KEY
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("Timer Expired!!!")
      setFetchingDesigner(false)
      if (authContext) {
        analytics.logEvent("wizard_no_designers_found", {
          userId: `${authContext.uid}`,
          destination: `${destination}`,
        })
        console.log({ destination })
        analytics.setUserProperties({ destination: `${destination}` })
      }
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const showDesigners = ({ hits }) => {
    console.log({ hits })
    if (hits && hits.length === 0) {
      return fetchingDesigner ? (
        <div
          className="spinner center spinner-brand-color"
          style={{ zIndex: "20" }}
        >
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      ) : (
        <div
          style={{ minWidth: "250px", maxWidth: "500px", margin: "0px auto" }}
        >
          <LazyLoadImage
            src={notFound}
            alt=""
            style={{ width: "100%", height: "120px" }}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              textTransform: "uppercase",
              letterSpacing: "0.5px",
              fontWeight: "600",
              marginTop: "5px",
            }}
          >
            {" "}
            No Designers found!
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              // textTransform: "uppercase",
              // letterSpacing: "0.5px",
              fontWeight: "400",
              marginTop: "5px",
              marginBottom: "30px",
              padding: "0px 10px",
            }}
          >
            Unfortunately there were no designers found that cover your travel
            destination. We'll notify you as soon as we have that place covered.
            We appreciate your patience!
          </p>
        </div>
      )
    }
    //iterate through the hits and match score
    let optimizedHits = hits.map((item, index) => {
      let matchScore = 0
      if (item.skills) {
        item.skills.map((skill, index) => {
          // findIndex(skillsAffinityMatrix, o=> o.
          let score = skillsAffinityMatrix[skill.value]
          matchScore = score + matchScore
        })
      }
      return { ...item, matchScore }
    })

    let sortedByMatchScore = sortBy(optimizedHits, o => o.matchScore).reverse()
    console.log({ sortedByMatchScore })

    return (
      <>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            minHeight: "200px",
          }}
        >
          {sortedByMatchScore.length > 0 && (
            // <div style={{ margin: "", pclassName="pick-your-designer-wrapper"osition: "relative" }}>
            <div
              style={{ position: "relative" }}
              className="pick-your-designer-wrapper"
            >
              <span
                className="tag is-dark top-match-tag"
                style={{
                  position: "absolute",
                  fontWeight: "600",
                  letterSpacing: "0.5px",
                }}
              >
                Top Match
              </span>
              <DesignerCardNew
                key={sortedByMatchScore[0].objectID}
                hit={sortedByMatchScore[0]}
                onSelect={onSelect}
              />
            </div>
          )}
          {sortedByMatchScore.length > 1 && (
            <div style={{ margin: "" }} className="pick-your-designer-wrapper">
              <DesignerCardNew
                key={sortedByMatchScore[1].objectID}
                hit={sortedByMatchScore[1]}
                onSelect={onSelect}
              />
            </div>
          )}
          {sortedByMatchScore.length > 2 && (
            <div style={{ margin: "" }} className="pick-your-designer-wrapper">
              <DesignerCardNew
                key={sortedByMatchScore[2].objectID}
                hit={sortedByMatchScore[2]}
                onSelect={onSelect}
              />
            </div>
          )}
        </div>
        {sortedByMatchScore.length > 3 && (
          <>
            <div>
              <button
                className="button is-light"
                id="showmorebutton"
                onClick={() => {
                  var x = document.getElementById("showmore")
                  var y = document.getElementById("showmorebutton")
                  if (x) {
                    if (x.style.display === "none") {
                      x.style.display = "flex"
                      if (y) y.innerText = "Hide more matches"
                    } else {
                      x.style.display = "none"
                      if (y) y.innerText = "Show more matches"
                    }
                  }
                }}
                style={{ margin: "10px 0px" }}
              >
                Show more matches
              </button>
            </div>

            <div
              id="showmore"
              style={{ display: "none", flexWrap: "wrap", marginTop: "20px" }}
            >
              {sortedByMatchScore.map((item, index) => {
                if (item.doNotShow) return ""
                if (index < 3) return ""
                return (
                  <div
                    className="pick-your-designer-wrapper"
                    // style={{ marginRight: "25px", marginBottom: "25px" }}
                  >
                    <DesignerCardNew
                      key={item.objectID}
                      hit={item}
                      onSelect={onSelect}
                    />
                  </div>
                )
              })}
            </div>
          </>
        )}
      </>
    )
  }

  const VirtualRefinementList = connectRefinementList(() => null)

  const CustomHits = connectHits(showDesigners)

  const HitsPerPage = ({ items, currentRefinement, refine, createURL }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {/* <p>The search display {currentRefinement} hits.</p> */}
      <div
        style={{
          fontSize: "14px",
        }}
      >
        Results per page
      </div>
      <div
        style={{
          width: "80px",
          fontSize: "14px",
          marginLeft: "5px",
        }}
      >
        <Select
          defaultValue={items.find(element => element.isRefined === true)}
          classNamePrefix="select"
          isSearchable="false"
          isClearable={false}
          onChange={(value, actionMeta) => {
            if (value) refine(value.value)
          }}
          options={items}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#fef0bf",
              primary: "#fed43f",
              primary50: "#fee27f",
              //neutral0: "#424242",
            },
          })}
        />
      </div>
      {/* <ul>
        {items.map(item => (
          <li key={item.value}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={event => {
                event.preventDefault()
                refine(item.value)
              }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul> */}
    </div>
  )
  const CustomHitsPerPage = connectHitsPerPage(HitsPerPage)

  const CurrentRefinements = ({ items, refine, createURL }) => {
    console.log(items)
    return (
      <ul>
        {items.map(item => (
          <li key={item.label}>
            {item.items ? (
              <React.Fragment>
                {item.label === "countriesForItineraries.value"
                  ? "Destination"
                  : item.label}
                <ul>
                  {item.items.map(nested => (
                    <li key={nested.label}>
                      <a
                        href={createURL(nested.value)}
                        onClick={event => {
                          event.preventDefault()
                          refine(nested.value)
                        }}
                      >
                        {nested.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ) : (
              <a
                href={createURL(item.value)}
                onClick={event => {
                  event.preventDefault()
                  refine(item.value)
                }}
              >
                {item.label === "countriesForItineraries.value"
                  ? "Destination"
                  : item.label}
              </a>
            )}
          </li>
        ))}
      </ul>
    )
  }

  const processAffinityList = level => {
    var refinements = []
    if (skillsAffinityMatrix) {
      const sortedSkillsAffinityMatrix = sortBy(
        toPairs(skillsAffinityMatrix),
        1
      ).reverse()
      console.log({ sortedSkillsAffinityMatrix })

      each(range(level), item => {
        if (sortedSkillsAffinityMatrix[item][1] > 0) {
          //check if there is selection.
          let itemIndex = findIndex(designerSkills, function(o) {
            return o.value == sortedSkillsAffinityMatrix[item][0]
          })
          if (itemIndex !== -1)
            refinements.push(designerSkills[itemIndex].label)
        }
      })
    }
    console.log({ refinements })
    return refinements
  }

  const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements)
  var toggleFilter = true
  return (
    <div>
      <InstantSearch
        refresh={true}
        indexName="designers"
        searchClient={searchClient}
      >
        <Configure filters="available:true" />
        {/* <CustomSearchBox /> */}
        <VirtualRefinementList
          attribute="skills.label"
          operator="and"
          defaultRefinement={processAffinityList(affinityLevel)}
        />
        <VirtualRefinementList
          attribute="countriesForItineraries.value"
          defaultRefinement={
            destination === "USA" || destination === "United States"
              ? [destination, "United States of America"]
              : destination
          }
        />
        {/* <div className="columns" style={{ marginTop: "20px" }}> */}
        {/* <div className="column"> */}
        <CustomHits />
        {/* </div> */}
        {/* </div> */}
      </InstantSearch>
    </div>
  )
}

export default InstantSearchComponentSimple

import React, { useContext, useEffect } from "react"
import {
  useTripStateStore,
  TripStateProvider,
} from "../../../State/trip-state/trip-state-store"
import swal from "sweetalert"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { FirebaseContext } from "../../../components/firebase"
import {
  useDashboardStateStore,
  DashboardStateProvider,
} from "../../../State/dashboard-state/dashboard-state"
import GoToTop from "../../../components/GoToTop/GoToTop"
import EditableProfileImageField from "../../../components/EditableFormComponents/EditableProfileImageField"
import { useState } from "react"

import { navigate } from "gatsby"
import TripDetailsComponent from "../TripDetailsTab/TripDetailsComponent"
import ItineraryComponent from "../ItineraryTab/ItineraryComponent"
import ChecklistComponent from "../ChecklistTab/ChecklistComponent"
import MessageActivityContainer from "../MessagesTab/MessageActivityContainer"
import ShowError from "../../../components/Error/ShowError"
import TripDesignerCardComponent from "../ItineraryTab/TripDesignerCardComponent"
import ReservationsComponent from "../ReservationTab/ReservationsComponent"
import ShowLoading from "../../../components/ShowLoading/ShowLoading"
import AuthUserContext from "../../../components/session/context"
import PickADesigner from "../../../components/TripCreationComponents/PickADesigner"
import { TripDashboardCombinedProvider } from "../../../State/TripDashboardCombinedProvider"
import ItineraryViewer from "../ItineraryTab/ItineraryViewer"
import ReservationViewer from "../ReservationTab/ReservationViewer"
import ChecklistViewer from "../ChecklistTab/ChecklistViewer"
import TripRecommendationsContainer from "../TripRecommendations/TripRecommendationsContainer"
import { useGlobalStateStore } from "../../../State/global-state/global-state"

const SampleTravelPlanBase = ({ tripUid, location }) => {
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripDispatch] = useTripStateStore()
  const [globalState, globalStateDispatch] = useGlobalStateStore()
  const dbContext = useContext(FirebaseContext)
  const [loading, setLoading] = useState(true)
  const [tripDesignComplete, setTripDesignComplete] = useState(false)

  useEffect(() => {
    console.log("%cDashboard-State", "color: #972426; font-size: 18px", {
      state,
    })
  }, [state])
  useEffect(() => {
    console.log("%Trip-State", "color: #972426; font-size: 18px", {
      state,
    })
  }, [tripState])
  useEffect(() => {
    console.log("%Global-State", "color: #FEC6FF; font-size: 18px", {
      globalState,
    })
  }, [globalState])

  useEffect(() => {
    if (dbContext) {
      dbContext
        .firestore()
        .collection("sampleTravelPlan")
        .doc(`tripDetails`)
        .get()
        .then(doc => {
          if (doc) {
            var tripData = doc.data()
            dispatch({
              type: "syncTripDetailsAndTripState",
              data: tripData,
            })
            // Update the trip state with current selected trip details.
            tripDispatch({
              type: "updateTripState",
              data: tripData,
            })
            if (
              tripData.designerDetails.status === "complete" ||
              tripData.designerDetails.status === "review"
            )
              setTripDesignComplete(true)

            if (tripData.designerDetails.status === "complete") {
              // Fetch the travel plan for review
              dbContext
                .firestore()
                .collection("sampleTravelPlan")
                .doc(`travelPlan`)
                .get()
                .then(doc => {
                  console.log(doc.id, " => ", doc.data())
                  dispatch({
                    type: "updateCurrentJobInfo",
                    data: doc.data(),
                  })
                  dispatch({
                    type: "setActiveTab",
                    data: "Itinerary",
                  })
                  setLoading(false)
                  //dispatch({ type: "setActiveTrips", data: doc.data() })
                  setLoading(false)
                })
            }

            // if (tripData.designerDetails.designerUid) {
            //   dbContext
            //     .firestore()
            //     .collection("users")
            //     .doc(`${tripData.designerDetails.designerUid}`)
            //     .collection("publicProfile")
            //     .doc(`${tripData.designerDetails.designerUid}`)
            //     .get()
            //     .then(function(doc) {
            //       if (doc.exists) {
            //         console.log("Document data:", doc.data())
            //         dispatch({
            //           type: "updateDesignerPublicProfile",
            //           data: doc.data(),
            //         })
            //       }
            //     })
            // }

            // dbContext
            //   .firestore()
            //   .collection("trips")
            //   .doc(`${authContext.uid}`)
            //   .get()
            //   .then(doc => {
            //     if (doc) {
            //       console.log(doc.data())
            //       dispatch({
            //         type: "updateTripManifest",
            //         data: doc.data(),
            //       })

            //     }
            //   })
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!")
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    }

    // if (
    //   state.tripDetails.designerDetails !== undefined &&
    //   (state.tripDetails.designerDetails.status === "active" ||
    //     state.tripDetails.designerDetails.status === "declined")
    // ) {
    //   if (state.tripDetails.designerDetails.designerUid !== undefined) {
    //     if (dbContext) {
    //       console.log(
    //         "fetching data from cloud for: ",
    //         state.tripDetails.designerDetails.designerUid
    //       )
    //       dbContext
    //         .firestore()
    //         .collection("users")
    //         .doc(`${state.tripDetails.designerDetails.designerUid}`)
    //         .collection("publicProfile")
    //         .doc(`${state.tripDetails.designerDetails.designerUid}`)
    //         .get()
    //         .then(function(doc) {
    //           if (doc.exists) {
    //             console.log("Document data:", doc.data())
    //             dispatch({
    //               type: "updateDesignerPublicProfile",
    //               data: doc.data(),
    //             })
    //           } else {
    //             // doc.data() will be undefined in this case
    //             console.log("No such document!")
    //           }
    //         })
    //         .catch(function(error) {
    //           console.log("Error getting document:", error)
    //         })
    //     }
    //   } else {
    //     //Show a designer not assigned yet image
    //   }
    //}
  }, [dbContext])

  const tabItemsList = tripDesignComplete
    ? tripState.type === "sample-for-traveler"
      ? [
          { name: "Itinerary", id: "Itinerary" },
          { name: "Other Info", id: "Recommendations" },
          // { name: "Reservations", id: "Bookings" },
          { name: "Checklist", id: "Checklist" },
          { name: "Trip Details", id: "TripDetails" },
        ]
      : [
          { name: "Trip Details", id: "TripDetails" },
          { name: "Itinerary", id: "Itinerary" },
          { name: "Other Info", id: "Recommendations" },
          // { name: "Reservations", id: "Bookings" },
          { name: "Checklist", id: "Checklist" },
          { name: "Messages", id: "Messages" },
        ]
    : [
        { name: "Trip Details", id: "TripDetails" },
        { name: "Messages", id: "Messages" },
      ]

  const renderTabItems = () => {
    return tabItemsList.map(item => (
      <li
        key={item.id}
        className={`${state.activeTab === item.id ? "is-active" : ""}`}
      >
        <a
          id={item.id}
          style={{
            letterSpacing: "0.5px",
            fontSize: "12px",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
          onClick={e => {
            dispatch({ type: "setActiveTab", data: `${e.currentTarget.id}` })
          }}
        >
          {item.name}
        </a>
      </li>
    ))
  }

  const renderNameField = () => {
    try {
      const fName = state.tripDetails.designerDetails.designerProfile.firstName
      const lName = state.tripDetails.designerDetails.designerProfile.lastName
      return <>{fName + " " + lName}</>
    } catch (error) {
      return <>"--"</>
    }
  }

  const renderSelectedComponent = () => {
    switch (state.activeTab) {
      case "TripDetails":
        if (state.tripDetails.designerDetails.status === "declined") {
          return (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "20px",
                }}
              >
                {/* Uh oh! <strong>{renderNameField()}</strong> wont be able to work
                on your travel plan, sorry! */}
              </p>
              {/* <p
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginTop: "20px",
                }}
              >
                Please pick a new travel designer
              </p> */}
              <PickADesigner reselectDesigner={true} />{" "}
            </>
          )
        } else {
          return (
            <>
              <TripDesignerCardComponent />
              <hr className="hr-branded"></hr>
              <TripDetailsComponent />
            </>
          )
        }
        break
      case "Itinerary":
        // return <ItineraryComponent />
        return <ItineraryViewer />
        break
      case "Bookings":
        // return <ReservationsComponent />
        return <ReservationViewer />
        break
      case "Checklist":
        // return <ChecklistComponent />
        return <ChecklistViewer />
        break
      case "Messages":
        return <MessageActivityContainer />
        break
      case "Recommendations":
        return <TripRecommendationsContainer canEdit={false} />
        break
      default:
        return <ShowError />
        break
    }
  }
  const getDates = data => {
    try {
      if (data) {
        console.log({ data })
        console.log(data.tripLogistics.travelDates)
        if (
          data.tripLogistics === undefined ||
          data.tripLogistics.travelDates === undefined ||
          data.tripLogistics.travelDates.start === "" ||
          data.tripLogistics.travelDates.end === ""
        )
          return "--"
        return `${data.tripLogistics.travelDates.start
          .toDate()
          .toLocaleDateString()} - ${data.tripLogistics.travelDates.end
          .toDate()
          .toLocaleDateString()}`
      }
    } catch (e) {
      console.log({ e })
      return `${new Date(
        data.tripLogistics.travelDates.start
      ).toLocaleDateString()} - ${new Date(
        data.tripLogistics.travelDates.end
      ).toLocaleDateString()}`
    }
  }

  return (
    <>
      {/* <GoToTop scrollStepInPx="80" delayInMs="30" /> */}
      {loading ? (
        <ShowLoading />
      ) : (
        <div className="columns">
          <div className="column is-1-desktop is-1-tablet "></div>
          <div className="column is-10-desktop is-10-tablet ">
            {/* <a
              onClick={() => {
                // onClose()
                // window.history.back()
                navigate("/app/dashboard")
              }}
              // className="is-text"
              style={{ color: "#4a4a4a" }}
            >
              <span className="icon">
                <i className="fas fa-chevron-left"></i>
              </span>
              <span
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                }}
              >
                Dashboard
              </span>
            </a> */}
            <div
              style={{ marginTop: "26px", marginBottom: "30px" }}
              align="center"
              className="is-centered destination-header"
            >
              <div
                style={{
                  // display: "flex",
                  alignItems: "flex-end",
                  textTransform: "uppercase",
                  justifyContent: "center",
                  fontSize: "18px",
                }}
              >
                <p
                  className="place"
                  style={{
                    fontWeight: "800",
                    borderBottom: "1px solid #fec600",
                    width: "fit-content",
                    letterSpacing: "0.5px",
                  }}
                >
                  {state.tripDetails.tripName === ""
                    ? state.tripDetails.tripPrimaryDestination.place
                    : state.tripDetails.tripName}
                </p>
                {/* <span
                  style={{
                    color: "#fec600",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  /
                </span> */}
                <p style={{ fontWeight: "400", fontSize: "14px" }}>
                  {/* {state.tripDetails.tripPrimaryDestination.country} */}
                  {getDates(state.tripDetails)}
                </p>
              </div>
            </div>

            <div className="tabs is-centered">
              <ul>{renderTabItems()}</ul>
            </div>
            {renderSelectedComponent()}
          </div>
          <div className="column is-1-desktop is-1-tablet "></div>
        </div>
      )}
    </>
  )
}

const SampleTravelPlan = ({ tripUid, location }) => {
  return (
    <TripStateProvider>
      <DashboardStateProvider>
        <SampleTravelPlanBase tripUid={tripUid} location={location} />
      </DashboardStateProvider>
    </TripStateProvider>
    // <TripDashboardCombinedProvider>
    //   <SampleTravelPlanBase tripUid={tripUid} />
    // </TripDashboardCombinedProvider>
  )
}
export default SampleTravelPlan

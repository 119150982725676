import React, { useState, useEffect, useContext } from "react"
import Select from "react-select"
import {
  cuisines,
  foodPreferences,
  vacationPacing,
  alcoholPreferences,
  vacationActivities,
} from "../../constants/data/general"
import { useTripStateStore } from "../../State/trip-state/trip-state-store"
import { FirebaseContext } from "../firebase"
import AuthUserContext from "../session/context"
import useRestrictCharacterLength from "../../hooks/useRestrictCharacterLength"
import { analytics } from "../firebase/firebase"
export function TripPreferencesFormElements({
  state,
  dispatch,
  onNext,
  onPrevious,
}) {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(750)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    try {
      if (state.tripPreferences.pacing === "") {
        dispatch({
          type: "updateTripPacing",
          data: "Balanced",
        })
      }
    } catch (err) {}
  }, [])

  const isSelected = pacing => {
    try {
      if (state.tripPreferences.pacing === pacing) return true
      else return false
    } catch (err) {}
  }

  const isActivitySelected = currentId => {
    if (state.tripPreferences.preferredActivities) {
      let a = state.tripPreferences.preferredActivities.findIndex(
        element => element.value === currentId.value
      )
      return a === -1 ? false : true
    }
  }

  return (
    <div className="columns fade-in">
      <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
      <div className="column is-6-desktop is-10-tablet is-12-mobile">
        <div
          style={{
            textAlign: "left",

            marginBottom: "40px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#fec600",
              letterSpacing: "0.5px",
              textAlign: "right",
            }}
          >
            2/3
          </p>
          <p
            style={{
              fontSize: "26px",
              fontWeight: "800",
              color: "#fec600",
              lineHeight: "30px",
              marginBottom: "15px",
            }}
          >
            Alright, tell us your travel preferences?
          </p>
          <p
            style={{
              fontSize: "14px",
            }}
          >
            The more details you can provide, the better.
          </p>
        </div>
        <div className="columns is-tablet is-multiline">
          <div className="column is-12-desktop is-12-tablet is-12-mobile">
            <div className="field">
              <label className="label" style={{ marginBottom: "7px" }}>
                Vacation pacing:{" "}
              </label>
              <div
                // style={{ display: "flex", flexDirection: "row" }}
                className="vacation-pacing"
              >
                <input
                  className="is-checkradio has-background-color is-warning"
                  id="radio_vacation_pacing_relaxed"
                  type="radio"
                  name="radio_vacation_pacing"
                  // disabled={isAccommodationNotRequired()}
                  // checked="checked"
                  checked={isSelected("Relaxed")}
                  onClick={e => {
                    dispatch({
                      type: "updateTripPacing",
                      data: "Relaxed",
                    })
                    // addOrRemove("vacationhomes", e.currentTarget.checked)
                  }}
                ></input>
                <label
                  for="radio_vacation_pacing_relaxed"
                  style={{ marginLeft: "8px" }}
                >
                  Relaxed
                </label>
                <input
                  className="is-checkradio has-background-color is-warning"
                  id="radio_vacation_pacing_balanced"
                  type="radio"
                  name="radio_vacation_pacing"
                  checked={isSelected("Balanced")}
                  // disabled={isAccommodationNotRequired()}
                  // checked="checked"
                  // checked={isSelected("vacationhomes")}
                  onClick={e => {
                    dispatch({
                      type: "updateTripPacing",
                      data: "Balanced",
                    })
                    // addOrRemove("vacationhomes", e.currentTarget.checked)
                  }}
                ></input>
                <label for="radio_vacation_pacing_balanced">Balanced</label>
                <input
                  className="is-checkradio has-background-color is-warning"
                  id="radio_vacation_pacing_fit_as_much"
                  type="radio"
                  name="radio_vacation_pacing"
                  checked={isSelected("Cover as much as possible")}
                  // disabled={isAccommodationNotRequired()}
                  // checked="checked"
                  // checked={isSelected("vacationhomes")}
                  onClick={e => {
                    dispatch({
                      type: "updateTripPacing",
                      data: "Cover as much as possible",
                    })
                    // addOrRemove("vacationhomes", e.currentTarget.checked)
                  }}
                ></input>
                <label for="radio_vacation_pacing_fit_as_much">
                  Cover as much as possible{" "}
                </label>
              </div>
            </div>
            <div className="control is-expanded" style={{ display: "none" }}>
              <Select
                // isMulti
                // className="basic-multi-select"
                defaultValue={vacationPacing.find(
                  element => element.label === state.tripPreferences.pacing
                )}
                classNamePrefix="select"
                isSearchable="true"
                isClearable="true"
                onChange={(value, actionMeta) => {
                  if (value)
                    dispatch({
                      type: "updateTripPacing",
                      data: value.label,
                    })
                }}
                options={vacationPacing}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#fef0bf",
                    primary: "#fed43f",
                    primary50: "#fee27f",
                    //neutral0: "#424242",
                  },
                })}
              />
            </div>
          </div>
          <div
            className="column is-6-desktop is-12-tablet is-12-mobile"
            style={{ display: "none" }}
          >
            {" "}
            <div className="field">
              <label className="label">Food preferences: </label>

              <div className="control is-expanded">
                <Select
                  defaultValue={state.tripPreferences.foodPreferences}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isSearchable="true"
                  isClearable="true"
                  onChange={(value, actionMeta) => {
                    if (value)
                      dispatch({
                        type: "updateFoodPreference",
                        data: Object.values(value),
                      })
                  }}
                  options={foodPreferences}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#fef0bf",
                      primary: "#fed43f",
                      primary50: "#fee27f",
                      //neutral0: "#424242",
                    },
                  })}
                />
                <p className="help">pick all that apply</p>
              </div>
            </div>
          </div>
          <div
            className="column is-6-desktop is-12-tablet is-12-mobile"
            style={{ display: "none" }}
          >
            <div className="field">
              <label className="label">Preferred cuisines:</label>
              {/* <p className="help">pick all that apply</p> */}

              <div className="control is-expanded">
                {/* <input className="input" type="text" placeholder="Text input" /> */}
                <Select
                  defaultValue={state.tripPreferences.preferredCuisines}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isSearchable="true"
                  isClearable="true"
                  onChange={(value, actionMeta) => {
                    if (value)
                      dispatch({
                        type: "updateCuisinePreference",
                        data: Object.values(value),
                      })
                  }}
                  placeholder="Select or start typing..."
                  options={cuisines}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#fef0bf",
                      primary: "#fed43f",
                      primary50: "#fee27f",
                      //neutral0: "#424242",
                    },
                  })}
                />
                <p className="help">pick all that apply</p>
              </div>
            </div>
          </div>
          <div
            className="column is-6-desktop is-12-tablet is-12-mobile"
            style={{ display: "none" }}
          >
            <div className="field">
              <label className="label">Drink preferences:</label>
              {/* <p className="help">pick all that apply</p> */}

              <div className="control is-expanded">
                {/* <input className="input" type="text" placeholder="Text input" /> */}
                <Select
                  defaultValue={state.tripPreferences.drinkPreferences}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isSearchable="true"
                  isClearable="true"
                  onChange={(value, actionMeta) => {
                    if (value)
                      dispatch({
                        type: "updateDrinkPreference",
                        data: Object.values(value),
                      })
                  }}
                  options={alcoholPreferences}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#fef0bf",
                      primary: "#fed43f",
                      primary50: "#fee27f",
                      //neutral0: "#424242",
                    },
                  })}
                />
                <p className="help">pick all that apply</p>
              </div>
            </div>
          </div>
          <div className="column is-12-desktop is-12-tablet is-12-mobile">
            {" "}
            <div className="field">
              <label className="label">Preferred vacation activities:</label>
              <p className="help" style={{ marginBottom: "7px" }}>
                select all that apply
              </p>
              {/* <p className="help">pick all that apply</p> */}
              <div className="tags">
                {vacationActivities.map((item, index) => {
                  return (
                    <button
                      className={`${
                        isActivitySelected(item)
                          ? "button is-warning is-small"
                          : "button is-light is-warning is-small"
                      }`}
                      id={item.value}
                      key={item.label}
                      style={{
                        marginRight: "12px",
                        marginBottom: "12px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        color: "#4a4a4a",
                        letterSpacing: "0.5px",
                        fontWeight: "400",
                        border: "1px solid #fec600",
                      }}
                      onClick={e => {
                        let a = item
                        console.log(a)
                        isActivitySelected(item)
                          ? dispatch({
                              type: "removeVacationActivities",
                              data: item,
                            })
                          : dispatch({
                              type: "addVacationActivities",
                              data: item,
                            })
                      }}
                    >
                      {item.label}
                    </button>
                  )
                })}
              </div>
              <div className="control is-expanded" style={{ display: "none" }}>
                {/* <input className="input" type="text" placeholder="Text input" /> */}
                <Select
                  defaultValue={state.tripPreferences.preferredActivities}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isSearchable="true"
                  isClearable="true"
                  placeholder="Select or start typing..."
                  onChange={(value, actionMeta) => {
                    if (value)
                      dispatch({
                        type: "updateVacationActivities",
                        data: Object.values(value),
                      })
                  }}
                  options={vacationActivities}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#fef0bf",
                      primary: "#fed43f",
                      primary50: "#fee27f",
                      //neutral0: "#424242",
                    },
                  })}
                />
                <p className="help">pick all that apply</p>
              </div>
            </div>
          </div>
          <div
            className="column is-12-desktop is-12-tablet is-12-mobile"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              flexDirection: "column",
              display: "none",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Didn't find an option your were looking for? <br></br>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Just mention it below.
              </span>
            </p>
            <i
              className="far fa-long-arrow-down fa-lg"
              style={{ color: "#fec600", marginTop: "7px" }}
            ></i>
          </div>
          <div className="column is-12-desktop is-12-tablet is-12-mobile">
            {/* <hr className="hr-branded"></hr> */}
            <span
              style={{
                color: "#4a4a4a",
                display: "block",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "0.5em",
              }}
            >
              Any other requirements you would like the designer to know?
            </span>

            <textarea
              onChange={e => {
                console.log(e.currentTarget.value)
                if (check(e.currentTarget.value.length)) return
                if (e.currentTarget.value)
                  dispatch({
                    type: "updateOtherTripPreferenceDetails",
                    data: e.currentTarget.value,
                  })
                else {
                  dispatch({
                    type: "updateOtherTripPreferenceDetails",
                    data: "",
                  })
                }
              }}
              value={state.tripPreferences.otherTripPreferenceDetails}
              className="textarea"
              placeholder="Preferred cuisines, vacation activities etc."
              rows="5"
            ></textarea>
            <p className={`help ${reachedMaxLength ? "is-danger" : ""}`}>
              Characters:{" "}
              {state.tripPreferences.otherTripPreferenceDetails.length}/
              {maxCharactersAllowed}
            </p>
          </div>
          {/* <div className="column is-2-desktop is-hidden-mobile"></div> */}
          <div className="column is-12-desktop is-12-tablet is-12-mobile">
            {onNext && (
              <div className="columns is-mobile is-multiline">
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {/* <hr className="hr-branded"></hr> */}
                </div>

                <div className="column is-6-desktop is-6-tablet is-5-mobile">
                  <button
                    className="button"
                    onClick={e => {
                      if (onPrevious) onPrevious()
                    }}
                  >
                    <i
                      className="fas fa-angle-left"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Logistics{" "}
                  </button>
                </div>
                <div className="column is-hidden-desktop is-hidden-tablet is-2-mobile"></div>
                <div className="column is-6-desktop is-6-tablet is-5-mobile">
                  <button
                    className="button is-warning"
                    style={{ float: "right" }}
                    onClick={e => {
                      if (onNext) {
                        dbContext
                          .firestore()
                          .collection("trips")
                          .doc(`${state.uid}`)
                          .collection("active-trips")
                          .doc(`${state.tripUid}`)
                          .update(state)
                          .then(function() {
                            console.log("Trip successfully updated!")
                            onNext()
                          })
                          .catch(error => {
                            console.log("Error getting document:", error)
                          })
                        analytics.logEvent("wizard_trip_summary", {
                          userId: `${authContext.uid}`,
                          tripId: `${state.tripUid}`,
                        })
                      }
                    }}
                  >
                    Traveler Info{" "}
                    <i
                      className="fas fa-angle-right"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* <div className="column is-2-desktop is-hidden-mobile"></div> */}
        </div>
      </div>
      <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
    </div>
  )
}

function TripPreferences({ onNext, onPrevious }) {
  const [state, dispatch] = useTripStateStore()

  useEffect(() => {
    //    console.info({ state })
    console.log("%cTrip-State", "color: #ffc600; font-size: 18px", { state })
  }, [state])
  return (
    <>
      <h3 align="center" className="subtitle is-3 align-center">
        {/* Trip preferences */}
      </h3>
      {/* <progress
        className="progress is-warning is-thin"
        value={state.wizardProgress}
        max="100"
      >
        25%
      </progress> */}

      <TripPreferencesFormElements
        state={state}
        dispatch={dispatch}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </>
  )
}

export default TripPreferences

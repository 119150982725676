import React, { useContext, useEffect } from "react"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import swal from "sweetalert"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
import { TripLogisticsFormComponents } from "../../../components/TripCreationComponents/TripLogisticsEntryPage"
import { TravellerDetailsTableFormElements } from "../../../components/TripCreationComponents/TravellerDetailsTable"
import { TripPreferencesFormElements } from "../../../components/TripCreationComponents/TripPreferences"
import AuthUserContext from "../../../components/session/context"
import Accordion from "../../../components/Accordion/Accordion"
import { getLabelValue } from "../../../utility/general"

const DisplayField = ({ labelText, data, setBgColor }) => {
  React.useEffect(() => {
    document.title = "Trip details | voyayge"
  }, [])

  return (
    <div className="columns">
      <div className="column is-0 is-hidden-mobile"></div>
      <div className="column is-3 padding-bottom-0">
        <label className="label">{labelText}</label>
      </div>
      <div className="column is-7 padding-top-0">
        <p> {data}</p>
      </div>
      <div className="column is-1-desktop is-hidden-mobile"></div>
    </div>
    //   <div
    //     className={`field is-horizontal ${setBgColor}
    // `}
    //   >
    //     <div className="my-custom-class">
    //       <div className="field-label">
    //         <label className="label">{labelText}</label>
    //       </div>
    //     </div>
    //     <div className="field-body">
    //       <div className="field">
    //         <div className="control is-expanded margin-right-20px">
    //           <p>{data}</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
  )
}

const DisplayTravelerDetails = () => {
  const [state, dispatch] = useDashboardStateStore()

  useEffect(() => {
    console.log({ state })
  }, [state])
  const populateTable = () => {
    return state.tripDetails.travelerDetails.map(detail => (
      <Tr key={detail.index}>
        <Td className="">{detail.firstName + " " + detail.lastName}</Td>
        <Td className="">{detail.gender || "--"}</Td>
        <Td className="">{detail.age || "--"}</Td>
        <Td className="">{detail.diet || "--"}</Td>
        <Td className="">{detail.wheelchair || "--"}</Td>
        <Td id={detail.index} className="">
          <div
            id={detail.index}
            className="link"
            onClick={e =>
              swal({
                title: "Additional Details",
                text: `${detail.additionalTravelerDetails ||
                  "No additional details"}`,
                // icon: "info",
                button: "close",
              })
            }
          >
            Additional Info
          </div>
        </Td>
        {/* <Td></Td> */}
      </Tr>
    ))
  }

  const displayRightData = params => {
    if (state.tripDetails.adultTravelerCount) {
      //This is legacy traveler details
      return (
        <div style={{ marginLeft: "17px" }}>
          <p style={{ marginBottom: "20px" }}>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginRight: "7px",
              }}
            >
              Primary Traveler:
            </span>
            <span>{`${state.tripDetails.travelerDetails[0].firstName} ${state.tripDetails.travelerDetails[0].lastName}`}</span>
          </p>
          <p style={{ marginBottom: "20px" }}>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginRight: "7px",
              }}
            >
              Total adults traveling:
            </span>
            <span>{state.tripDetails.adultTravelerCount}</span>
          </p>
          <p style={{ marginBottom: "20px" }}>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginRight: "7px",
              }}
            >
              Kids traveling:
            </span>
            <span>{state.tripDetails.kidsTravelerCount}</span>
          </p>
          <p style={{ marginBottom: "20px" }}>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginRight: "7px",
              }}
            >
              Anything particular your designer should keep in mind about the
              travelers?
            </span>
            <span
              style={{
                display: "block",
                marginTop: "7px",
              }}
            >
              {state.tripDetails.travelerAdditionalInfo}
            </span>
          </p>
        </div>
      )
    } else {
      return (
        <Table>
          <Thead>
            <Tr>
              <Th
                className=""
                style={{ textTransform: "uppercase", fontSize: "12px" }}
              >
                Name
              </Th>
              <Th
                className=""
                style={{ textTransform: "uppercase", fontSize: "12px" }}
              >
                Gender
              </Th>
              <Th
                className=""
                style={{ textTransform: "uppercase", fontSize: "12px" }}
              >
                Age
              </Th>
              <Th
                className=""
                style={{ textTransform: "uppercase", fontSize: "12px" }}
              >
                Diet
              </Th>
              <Th
                className=""
                style={{ textTransform: "uppercase", fontSize: "12px" }}
              >
                Wheelchair
              </Th>
              {/* <Th className=""></Th> */}
            </Tr>
          </Thead>
          <Tbody>{populateTable()}</Tbody>
        </Table>
      )
    }
  }

  return <>{displayRightData()}</>
}

//Todo: fix the layout, specifically with traveler details in edit mode.
const TripDetailsComponent = () => {
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  useEffect(() => {
    console.log("TripState inside Trip Details Component: ", tripState)
  }, [tripState])

  //ToDo: later optimize this check and only push to cloud if there are changes?
  const pushChangedStateToCloud = () => {
    if (dbContext) {
      dbContext
        .firestore()
        .collection("trips")
        .doc(`${authContext.uid}`)
        .collection("active-trips")
        .doc(`${state.tripDetails.tripUid}`)
        .update(tripState)
        .then(function() {
          console.log("Document successfully updated!")
          //Update travel manifest
          var oldManifest = [...state.travelManifest]
          var travelManifest = []
          console.log(tripState.tripName)
          oldManifest.map((item, index) => {
            if (item.tripUid == tripState.tripUid) {
              var updatedObj = {
                tripUid: item.tripUid,
                tripPrimaryDestination: tripState.tripPrimaryDestination,
                tripName: tripState.tripName,
                createdAt: item.createdAt,
                cardPictureURL: item.cardPictureURL,
                travelDates: tripState.tripLogistics.travelDates,
              }
              travelManifest.push(updatedObj)
            } else {
              travelManifest.push(item)
            }
          })
          //   var newObj = { travelManifest: { ...travelManifest } }
          dbContext
            .firestore()
            .collection("trips")
            .doc(`${authContext.uid}`)
            .update({ travelManifest: [...travelManifest] })
            .then(() => console.log("Travel manifest updated successfully!"))
          // console.log({ newObj })
        })
        .catch(error => {
          console.log("Error getting document:", error)
        })
    }
  }

  const getDates = data => {
    try {
      if (data) {
        console.log({ data })
        console.log(data.tripLogistics.travelDates)
        if (
          data.tripLogistics === undefined ||
          data.tripLogistics.travelDates === undefined ||
          data.tripLogistics.travelDates.start === "" ||
          data.tripLogistics.travelDates.end === ""
        )
          return "--"
        return `${data.tripLogistics.travelDates.start
          .toDate()
          .toLocaleDateString()} - ${data.tripLogistics.travelDates.end
          .toDate()
          .toLocaleDateString()}`
      }
    } catch (e) {
      console.log({ e })
      return `${new Date(
        data.tripLogistics.travelDates.start
      ).toLocaleDateString()} - ${new Date(
        data.tripLogistics.travelDates.end
      ).toLocaleDateString()}`
    }
  }

  const getLabelValues = data => {
    try {
      let a = []
      data.map(d => {
        if (d.label === "") {
        } else {
          a.push(d.label)
        }
      })
      console.log({ a })
      return a.length === 0 ? "- Not Specified -" : a.join(", ")
    } catch (e) {
      console.log({ e })
      return "--"
    }
  }
  const getPlaces = data => {
    try {
      let a = []
      data.map(d => {
        if (d.label === "") {
        } else {
          a.push(d.label)
        }
      })
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return "--"
    }
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  const showTripDestinations = () => {
    if (state.tripDetails.tripDestinations) {
      var destinationString = ""
      state.tripDetails.tripDestinations.map((item, index) => {
        console.log(state.tripDetails.tripDestinations.length, index)
        destinationString =
          destinationString +
          item.place +
          "-" +
          item.country +
          (state.tripDetails.tripDestinations.length === index + 1 ? "" : ", ")
      })
      return destinationString
    }
  }

  const faqWrapper = qNa => {
    return <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>{qNa}</div>
  }

  const showRecommendations = () => {
    try {
      if (tripState.destinationRecommendations) {
        return tripState.destinationRecommendations.map((item, index) => {
          return faqWrapper(
            <Accordion
              header={
                item.id.recommenderName //+
                // " " +
                // "(" +
                // item.id.recommenderEmail +
                // ")"
              }
              data={item.id.recommendation}
              fontSize="16px"
            />
          )
        })
      } else {
        if (state.tripDetails.designerDetails.status === "active")
          return (
            <div style={{ marginLeft: "0px" }}>
              <p style={{ fontSize: "16px", fontWeight: "400" }}>
                -{" "}
                <span
                  className="link"
                  onClick={() => {
                    let e = document.getElementById("send-invite")
                    if (e) e.scrollIntoView()
                  }}
                >
                  Send invites{" "}
                </span>
                to see your friends & family recommendations here. -
              </p>
            </div>
          )
        else return "--"
      }
    } catch (err) {
      console.log({ err })
      return "--"
    }
  }

  const displayAccommodationDetails = () => {
    try {
      if (state.tripDetails.tripLogistics.isAccommodationNotRequired) {
        return (
          <DisplayField
            labelText="Accommodation preference:"
            data={"Accommodation recommendation not required"}
          />
        )
      } else {
        return (
          <>
            {" "}
            <DisplayField
              labelText="Accommodation preference:"
              data={getLabelValues(
                state.tripDetails.tripLogistics.accommodationPreference
              )}
            />
            <DisplayField
              labelText="Accommodation details:"
              data={
                state.tripDetails.tripLogistics.accommodationDetails === "" ||
                state.tripDetails.tripLogistics.accommodationDetails ===
                  undefined
                  ? "- None -"
                  : state.tripDetails.tripLogistics.accommodationDetails
              }
            />
          </>
        )
      }
    } catch (err) {
      console.log(err)

      return (
        <>
          <DisplayField
            labelText="Accommodation preference:"
            data={"- None -"}
          />
          <DisplayField labelText="Accommodation details:" data={"- None -"} />
        </>
      )
    }
  }

  const displayFoodPreferences = () => {
    try {
      if (state.tripDetails.tripPreferences.foodPreferences) {
        if (state.tripDetails.tripPreferences.foodPreferences[0] !== "")
          return (
            <DisplayField
              labelText="Food preferences:"
              data={getLabelValues(
                state.tripDetails.tripPreferences.foodPreferences
              )}
            />
          )
      }
    } catch (err) {
      return ""
    }
  }
  const displayDrinkPreferences = () => {
    try {
      if (state.tripDetails.tripPreferences.drinkPreferences) {
        if (state.tripDetails.tripPreferences.drinkPreferences[0] !== "")
          return (
            <DisplayField
              labelText="Drink preferences:"
              data={getLabelValues(
                state.tripDetails.tripPreferences.drinkPreferences
              )}
            />
          )
      }
    } catch (err) {
      return ""
    }
  }

  return (
    <>
      {state.tripDetails && (
        <>
          {" "}
          <h3
            style={{
              marginTop: "28px",
              textTransform: "uppercase",
              fontWeight: "800",
            }}
            className="subtitle is-4"
            // style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
          >
            {" "}
            Trip Logistics<span style={{ color: "#fec600" }}>.</span>
          </h3>
          {globalState.currentSessionRole === "traveler" && !state.editLogistics
            ? ""
            : // Disabled for now.
              // <button
              //   className="button is-warning is-small is-pulled-right"
              //   onClick={e => {
              //     dispatch({ type: "toggleLogisticsEditMode" })
              //     //window.scrollTo(0, editProfileRef.current.offsetTop)
              //   }}
              // >
              //   Edit Trip Logistics
              // </button>
              ""}
          {state.editLogistics ? (
            <>
              {/* <hr className="hr-branded"></hr> */}
              <div className="columns is-centered branded-background">
                <div className="column">
                  <TripLogisticsFormComponents
                    state={tripState}
                    dispatch={tripStateDispatch}
                  />

                  <div
                    className="field is-grouped is-grouped-right"
                    style={{ marginTop: "20px" }}
                  >
                    <p className="control">
                      <a
                        className="button is-light"
                        onClick={() => {
                          dispatch({ type: "toggleLogisticsEditMode" })
                        }}
                      >
                        Cancel
                      </a>
                    </p>
                    <p className="control">
                      <a
                        className="button is-warning"
                        onClick={() => {
                          pushChangedStateToCloud()
                          dispatch({
                            type: "syncTripDetailsAndTripState",
                            data: tripState,
                          })
                          dispatch({ type: "toggleLogisticsEditMode" })
                        }}
                      >
                        Save Changes
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* <hr className="hr-branded"></hr> */}
            </>
          ) : (
            <>
              <div className="columns is-centered branded-background">
                {/* <div className="column is-three-quarters"> */}
                <div className="column">
                  {/* <DisplayField
                    labelText="Trip name:"
                    data={state.tripDetails.tripName}
                  /> */}
                  <DisplayField
                    labelText="Trip type:"
                    data={
                      state.tripDetails.tripLogistics
                        ? getLabelValue(
                            state.tripDetails.tripLogistics.tripType
                          )
                        : "--"
                    }
                  />
                  <DisplayField
                    labelText="Destination:"
                    data={showTripDestinations()}
                    setBgColor="toggle-field-background"
                  />
                  {/* <DisplayField
                    labelText="Purpose of the trip:"
                    data={state.tripDetails.tripLogistics.tripsPurpose}
                  /> */}
                  <DisplayField
                    labelText="Scheduled travel dates:"
                    data={getDates(state.tripDetails)}
                    setBgColor="toggle-field-background"
                  />
                  {/* <DisplayField
                    labelText="Travel dates flexible:"
                    data={
                      state.tripDetails.tripLogistics.travelDatesFlexible
                        ? "Yes"
                        : "No"
                    }
                  /> */}
                  {/* <DisplayField
                    style={{ display: "none" }}
                    labelText="Trip budget:"
                    data={`${formatter.format(
                      state.tripDetails.tripLogistics.totalTripBudgetRange
                        .minBudget
                    )}`}
                    setBgColor="toggle-field-background"
                  /> */}
                  {displayAccommodationDetails()}
                  {/* <DisplayField
                    labelText="Accommodation preference:"
                    data={getLabelValues(
                      state.tripDetails.tripLogistics.accommodationPreference
                    )}
                  />
                  <DisplayField
                    labelText="Accommodation details:"
                    data={
                      state.tripDetails.tripLogistics.accommodationDetails ===
                      ""
                        ? "- None -"
                        : state.tripDetails.tripLogistics.accommodationDetails
                    }
                  /> */}
                </div>
              </div>
            </>
          )}
          <hr className="hr-branded"></hr>
          <h3
            style={{ marginTop: "26px" }}
            className="subtitle is-4"
            style={{
              marginTop: "28px",
              textTransform: "uppercase",
              fontWeight: "800",
            }}
            // style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
          >
            Traveler Details<span style={{ color: "#fec600" }}>.</span>
          </h3>
          {globalState.currentSessionRole === "traveler" &&
          !state.editTravelerDetails
            ? ""
            : //disabled for now
              // <button
              //   className="button is-warning is-small is-pulled-right"
              //   onClick={e => {
              //     dispatch({ type: "toggleTravelerDetailsEditMode" })
              //     //window.scrollTo(0, editProfileRef.current.offsetTop)
              //   }}
              // >
              //   Edit Traveler Details
              // </button>
              ""}
          {state.editTravelerDetails ? (
            <>
              {/* <hr className="hr-branded"></hr> */}
              <div className="columns is-centered branded-background highlight-background">
                <div className="column">
                  <TravellerDetailsTableFormElements
                    state={tripState}
                    dispatch={tripStateDispatch}
                  />

                  <div
                    className="field is-grouped is-grouped-right"
                    style={{ marginTop: "20px" }}
                  >
                    <p className="control">
                      <a
                        className="button is-light"
                        onClick={() => {
                          dispatch({ type: "toggleTravelerDetailsEditMode" })
                        }}
                      >
                        Cancel
                      </a>
                    </p>
                    <p className="control">
                      <a
                        className="button is-warning"
                        onClick={() => {
                          pushChangedStateToCloud()
                          dispatch({
                            type: "syncTripDetailsAndTripState",
                            data: tripState,
                          })
                          dispatch({
                            type: "toggleTravelerDetailsEditMode",
                          })
                        }}
                      >
                        Save Changes
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* <hr className="hr-branded"></hr>{" "} */}
            </>
          ) : (
            <>
              {/* <hr className="hr-branded"></hr> */}
              <div className="columns is-centered branded-background">
                {/* <div className="column is-three-quarters"> */}
                <div className="column">
                  <DisplayTravelerDetails />
                </div>
              </div>
            </>
          )}
          <hr className="hr-branded"></hr>
          <h3
            style={{ marginTop: "26px" }}
            className="subtitle is-4"
            style={{
              marginTop: "28px",
              textTransform: "uppercase",
              fontWeight: "800",
            }}
          >
            Traveler Preferences<span style={{ color: "#fec600" }}>.</span>
          </h3>
          {globalState.currentSessionRole === "traveler" &&
          !state.editTravelerPreferences
            ? ""
            : //disabled for now
              // <button
              //   className="button is-warning is-small is-pulled-right"
              //   onClick={e => {
              //     dispatch({ type: "toggleTravelerPreferencesEditMode" })
              //     //window.scrollTo(0, editProfileRef.current.offsetTop)
              //   }}
              // >
              //   Edit Traveler Preferences
              // </button>
              ""}
          {state.editTravelerPreferences ? (
            <>
              <hr className="hr-branded"></hr>
              <div className="columns is-centered branded-background">
                <div className="column">
                  <TripPreferencesFormElements
                    state={tripState}
                    dispatch={tripStateDispatch}
                  />
                  <div
                    className="field is-grouped is-grouped-right"
                    style={{ marginTop: "20px" }}
                  >
                    <p className="control">
                      <a
                        className="button is-light"
                        onClick={() => {
                          dispatch({
                            type: "toggleTravelerPreferencesEditMode",
                          })
                        }}
                      >
                        Cancel
                      </a>
                    </p>
                    <p className="control">
                      <a
                        className="button is-warning"
                        onClick={() => {
                          pushChangedStateToCloud()
                          dispatch({
                            type: "syncTripDetailsAndTripState",
                            data: tripState,
                          })
                          dispatch({
                            type: "toggleTravelerPreferencesEditMode",
                          })
                        }}
                      >
                        Save Changes
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <hr className="hr-branded"></hr>
            </>
          ) : (
            <>
              {/* <hr className="hr-branded"></hr> */}
              <div className="columns is-centered branded-background">
                {/* <div className="column is-three-quarters"> */}
                <div className="column">
                  <DisplayField
                    labelText="Vacation pacing:"
                    data={state.tripDetails.tripPreferences.pacing}
                  />
                  {displayFoodPreferences()}
                  {displayDrinkPreferences()}
                  {/* <DisplayField
                    labelText="Food preferences:"
                    data={getLabelValues(
                      state.tripDetails.tripPreferences.foodPreferences
                    )}
                  /> */}
                  {/* <DisplayField
                    labelText="Preferred cuisines:"
                    data={getLabelValues(
                      state.tripDetails.tripPreferences.preferredCuisines
                    )}
                    setBgColor="toggle-field-background"
                  /> */}
                  {/* <DisplayField
                    labelText="Drink preferences:"
                    data={getLabelValues(
                      state.tripDetails.tripPreferences.drinkPreferences
                    )}
                  /> */}
                  <DisplayField
                    labelText="Preferred vacation activities:"
                    data={getLabelValues(
                      state.tripDetails.tripPreferences.preferredActivities
                    )}
                    setBgColor="toggle-field-background"
                  />
                  <DisplayField
                    labelText="Additional details:"
                    data={
                      state.tripDetails.tripPreferences
                        .otherTripPreferenceDetails === ""
                        ? "- None -"
                        : state.tripDetails.tripPreferences
                            .otherTripPreferenceDetails
                    }
                  />
                </div>
              </div>

              <hr className="hr-branded"></hr>
            </>
          )}
          <h3
            style={{ marginTop: "26px" }}
            className="subtitle is-4"
            style={{
              marginTop: "28px",
              textTransform: "uppercase",
              fontWeight: "800",
            }}
          >
            Friends & Family - Destination Recommendations
            <span style={{ color: "#fec600" }}>.</span>
          </h3>
          <div className="columns is-centered branded-background">
            <div className="column">{showRecommendations()}</div>
          </div>
          <hr className="hr-branded"></hr>
        </>
      )}
    </>
  )
}
export default TripDetailsComponent

import { createContext } from "react"
import { tripStateReducer } from "./trip-state-reducer"
import { useImmerReducer } from "use-immer"
import React from "react"

const defaultTripState = {
  tripUid: 0, //unique trip identifier
  uid: 0, //User identifier - this is how trips are tied to a particular user
  travelerId: 0,
  tripCreatedDateTime: 0,
  tripUpdatedDateTime: 0,
  tripName: "",
  designerReviewed: false,
  tripPrimaryDestination: "",
  tripDestinations: [],
  tripImageURL: "",
  adultTravelerCount: 1,
  kidsTravelerCount: 0,
  tripLogistics: {
    tripsPurpose: "",
    noSpecificTravelDatesChecked: false,
    travelDates: {
      start: "",
      end: "",
    },
    travelDatesFlexible: false,
    adultTravelerCount: 1,
    childrenTravelerCount: 0,
    totalTripBudgetRange: {
      minBudget: 0,
      maxBudget: 0,
    },
    accommodationPreference: [],
  },
  tripPreferences: {
    pacing: "",
    foodPreferences: [""],
    preferredCuisines: [""],
    drinkPreferences: [""],
    preferredActivities: [],
    otherTripPreferenceDetails: "",
  },
  travelerDetails: [
    {
      uid: 0,
      index: 0,
      primary: false,
      travelerId: 0,
      travelerEmailId: "",
      firstName: "",
      lastName: "",
      gender: "",
      diet: "",
      age: "",
      wheelchair: "No",
      additionalTravelerDetails: "",
    },
  ],
  designerDetails: { status: "unassigned", designerUid: "" },
  // designerPhotoUrl: "",
  // designerName: "",
  // Itinerary: [
  //   {
  //     date: "",
  //   },
  // ],
  wizardStatus: "0",
}

const tripStateContext = createContext(defaultTripState)
const dispatchTripStateContext = createContext(undefined)

export const useTripStateStore = () => [
  React.useContext(tripStateContext),
  React.useContext(dispatchTripStateContext),
]

export const TripStateProvider = ({ children }) => {
  const [tripState, tripDispatch] = useImmerReducer(
    tripStateReducer,
    defaultTripState
  )
  return (
    <tripStateContext.Provider value={tripState}>
      <dispatchTripStateContext.Provider value={tripDispatch}>
        {children}
      </dispatchTripStateContext.Provider>
    </tripStateContext.Provider>
  )
}

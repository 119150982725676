import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"
// import reservations from "./../../../images/reservations/reservations.svg"
import ReservationCard from "./ReservationCard"
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table"
import DateTimePicker from "react-datetime-picker"
const ReservationViewer = () => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  const [inEditMode, setInEditMode] = useState(false)

  const addReservationItem = () => {
    dispatch({ type: "initializeReservationTempItem" })
  }

  useEffect(() => {
    try {
      if (state.currentJobInfo.tripReservationSyncRequired) {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${authContext.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({ tripReservations: state.currentJobInfo.tripReservations })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => console.log(e))
        }

        dispatch({ type: "tripReservationSyncRequired", data: false })
      }
    } catch (error) {
      console.log(error)
    }
  }, [state.currentJobInfo.tripReservationSyncRequired])

  useEffect(() => {
    if (state.currentJobInfo.tripReservationTemp) setInEditMode(true)
  }, [state.currentJobInfo.tripReservationTemp])

  const pickRightDateFormat = () => {
    try {
      return state.currentJobInfo.tripReservationTemp.dateTime === ""
        ? new Date()
        : state.currentJobInfo.tripReservationTemp.dateTime.toDate()
    } catch (error) {
      console.log(error)
      return state.currentJobInfo.tripReservationTemp.dateTime === ""
        ? new Date()
        : state.currentJobInfo.tripReservationTemp.dateTime
    }
  }
  const onEdit = e => {
    dispatch({ type: "setReservationTempForEdit", index: e.currentTarget.id })
  }

  const showReservationEntryForm = () => {
    return (
      <div className="columns">
        <div className="column is-3 is-desktop"></div>
        <div className="column is-6 is-desktop">
          <hr className="hr-branded"></hr>
          <div style={{ marginTop: "20px" }}>
            <div className="field">
              <label className="label">Venue or Event Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={state.currentJobInfo.tripReservationTemp.name || "--"}
                  onChange={e =>
                    dispatch({
                      type: "updateVenueName",
                      data: e.currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Reservation Date and Time</label>
              <div className="control">
                <DateTimePicker
                  onChange={value => {
                    dispatch({
                      type: "updateReservationDateTime",
                      data: value,
                    })
                  }}
                  value={pickRightDateFormat()}
                  disableClock={true}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Confirmation/Ticker Number:</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={
                    state.currentJobInfo.tripReservationTemp.confirmationNumber
                  }
                  onChange={e =>
                    dispatch({
                      type: "updateConfirmationNumber",
                      data: e.currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Additional Info: </label>
              <div className="control">
                <textarea
                  className="textarea"
                  rows="3"
                  value={
                    state.currentJobInfo.tripReservationTemp.additionalNote
                  }
                  onChange={e =>
                    dispatch({
                      type: "updateAdditionalNote",
                      data: e.currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field is-grouped is-grouped-right">
              <p className="control">
                <a
                  className="button is-light"
                  onClick={e => {
                    dispatch({ type: "clearReservationTempItem" })
                    setInEditMode(false)
                  }}
                >
                  Cancel
                </a>
              </p>
              <p className="control">
                <a
                  className="button is-warning"
                  onClick={e => {
                    state.currentJobInfo.tripReservationEditExisting
                      ? //If editing an existing reservation
                        dispatch({
                          type: "saveReservationChanges",
                          index: state.currentJobInfo.tripReservationTemp.index,
                        })
                      : // Adding a new reservation.
                        dispatch({
                          type: "addReservation",
                        })
                    // Required to notify a cloud DB update
                    dispatch({
                      type: "tripReservationSyncRequired",
                      data: true,
                    })
                    //Close reservation entry form
                    setInEditMode(false)
                  }}
                >
                  {state.currentJobInfo.tripReservationEditExisting
                    ? "Save Changes"
                    : "Add Reservation"}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="column is-3 is-desktop"></div>
      </div>
    )
  }
  const compareReservationStartDate = (a, b) => {
    if (a.dateTime.seconds > b.dateTime.seconds) return 1
    if (a.dateTime.seconds < b.dateTime.seconds) return -1
    return 0
  }
  const showReservations = () => {
    if (state.currentJobInfo.tripReservations.length > 0)
      var reservationsList = state.currentJobInfo.tripReservations.slice()
    if (reservationsList) reservationsList.sort(compareReservationStartDate)
    if (reservationsList)
      return reservationsList.map((item, index) => {
        console.log({ item })
        return (
          <ReservationCard
            key={index}
            data={item}
            onEdit={onEdit}
            canEdit={false}
          />
        )
      })
    // return (
    //   <Table>
    //     <Thead>
    //       <Tr>
    //         <Th className="" style={{ paddingBottom: "10px" }}>
    //           Reservation Date
    //         </Th>
    //         <Th className="" style={{ paddingBottom: "10px" }}>
    //           Reservation Time
    //         </Th>
    //         <Th className="" style={{ paddingBottom: "10px" }}>
    //           Confirmation No.{" "}
    //         </Th>
    //         <Th className="" style={{ paddingBottom: "10px" }}>
    //           Venue Name
    //         </Th>
    //         <Th className="" style={{ paddingBottom: "10px" }}>
    //           Additional Info
    //         </Th>
    //       </Tr>
    //     </Thead>
    //     <Tbody>
    //       {state.currentJobInfo.tripReservations.map((item, index) => {
    //         console.log({ item })
    //         return (
    //           <ReservationCard
    //             key={index}
    //             data={item}
    //             onEdit={onEdit}
    //             canEdit={false}
    //           />
    //         )
    //       })}
    //     </Tbody>
    //   </Table>
    // )
    else return ""
  }

  return (
    <>
      {inEditMode ? (
        showReservationEntryForm()
      ) : (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
          className=""
        >
          {/* <div
            style={{
              marginBottom: "20px",
            }}
          >
            <button className="button is-warning" onClick={addReservationItem}>
              <span className="icon is-small">
                <i className="far fa-plus-square"></i>
              </span>
              <span>Add a new reservation</span>
            </button>
          </div> */}
          <p style={{ fontSize: "22px", fontWeight: "600px" }}>
            Your Reservations
          </p>
          <hr className="hr-branded"></hr>
          <div
            className=""
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            {showReservations()}
          </div>

          {/* <div style={{ zIndex: "-5", position: "absolute", top: "50px" }}>
            <img
              src={reservations}
              style={{ maxWidth: "400px", marginTop: "80px" }}
            ></img>
          </div> */}
        </div>
      )}
    </>
  )
}

export default ReservationViewer

import moment from "moment"
import React, { useState } from "react"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

export const MoveActivityConfirmationModal = ({
  onConfirm,
  title,
  text,
  startDate,
  endDate,
  sendBackValue,
  cancelText,
  confirmationText,
  selectedIndex,
  hideWishlist = false,
}) => {
  var selectedItem = ""
  var buttonList = []
  var selectedDate = "--"
  var getDaysArray = function(s, e) {
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d))
      buttonList.push("")
    }
    return a
  }

  const resetButtons = () => {
    try {
      getDaysArray(startDate, endDate).map((item, index) => {
        var element = document.getElementById(`move-confirmation-item-${index}`)

        if (element) {
          element.classList.remove("is-warning")
        }
      })
      var element = document.getElementById(`move-confirmation-item-${9999}`)

      if (element) {
        element.classList.remove("is-warning")
      }
    } catch (error) {}
  }

  const parseIndexFromString = inputString => {
    try {
      //const regex = /Day:(\d*)-Item:(\d*)/gm
      const regex = /move-confirmation-item-(\d+)/gm
      let m = regex.exec(inputString)
      var z = []
      m.forEach((match, groupIndex) => {
        console.log(`Found match, group ${groupIndex}: ${match}`)
        z.push(match)
      })
      return z
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const showDateButtons = () => {
    console.log({ startDate })
    console.log({ endDate })
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          userSelect: "none",
          margin: "10px auto",
          marginBottom: "20px",
        }}
      >
        {getDaysArray(startDate, endDate).map((item, index) => {
          var date = item.getDate()

          // console.log({ index })
          // console.log({ selectedIndex })
          if (index !== parseInt(selectedIndex)) {
            return (
              <div
                key={date}
                id={`move-confirmation-item-${index}`}
                className="button is-text"
                onClick={e => {
                  try {
                    selectedItem = parseIndexFromString(e.currentTarget.id)[1]
                    console.log(selectedItem)
                    let mButton = document.getElementById("move-activity")
                    if (mButton) mButton.disabled = false
                    resetButtons()
                    var element = document.getElementById(e.currentTarget.id)
                    // console.log(element)
                    if (element) {
                      element.classList.add("is-warning")
                    }
                    selectedDate = moment(item).format("L")
                    var selectedDataDisplayElement = document.getElementById(
                      "selected-date-display"
                    )
                    if (selectedDataDisplayElement)
                      selectedDataDisplayElement.innerHTML = moment(
                        item
                      ).format("L")
                  } catch (err) {
                    console.log(err)
                  }
                }}
                style={{
                  margin: "5px",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "rgba(78,78,78,1)",
                    //   marginTop: "-6px",
                  }}
                  align="center"
                >
                  {moment(item).format("L")}
                </p>
              </div>
            )
          }
        })}
        {!hideWishlist && (
          <div
            id={`move-confirmation-item-${9999}`}
            className="button is-text"
            onClick={e => {
              try {
                selectedItem = parseIndexFromString(e.currentTarget.id)[1]
                console.log(selectedItem)
                let mButton = document.getElementById("move-activity")
                if (mButton) mButton.disabled = false
                resetButtons()
                var element = document.getElementById(e.currentTarget.id)
                // console.log(element)
                if (element) {
                  element.classList.add("is-warning")
                }
                selectedDate = "Wishlist"
                var selectedDataDisplayElement = document.getElementById(
                  "selected-date-display"
                )
                if (selectedDataDisplayElement)
                  selectedDataDisplayElement.innerHTML = "Wishlist"
              } catch (err) {
                console.log(err)
              }
            }}
            style={{
              margin: "5px",
              width: "100px",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "rgba(78,78,78,1)",
                //   marginTop: "-6px",
              }}
              align="center"
            >
              Wishlist
            </p>
          </div>
        )}
      </div>
    )
  }

  console.log({ onConfirm })
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className="box"
          style={{
            width: "400px",
            //height: "200px",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
          }}
        >
          {/* <p style={{ fontSize: "20px", padding: "10px", fontWeight: "500" }}>
            {title}
          </p> */}
          <p
            style={{ fontSize: "16px", padding: "10px", paddingBottom: "none" }}
          >
            {text}
          </p>
          <p
            id="selected-date-display"
            style={{ fontSize: "18px", padding: "10px", margin: "15px auto" }}
          >
            {selectedDate}
          </p>
          {showDateButtons()}
          <button
            className="button is-warning is-inverted"
            style={{ width: "160px", padding: "10px", margin: "10px" }}
            onClick={onClose}
          >
            {cancelText ?? "Cancel"}
          </button>
          <button
            id="move-activity"
            className="button is-warning"
            // disabled
            style={{ width: "160px", padding: "10px", margin: "10px" }}
            onClick={() => {
              if (selectedItem == "") return
              console.log("here")
              onConfirm(selectedItem)
              onClose()
            }}
          >
            {confirmationText ?? "Move Activity"}
          </button>
        </div>
      )
    },
  })
}

export const ConfirmationActivityOverwriteModal = ({
  onOverwrite,
  onDuplicate,
  title,
  text,
  sendBackValue,
  cancelText,
}) => {
  console.log("test")
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className="box"
          style={{
            width: "400px",
            //height: "200px",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
          }}
        >
          <p style={{ fontSize: "20px", padding: "10px", fontWeight: "500" }}>
            {title}
          </p>
          <p style={{ fontSize: "16px", padding: "10px" }}>{text}</p>
          <button
            className="button is-warning is-inverted"
            style={{
              width: "160px",
              padding: "10px",
              margin: "10px",
              fontSize: "14px",
            }}
            onClick={onClose}
          >
            {cancelText ?? "Cancel"}
          </button>
          <button
            className="button is-warning"
            style={{
              width: "160px",
              padding: "10px",
              margin: "10px",
              fontSize: "14px",
            }}
            onClick={() => {
              onOverwrite(sendBackValue)
              onClose()
            }}
          >
            {"Overwrite"}
          </button>
          <button
            className="button is-warning"
            style={{
              width: "160px",
              padding: "10px",
              margin: "10px",
              fontSize: "14px",
            }}
            onClick={() => {
              onDuplicate(sendBackValue)
              onClose()
            }}
          >
            {"Duplicate"}
          </button>
        </div>
      )
    },
  })
}

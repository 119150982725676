import loadable from "@loadable/component"
// import AlgoliaPlaces from "algolia-places-react"
import GoogleMapReact from "google-map-react"
import is from "is_js"
import { findIndex, isEqual, uniqWith, debounce } from "lodash"
import moment from "moment"
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react"
import { DateRangePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "react-day-picker/lib/style.css"
// import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import Select from "react-select"
import {
  accommodationPreference,
  countryCode,
  vacationType,
} from "../../constants/data/general"
import useRestrictCharacterLength from "../../hooks/useRestrictCharacterLength"
import { useDashboardStateStore } from "../../State/dashboard-state/dashboard-state"
import { useGlobalStateStore } from "../../State/global-state/global-state"
import { useTripStateStore } from "../../State/trip-state/trip-state-store"
import { FirebaseContext } from "../firebase"
import { analytics, functions } from "../firebase/firebase"
import AuthUserContext from "../session/context"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { dottedText } from "../../utility/general"
//import { MultiValueGeneric } from "react-select/src/components/MultiValue"
const AlgoliaPlaces = loadable(() => import("algolia-places-react"))
const ShowDestinationMap = ({ destinationState, seed }) => {
  const mapRef = useRef()
  const MyMarker = () => (
    <div className="marker">
      <i
        className="fas fa-map-marker-alt fa-3x"
        style={{ color: "#ffc600" }}
      ></i>
    </div>
  )
  const createMapOptions = maps => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      // styles: [
      //   {
      //     stylers: [
      //       { saturation: -100 },
      //       { gamma: 0.8 },
      //       { lightness: 8 },
      //       { visibility: "on" },
      //     ],
      //   },
      // ],
      styles: [
        {
          featureType: "all",
          elementType: "geometry.fill",
          stylers: [
            {
              weight: "2.00",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#9c9c9c",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              color: "#f2f2f2",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7b7b7b",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              color: "#46bcec",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c8d7d4",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#070707",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
      ],
      // [
      //   { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      //   { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      //   { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      //   {
      //     featureType: "administrative.locality",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "poi",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "poi.park",
      //     elementType: "geometry",
      //     stylers: [{ color: "#263c3f" }],
      //   },
      //   {
      //     featureType: "poi.park",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#6b9a76" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "geometry",
      //     stylers: [{ color: "#38414e" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "geometry.stroke",
      //     stylers: [{ color: "#212a37" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#9ca5b3" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "geometry",
      //     stylers: [{ color: "#746855" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "geometry.stroke",
      //     stylers: [{ color: "#1f2835" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#f3d19c" }],
      //   },
      //   {
      //     featureType: "transit",
      //     elementType: "geometry",
      //     stylers: [{ color: "#2f3948" }],
      //   },
      //   {
      //     featureType: "transit.station",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "geometry",
      //     stylers: [{ color: "#17263c" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#515c6d" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "labels.text.stroke",
      //     stylers: [{ color: "#17263c" }],
      //   },
      // ],
    }
  }

  var bounds = ""
  // if (state.tripDestinations) {
  //   if (state.tripDestinations.length === 0) return ""
  //   if (centerLat !== state.tripDestinations[0].latlng.lat)
  //     setCenterLat(state.tripDestinations[0].latlng.lat)
  //   if (centerLng !== state.tripDestinations[0].latlng.lng)
  //     setCenterLng(state.tripDestinations[0].latlng.lng)
  return (
    <div
      style={{
        height: "200px",
        width: "100%",
        border: "2px solid #fec600",
        borderRadius: "6px",
        marginTop: "10px",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `${process.env.GOOGLE_MAPS_API_KEY}`,
          language: "en",
          region: "US",
        }}
        // defaultCenter={{ lat: centerLat, lng: centerLng }}
        defaultCenter={{ lat: 36.1673, lng: -115.149 }}
        defaultZoom={12}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map
          console.log({ mapRef })
        }}
      >
        {destinationState.map((item, index) => {
          if (index === 0) {
            if (window.google) bounds = new window.google.maps.LatLngBounds()
            //   if (centerLat !== item.latlng.lat) setCenterLat(item.latlng.lat)
            //   if (centerLng !== item.latlng.lng) setCenterLng(item.latlng.lng)
            if (mapRef.current) {
              mapRef.current.setCenter({
                lat: item.latlng ? item.latlng.lat : 0,
                lng: item.latlng ? item.latlng.lng : 0,
              })
              mapRef.current.setZoom(12)
            }
          }

          console.log({ bounds })
          if (bounds !== "")
            bounds.extend({
              lat: item.latlng ? item.latlng.lat : 0,
              lng: item.latlng ? item.latlng.lng : 0,
            })
          if (index > 0 && mapRef.current) mapRef.current.fitBounds(bounds)

          return (
            <MyMarker
              key={index}
              lat={item.latlng ? item.latlng.lat : 0}
              lng={item.latlng ? item.latlng.lng : 0}
            />
          )
        })}
      </GoogleMapReact>
      {/* <Map
          google={props.google}
          zoom={8}
          //style={mapStyles}
          initialCenter={{ lat: centerLat, lng: centerLng }}
        /> */}
    </div>
  )
  // }
}

// import { FirebaseContext } from "../firebase"
// import AuthUserContext from "../session/context"
export const TripLogisticsFormComponents = ({
  state,
  dispatch,
  onNext,
  onPrevious,
}) => {
  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   errors,
  //   triggerValidation,
  //   clearError,
  //   control,
  //   setValue,
  // } = useForm()
  const maxDestinationsAllowed = 10
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [dashboardState, dispatchDashboard] = useDashboardStateStore()
  const [placeValue, setPlaceValue] = useState({
    inputValue: "",
    value: [""],
  })
  const [buildYourOwn, setBuildYourOwn] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [destinationError, setDestinationError] = useState(false)
  const [tripNameError, setTripNameError] = useState(false)
  const [tripTypeError, setTripTypeError] = useState(false)
  const [dateRangeError, setDateRangeError] = useState(false)
  const [centerLat, setCenterLat] = useState(47.444)
  const [centerLng, setCenterLng] = useState(-122.176)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showMainErrorMessage, setShowMainErrorMessage] = useState(false)
  const [tripDurationError, setTripDurationError] = useState(false)
  const [placeSearchValue, setPlaceSearchValue] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [showPlaceLoading, setShowPlaceLoading] = useState(false)
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
  const [showNoResults, setShowNoResults] = useState(false)
  const datePicker = useRef()
  const [focusedInput, setFocusedInput] = useState(null)
  const handleDatesChange = ({ startDate, endDate }) => {
    setDateRangeError(false)
    setTripDurationError(false)
    console.log({ startDate, endDate })
    // if (startDate < moment() || endDate < moment()) return
    if (startDate) {
      let d0 = new Date(startDate)
      dispatch({
        type: "updateTravelStartDate",
        data: d0.toUTCString(),
      })
      setStartDate(startDate)
    }
    if (endDate) {
      let d1 = new Date(endDate)
      dispatch({
        type: "updateTravelEndDate",
        data: d1.toUTCString(),
      })

      setEndDate(endDate)
    }
  }
  const [pickedOther, setPickedOther] = useState(false)
  const [maxDestinationCountReached, setMaxDestinationCountReached] = useState(
    false
  )
  let error = false
  // const [reachedMaxLength, setReachedMaxLength] = useState(false)
  // const maxCharactersAllowed = 300
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(500)
  const [
    reachedMaxLengthForTripName,
    maxCharactersAllowedForTripName,
    checkTripName,
  ] = useRestrictCharacterLength(30)
  const [
    reachedMaxLengthForTripType,
    maxCharactersAllowedForTripType,
    checkTripType,
  ] = useRestrictCharacterLength(30)
  const [value, setValue] = useState(null)
  var a = Math.random() * 1000
  // const [destinationError, setDestinationError] = useState(false)
  // const [destinationError, setDestinationError] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (state.tripDestinations) {
      let a = []
      state.tripDestinations.map(value => {
        if (value !== undefined) a.push(createOption(value))
      })
      setPlaceValue({ inputValue: "", value: a })
    }
  }, [])

  useEffect(() => {
    console.log({ dashboardState })
  }, [dashboardState])
  useEffect(() => {
    console.log({ value })
  }, [value])

  useEffect(() => {
    console.log(formatStartDate()[0])
    console.log(formatEndDate()[0])
    setShowMainErrorMessage(false)
    if (state) {
      if (state.tripLogistics) {
        if (state.tripLogistics.isAccommodationNotRequired === undefined) {
          setIsAccommodationNotRequired(true)
        }
        if (
          state.tripLogistics.tripType &&
          state.tripLogistics.tripType.value === "other"
        ) {
          setPickedOther(true)
        } else {
          setPickedOther(false)
        }
      }
      if (state.tripDestinations) {
        if (state.tripDestinations.length >= maxDestinationsAllowed) {
          console.log("Max Reached")
          setMaxDestinationCountReached(true)
          error = true
        } else {
          setMaxDestinationCountReached(false)
          error = false
        }
      }
    }
  }, [state])

  useEffect(() => {
    if (dbContext && onNext) {
      console.log("Fetching Manifest")
      dbContext
        .firestore()
        .collection("trips")
        .doc(`${authContext.uid}`)
        .get()
        .then(doc => {
          if (doc) {
            console.log(doc.data())
            dispatchDashboard({ type: "updateTripManifest", data: doc.data() })

            //dispatch({ type: "setActiveTrips", data: doc.data() })
            //setLoading(false)
          }
        })
    }
  }, [dbContext])

  useEffect(() => {
    if (!datePicker.current) {
      return
    }

    // Replace DatePicker's onOutsideAction with our own that takes Gatsby's focus wrapper into account

    const _this = datePicker.current

    _this.onOutsideAction = event => {
      if (
        _this.wrapper &&
        !_this.wrapper.contains(event.target) &&
        // This is the line that fixes it
        event.target.id !== "gatsby-focus-wrapper"
      ) {
        _this.closeCalendar()
      }
    }
  }, [datePicker.current])
  const handleFlexibleDatesToggle = e => {
    e.currentTarget.name === "yes"
      ? dispatch({ type: "updateFlexibility", data: true })
      : dispatch({ type: "updateFlexibility", data: false })
  }

  const onChecked = e => {
    dispatch({
      type: "updateNoSpecificDates",
      data: e.currentTarget.checked,
    })
  }

  let specificTravelDatesChecked = ""
  let flexibleButton = ""
  if (state.tripLogistics.noSpecificTravelDatesChecked === true) {
    specificTravelDatesChecked = (
      <input id="box1" onChange={onChecked} type="checkbox" checked />
    )
    flexibleButton = (
      <>
        <button
          name="yes"
          disabled
          className="button is-small button-brand-height"
        >
          Yes
        </button>
        <button
          name="no"
          disabled
          className="button is-warning is-small button-brand-height"
        >
          No
        </button>
      </>
    )
  } else {
    const toggle = state.tripLogistics.travelDatesFlexible
    specificTravelDatesChecked = (
      <input id="box1" onChange={onChecked} type="checkbox" />
    )
    flexibleButton = (
      <>
        <button
          name="yes"
          onClick={handleFlexibleDatesToggle}
          className={`button is-small button-brand-height ${
            toggle ? "is-warning" : ""
          }`}
        >
          Yes
        </button>{" "}
        <button
          name="no"
          onClick={handleFlexibleDatesToggle}
          className={`button is-small button-brand-height ${
            toggle ? "" : "is-warning"
          }`}
        >
          No
        </button>{" "}
      </>
    )
  }

  const handleChange = (value, actionMeta) => {
    console.log(value, actionMeta)
    setPlaceValue({ ...placeValue, value: value })
  }

  const handleInputChange = inputValue => {
    setPlaceValue({ ...placeValue, inputValue: inputValue })
  }
  const handleKeyDown = event => {
    const { inputValue, value } = placeValue
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        setPlaceValue({
          ...placeValue,
          inputValue: "",
          value: [...(value || []), createOption(inputValue)],
        })
        event.preventDefault()
    }
  }
  const createOption = label => ({
    label,
    value: label,
  })

  const validateDateRange = () => {
    if (
      state.tripLogistics.travelDates.start === "" ||
      state.tripLogistics.travelDates.end === ""
    ) {
      setDateRangeError(true)
      return false
    } else {
      let endDate = Date.parse(state.tripLogistics.travelDates.end)
      let startDate = Date.parse(state.tripLogistics.travelDates.start)
      var totalTravelDays =
        Math.round((endDate - startDate) / (24 * 60 * 60 * 1000)) + 1
      console.log("Total travel days: ", totalTravelDays)
      if (totalTravelDays < 1 || totalTravelDays > 14) {
        setTripDurationError(true)
        return false
      } else {
        setTripDurationError(false)
        return true
      }
    }
  }

  const getCOVIDDetailsURLS = async countryCode => {
    if (dbContext && countryCode) {
      console.log("Fetching Country COVID Info", countryCode)
      return dbContext
        .firestore()
        .collection("countries-covid-info")
        .doc(`${countryCode}`)
        .get()
    }
  }

  const getPlaceName = qValue => {
    console.log("getPlaceName called")
    setShowPlaceLoading(true)
    let data = {
      qValue: qValue,
    }
    if (qValue) {
      var getPlaceName = functions.httpsCallable("getPlaceName")
      getPlaceName(data)
        .then(result => {
          // Read result of the Cloud Function.
          setShowPlaceLoading(false)
          console.log({ result })
          if (result && result.data) {
            let r = result.data.geonames.length > 0 ? result.data.geonames : []
            setSearchResults([...r])
            console.log(result.data.totalResultsCount)
            if (result.data.totalResultsCount === 0) setShowNoResults(true)
            else setShowNoResults(false)
          } else console.log("outside")
        })
        .catch(err => {
          console.log(err)
          setShowPlaceLoading(false)
        })
    }
  }
  const showCOVIDInfoForDestination = () => {
    try {
      if (state.tripDestinations) {
        if (state.tripDestinations.length === 0) return "- none -"
        let countries = []
        state.tripDestinations.map(async item => {
          try {
            const _foundItem = countryCode.find(
              element => element.Name === item.country.trim()
            )
            console.log(_foundItem)
            if (_foundItem !== -1) {
              countries.push({
                countryName: item.country,
                countryCode: _foundItem.Code,
                countryTravelAdvisoryURL: item.travelAdvisory,
                countryCOVIDNumbersURL: item.covidCasesURL,
              })
            }
          } catch (err) {
            console.log(err)
          }
        })
        // let uniqueCountries = [...new Set(countries)]
        // let uniqueCountries = countries.filter((value, index, self) => {
        //   return self.indexOf(value) === index
        // })

        let uniqueCountries = uniqWith(countries, isEqual)

        console.log(uniqueCountries)
        return uniqueCountries.map((item, index) => {
          return (
            <div>
              <p
                style={{
                  fontSize: "12px",
                  margin: "7px auto",
                  textTransform: "uppercase",
                }}
              >
                <>{item.countryName}</>
                {item.countryTravelAdvisoryURL === undefined &&
                item.countryCOVIDNumbersURL === undefined ? (
                  <span
                    style={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      marginLeft: "10px",
                      letterSpacing: "0.5px",

                      color: "rgba(78,78,78)",
                      fontWeight: "bold",
                    }}
                    className=""
                  >
                    Information unavailable
                  </span>
                ) : (
                  ""
                )}
                {item.countryTravelAdvisoryURL && (
                  <a
                    style={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      marginLeft: "10px",
                      letterSpacing: "0.5px",
                      textDecoration: "underline",
                      color: "rgba(78,78,78)",
                      fontWeight: "bold",
                      textDecorationColor: "#fec600",
                    }}
                    className=""
                    onClick={() => {
                      window.open(item.countryTravelAdvisoryURL, "_blank")
                    }}
                  >
                    Travel Advisory
                  </a>
                )}
                {item.countryCOVIDNumbersURL && (
                  <a
                    style={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      marginLeft: "10px",
                      letterSpacing: "0.5px",
                      textDecoration: "underline",
                      color: "rgba(78,78,78)",
                      fontWeight: "bold",
                      textDecorationColor: "#fec600",
                    }}
                    className=""
                    onClick={() => {
                      window.open(item.countryCOVIDNumbersURL, "_blank")
                    }}
                  >
                    COVID-19 Cases
                  </a>
                )}
              </p>
            </div>
          )
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const showDestinationTags = () => {
    if (state.tripDestinations) {
      if (state.tripDestinations.length === 0) return ""
      return state.tripDestinations.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className="tags are-small has-addons place-tags"
              style={{
                marginTop: "15px",
                marginRight: "10px",
                display: "inline-flex",
                position: "relative",

                //width: "50%",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  left: "0px",
                  top: "-17px",
                }}
              >
                {moment.localeData().ordinal(index + 1)} Destination
              </p>
              <span className="tag is-warning">{item.place}</span>
              <span className="tag is-warning">{item.country}</span>
              <a
                id={index}
                className="tag is-delete"
                onClick={e => {
                  dispatch({
                    type: "removeDestination",
                    data: e.currentTarget.id,
                  })
                }}
              ></a>
              {/* <iframe
              title="Paris"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=7&q=${item.place}+${item.country}`}
              width="200px"
              height="200px"
              allowFullScreen
            ></iframe> */}
            </div>
          </>
        )
      })
    }
  }

  const calculateTripDuration = () => {
    try {
      let duration =
        Math.round(
          (new Date(formatEndDate()[0]) - new Date(formatStartDate()[0])) /
            (24 * 60 * 60 * 1000)
        ) + 1

      let tripDuration = duration < 0 ? 1 : duration
      dispatch({
        type: "updateTravelDuration",
        data: tripDuration,
      })
      return tripDuration
    } catch (err) {
      console.log(err)
      return 0
    }
  }

  // Had to do this to cover both the cases of firebase toDate and regular Date object. Did find a better way to do fix the issue.
  const formatStartDate = () => {
    try {
      if (state.tripLogistics.travelDates.start) {
        return [
          state.tripLogistics.travelDates.start === ""
            ? new Date()
            : state.tripLogistics.travelDates.start.toDate(),
        ]
      } else return [new Date()]
    } catch (error) {
      // console.log(state.tripLogistics.travelDates.start)
      // console.log(state.tripLogistics.travelDates.end)
      if (state.tripLogistics.travelDates.start) {
        return [
          state.tripLogistics.travelDates.start === ""
            ? new Date()
            : state.tripLogistics.travelDates.start,
        ]
      } else {
        return [new Date()]
      }
    }
  }
  const formatEndDate = () => {
    try {
      if (state.tripLogistics.travelDates.end) {
        return [
          state.tripLogistics.travelDates.end === ""
            ? new Date()
            : state.tripLogistics.travelDates.end.toDate(),
        ]
      } else return [new Date()]
    } catch (error) {
      // console.log(error)
      if (state.tripLogistics.travelDates.end) {
        return [
          state.tripLogistics.travelDates.end === ""
            ? new Date()
            : state.tripLogistics.travelDates.end,
        ]
      } else {
        return [new Date()]
      }
    }
  }

  const addToNewTripsPool = async () => {
    try {
      console.log("addToNewTripsPool called")
      dbContext
        .firestore()
        .collection("newTripsPool")
        .doc(`${state.tripUid}`)
        .set({
          createdAt: new Date(),
          travelDates: state.tripLogistics.travelDates,
          tripName: state.tripName,
          tripPrimaryDestination: state.tripPrimaryDestination,
          tripUid: state.tripUid,
          travelerUid: state.uid,
          travelerEmailId: authContext.email,
          remindersSentCount: 0,
          jobCreated: false,
          travelerFirstName: globalState.currentUserData
            ? globalState.currentUserData.firstName
            : "",
        })
        .then(() => {
          console.log("New trip added to pool")
        })
    } catch (err) {
      console.log(err)
    }
  }

  const addNewManifestEntry = async type => {
    try {
      var manifestEntry = {
        createdAt: new Date(),
        travelDates: state.tripLogistics.travelDates,
        tripName: state.tripName,
        tripPrimaryDestination: state.tripPrimaryDestination,
        tripUid: state.tripUid,
        cardPictureURL: "",
        status: "work-in-progress",
        ...(type === "self" ? { selfPlanned: true } : ""),
      }
      dbContext
        .firestore()
        .collection("trips")
        .doc(`${state.uid}`)
        .update({
          travelManifest: dbContext.firestore.FieldValue.arrayUnion(
            manifestEntry
          ),
        })
        .then(() => {
          console.log("Travel manifest successfully updated!")
          // Todo : update local manifest array in dashboard state.
          dispatchDashboard({
            type: "addNewManifestEntry",
            data: manifestEntry,
          })
          addToNewTripsPool()
          onNext()
        })
    } catch (err) {
      console.log(err)
    }
  }

  const addDaysToTripStartDateAndDispatch = days => {
    console.log(days)
    let start_date = new Date(state.tripLogistics.travelDates.start)
    var end_date = start_date.setDate(start_date.getDate() + days)

    console.log(end_date)
    console.log(start_date)
  }
  const getOtherType = () => {
    try {
      if (state.tripLogistics.tripType) {
        if (state.tripLogistics.tripType.label) {
          return state.tripLogistics.tripType.label === "Other"
            ? ""
            : state.tripLogistics.tripType.label
        }
      }
    } catch (err) {}
  }

  const getTripType = () => {
    try {
      if (state.tripLogistics.tripType) {
        if (state.tripLogistics.tripType.label) {
          return state.tripLogistics.tripType.value === "other"
            ? { label: "Other", value: "Other" }
            : state.tripLogistics.tripType
        }
      }
    } catch (err) {}
  }

  const isSelected = type => {
    try {
      if (state.tripLogistics.accommodationPreference) {
        let r = findIndex(state.tripLogistics.accommodationPreference, o => {
          return o.value === type
        })
        if (r === -1) return false
        else return true
      }
    } catch (err) {}
  }
  const isAccommodationNotRequired = type => {
    try {
      if (state.tripLogistics.isAccommodationNotRequired) {
        return state.tripLogistics.isAccommodationNotRequired
      }
    } catch (err) {}
  }
  const setIsAccommodationNotRequired = value => {
    try {
      dispatch({
        type: "setIsAccommodationNotRequired",
        data: value,
      })
    } catch (err) {}
  }

  const addOrRemove = (type, isChecked) => {
    console.log(type)
    console.log(isChecked)
    if (state.tripLogistics.accommodationPreference) {
      if (isChecked) {
        let r = findIndex(state.tripLogistics.accommodationPreference, o => {
          return o.value === type
        })
        if (r === -1) {
          let i = findIndex(accommodationPreference, o => {
            return o.value === type
          })
          if (i !== -1)
            dispatch({
              type: "appendAccommodationPreference",
              data: accommodationPreference[i],
            })
        } else return
      } else {
        let r = findIndex(state.tripLogistics.accommodationPreference, o => {
          return o.value === type
        })
        if (r === -1) {
          return
        } else {
          dispatch({
            type: "removeAccommodationPreference",
            data: r,
          })
        }
      }
    }
  }
  const isVacationTypeSelected = currentId => {
    if (state.tripLogistics.tripType) {
      return state.tripLogistics.tripType.value === currentId.value
        ? true
        : false
    } else return false
  }

  const isOutsideRange = day => day.isBefore(moment().add(7, "d")) //Make sure the trip start date is at least weeks away.
  const debounceSearch = useCallback(debounce(getPlaceName, 500), [])
  const parsePlaceType = item => {
    try {
      let n = item.fclName
      const regex = /^[^,]*/g
      const str = n
      let m
      var ext = ""
      switch (item.fcode) {
        case "PCLI":
          return "country"
          break
        case "ADM1":
          return "state"
          break
        case "ADM2":
          return "county"
          break
        case "RGN":
        case "RGNE":
          return "region"
        case "ISL":
          return "Island"
          break
        default:
          break
      }
      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          // console.log(`Found match: ${match}`)
          ext = match
        })
      }
      // console.log({ ext })
      return ext === "parks" ? "National Park" : ext
    } catch (error) {
      return ""
    }
  }

  // To handle the UP, DOWN and ENTER key press results in the search bar.
  const keyChanged = e => {
    // console.log({ selectedItemIndex })
    // console.log(e.keyCode)
    try {
      switch (e.keyCode) {
        case 27: // ESC
          return
        case 38: // UP
          if (selectedItemIndex > 0) {
            // clearFocus()
            var a = selectedItemIndex - 1
            setSelectedItemIndex(a)
          }
          break
        case 40: // DOWN
          if (selectedItemIndex < searchResults.length - 1) {
            var a = selectedItemIndex + 1
            // if (selectedItemIndex != -1) clearFocus()
            setSelectedItemIndex(a)
          }
          break
        case 13: //ENTER
          if (selectedItemIndex != -1) {
            dispatch({
              type: "updateDestination",
              data: {
                country: searchResults[selectedItemIndex].countryName,
                place: searchResults[selectedItemIndex].name,
                latlng: {
                  lat: parseFloat(searchResults[selectedItemIndex].lat),
                  lng: parseFloat(searchResults[selectedItemIndex].lng),
                },
              },
              maxDestinationsCount: maxDestinationsAllowed,
            })
            setPlaceSearchValue("")
            setSearchResults([])
          }
          break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }
  const showSearchResults = () => {
    try {
      if (searchResults && searchResults.length > 0) {
        return (
          <ul>
            {searchResults.map((item, index) => {
              return (
                <li
                  key={item.geonameId}
                  className="aa-Item"
                  style={{
                    background:
                      selectedItemIndex === index
                        ? "rgba(254, 198, 0, 1)"
                        : "white",
                  }}
                  // style={{
                  //   background: "white",
                  // }}
                >
                  <div
                    style={{
                      padding: "10px",
                      minHeight: "40px",
                      cursor: "pointer",
                    }}
                    className="airport-code-items"
                    onClick={() => {
                      console.log("dispatch")
                      dispatch({
                        type: "updateDestination",
                        data: {
                          country: item.countryName,
                          place: item.name,
                          latlng: {
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng),
                          },
                        },
                        maxDestinationsCount: maxDestinationsAllowed,
                      })
                      setPlaceSearchValue("")
                      setSearchResults([])
                    }}
                  >
                    <nav className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ fontSize: "14px" }}>
                              {dottedText(item.name, 40)}
                            </div>
                            <div style={{ fontSize: "10px" }}>
                              {item.name === item.countryName
                                ? ""
                                : `${dottedText(item.adminName1, 40)}${
                                    item.adminName1 ? "," : ""
                                  }
                                ${dottedText(item.countryName, 20)}`}
                              {/* {dottedText(item.name, 40)},{" "}
                              {dottedText(item.countryName, 20)} */}
                            </div>
                            <div>
                              {/* <p
                                                  className="help"
                                                  style={{
                                                    marginTop: "-2px",
                                                  }}
                                                >
                                                  {item.fcodeName}
                                                </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="level-right">
                        <span
                          className="tag is-warning is-small"
                          style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.5px",
                            fontWeight: "500",
                            fontSize: "10px",
                            color: "rgba(74,74,74,0.8)",
                          }}
                        >
                          {" "}
                          {parsePlaceType(item)}
                        </span>
                      </div>
                    </nav>
                  </div>
                </li>
              )
            })}
          </ul>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div className="columns fade-in">
        <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-6-desktop is-10-tablet is-12-mobile">
          <div className="columns is-multiline">
            <div className="column is-12-desktop is-12-tablet is-12-mobile">
              <div style={{ textAlign: "left", marginBottom: "40px" }}>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#fec600",
                    letterSpacing: "0.5px",
                    textAlign: "right",
                  }}
                >
                  1/3
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "800",
                    color: "#fec600",
                  }}
                >
                  Let's start with the basics.
                </p>
                {/* <p>This will help your trip designer better curate your activities</p> */}
              </div>
              <div className="field" style={{ marginBottom: "30px" }}>
                <label
                  className="label"
                  style={{ position: "relative", fontSize: "18px" }}
                >
                  {/* Where are you planning to go? ( type your destination city ){" "} */}
                  Where would you like to go?
                  {/* <span style={{ fontSize: "12px" }}>
                    - your destination cities -{" "}
                  </span> */}
                  {/* <i
                    className="far fa-question-circle"
                    style={{ float: "right" }}
                  ></i> */}
                  {/* <p
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "400",
                      float: "right",
                      position: "absolute",
                      right: "0",
                      bottom: "0",
                    }}
                  >
                    Note: You can select multiple cities
                  </p> */}
                </label>

                <div className="control is-expanded">
                  {/* <RHFInput
                    as={
                      <CreatableSelect
                        components={{ DropdownIndicator: null }}
                        inputValue={placeValue.inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onBlur={e => {
                          // triggerValidation()
                          dispatch({
                            type: "updateDestination",
                            data: placeValue,
                          })
                        }}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Type a place and press enter..."
                        value={placeValue.value}
                        onFocus={() => {
                          clearError("destination")
                        }}
                      />
                    }
                    name="destination"
                    register={register}
                    setValue={setValue}
                    // ref={register({ name: "destination", required: true })}
                    rules={{ required: true }}
                    mode="onBlur"
                  /> */}

                  {/* <GooglePlacesAutocomplete
                    selectProps={{
                      value,
                      onChange: setValue,
                      isClearable: true,
                      placeholder: " ",
                    }}
                    apiKey={`${process.env.GOOGLE_MAPS_API_KEY}`}
                    autocompletionRequest={{
                      types: ["(regions)"],
                    }}
                    debounce="500"
                  /> */}
                  <div style={{ display: "none" }}>
                    <AlgoliaPlaces
                      placeholder="e.g. Las Vegas, Paris, Tokyo"
                      disabled={maxDestinationCountReached}
                      options={{
                        appId: `${process.env.ALGOLIA_APP_ID}`,
                        apiKey: `${process.env.ALGOLIA_API_KEY}`,
                        language: "en",
                        // placeholder: "Where do you want to go?",
                        // countries: ["se"],
                        useDeviceLocation: false,
                        aroundLatLngViaIP: false,
                        type: "city",
                        templates: {
                          value: function(suggestion) {
                            // return suggestion.name + ", " + suggestion.country
                            return " "
                          },
                          // suggestion: function(suggestion) {
                          //   return suggestion.name + ", " + suggestion.country
                          //   //return ""
                          // },
                        },
                        // Other options from https://community.algolia.com/places/documentation.html#options
                      }}
                      onFocus={() => {
                        setDestinationError(false)
                        console.log("onFocus")
                      }}
                      onBlur={() => {
                        if (state.tripDestinations === [])
                          setDestinationError(true)
                        console.log("onBlur")
                      }}
                      onChange={({
                        query,
                        rawAnswer,
                        suggestion,
                        suggestionIndex,
                      }) => {
                        console.log({
                          query,
                          rawAnswer,
                          suggestion,
                          suggestionIndex,
                        })
                        // const _foundItem = countryCode.find(
                        //   element => element.Name === suggestion.country.trim()
                        // )
                        // console.log(_foundItem)
                        // let d = ""
                        // if (_foundItem !== -1 && _foundItem !== undefined) {
                        //   d = await getCOVIDDetailsURLS(_foundItem.Code)
                        // }

                        dispatch({
                          type: "updateDestination",
                          data: {
                            country: suggestion.country,
                            place: suggestion.name,
                            latlng: suggestion.latlng,
                          },
                          maxDestinationsCount: maxDestinationsAllowed,
                        })

                        // console.log(d.exits)
                        // if (d !== "" && d.data()) {
                        //   dispatch({
                        //     type: "updateDestination",
                        //     data: {
                        //       country: suggestion.country,
                        //       place: suggestion.name,
                        //       latlng: suggestion.latlng,
                        //       travelAdvisory: d.data().travelGuidanceURL,
                        //       covidCasesURL: d.data().covidCasesURL,
                        //     },
                        //   })
                        // } else {
                        //   dispatch({
                        //     type: "updateDestination",
                        //     data: {
                        //       country: suggestion.country,
                        //       place: suggestion.name,
                        //       latlng: suggestion.latlng,
                        //     },
                        //   })
                        // }
                        setDestinationError(false)
                      }}
                      // onSuggestions={({ rawAnswer, query, suggestions }) =>
                      //   console.log(
                      //     "Fired when dropdown receives suggestions. You will receive the array of suggestions that are displayed."
                      //   )
                      // }
                      onLimit={({ message }) =>
                        console.log(
                          "Fired when you reached your current rate limit."
                        )
                      }
                      onError={({ message }) =>
                        console.log(
                          "Fired when we could not make the request to Algolia Places servers for any reason but reaching your rate limit."
                        )
                      }
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <div
                      class={`${
                        showPlaceLoading ? "control is-loading" : "control"
                      }`}
                    >
                      <input
                        value={placeSearchValue}
                        type="text"
                        autoFocus
                        placeholder="e.g. Las Vegas, Paris, Japan"
                        disabled={maxDestinationCountReached}
                        className="input"
                        style={{ paddingRight: "10px" }}
                        onBlur={() => {
                          setTimeout(() => {
                            let a = document.getElementById(
                              "place-results-panel"
                            )
                            if (a) a.style.display = "none"
                          }, 300)
                        }}
                        onKeyDown={keyChanged}
                        onFocus={() => {
                          setDestinationError(false)
                          let a = document.getElementById("place-results-panel")
                          if (a) a.style.display = "block"
                          // console.log("onFocus")
                        }}
                        onChange={e => {
                          setDestinationError(false)
                          let value = e.currentTarget.value
                          if (value) {
                            setPlaceSearchValue(value)
                            if (value.length > 2) debounceSearch(value)
                          } else {
                            setPlaceSearchValue("")
                            setSearchResults([])
                            setShowNoResults(false)
                          }
                        }}
                      />
                      {searchResults.length > 0 && (
                        <div
                          className="aa-Panel"
                          style={{ marginTop: "3px" }}
                          id="place-results-panel"
                        >
                          {showSearchResults()}
                        </div>
                      )}
                      {showNoResults && (
                        <div
                          className="aa-Panel"
                          style={{ marginTop: "3px" }}
                          id="place-results-panel"
                        >
                          <p style={{ padding: "5px 10px", fontSize: "14px" }}>
                            No Results
                          </p>
                        </div>
                      )}
                      {placeSearchValue.length > 0 && !showPlaceLoading ? (
                        <span
                          className="icon is-medium is-right"
                          style={{
                            position: "absolute",
                            right: "0px",
                            top: "4px",
                          }}
                          onClick={() => {
                            setPlaceSearchValue("")
                            setSearchResults([])
                            setShowNoResults(false)
                          }}
                        >
                          <i
                            style={{
                              color: "#fec600",
                              cursor: "pointer",
                              zIndex: "99",
                            }}
                            className="far fa-times"
                          ></i>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {maxDestinationCountReached ? (
                    <p className="help">
                      {" "}
                      <i className="fas fa-exclamation-circle"></i> Reached
                      maximum destinations allowed
                    </p>
                  ) : (
                    ""
                  )}
                  {destinationError ? (
                    <p className="help is-danger">
                      {" "}
                      <i className="fas fa-exclamation-circle"></i> Please pick
                      at least one destination
                    </p>
                  ) : (
                    <p className="help">
                      {/* Type your destination cities.  */}
                      Type slowly to select a destination from one of the
                      options on the list. Note: You can select multiple
                      destinations
                    </p>
                  )}
                  {/* <p style={{ marginTop: "20px", marginBottom: "5px" }}>
                    Selected destinations:
                  </p> */}

                  <div style={{ marginTop: "10px", marginBottom: "5px" }}>
                    {showDestinationTags()}
                  </div>

                  {/* {state.tripDestinations.length === 0 ? (
                    ""
                  ) : (
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      <p style={{ fontSize: "14px" }}>
                        {" "}
                        <i
                          className="fas fa-info-square"
                          style={{ marginRight: "2px" }}
                        ></i>{" "}
                        COVID-19 Travel Information:
                      </p>
                      {showCOVIDInfoForDestination()}
                    </div>
                  )} */}

                  {/* <CreatableSelect
                    components={{ DropdownIndicator: null }}
                    inputValue={placeValue.inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onBlur={e => {
                      triggerValidation("destination")
                      dispatch({ type: "updateDestination", data: placeValue })
                    }}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Type a place and press enter..."
                    value={placeValue.value}
                    onFocus={() => {}}
                  /> */}
                  {/* {errors.destination ? (
                    <p className="help is-danger">
                      Please enter a valid destination
                    </p>
                  ) : (
                    <p className="help"></p>
                  )} */}
                </div>
              </div>
            </div>
            {/* <div className="column is-12-desktop is-12-tablet is-12-mobile">
              {" "}
              <ShowDestinationMap
                destinationState={state.tripDestinations}
                seed={a}
              />
            </div> */}
            <div className="column is-12-desktop is-12-tablet is-12-mobile">
              <label className="label">Travel dates</label>
              <div>
                <span style={{ fontSize: "12px", marginLeft: "25px" }}>
                  pick start date
                </span>
                <span style={{ fontSize: "12px", marginLeft: "95px" }}>
                  pick end date
                </span>
              </div>
              <div className="control">
                <DateRangePicker
                  startDate={moment(formatStartDate()[0])}
                  withPortal={is.mobile() ? true : false}
                  withFullScreenPortal={is.mobile() ? true : false}
                  orientation={
                    is.mobile() ? "verticalScrollable" : "horizontal"
                  }
                  startDateId="tata-start-date"
                  endDate={moment(formatEndDate()[0])}
                  endDateId="tata-end-date"
                  onDatesChange={handleDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={focusedInput => {
                    console.log({ focusedInput })
                    setFocusedInput(focusedInput)
                  }}
                  minDate={moment().add(5, "d")}
                  readOnly={is.mobile() ? true : false}
                  numberOfMonths={is.mobile() ? 24 : 2}
                  disableScroll={false}
                  navPrev={is.mobile() ? <></> : ""}
                  navNext={is.mobile() ? <></> : ""}
                  // isOutsideRange={isOutsideRange}
                  // verticalHeight={window.innerHeight}
                />
                {/* <i
                  className="fal fa-calendar-alt fa-lg"
                  style={{
                    color: "#4a4a4a",
                    position: "absolute",
                    left: "10px",
                    top: "12px",
                    zIndex: "-5",
                  }}
                ></i>
                <DayPickerInput
                  value={moment(formatStartDate()[0]).format("l")}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format="l"
                  placeholder={`${formatDate(new Date())}`}
                  dayPickerProps={{
                    disabledDays: {
                      before: new Date(),
                    },
                    selectedDays: new Date(formatStartDate()[0]),
                    locale: "l",
                    localeUtils: MomentLocaleUtils,
                  }}
                  onDayChange={date => {
                    try {
                      console.log(date)
                      if (date) {
                        setDateRangeError(false)
                        setTripDurationError(false)
                        let d0 =
                          typeof date === "string" ? new Date(date) : date
                        dispatch({
                          type: "updateTravelStartDate",
                          data: d0.toUTCString(),
                        })
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }}
                /> */}
                {/* <DatePicker
                  ref={datePicker}
                  minDate={new Date()}
                  // maxDate={moment().subtract(18, "years")._d}
                  // style={datePickerStyle}
                  shouldCalendarClose={false}
                  clearIcon={false}
                  isOpen={false}
                  onFocus={e => {}}
                  value={formatStartDate()}
                  onChange={date => {
                    try {
                      console.log(date)
                      if (date) {
                        setDateRangeError(false)
                        setTripDurationError(false)
                        let d0 =
                          typeof date === "string" ? new Date(date) : date
                        dispatch({
                          type: "updateTravelStartDate",
                          data: d0.toUTCString(),
                        })
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }}
                ></DatePicker> */}
                {dateRangeError ? (
                  <p
                    className="help is-danger"
                    // style={{ display: "inline-block" }}
                  >
                    <i className="fas fa-exclamation-circle"></i> Please enter
                    valid itinerary dates.
                  </p>
                ) : (
                  ""
                  // <p className="help">pick all that apply</p>
                )}
              </div>
            </div>
            <div
              style={{
                display: "none",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-around",
                margin: "40px 0px",
              }}
            >
              <button
                onClick={e => {
                  if (isBusy === false) {
                    setIsBusy(true)
                    var a = true
                    var b = true
                    var c = true

                    if (state.tripDestinations.length === 0) {
                      setDestinationError(true)
                      a = false
                    }

                    // if (state.tripName === "") {
                    //   setTripNameError(true)
                    //   b = false
                    // }
                    c = validateDateRange()

                    console.log(a, b, c)
                    if (a && c) {
                      console.log("CLEAN!!")
                      if (onNext) {
                        //Todo - improve to check if the state changed on then trigger an update
                        dbContext
                          .firestore()
                          .collection("trips")
                          .doc(`${state.uid}`)
                          .collection("active-trips")
                          .doc(`${state.tripUid}`)
                          .set(state)
                          .then(function() {
                            console.log("Trip successfully updated!")
                            if (dashboardState.travelManifest === undefined) {
                              addNewManifestEntry("self")
                            } else {
                              var itemIndex = dashboardState.travelManifest.findIndex(
                                item => item.tripUid === state.tripUid
                              )
                              if (itemIndex === -1) {
                                //Check if the travel manifest entry is already there. This will happen when the user comes back to work on the trip
                                addNewManifestEntry("self")
                              } else {
                                var oldManifest = [
                                  ...dashboardState.travelManifest,
                                ]
                                var travelManifest = []
                                console.log(state.tripName)
                                oldManifest.map((item, index) => {
                                  if (item.tripUid == state.tripUid) {
                                    var updatedObj = {
                                      tripUid: item.tripUid,
                                      tripPrimaryDestination:
                                        state.tripPrimaryDestination,
                                      tripName: state.tripName,
                                      createdAt: item.createdAt,
                                      cardPictureURL: item.cardPictureURL,
                                      travelDates:
                                        state.tripLogistics.travelDates,
                                      status: item.status,
                                      selfPlanned: true,
                                    }
                                    travelManifest.push(updatedObj)
                                  } else {
                                    travelManifest.push(item)
                                  }
                                })
                                //   var newObj = { travelManifest: { ...travelManifest } }
                                dbContext
                                  .firestore()
                                  .collection("trips")
                                  .doc(`${authContext.uid}`)
                                  .update({
                                    travelManifest: [...travelManifest],
                                  })
                                  .then(() => {
                                    setIsBusy(false)
                                    // onNext()
                                    console.log(
                                      "Travel manifest updated successfully!"
                                    )
                                  })

                                  // dbContext
                                  //   .firestore()
                                  //   .collection("newTripsPool")
                                  //   .doc(`${state.tripUid}`)
                                  //   .update({
                                  //     updatedAt: new Date(),
                                  //     travelDates:
                                  //       state.tripLogistics.travelDates,
                                  //     tripName: state.tripName,
                                  //     tripPrimaryDestination:
                                  //       state.tripPrimaryDestination,
                                  //     tripUid: state.tripUid,
                                  //     travelerUid: state.uid,
                                  //     travelerEmailId: authContext.email,
                                  //     remindersSentCount: 0,
                                  //     jobCreated: false,
                                  //     travelerFirstName: globalState.currentUserData
                                  //       ? globalState.currentUserData
                                  //           .firstName
                                  //       : "",
                                  //   })
                                  .then(() => {
                                    console.log("New trip added to pool")
                                  })
                                  .catch(error => {})
                              }
                            }
                          })
                          .catch(error => {
                            setIsBusy(false)
                            console.log("Error getting document:", error)
                          })
                        // setIsBusy(false)
                        analytics.logEvent("wizard_travelers_info", {
                          userId: `${authContext.uid}`,
                          tripId: `${state.tripUid}`,
                        })
                      }
                    } else {
                      console.log("ERROR!")
                      setIsBusy(false)
                      setShowMainErrorMessage(true)
                    }
                  } else {
                    console.log("not allowed")
                  }
                }}
                className="button is-warning"
              >
                Build Your Own
              </button>
              <button
                className="button is-warning"
                onClick={() => {
                  setBuildYourOwn(false)
                }}
              >
                Hire a trip designer
              </button>
            </div>
            {!buildYourOwn && (
              <>
                <div
                  className={`${
                    pickedOther
                      ? "column is-6-desktop is-12-tablet is-12-mobile"
                      : "column is-12-desktop is-12-tablet is-12-mobile"
                  }`}
                >
                  <div className="field">
                    <label className="label">Select trip type:</label>
                    <div className="tags">
                      {vacationType.map((item, index) => {
                        return (
                          <button
                            className={`${
                              isVacationTypeSelected(item)
                                ? "button is-warning is-small"
                                : "button is-light is-warning is-small"
                            }`}
                            id={item.value}
                            key={item.label}
                            style={{
                              marginRight: "12px",
                              marginBottom: "12px",
                              fontSize: "14px",
                              color: "#4a4a4a",
                              letterSpacing: "0.5px",
                              fontWeight: "400",
                              border: "1px solid #fec600",
                              borderRadius: "4px",
                            }}
                            onClick={e => {
                              console.log({ item })
                              if (item && item.value === "other") {
                                setPickedOther(true)
                                dispatch({
                                  type: "updateTripType",
                                  data: item ? item : "",
                                })
                              } else {
                                setPickedOther(false)
                                dispatch({
                                  type: "updateTripType",
                                  data: item ? item : "",
                                })
                              }
                            }}
                          >
                            {item.label}
                          </button>
                        )
                      })}
                    </div>
                    {/* <div className="control is-expanded">
                  <Select
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    // isMulti
                    onFocus={() => {
                      //  clearError("accommodation")
                      setTripTypeError(false)
                    }}
                    placeholder="Select or start typing..."
                    // defaultValue={state.tripLogistics.tripType}
                    defaultValue={getTripType()}
                    // className="basic-select"
                    classNamePrefix="select"
                    onChange={(value, actionMeta) => {
                      console.log({ value })
                      if (value && value.value === "other") setPickedOther(true)
                      else setPickedOther(false)
                      dispatch({
                        type: "updateTripType",
                        data: value ? value : "",
                      })
                    }}
                    isSearchable="true"
                    isClearable="true"
                    options={vacationType}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#fef0bf",
                        primary50: "#fee27f",
                        primary: "#fed43f",

                        //neutral0: "#424242",
                      },
                    })}
                  />
                  {tripTypeError ? (
                    <p className="help is-danger">Please pick a trip type</p>
                  ) : (
                    ""
                  )}
                </div> */}
                  </div>
                  {/* <div className="field">
                <label className="label">
                  Name your trip:
                </label>

                <div className="control">
                  {" "}
                  <input
                    className={`${tripNameError ? "input" : "input"}`}
                    // placeholder="Trip Name"
                    onChange={e => {
                      if (checkTripName(e.currentTarget.value.length)) return
                      dispatch({
                        type: "updateTripName",
                        data: e.currentTarget.value,
                      })
                    }}
                    value={state.tripName}
                    // name="tripName"
                    // ref={register({ required: true })}
                    onFocus={() => {
                      setTripNameError(false)
                    }}
                    onBlur={() => {
                      if (state.tripName === "") setTripNameError(true)
                    }}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {tripNameError ? (
                      <p className="help is-danger">
                        {" "}
                        <i className="fas fa-exclamation-circle"></i> Trip name
                        is required
                      </p>
                    ) : (
                      <p className="help">E.g. Italy family vacation 2020</p>
                    )}
                    <p
                      className={`help is-pulled-right ${
                        reachedMaxLengthForTripName ? "is-danger" : ""
                      }`}
                    >
                      Characters: {state.tripName.length}/
                      {maxCharactersAllowedForTripName}
                    </p>
                  </div>
                </div>
              </div> */}
                </div>
                {pickedOther && (
                  <div className="column is-6-desktop is-12-tablet is-12-mobile">
                    <div className="field">
                      <label className="label">Enter trip type: </label>

                      <div className="control">
                        {" "}
                        <input
                          className={`${tripNameError ? "input" : "input"}`}
                          // placeholder="Trip Name"
                          onChange={e => {
                            let value = {
                              label: e.currentTarget.value,
                              value: "other",
                            }
                            console.log({ value })
                            dispatch({
                              type: "updateTripType",
                              data: value ? value : "",
                            })
                          }}
                          value={getOtherType()}
                          // name="tripName"
                          // ref={register({ required: true })}
                          onFocus={() => {
                            setTripNameError(false)
                          }}
                          onBlur={() => {
                            if (state.tripName === "") setTripNameError(true)
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            className={`help is-pulled-right ${
                              reachedMaxLengthForTripType ? "is-danger" : ""
                            }`}
                          >
                            Characters:{" "}
                            {state.tripLogistics.tripType
                              ? state.tripLogistics.tripType.label
                                ? state.tripLogistics.tripType.label.length
                                : 0
                              : 0}
                            /{maxCharactersAllowedForTripType}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="column is-5-desktop is-12-tablet is-12-mobile"
                  style={{ display: "none" }}
                >
                  <div className="field">
                    <label className="label">Name your trip:</label>

                    <div className="control">
                      {" "}
                      <input
                        className={`${tripNameError ? "input" : "input"}`}
                        // placeholder="Trip Name"
                        onChange={e => {
                          if (checkTripName(e.currentTarget.value.length))
                            return
                          dispatch({
                            type: "updateTripName",
                            data: e.currentTarget.value,
                          })
                        }}
                        value={state.tripName}
                        // name="tripName"
                        // ref={register({ required: true })}
                        onFocus={() => {
                          setTripNameError(false)
                        }}
                        onBlur={() => {
                          if (state.tripName === "") setTripNameError(true)
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {tripNameError ? (
                          <p className="help">
                            {/* {" "}
                        <i className="fas fa-exclamation-circle"></i> Trip name
                        is required */}
                            <p className="help">
                              E.g. Italy family vacation 2021
                            </p>
                          </p>
                        ) : (
                          <p className="help">
                            E.g. Italy family vacation 2021
                          </p>
                        )}
                        <p
                          className={`help is-pulled-right ${
                            reachedMaxLengthForTripName ? "is-danger" : ""
                          }`}
                        >
                          Characters: {state.tripName.length}/
                          {maxCharactersAllowedForTripName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="column is-6-desktop is-6-tablet is-12-mobile">
              {" "}
              <div className="field">
                <label className="label">
                  Primary purpose of this trip:{" "}
                </label>
                <div className="control is-expanded">
                  <Select
                    className="basic-single"
                    onFocus={() => {
                      //  clearError("purpose")
                    }}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    defaultValue={tripPurpose.find(
                      element =>
                        element.label === state.tripLogistics.tripsPurpose
                    )}
                    menuPortalTarget={document.body}
                    classNamePrefix="select"
                    isSearchable="true"
                    isClearable="true"
                    onChange={(value, actionMeta) => {
                      if (value)
                        dispatch({
                          type: "updateTripPurpose",
                          data: value.label,
                        })
                    }}
                    options={tripPurpose}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#fef0bf",
                        primary: "#fed43f",

                        //neutral0: "#424242",
                      },
                    })}
                  />
                </div>
              </div>
            </div> */}
                {/* <div className="column is-6-desktop is-6-tablet is-12-mobile"></div> */}
                {/* <div className="column is-6-desktop is-12-tablet is-12-mobile"></div> */}

                <div
                  className="column is-6-desktop is-6-tablet is-12-mobile"
                  style={{ display: "none" }}
                >
                  {/* <label className="label">Itinerary end date:</label> */}
                  <div className="control is-expanded">
                    {/* <i
                  className="fal fa-calendar-alt fa-lg"
                  style={{
                    color: "#4a4a4a",
                    position: "absolute",
                    left: "10px",
                    top: "12px",
                    zIndex: "-5",
                  }}
                ></i>
                <DayPickerInput
                  value={moment(
                    new Date(formatEndDate()[0]) <
                      new Date(formatStartDate()[0])
                      ? new Date(formatStartDate()[0])
                      : new Date(formatEndDate()[0])
                  ).format("l")}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format="l"
                  placeholder={`${formatDate(new Date())}`}
                  dayPickerProps={{
                    disabledDays: {
                      before: new Date(formatStartDate()[0]),
                    },
                    month:
                      new Date(formatEndDate()[0]) <
                      new Date(formatStartDate()[0])
                        ? new Date(formatStartDate()[0])
                        : new Date(formatEndDate()[0]),
                    selectedDays:
                      new Date(formatEndDate()[0]) <
                      new Date(formatStartDate()[0])
                        ? new Date(formatStartDate()[0])
                        : new Date(formatEndDate()[0]),
                    locale: "l",
                    localeUtils: MomentLocaleUtils,
                  }}
                  onDayChange={date => {
                    try {
                      console.log(date)
                      setDateRangeError(false)
                      setTripDurationError(false)
                      if (date) {
                        let d0 =
                          typeof date === "string" ? new Date(date) : date
                        dispatch({
                          type: "updateTravelEndDate",
                          data: d0.toUTCString(),
                        })
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }}
                /> */}
                    {/* <DatePicker
                  minDate={new Date(formatStartDate()[0])}
                  // maxDate={moment().subtract(18, "years")._d}
                  // style={datePickerStyle}
                  value={formatEndDate()}
                  onChange={date => {
                    try {
                      console.log(date)
                      setDateRangeError(false)
                      setTripDurationError(false)
                      if (date) {
                        let d0 =
                          typeof date === "string" ? new Date(date) : date
                        dispatch({
                          type: "updateTravelEndDate",
                          data: d0.toUTCString(),
                        })
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }}
                ></DatePicker> */}
                    {/* {dateRangeError ? (
                  <p
                    className="help is-danger"
                    // style={{ display: "inline-block" }}
                  >
                    <i className="fas fa-exclamation-circle"></i> Please enter
                    valid itinerary dates.
                  </p>
                ) : (
                  ""
                  // <p className="help">pick all that apply</p>
                )} */}
                  </div>
                </div>
                <div
                  className="column is-2-desktop is-12-tablet is-12-mobile"
                  style={{ display: "none" }}
                >
                  <label className="label">Total Days:</label>

                  <span
                    style={{
                      fontSize: "22px",
                      fontWeight: "800",
                      lineHeight: "40px",
                      letterSpacing: "0.5px",
                    }}
                    className={`${tripDurationError ? "has-text-danger" : ""}`}
                  >
                    {calculateTripDuration()}
                  </span>
                  {/* {tripDurationError ? (
                <p
                  className="help is-danger"
                  // style={{ display: "inline-block" }}
                >
                  <i className="fas fa-exclamation-circle"></i> Need to get more
                  than 14 days planned?
                  <a href="mailto: support@voyayge.com"> contact voyay!ge</a>
                </p>
              ) : (
                ""
                // <p className="help">pick all that apply</p>
              )} */}
                </div>
                <div
                  className="column is-12-desktop is-12-tablet is-12-mobile"
                  style={{ display: "" }}
                >
                  {tripDurationError ? (
                    <p
                      className="help is-danger"
                      // style={{ display: "inline-block" }}
                    >
                      <i
                        className="fas fa-exclamation-circle"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Trip days has to be between <strong> 1 to 14 days</strong>
                      . Need to get more than 14 days planned?
                      <a href="mailto: support@voyayge.com"> contact voyayge</a>
                    </p>
                  ) : (
                    ""
                    // <p className="help">pick all that apply</p>
                  )}
                </div>
                {/* <div className="column is-4-desktop is-3-tablet is-12-mobile">
              <label className="label">
                No. of days you need the itinerary planed:
              </label>
              <div className="control is-expanded">
                <input
                  name="min"
                  className="input"
                  type="number"
                  placeholder=""
                  value={state.tripLogistics.travelDuration}
                  onChange={e => {
                    dispatch({
                      type: "updateTravelDuration",
                      data: parseInt(e.currentTarget.value),
                    })
                    addDaysToTripStartDateAndDispatch(
                      parseInt(e.currentTarget.value)
                    )
                  }}
                  //   style={{ maxWidth: "130px" }}
                />
              </div>
            </div> */}
                {/* <div className="column is-6-desktop is-6-tablet is-12-mobile">
              <div className="field">
                <label className="label">
                  Days you need the itinerary planed:
                  <p
                    style={{
                      display: "inline-block",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginLeft: "7px",
                      // textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    - select a date range and exclude your fly in/out days -
                  </p>
                </label>

                <div className="control is-expanded">
                  <DateRangePicker
                    // value={formatDate()}
                    minDate={new Date()}
                    onChange={date => {
                      try {
                        if (date) {
                          let d0 =
                            typeof date[0] === "string"
                              ? new Date(date[0])
                              : date[0]
                          let d1 =
                            typeof date[1] === "string"
                              ? new Date(date[1])
                              : date[1]

                          console.log(
                            "Trip Days: ",
                            Math.round((d1 - d0) / (24 * 60 * 60 * 1000))
                          )
                          dispatch({
                            type: "updateTravelDates",
                            data: {
                              start: d0.toUTCString(),
                              end: d1.toUTCString(),
                            },
                          })
                        }
                      } catch (err) {
                        console.log(err)
                      }
                    }}
                    onFocus={() => {
                      setDateRangeError(false)
                      console.log("onFocus")
                    }}
                  ></DateRangePicker>
                  {dateRangeError ? (
                    <p className="help is-danger">
                      <i className="fas fa-exclamation-circle"></i> Please enter
                      valid travel dates. Note: trip duration has to be between{" "}
                      <strong> 1 to 10 days </strong>. Need to plan a trip for
                      more than 10 days?{" "}
                      <a href="mailto: support@voyayge.com">contact voyay!ge</a>
                    </p>
                  ) : (
                    ""
                    // <p className="help">pick all that apply</p>
                  )}

                </div>
              </div>
            </div> */}
                {/* <div className="column is-6-desktop is-6-tablet is-12-mobile">
              <div className="field">
                <label className="label">Are your dates flexible?</label>
                <div className="control is-expanded">
                  <div className="buttons has-addons">{flexibleButton}</div>
                </div>
              </div>
            </div> */}
                <div
                  className="column is-6-desktop is-6-tablet is-12-mobile"
                  style={{ display: "none" }}
                >
                  <div className="field">
                    <label className="label">
                      Total trip budget: (Excluding flights)
                    </label>
                    <div className="field-body">
                      <div
                        className="field has-addons"
                        // style={{ maxWidth: "200px" }}
                      >
                        <p className="control">
                          <a className="button is-static force-brand-color">
                            <i className="fas fa-dollar-sign"></i>
                          </a>
                        </p>
                        <div className="control is-expanded">
                          <input
                            name="min"
                            className="input"
                            type="number"
                            placeholder="Minimum"
                            value={
                              state.tripLogistics.totalTripBudgetRange.minBudget
                            }
                            onChange={e => {
                              dispatch({
                                type: "updateMinBudget",
                                data: e.currentTarget.value,
                              })
                            }}
                            //   style={{ maxWidth: "130px" }}
                          />
                          <p className="help" style={{ marginLeft: "-40px" }}>
                            Note: This helps designer make restaurant and
                            accommodations recommendations that align with your
                            budget
                          </p>
                        </div>
                      </div>
                      {/* <span
              style={{
                borderBottom: "3px solid #ffc600",
                width: "20px",
                paddingBottom: "25px",
                height: "0px",
                marginTop: "20px",
                marginLeft: "-30px",
                marginRight: "10px",
              }}
            ></span> */}
                      {/* <div className="field has-addons">
              <p className="control">
                <a className="button is-static force-brand-color">
                  <i className="fas fa-dollar-sign"></i>
                </a>
              </p>
              <div className="control">
                <input
                  name="max"
                  className="input"
                  type="number"
                  placeholder="Maximum"
                  value={state.tripLogistics.totalTripBudgetRange.maxBudget}
                  onChange={e => {
                    dispatch({
                      type: "updateMaxBudget",
                      data: e.currentTarget.value,
                    })
                  }}
                  style={{ maxWidth: "130px" }}
                />
                <p className="help">Maximum budget</p>
              </div>
            </div> */}
                    </div>
                  </div>
                </div>

                <div
                  className="column is-12-desktop is-12-tablet is-12-mobile"
                  style={{ marginBottom: "20px" }}
                >
                  <p className="label" style={{ marginBottom: "8px" }}>
                    Need accommodation suggestions?
                  </p>
                  <div className="field">
                    <input
                      id="switchColorWarning"
                      type="checkbox"
                      name="switchColorWarning"
                      className="switch is-warning"
                      checked={!state.tripLogistics.isAccommodationNotRequired}
                      onChange={e => {
                        console.log(e.currentTarget.value)
                        console.log(e.currentTarget.checked)
                        let newVal = state.tripLogistics
                          .isAccommodationNotRequired
                          ? !state.tripLogistics.isAccommodationNotRequired
                          : true
                        setIsAccommodationNotRequired(newVal)
                      }}
                    ></input>
                    No
                    <label
                      for="switchColorWarning"
                      style={{ userSelect: "none", marginLeft: "8px" }}
                    >
                      {state.tripLogistics.isAccommodationNotRequired
                        ? state.tripLogistics.isAccommodationNotRequired
                          ? ""
                          : ""
                        : ""}
                    </label>
                    Yes
                  </div>
                </div>
                {!state.tripLogistics.isAccommodationNotRequired && (
                  <>
                    <div className="column is-12-desktop is-12-tablet is-12-mobile">
                      <div className="field">
                        <label className="label">
                          Accommodation preference:
                        </label>
                        <div style={{ display: "" }}>
                          {/* <div className="field">
                    <input
                      className="is-checkradio has-background-color is-warning"
                      id="accomodation_not_required"
                      type="checkbox"
                      checked={isAccommodationNotRequired()}
                      name="accomodation_not_required"
                      // checked="checked"

                      onClick={e => {
                        setIsAccommodationNotRequired(e.currentTarget.checked)
                      }}
                    ></input>
                    <label for="accomodation_not_required">Not Required</label>
                  </div> */}
                          <div className="field">
                            <input
                              className="is-checkradio has-background-color is-warning"
                              id="hotel"
                              type="checkbox"
                              name="hotel"
                              disabled={isAccommodationNotRequired()}
                              checked={isSelected("hotel")}
                              onClick={e => {
                                addOrRemove("hotel", e.currentTarget.checked)
                              }}
                            ></input>
                            <label for="hotel">Hotel</label>
                          </div>
                          <div className="field">
                            <input
                              className="is-checkradio has-background-color is-warning"
                              id="checkbox_vacation_rental"
                              type="checkbox"
                              name="checkbox_vacation_rental"
                              disabled={isAccommodationNotRequired()}
                              // checked="checked"
                              checked={isSelected("vacationhomes")}
                              onClick={e => {
                                addOrRemove(
                                  "vacationhomes",
                                  e.currentTarget.checked
                                )
                              }}
                            ></input>
                            <label for="checkbox_vacation_rental">
                              Vacation Rentals (airbnb, Vrbo etc.)
                            </label>
                          </div>
                        </div>
                        <div
                          className="control is-expanded"
                          style={{ display: "none" }}
                        >
                          <Select
                            isMulti
                            onFocus={() => {
                              //  clearError("accommodation")
                            }}
                            defaultValue={
                              state.tripLogistics.accommodationPreference
                            }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(value, actionMeta) => {
                              if (value)
                                dispatch({
                                  type: "updateAccommodationPreference",
                                  data: Object.values(value),
                                })
                            }}
                            isSearchable="true"
                            isClearable="true"
                            options={accommodationPreference}
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#fef0bf",
                                primary50: "#fee27f",
                                primary: "#fed43f",

                                //neutral0: "#424242",
                              },
                            })}
                          />
                          <p className="help">pick all that apply</p>
                          {/* {errors.accommodation ? (
                    <p className="help is-danger">
                      Please pick you accommodation preference
                    </p>
                  ) : (
                    <p className="help">pick all that apply</p>
                  )} */}
                        </div>
                      </div>
                    </div>

                    <div className="column is-12-desktop is-12-tablet is-12-mobile">
                      <div className="field">
                        <label className="label">Accommodation details:</label>
                        <div className="control is-expanded">
                          <textarea
                            onChange={e => {
                              if (check(e.currentTarget.value.length)) return
                              // console.log({ reachedMaxLength })
                              // if (reachedMaxLength) return

                              if (e.currentTarget.value) {
                                dispatch({
                                  type: "updateAccommodationDetails",
                                  data: e.currentTarget.value,
                                })
                              } else {
                                dispatch({
                                  type: "updateAccommodationDetails",
                                  data: "",
                                })
                              }
                            }}
                            value={state.tripLogistics.accommodationDetails}
                            disabled={isAccommodationNotRequired()}
                            rows="3"
                            className="textarea"
                            placeholder="WIFI, in-house laundry, kitchen, parking, close to public Transportation etc."
                          ></textarea>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="help">
                              {/* Tip: More details would help the designer provide better
                      accommodation recommendations. */}
                            </p>
                            <p
                              className={`help is-pulled-right ${
                                reachedMaxLength ? "is-danger" : ""
                              }`}
                            >
                              {/* Characters:{" "} */}
                              {state.tripLogistics.accommodationDetails
                                ? state.tripLogistics.accommodationDetails
                                    .length
                                : "0"}
                              /{maxCharactersAllowed}
                            </p>
                          </div>
                          {/* {errors.accommodation ? (
                    <p className="help is-danger">
                      Please pick you accommodation preference
                    </p>
                  ) : (
                    <p className="help">pick all that apply</p>
                  )} */}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {destinationError && (
                  <div className="column is-12-desktop is-12-tablet is-12-mobile">
                    <p
                      className="help is-danger"
                      style={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Please pick at least one destination above.
                    </p>
                  </div>
                )}
                {dateRangeError && (
                  <div className="column is-12-desktop is-12-tablet is-12-mobile">
                    <p
                      className="help is-danger"
                      style={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Please enter valid itinerary dates above.
                    </p>
                  </div>
                )}
                {onNext && (
                  <div className="column is-12-desktop is-12-tablet is-12-mobile">
                    <div className="columns is-mobile is-multiline">
                      <div className="column is-3-desktop is-5-tablet is-5-mobile">
                        <button
                          className="button"
                          onClick={e => {
                            if (onPrevious) onPrevious()
                          }}
                        >
                          <i
                            className="fas fa-angle-left"
                            style={{ marginRight: "5px" }}
                          ></i>
                          Exit Wizard
                        </button>
                      </div>
                      <div className="column is-6-desktop is-2-tablet is-2-mobile"></div>
                      <div className="column is-3-desktop is-5-tablet is-5-mobile">
                        <button
                          className={`${
                            isBusy
                              ? "button is-warning is-loading"
                              : "button is-warning "
                          }`}
                          style={{ float: "right" }}
                          onClick={e => {
                            if (isBusy === false) {
                              setIsBusy(true)
                              var a = true
                              var b = true
                              var c = true

                              if (state.tripDestinations.length === 0) {
                                setDestinationError(true)
                                a = false
                              }
                              if (state.tripLogistics.tripType === "") {
                                setTripTypeError(true)
                                b = false
                              }
                              // if (state.tripName === "") {
                              //   setTripNameError(true)
                              //   b = false
                              // }
                              c = validateDateRange()

                              console.log(a, b, c)
                              if (a && b && c) {
                                console.log("CLEAN!!")
                                if (onNext) {
                                  //Todo - improve to check if the state changed on then trigger an update
                                  dbContext
                                    .firestore()
                                    .collection("trips")
                                    .doc(`${state.uid}`)
                                    .collection("active-trips")
                                    .doc(`${state.tripUid}`)
                                    .set(state)
                                    .then(function() {
                                      console.log("Trip successfully updated!")
                                      if (
                                        dashboardState.travelManifest ===
                                        undefined
                                      ) {
                                        addNewManifestEntry()
                                      } else {
                                        var itemIndex = dashboardState.travelManifest.findIndex(
                                          item => item.tripUid === state.tripUid
                                        )
                                        if (itemIndex === -1) {
                                          //Check if the travel manifest entry is already there. This will happen when the user comes back to work on the trip
                                          addNewManifestEntry()
                                        } else {
                                          var oldManifest = [
                                            ...dashboardState.travelManifest,
                                          ]
                                          var travelManifest = []
                                          console.log(state.tripName)
                                          oldManifest.map((item, index) => {
                                            if (item.tripUid == state.tripUid) {
                                              var updatedObj = {
                                                tripUid: item.tripUid,
                                                tripPrimaryDestination:
                                                  state.tripPrimaryDestination,
                                                tripName: state.tripName,
                                                createdAt: item.createdAt,
                                                cardPictureURL:
                                                  item.cardPictureURL,
                                                travelDates:
                                                  state.tripLogistics
                                                    .travelDates,
                                                status: item.status,
                                              }
                                              travelManifest.push(updatedObj)
                                            } else {
                                              travelManifest.push(item)
                                            }
                                          })
                                          //   var newObj = { travelManifest: { ...travelManifest } }
                                          dbContext
                                            .firestore()
                                            .collection("trips")
                                            .doc(`${authContext.uid}`)
                                            .update({
                                              travelManifest: [
                                                ...travelManifest,
                                              ],
                                            })
                                            .then(() => {
                                              onNext()
                                              console.log(
                                                "Travel manifest updated successfully!"
                                              )
                                            })

                                          dbContext
                                            .firestore()
                                            .collection("newTripsPool")
                                            .doc(`${state.tripUid}`)
                                            .update({
                                              updatedAt: new Date(),
                                              travelDates:
                                                state.tripLogistics.travelDates,
                                              tripName: state.tripName,
                                              tripPrimaryDestination:
                                                state.tripPrimaryDestination,
                                              tripUid: state.tripUid,
                                              travelerUid: state.uid,
                                              travelerEmailId:
                                                authContext.email,
                                              remindersSentCount: 0,
                                              jobCreated: false,
                                              travelerFirstName: globalState.currentUserData
                                                ? globalState.currentUserData
                                                    .firstName
                                                : "",
                                            })
                                            .then(() => {
                                              console.log(
                                                "New trip added to pool"
                                              )
                                            })
                                            .catch(error => {})
                                        }
                                      }
                                    })
                                    .catch(error => {
                                      setIsBusy(false)
                                      console.log(
                                        "Error getting document:",
                                        error
                                      )
                                    })
                                  // setIsBusy(false)
                                  analytics.logEvent("wizard_travelers_info", {
                                    userId: `${authContext.uid}`,
                                    tripId: `${state.tripUid}`,
                                  })
                                }
                              } else {
                                console.log("ERROR!")
                                setIsBusy(false)
                                setShowMainErrorMessage(true)
                              }
                            } else {
                              console.log("not allowed")
                            }
                          }}
                        >
                          Preferences{" "}
                          <i
                            className="fas fa-angle-right"
                            style={{ marginLeft: "5px" }}
                          ></i>
                        </button>
                      </div>
                      <div className="column is-12-desktop is-12-tablet is-12-mobile">
                        <hr className="hr-branded"></hr>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* <div className="columns">
                    <div className="column is-6"></div>
                    <div className="column is-6"></div>
                  </div> */}

            {/* <div className="field is-horizontal">
      <div className="my-custom-class">
        <div className="field-label is-normal">
          <label className="label">Number of travelers</label>
        </div>
      </div>
    </div> */}
            {/* <div className="field is-horizontal">
        <div className="my-custom-class">
          <div className="field-label is-normal">
            <label className="label">Number of adults traveling:</label>
          </div>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control is-expanded">
              <div className="buttons has-addons">
                <button
                  onClick={() => dispatch({ type: "decrementAdultCount" })}
                  className="button is-warning"
                >
                  -
                </button>

                <span className="button is-static white-background-color">
                  {state.tripLogistics.adultTravelerCount}
                </span>
                <button
                  onClick={() => dispatch({ type: "incrementAdultCount" })}
                  className="button is-warning"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="my-custom-class">
          <div className="field-label is-normal">
            <label className="label">Number of children traveling:</label>
          </div>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control is-expanded">
              <div className="buttons has-addons">
                <button
                  onClick={() => dispatch({ type: "decrementChildCount" })}
                  className="button is-warning"
                >
                  -
                </button>

                <span className="button is-static white-background-color">
                  {state.tripLogistics.childrenTravelerCount}
                </span>
                <button
                  onClick={() => dispatch({ type: "incrementChildCount" })}
                  className="button is-warning"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
          </div>
        </div>
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
      </div>
    </>
  )
}

const TripLogisticsEntryPage = ({ onNext, onPrevious }) => {
  const [state, dispatch] = useTripStateStore()

  useEffect(() => {
    //    console.info({ state })
    console.log("%cTrip-State", "color: #ffc600; font-size: 18px", { state })
  }, [state])

  return (
    <>
      <h3
        align="center"
        className="subtitle is-3 align-center"
        //style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
      >
        {" "}
        {/* Trip Logistics */}
      </h3>

      {/* <hr className="hr-branded"></hr> */}
      {/* <progress
        className="progress is-warning is-thin"
        value={state.wizardProgress}
        max="100"
      >
        25%
      </progress> */}
      <TripLogisticsFormComponents
        state={state}
        dispatch={dispatch}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </>
  )
}
export default TripLogisticsEntryPage

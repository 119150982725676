import React, { useState, useEffect, useRef } from "react"
import Select from "react-select"
import { dietaryPreferences } from "./../../constants/data/general.js"
import { useTripStateStore } from "../../State/trip-state/trip-state-store"
const Details = ({ setCancel }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [age, setAge] = useState("")
  const [gender, setGender] = useState("")
  const [diet, setDiet] = useState("")
  const [wheelchair, setWheelchair] = useState("No")
  const [additionalDetails, setAdditionalDetails] = useState("")
  const [state, dispatch] = useTripStateStore()

  const clearAndClose = () => {
    setFirstName("")
    setLastName("")
    setAge("")
    setGender("")
    setDiet("")
    setWheelchair("No")
    setAdditionalDetails("")
    setCancel()
  }
  const updateState = () => {
    let _tDetails = {
      firstName: firstName,
      lastName: lastName,
      index: state.travelerDetails.length,
      gender: gender,
      diet: diet,
      age: age,
      wheelchair: wheelchair,
      additionalTravelerDetails: additionalDetails,
    }
    dispatch({ type: "addTravelerDetails", data: _tDetails })
    clearAndClose()
  }
  const editDetailsRef = useRef(null)
  const scrollToRef = ref => {
    try {
      window.scrollTo(0, ref.current.offsetTop)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    scrollToRef(editDetailsRef)
  }, [])

  useEffect(() => {
    //    console.info({ state })
    console.log("%cTrip-State", "color: #ffc600; font-size: 18px", { state })
  }, [state])
  const flexibleButton = (
    <>
      <button
        name="Yes"
        onClick={() => {
          setWheelchair("Yes")
        }}
        className={`button ${wheelchair === "Yes" ? "is-warning" : ""}`}
      >
        Yes
      </button>{" "}
      <button
        name="No"
        onClick={() => {
          setWheelchair("No")
        }}
        className={`button ${wheelchair === "Yes" ? "" : "is-warning"}`}
      >
        No
      </button>{" "}
    </>
  )

  return (
    <div>
      <div className="columns">
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet is-12-mobile">
          <div className="columns is-multiline">
            <div
              className="column is-6-desktop is-6-tablet"
              ref={editDetailsRef}
            >
              <div className="field">
                <label className="label">First name:</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    //   placeholder="First Name"
                    value={firstName}
                    onChange={e => {
                      setFirstName(e.currentTarget.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="column is-6-desktop is-6-tablet">
              <div className="field">
                <label className="label">Last name:</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    //placeholder="Last Name"
                    value={lastName}
                    onChange={e => {
                      setLastName(e.currentTarget.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="column is-3-desktop is-6-tablet">
              <div className="field">
                <label className="label">Age:</label>

                {/* <p className="help">Age</p> */}
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    //placeholder="Enter traveler's age"
                    value={age}
                    onChange={e => {
                      setAge(e.currentTarget.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="column is-3-desktop is-6-tablet">
              <div className="field">
                <label className="label">Gender:</label>

                {/* <p className="help">Gender</p> */}
                <div className="control is-expanded">
                  <Select
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    className="basic-single"
                    menuPortalTarget={document.body}
                    classNamePrefix="select"
                    isSearchable="true"
                    //isClearable="true"
                    onChange={(value, actionMeta) => {
                      if (value) setGender(value.label)
                    }}
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" },
                      { value: "nonbinary", label: "Non-binary" },
                      { value: "declinetoanswer", label: "Decline to answer" },
                    ]}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#fef0bf",
                        primary: "#fed43f",
                        //neutral0: "#424242",
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="column is-6-desktop is-6-tablet">
              <div className="field">
                <label className="label">Dietary Restrictions:</label>

                {/* <p className="help">Gender</p> */}
                <div className="control is-expanded">
                  <Select
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    className="basic-single"
                    menuPortalTarget={document.body}
                    classNamePrefix="select"
                    isSearchable="true"
                    //isClearable="true"
                    onChange={(value, actionMeta) => {
                      if (value) setDiet(value.label)
                    }}
                    options={dietaryPreferences}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#fef0bf",
                        primary: "#fed43f",
                        //neutral0: "#424242",
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="column is-12-desktop is-6-tablet">
              <div className="field">
                <label className="label">Wheelchair Access:</label>

                {/* <p className="help">Gender</p> */}
                <div className="control is-expanded">
                  <div className="buttons has-addons are-small">
                    {flexibleButton}
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-12-desktop is-12-tablet">
              <label className="label">Additional details:</label>
              <textarea
                value={additionalDetails}
                className="textarea"
                placeholder="Additional details..."
                onChange={e => {
                  setAdditionalDetails(e.currentTarget.value)
                }}
              ></textarea>
            </div>
            <div className="column is-12-desktop is-12-tablet">
              <div className="columns is-mobile">
                <div className="column is-6-desktop is-6-tablet  is-6-mobile">
                  <button
                    onClick={() => {
                      setCancel()
                    }}
                    className="button is-light"
                  >
                    Cancel
                  </button>
                </div>
                <div className="column is-6-desktop is-6-tablet  is-6-mobile">
                  <button
                    onClick={() => {
                      updateState()
                    }}
                    className="button is-warning"
                    style={{ float: "right" }}
                  >
                    Add traveler
                  </button>
                </div>
              </div>
            </div>
            <div className="column is-6-desktop is-6-tablet"></div>
            <div className="column is-6-desktop is-6-tablet"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details

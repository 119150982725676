import { createContext } from "react"
import { useImmerReducer } from "use-immer"
import React from "react"
import { dashboardStateReducer } from "./dashboard-state-reducer"

const defaultDashboardState = {
  activeTrips: [],
  archivedTrips: [],
  tripDetails: "",
  jobs: [],
  currentJobInfo: "",
  activeTab: "Itinerary",
  editLogistics: false,
  editTravelerDetails: false,
  editTravelerPreferences: false,
  designerItineraryWorkspace: {},
  activityScratchPad: {},
}

const dashboardStateContext = createContext(defaultDashboardState)
const dispatchDashboardStateContext = createContext(undefined)

export const useDashboardStateStore = () => [
  React.useContext(dashboardStateContext),
  React.useContext(dispatchDashboardStateContext),
]

export const DashboardStateProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(
    dashboardStateReducer,
    defaultDashboardState
  )
  return (
    <dashboardStateContext.Provider value={state}>
      <dispatchDashboardStateContext.Provider value={dispatch}>
        {children}
      </dispatchDashboardStateContext.Provider>
    </dashboardStateContext.Provider>
  )
}

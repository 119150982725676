import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"

import ChecklistCreator from "./../ChecklistTab/ChecklistCreator"

function ChecklistComponent() {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()

  const beginWork = e => {
    //Update the required state and db values
    try {
      //get all the date between the trip start and end dates.

      if (dbContext) {
        dbContext
          .firestore()
          .collection("jobs")
          .doc(`${authContext.uid}`)
          .collection("active-jobs")
          .doc(`${state.currentJobInfo.jobUid}`)
          .update({
            tripChecklistStatus: "in-progress",
            tripChecklist: [],
          })
          .then(() => {
            console.log("document update successful")
            //update local state now
            dispatch({ type: "tripChecklistInProgress" })
            // dispatch({
            //   type: "initializeDesignerWorkspaceForItineraries",
            //   data: a,
            // })

            //
          })
          .catch(e => console.log(e))
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/* If no work as commenced yet on itineraries*/}
      {state.currentJobInfo.tripChecklistStatus === "in-progress" ? (
        <ChecklistCreator />
      ) : (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <button className="button is-warning" onClick={beginWork}>
              Create a new traveler checklist
            </button>
          </div>
          <div>
            {/* <img
              src={checklist}
              style={{ maxWidth: "500px", marginTop: "80px" }}
            ></img> */}
          </div>
        </div>
      )}
    </>
  )
}

export default ChecklistComponent

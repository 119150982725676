import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"
// import checklist from "./../../../images/checklist/checklist.svg"
import { ChecklistCard } from "./ChecklistCard"

function ChecklistViewer() {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  const [toggleStar, setToggleStar] = useState(false)
  const [toggleCheck, setToggleCheck] = useState(false)
  const [checkListItems, setCheckListItems] = useState("")

  const [syncRequired, setSyncRequired] = useState(false)

  const addChecklistItem = () => {
    dispatch({ type: "addChecklistItem" })
  }

  const onSyncRequired = e => {}
  const showChecklistItems = () => {
    state.currentJobInfo.tripChecklist.map((item, index) => {
      return <ChecklistCard date={item} onSyncRequired={onSyncRequired} />
    })
  }

  useEffect(() => {}, [state.currentJobInfo.tripChecklist])

  useEffect(() => {
    try {
      console.log("change to sync  flag")
      if (state.currentJobInfo.tripChecklistSyncRequired) {
        //get all the date between the trip start and end dates.
        console.log("updating DB")
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${authContext.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({ tripChecklist: state.currentJobInfo.tripChecklist })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => console.log(e))
        }

        dispatch({ type: "tripChecklistSyncRequired", data: false })
      }
    } catch (error) {
      console.log(error)
    }
  }, [state.currentJobInfo.tripChecklistSyncRequired])

  const onRemove = e => {
    dispatch({
      type: "deleteChecklistItem",
      index: e.currentTarget.id,
    })
    dispatch({ type: "tripChecklistSyncRequired", data: true })
  }

  return (
    <div>
      <div className="columns">
        <div className="column  is-2-desktop is-1-tablet"></div>
        <div className="column  is-8-desktop is-10-tablet">
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
            className=""
          >
            {/* <div
            style={{
              marginBottom: "20px",
            }}
          >
            <button className="button is-warning" onClick={addChecklistItem}>
              <span className="icon is-small">
                <i className="far fa-plus-square"></i>
              </span>
              <span>Add a new checklist item</span>
            </button>
          </div> */}
            <p style={{ fontSize: "22px", fontWeight: "600px" }}>
              Your Checklist
            </p>
            {state.currentJobInfo.tripChecklist.map((item, index) => {
              console.log({ item })
              if (item != undefined)
                return (
                  <ChecklistCard
                    key={index}
                    data={item}
                    onRemove={onRemove}
                    canEdit={false}
                  />
                )
            })}
            <div style={{ zIndex: "-5", position: "absolute", top: "50px" }}>
              {/* <img
                src={checklist}
                style={{ maxWidth: "400px", marginTop: "80px" }}
              ></img> */}
            </div>
          </div>
        </div>
        <div className="column  is-2-desktop is-1-tablet"></div>
      </div>
    </div>
  )
}

export default ChecklistViewer

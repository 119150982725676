import algoliasearch from "algoliasearch/lite"
import { findIndex, sortBy, toPairs } from "lodash"
import React, { useContext, useEffect, useState } from "react"
import {
  Configure,
  connectCurrentRefinements,
  connectHits,
  connectHitsPerPage,
  connectPagination,
  connectPoweredBy,
  connectRefinementList,
  connectSearchBox,
  Highlight,
  InstantSearch,
} from "react-instantsearch-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Select from "react-select"
import { analytics } from "../../components/firebase/firebase"
import { AuthUserContext } from "../../components/session"
import DesignerCard from "../../components/TripCards/DesignerCard"
import { designerSkills } from "../../constants/data/general"
import notFound from "./../../images/not-found.svg"
import algoliaImg from "./../../images/search-by-algolia.png"
import "./SearchDesigner.scss"

const searchClient = algoliasearch(
  `${process.env.ALGOLIA_SEARCH_APP_ID}`,
  `${process.env.ALGOLIA_SEARCH_API_KEY}`
)
const InstantSearchComponent = ({
  onSelect,
  destination,
  skillsAffinityMatrix,
}) => {
  const [fetchingDesigner, setFetchingDesigner] = useState(true)
  const authContext = useContext(AuthUserContext)
  useEffect(() => {
    // if (is.mobile()) {
    var x = document.getElementById("filterDIV")
    var y = document.getElementById("filterButton")
    x.style.display = "none"
    y.innerText = "Show filters"
    // } else {
    //   var x = document.getElementById("filterDIV")
    //   var y = document.getElementById("filterButton")
    //   x.style.display = "block"
    //   toggleFilter = false
    //   y.innerText = "Hide filters"
    // }
    // console.log({ destination })
    let ID = process.env.ALGOLIA_SEARCH_APP_ID
    let Key = process.env.ALGOLIA_SEARCH_API_KEY
    // console.log({ ID })
    // console.log({ Key })
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("Timer Expired!!!")
      setFetchingDesigner(false)
      if (authContext) {
        analytics.logEvent("wizard_no_designers_found", {
          userId: `${authContext.uid}`,
          destination: `${destination}`,
        })
        analytics.setUserProperties({ destination: `${destination}` })
      }
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const showDesigners = ({ hits }) => {
    console.log({ hits })
    if (hits && hits.length === 0) {
      return fetchingDesigner ? (
        <div
          className="spinner center spinner-brand-color"
          style={{ zIndex: "20" }}
        >
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      ) : (
        <div
          style={{ minWidth: "250px", maxWidth: "500px", margin: "0px auto" }}
        >
          <LazyLoadImage
            src={notFound}
            alt=""
            style={{ width: "100%", height: "120px" }}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              textTransform: "uppercase",
              letterSpacing: "0.5px",
              fontWeight: "600",
              marginTop: "5px",
            }}
          >
            {" "}
            No Designers found!
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              // textTransform: "uppercase",
              // letterSpacing: "0.5px",
              fontWeight: "400",
              marginTop: "5px",
              marginBottom: "30px",
              padding: "0px 10px",
            }}
          >
            Unfortunately there were no designers found that cover your travel
            destination. We'll notify you as soon as we have that place covered.
            We appreciate your patience!
          </p>
        </div>
      )
    }
    return hits.map((item, index) => {
      if (item.doNotShow) return ""

      return (
        <div className="" style={{ marginRight: "25px", marginBottom: "25px" }}>
          <DesignerCard key={index} hit={item} onSelect={onSelect} />
        </div>
      )
    })
  }
  // const showDesigners = ({ hits }) => {
  //   return hits.map((item, index) => {
  //     if (item.doNotShow) return ""
  //     else return <DesignerCard key={index} hit={item} onSelect={onSelect} />
  //   })
  // }

  function getPagingRange(current, { min = 1, total = 20, length = 5 } = {}) {
    if (length > total) length = total

    let start = current - Math.floor(length / 2)
    start = Math.max(start, min)
    start = Math.min(start, min + total - length)

    return Array.from({ length: length }, (el, i) => start + i)
  }

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
    // return the DOM output
    console.log({ nbPages })
    if (nbPages <= 1) return ""
    return (
      <nav
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        <a
          className="pagination-previous"
          onClick={event => {
            event.preventDefault()
            if (currentRefinement > 1) refine(currentRefinement - 1)
          }}
        >
          Previous
        </a>
        <a
          className="pagination-next"
          onClick={event => {
            event.preventDefault()
            if (currentRefinement < nbPages) refine(currentRefinement + 1)
          }}
        >
          Next page
        </a>
        <ul className="pagination-list">
          {getPagingRange(currentRefinement, {
            min: 1,
            total: nbPages,
            length: 5,
          }).map((i, index) => {
            const page = i
            const style = {
              fontWeight: currentRefinement === page ? "bold" : "",
            }
            console.log({ i })
            console.log({ currentRefinement })
            return (
              <li key={i}>
                <a
                  className={`pagination-link ${
                    currentRefinement === page ? "is-current" : ""
                  }`}
                  href={createURL(page)}
                  style={style}
                  onClick={event => {
                    event.preventDefault()
                    refine(page)
                  }}
                >
                  {page}
                </a>
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }
  const CustomPagination = connectPagination(Pagination)

  const PoweredBy = ({ url }) => (
    <a href={url} style={{ color: "#4a4a4a", fontSize: "14px" }}>
      <img src={algoliaImg} alt="algolia" style={{ maxHeight: "18px" }} />
    </a>
  )
  const CustomPoweredBy = connectPoweredBy(PoweredBy)

  const RefinementList = ({
    items,
    currentRefinement,
    refine,
    isFromSearch,
    searchForItems,
    createURL,
    showMore,
    limit,
    translations,
  }) => {
    const [extended, setExtended] = useState(false)
    return (
      <>
        {/* <div>
          Tags selected: {currentRefinement ? currentRefinement.join(", ") : ""}
        </div> */}
        <ul style={{ color: "#4a4a4a" }}>
          <li>
            <div className="field" style={{ marginBottom: "10px" }}>
              <div className="control has-icons-right">
                <input
                  className="input is-warning is-small"
                  type="search"
                  onChange={event => searchForItems(event.currentTarget.value)}
                  placeholder="search"
                  // style={{
                  //   position: "relative",
                  // }}
                />
                <span
                  className="icon is-small is-right"
                  // style={{
                  //   position: "absolute",
                  //   right: "10px",
                  //   top: "7px",
                  //   color: "#4a4a4a",
                  // }}
                >
                  <i className="far fa-search"></i>
                </span>
              </div>
            </div>
          </li>
          {items
            ? items.map(
                (item, i) =>
                  (i < limit || extended) && (
                    <li key={item.label}>
                      <a
                        href={createURL(item.value)}
                        style={{
                          fontWeight: item.isRefined ? "bold" : "",
                          color: "#4a4a4a",
                        }}
                        onClick={event => {
                          event.preventDefault()
                          refine(item.value)
                        }}
                      >
                        {/* <input
                type="checkbox"
                style={{ marginRight: "5px" }}
                checked={true}
              ></input> */}
                        {item.isRefined ? (
                          <i
                            className="far fa-check-square fa-lg"
                            style={{ color: "#fec600", marginRight: "5px" }}
                          ></i>
                        ) : (
                          <i
                            className="far fa-square fa-lg"
                            style={{ color: "#fec600", marginRight: "5px" }}
                          ></i>
                        )}
                        {isFromSearch ? (
                          <Highlight attribute="label" hit={item} />
                        ) : (
                          item.label
                        )}{" "}
                        ({item.count})
                      </a>
                    </li>
                  )
              )
            : ""}

          {/* <button onClick={toggleShowMore}>show more</button> */}
        </ul>
        {showMore && (
          <button
            onClick={() => {
              setExtended(!extended)
            }}
            className="button is-small is-warning"
            style={{ margin: "10px 0" }}
          >
            {translations["showMore"](extended)}
          </button>
        )}
      </>
    )
  }

  const CustomRefinementList = connectRefinementList(RefinementList)
  // const HitsPerPage = ({ items, currentRefinement, refine, createURL }) => (
  //   <div>
  //     {/* <p>The search display {currentRefinement} hits.</p> */}
  //     <Select
  //       // defaultValue={vacationPacing.find(
  //       //   element => element.label === state.tripPreferences.pacing
  //       // )}
  //       classNamePrefix="select"
  //       isSearchable="false"
  //       isClearable="false"
  //       onChange={(value, actionMeta) => {}}
  //       options={items}
  //       theme={theme => ({
  //         ...theme,
  //         colors: {
  //           ...theme.colors,
  //           primary25: "#fef0bf",
  //           primary: "#fed43f",
  //           primary50: "#fee27f",
  //           //neutral0: "#424242",
  //         },
  //       })}
  //     />
  //     <ul>
  //       {items.map(item => (
  //         <li key={item.value}>
  //           <a
  //             href={createURL(item.value)}
  //             style={{ fontWeight: item.isRefined ? "bold" : "" }}
  //             onClick={event => {
  //               event.preventDefault()
  //               refine(item.value)
  //             }}
  //           >
  //             {item.label}
  //           </a>
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // )
  // const CustomHitsPerPage = connectHitsPerPage(HitsPerPage)
  const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
    return (
      <div className="field has-addons">
        {/* <p className="control">
          <a className="button is-static force-brand-color">
            <i className="fas fa-search"></i>
          </a>
        </p> */}
        <div className="control is-expanded">
          <input
            className="input"
            value={currentRefinement}
            type="text"
            placeholder="Search..."
            style={{
              position: "relative",
              paddingRight: "107px",
            }}
            onChange={e => refine(e.currentTarget.value)}
          />
          <p className="help">
            Search by your destination country or travel designer name e.g.
            Cambodia, John
          </p>
          <span
            style={{
              position: "absolute",
              right: "-35px",
              top: "-20px",
              color: "#4a4a4a",
              zIndex: "10",
            }}
          >
            <CustomPoweredBy />
          </span>
        </div>
        <div className="control">
          <button
            className="button"
            onClick={() => refine("")}
            style={{
              border: "1px solid #fec600",
              borderLeft: "none",
            }}
          >
            <span className="icon is-small">
              <i className="fal fa-times" style={{ color: "#4a4a4a" }}></i>
            </span>
          </button>
        </div>
        {isSearchStalled ? "My search is stalled" : ""}
      </div>
    )
  }
  const CustomSearchBox = connectSearchBox(SearchBox)
  const CustomHits = connectHits(showDesigners)

  const HitsPerPage = ({ items, currentRefinement, refine, createURL }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {/* <p>The search display {currentRefinement} hits.</p> */}
      <div
        style={{
          fontSize: "14px",
        }}
      >
        Results per page
      </div>
      <div
        style={{
          width: "80px",
          fontSize: "14px",
          marginLeft: "5px",
        }}
      >
        <Select
          defaultValue={items.find(element => element.isRefined === true)}
          classNamePrefix="select"
          isSearchable="false"
          isClearable={false}
          onChange={(value, actionMeta) => {
            if (value) refine(value.value)
          }}
          options={items}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#fef0bf",
              primary: "#fed43f",
              primary50: "#fee27f",
              //neutral0: "#424242",
            },
          })}
        />
      </div>
      {/* <ul>
        {items.map(item => (
          <li key={item.value}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={event => {
                event.preventDefault()
                refine(item.value)
              }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul> */}
    </div>
  )
  const CustomHitsPerPage = connectHitsPerPage(HitsPerPage)

  const CurrentRefinements = ({ items, refine, createURL }) => {
    console.log(items)
    return (
      <ul>
        {items.map(item => (
          <li key={item.label}>
            {item.items ? (
              <React.Fragment>
                {item.label === "countriesForItineraries.value"
                  ? "Destination"
                  : item.label}
                <ul>
                  {item.items.map(nested => (
                    <li key={nested.label}>
                      <a
                        href={createURL(nested.value)}
                        onClick={event => {
                          event.preventDefault()
                          refine(nested.value)
                        }}
                      >
                        {nested.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ) : (
              <a
                href={createURL(item.value)}
                onClick={event => {
                  event.preventDefault()
                  refine(item.value)
                }}
              >
                {item.label === "countriesForItineraries.value"
                  ? "Destination"
                  : item.label}
              </a>
            )}
          </li>
        ))}
      </ul>
    )
  }

  const processAffinityList = () => {
    var refinements = []
    if (skillsAffinityMatrix) {
      const sortedSkillsAffinityMatrix = sortBy(
        toPairs(skillsAffinityMatrix),
        1
      ).reverse()
      let itemIndex = findIndex(designerSkills, function(o) {
        return o.value == sortedSkillsAffinityMatrix[0][0]
      })
      if (itemIndex !== -1) refinements.push(designerSkills[itemIndex].label)
    }
    console.log({ refinements })
    return refinements
  }

  const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements)
  var toggleFilter = true
  return (
    <div>
      <InstantSearch
        refresh={true}
        indexName="designers"
        searchClient={searchClient}
      >
        <Configure filters="available:true" />
        {/* <Stats
          translations={{
            stats(nbHits, timeSpentMS) {
              return `${nbHits} results found in ${timeSpentMS}ms`
            },
          }}
        /> */}
        <CustomSearchBox />
        {/* <PoweredBy
              translations={{
                searchBy: "Powered by ",
              }}
            /> */}
        {/* <HitsPerPage
              defaultRefinement={5}
              items={[
                { value: 1, label: "Show 1" },
                { value: 5, label: "Show 5" },
                { value: 10, label: "Show 10" },
              ]}
            /> */}

        <nav className="level is-mobile">
          <div className="level-left">
            <div className="level-item">
              <button
                id="filterButton"
                className="button is-small is-warning"
                onClick={e => {
                  var x = document.getElementById("filterDIV")
                  var y = document.getElementById("filterButton")
                  // toggleFilter = !toggleFilter
                  if (x.style.display === "none") {
                    x.style.display = "block"
                    toggleFilter = false
                    y.innerText = "Hide filters"
                  } else {
                    x.style.display = "none"
                    toggleFilter = true
                    y.innerText = "Show filters"
                  }
                }}

                // style={{ textAlign: "right", fontSize: "16px" }}
              >
                Hide filters
                {console.log(toggleFilter)
                // toggleFilter ? (
                //   <p>
                //     Show Filters{" "}
                //     <i style={{ marginLeft: "5px" }} className="far fa-chevron-down"></i>
                //   </p>
                // ) : (
                //   <p>
                //     Hide Filters{" "}
                //     <i style={{ marginLeft: "5px" }} className="far fa-chevron-right"></i>
                //   </p>
                // )
                }
              </button>
            </div>
          </div>

          {/* <div className="level-right">
            <div className="level-item">
              <CustomHitsPerPage
                defaultRefinement={10}
                items={[
                  { value: 5, label: "5" },
                  { value: 10, label: "10" },
                  { value: 20, label: "20" },
                ]}
              />
            </div>
          </div> */}
        </nav>

        {/* <p>Current Refinements:</p>
            <CustomCurrentRefinements /> */}

        <div className="columns" style={{ marginTop: "20px" }}>
          <div
            className="column is-3-desktop is-3-tablet fade-in"
            id="filterDIV"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "16px",
                marginBottom: "10px",
                textDecoration: "underline",
                textDecorationColor: "#fec600",
              }}
            ></div>
            {/* <ToggleRefinement
              attribute="skills.label"
              label="Family Fun"
              value="Family Fun"
              defaultRefinement={true}
            />
            <ToggleRefinement
              attribute="skills.label"
              label="Whiskey Connoisseur"
              value="Whiskey Connoisseur"
              defaultRefinement={true}
            /> */}
            <div className="">
              <p style={{ fontWeight: "500", fontSize: "14px" }}>
                Travel Destination:
              </p>
              <div
                style={{
                  padding: "5px",
                  marginBottom: "20px",
                  padding: "10px",
                  // paddingTop: "20px",
                  borderTop: "1px solid #fec600",
                  // borderRadius: "5px",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                <CustomRefinementList
                  limit={10}
                  showMore={true}
                  showMoreLimit={150}
                  searchable={true}
                  attribute="countriesForItineraries.value"
                  defaultRefinement={destination}
                  operator="and"
                  translations={{
                    showMore(expanded) {
                      return expanded ? "Show less" : "Show more"
                    },
                  }}
                />
              </div>
              <p style={{ fontWeight: "500", fontSize: "14px" }}>
                Languages Spoken:
              </p>
              <div
                style={{
                  padding: "5px",
                  marginBottom: "20px",
                  padding: "10px",
                  // paddingTop: "20px",
                  borderTop: "1px solid #fec600",
                  // borderRadius: "5px",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                <CustomRefinementList
                  limit={5}
                  showMore={true}
                  showMoreLimit={25}
                  searchable={true}
                  attribute="languagesSpoken.value"
                  // defaultRefinement={}
                  translations={{
                    showMore(expanded) {
                      return expanded ? "Show less" : "Show more"
                    },
                  }}
                />
              </div>
              <p style={{ fontWeight: "500", fontSize: "14px" }}>
                Designer Skills:
              </p>
              <div
                style={{
                  padding: "5px",
                  marginBottom: "20px",
                  padding: "10px",
                  // paddingTop: "20px",
                  borderTop: "1px solid #fec600",
                  // borderRadius: "5px",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                <CustomRefinementList
                  limit={5}
                  showMore={true}
                  showMoreLimit={25}
                  searchable={true}
                  operator="and"
                  attribute="skills.label"
                  // defaultRefinement={processAffinityList()}
                  // defaultRefinement={[`Family Fun`]}
                  translations={{
                    showMore(expanded) {
                      return expanded ? "Show less" : "Show more"
                    },
                  }}
                />
              </div>
            </div>
          </div>
          {/* <p style={{ fontWeight: "500", fontSize: "18px" }}>Tags:</p>
                <div
                  style={{
                    padding: "5px",
                    marginBottom: "20px",
                    padding: "10px",
                    // paddingTop: "20px",
                    borderTop: "1px solid #fec600",
                    // borderRadius: "5px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  <CustomRefinementList
                    limit={5}
                    showMore={true}
                    showMoreLimit={25}
                    searchable={true}
                    attribute="tags"
                    translations={{
                      showMore(expanded) {
                        return expanded ? "Show less" : "Show more"
                      },
                    }}
                  />
                </div> */}

          {/* <RefinementList
                showMore={true}
                showMoreLimit={7}
                searchable={true}
                attribute="countriesVisited.label"
              /> */}
          {/* <RatingMenu
                attribute="reviewRating"
                // defaultRefinement={{ min: 2 }}
                max={5}
              /> */}

          <div className="column">
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                minHeight: "200px",
              }}
            >
              <CustomHits />
            </div>
            <CustomPagination />
          </div>
        </div>
      </InstantSearch>
    </div>
  )
}

export default InstantSearchComponent

import React, { useContext, useRef, useMemo, useCallback } from "react"
import { useState, useEffect } from "react"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import { DebounceInput } from "react-debounce-input"
import TimePicker from "react-time-picker"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { ReactSortable } from "react-sortablejs"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import noevents from "./../../../images//activities.svg"
import algoliaImg from "./../../../images/search-by-algolia.png"
import swal from "sweetalert"
import GoogleMapReact from "google-map-react"
import AuthUserContext from "../../../components/session/context"
import { FirebaseContext } from "../../../components/firebase"
import { NewActivityForm } from "./NewActivityForm"
import { ActivityCard } from "./ActivityCard"
import { element } from "prop-types"
import { CommuteCard } from "./../ItineraryTab/CommuteCard"
import { NewCommuteForm } from "./../ItineraryTab/NewCommuteForm"
import ReactTooltip from "react-tooltip"
import CreatableSelect from "react-select/creatable"
import {
  addZeroBefore,
  dottedText,
  getHours,
  getMinutes,
  parseDayAndItemFromString,
  titleCase,
} from "../../../utility/general"
import Geocode from "react-geocode"
import {
  connectAutoComplete,
  InstantSearch,
  Highlight,
  connectPoweredBy,
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import moment from "moment"
import Select from "react-select"
const searchClient = algoliasearch(
  `${process.env.ALGOLIA_SEARCH_APP_ID}`,
  `${process.env.ALGOLIA_SEARCH_API_KEY}`
)

function ItineraryDateTabs({ onTabChange }) {
  const [currentItem, setCurrentItem] = useState(0)
  const [dateButtons, setDateButtons] = useState()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [state, dispatch] = useDashboardStateStore()

  useEffect(() => {
    try {
      var currentItem = state.activeDay !== undefined ? state.activeDay : 0
      onTabChange(currentItem)
      buttonList[currentItem] = "selected"

      //Generate the date tab buttons for all the travel days.
      var startDate =
        typeof state.currentJobInfo.travelDates.start === "string"
          ? new Date(state.currentJobInfo.travelDates.start)
          : state.currentJobInfo.travelDates.start.toDate()
      var endDate =
        typeof state.currentJobInfo.travelDates.end === "string"
          ? new Date(state.currentJobInfo.travelDates.end)
          : state.currentJobInfo.travelDates.end.toDate()
      setDateButtons(generateDateButtons(startDate, endDate))
    } catch (error) {
      console.log(error)
    }
  }, [state])

  var buttonList = []
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  var getDaysArray = function(s, e) {
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d))
      buttonList.push("")
    }
    return a
  }

  const formatDestinations = activeTab => {
    try {
      var destinationString = ""
      console.log({ activeTab })
      state.currentJobInfo.designerWorkspace.itinerary[
        activeTab
      ].daysDestination.map((item, index) => {
        destinationString =
          destinationString +
          item.label +
          (state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .daysDestination.length ===
          index + 1
            ? ""
            : ", ")
      })
      console.log(destinationString)
      return destinationString
    } catch (err) {
      console.log(err)
      return ""
    }
  }
  // This is for the date selection buttons on the itinerary tab.
  const generateDateButtons = (startDate, endData) => {
    return (
      <div className="day-box-container" style={{ flexDirection: "row" }}>
        {getDaysArray(startDate, endData).map((item, index) => {
          var month = months[item.getMonth()]
          var date = item.getDate()
          var day = weekdays[item.getDay()]
          console.log({ index })
          return (
            <div
              key={month + date}
              id={index}
              className={`day-box ${buttonList[index]}`}
              onClick={e => {
                console.log(e.currentTarget.id)
                dispatch({ type: "setActiveDay", data: e.currentTarget.id })
                // setCurrentItem(e.currentTarget.id)
              }}
              style={{
                marginTop: "25px",
              }}
            >
              <p
                style={{
                  paddingTop: "1px",
                  fontSize: "10px",
                  marginTop: "-17px",
                  letterSpacing: "0.5px",
                  textTransform: "uppercase",
                  color: "rgba(78,78,78,0.8)",
                }}
                align="center"
              >
                {month}
              </p>
              <p
                style={{
                  paddingTop: "1px",
                  fontSize: "10px",
                  marginTop: "2px",
                }}
                align="center"
              >
                {day}
              </p>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "rgba(78,78,78,0.7)",
                  marginTop: "-4px",
                }}
                align="center"
              >
                {date}
              </p>
              {/* <p
                style={{
                  paddingTop: "1px",
                  fontSize: "10px",
                  marginTop: "7px",
                  letterSpacing: "0.5px",
                  textTransform: "uppercase",
                  color: "rgba(78,78,78,0.8)",
                }}
                align="center"
              >
                {formatDestinations(index)}
              </p> */}
            </div>
          )
        })}
      </div>
    )
  }

  return <>{dateButtons}</>
}

const checkIfCommute = value => {
  try {
    return value.activity.activityType === "commute" ? "commute" : ""
  } catch (err) {
    return ""
  }
}

// Using the HOC to create a draggable list container.
const SortableList = SortableContainer(
  ({
    items,
    onRemove,
    activeTab,
    onEditActivity,
    onEditCommute,
    onAddCommute,
    onRemoveCommute,
    onRemoveBackup,
    disableDrag,
    type,
    onMoveToBackup,
    isSetAsBackup,
    onMoveToActive,
  }) => {
    console.log({ items })
    return (
      <>
        <div className="list-container">
          {items.map((value, i) => {
            console.log({ i })
            return (
              <React.Fragment key={`card-${i}`}>
                <ActivityCard
                  key={`card-${i}`}
                  // key={`card-${value.activity.activityUid}`}
                  // type={type}
                  itemNo={i}
                  index={i}
                  value={value}
                  onRemove={onRemove}
                  activeTab={activeTab}
                  onEditActivity={onEditActivity}
                  type={checkIfCommute(value)}
                  onEditCommute={onEditCommute}
                  onAddCommute={onAddCommute}
                  onRemoveCommute={onRemoveCommute}
                  canEdit={true}
                  disabled={disableDrag}
                  onMoveToBackup={onMoveToBackup}
                  isSetAsBackup={isSetAsBackup}
                  onMoveToActive={onMoveToActive}
                  onRemoveBackup={onRemoveBackup}
                  // disabled={
                  //   value.activity.activityType === "commute" ? "disabled" : ""
                  // }
                />
                {/* <hr className="hr-branded-type2"></hr> */}
              </React.Fragment>
            )
          })}
        </div>
      </>
    )
  }
)

function ItineraryCreator() {
  const [activeTab, setActiveTab] = useState(0)
  const [searchResults, setSearchResults] = useState()

  //This state variable stores the text that the user is typing to search an activity.
  const [searchText, setSearchText] = useState("")

  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)

  const [isSearching, setIsSearching] = useState(false)

  const [state, dispatch] = useDashboardStateStore()

  const [state1, setState1] = useState([])

  const [showNewActivityEntry, setShowNewActivityEntry] = useState(false)

  const [inActivityEditMode, setInActivityEditMode] = useState(false)
  const [inCommuteEditMode, setInCommuteEditMode] = useState(false)
  const [showNewCommuteEntry, setShowNewCommuteEntry] = useState(false)

  const [designerWorkSpaceModified, setDesignerWorkSpaceModified] = useState(
    false
  )
  const [startTime, setStartTime] = useState("")
  const [daysStartTimeInEditMode, setDaysStartTimeInEditMode] = useState(false)
  //This state variable stores the <li> search results when the user uses UP or DOWN arrow to navigate the search results.
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
  const [disableDrag, setDisableDrag] = useState(true)
  const [showHits, setShowHits] = useState(false)

  const [markerCoordinates, setMarkerCoordinates] = useState([])
  const [editDayDestination, setEditDayDestination] = useState(false)

  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  useEffect(() => {
    if (inActivityEditMode) setShowNewActivityEntry(true)
  }, [inActivityEditMode])

  useEffect(() => {
    if (inCommuteEditMode) setShowNewCommuteEntry(true)
  }, [inCommuteEditMode])

  useEffect(() => {
    //  recalculateSchedule()
    // geoCodeActivityAddress()
    setActiveTab(0)
    if (state.currentJobInfo.designerWorkspace !== undefined)
      dispatch({ type: "setActiveDay", data: 0 })
    // Initialize the selection index and the search results.
    dispatch({ type: "updateSearchResults", data: [] })

    //This clears the flag the triggers a db sync.
    dispatch({ type: "syncSchedule", data: false })
    setSelectedItemIndex(-1)
  }, [])

  useEffect(() => {
    console.log("designerWorkSpaceModified: ", designerWorkSpaceModified)
    // Initialize the selection index and the search results.
  }, [designerWorkSpaceModified])

  useEffect(() => {
    console.log("markerCoordinates: ", markerCoordinates)
    // Initialize the selection index and the search results.
  }, [markerCoordinates])
  useEffect(() => {
    try {
      let dayIndex = state.activeDay
      geoCodeActivityAddress(dayIndex)
    } catch (err) {}
    // Initialize the selection index and the search results.
  }, [state.currentJobInfo.designerWorkspace ? state.activeDay : null])

  useEffect(() => {
    try {
      if (state.activeDay !== undefined) setActiveTab(state.activeDay)

      //
      if (state.currentJobInfo.designerWorkspace.updateCommute) {
        //syncDesignerWorkSpaceDB()
        //update itinerary times
        console.log("Updating Commute")
        refreshCommute()
        dispatch({ type: "syncCommute", data: false })
        dispatch({ type: "syncSchedule", data: true })
      }
      if (state.currentJobInfo.designerWorkspace.updateRequired) {
        geoCodeActivityAddress(state.activeDay)
        //syncDesignerWorkSpaceDB()
        //update itinerary times
        console.log("Updating Itinerary Schedule")
        recalculateSchedule()
        dispatch({ type: "syncSchedule", data: false })
        dispatch({ type: "syncStats", data: true })
      }

      if (state.currentJobInfo.designerWorkspace.updateStats) {
        //syncDesignerWorkSpaceDB()
        //update itinerary times
        console.log("Updating Days Stats")
        refreshDaysStats()
        dispatch({ type: "syncStats", data: false })
        dispatch({ type: "syncDB", data: true })
      }
      if (state.currentJobInfo.designerWorkspace.updateDB) {
        console.log("Updating DB")
        syncDesignerWorkSpaceDB()
        dispatch({ type: "syncDB", data: false })
      }
    } catch (err) {
      console.log(err)
    }
  }, [state.currentJobInfo.designerWorkspace])

  useEffect(() => {
    try {
      if (selectedItemIndex != -1) {
        // Highlight the search results item when using UP and DOWN key.
        document.getElementById(
          state.searchResults[selectedItemIndex].activityUid
        ).className = "focus"

        // Update the search bar with the item the user has the focus on using the arrow key.
        document.getElementById("search-field").value =
          state.searchResults[selectedItemIndex].activityName
      }
    } catch (err) {
      console.log(err)
    }
  }, [selectedItemIndex])

  useEffect(() => {
    try {
      if (searchText.length > 0) {
        // When the search text is ready after debounce get the filtered results from firestore.
        if (dbContext) {
          dbContext
            .firestore()
            .collection("activities")
            .doc(`${authContext.uid}`)
            .collection("activities")
            .where("activityNameSplitString", "array-contains", searchText)
            .limit(10)
            .get()
            .then(function(querySnapshot) {
              var a = []
              querySnapshot.forEach(function(doc) {
                a.push(doc.data())
              })
              // Clear previous search results and update the state.activities.searchResults with new results.
              dispatch({ type: "updateSearchResults", data: a })
            })
            .catch(function(error) {
              console.log("Error getting documents: ", error)
            })
        }
      } else {
        // reset when the search input box is cleared.
        dispatch({ type: "updateSearchResults", data: [] })
        setSelectedItemIndex(-1)
      }
    } catch (error) {}
  }, [searchText])

  const onTabChange = tab => {
    // Switch tabs for highlighting the tab class.
    dispatch({ type: "setActiveDay", data: tab })
    // geoCodeActivityAddress()
    setActiveTab(tab)
  }

  const showDaysPlan = () => {
    try {
      if (state.currentJobInfo.designerWorkspace.itinerary != undefined) {
        return state.currentJobInfo.designerWorkspace.itinerary[
          activeTab
        ].date.toDateString()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const showActivitiesType2 = () => {
    try {
      return (
        <div>
          <ReactSortable
            animation="200"
            ghostclassName="sort-ghost-card"
            list={state1}
            setList={setState1}
            group="shared"
            // easing="cubic-bezier(.2, 0, 0, 1)"
          >
            {state1.map(item => (
              <div className="sort-card" key={item.id}>
                <div className="sort-item-data">{item.name}</div>
              </div>
            ))}
          </ReactSortable>
        </div>
        // <ReactSortable
        //   animation="150"
        //   ghostclassName="sort-ghost-card"
        //   list={tripState.itinerary[activeTab].activities}
        //   setList={""}
        //   //group="shared"
        //   // easing="cubic-bezier(.2, 0, 0, 1)"
        // >
        //   {tripState.itinerary[activeTab].activities.map((item, index) => (
        //     <div className="sort-card" key={index}>
        //       <div className="sort-item-data">{item.activity}</div>
        //     </div>
        //   ))}
        // </ReactSortable>
      )
    } catch (error) {
      console.log(error)
    }
  }

  const onEditActivity = e => {
    console.log("on oEdit")
    setInActivityEditMode(true)
  }

  const onEditCommute = e => {
    console.log("on edit commute")
    setInCommuteEditMode(true)
  }

  const onAddCommute = e => {
    console.log("on add commute")
    setShowNewCommuteEntry(true)
  }

  const onRemoveCommute = e => {}
  const onRemove = e => {
    try {
      var results = parseDayAndItemFromString(e)
      if (results !== []) {
        dispatch({
          type: "deleteActivity",
          dayId: results[1],
          itemId: results[2],
          withCommute: true,
        })
        dispatch({ type: "syncCommute", data: true })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onMoveToBackup = e => {
    try {
      var results = parseDayAndItemFromString(e.currentTarget.id)
      if (results !== []) {
        dispatch({
          type: "moveActivityToBackup",
          dayId: results[1],
          itemId: results[2],
        })
        dispatch({ type: "syncCommute", data: true })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onRemoveBackup = (index, dayId) => {
    try {
      if (index != undefined && dayId != undefined) {
        dispatch({
          type: "deleteBackupActivity",
          dayId: dayId,
          itemIndex: index,
        })
        dispatch({ type: "syncCommute", data: true })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onMoveToActive = (index, dayId) => {
    try {
      console.log(index, dayId)
      if (index != undefined && dayId != undefined) {
        let backupActivity =
          state.currentJobInfo.designerWorkspace.itinerary[dayId]
            .backupActivities[index].activity

        dispatch({
          type: "addSearchedActivity",
          activeTab: dayId,
          selectedItem: backupActivity,
          addCommute: true,
        })
        dispatch({
          type: "deleteBackupActivity",
          dayId: dayId,
          itemIndex: index,
        })
        dispatch({ type: "syncSchedule", data: true })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const retrieveDate = date => {
    try {
      return typeof date === "string"
        ? `${months[new Date(date).getMonth()]} ${new Date(date).getDate()}`
        : `${months[date.toDate().getMonth()]} ${date.toDate().getDate()}`
    } catch (error) {
      console.log(error)
      return `${months[date.getMonth()]} ${date.getDate()}`
    }
  }

  const options = {
    hour: "numeric",
    minute: "numeric",
  }

  const getSafeStartTimeFormatted = () => {
    try {
      return typeof state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime === "string"
        ? moment
            .utc(
              // new Date(
              state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                .dayScheduleStartTime
              // )
            )
            .format("hh:mm")
        : state.currentJobInfo.designerWorkspace.itinerary[
            activeTab
          ].dayScheduleStartTime.toDate()
    } catch (err) {
      console.log(err)
      return state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime
    }
  }

  const getSafeStartTime = () => {
    try {
      return typeof state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime === "string"
        ? new Date(
            state.currentJobInfo.designerWorkspace.itinerary[
              activeTab
            ].dayScheduleStartTime
          )
        : state.currentJobInfo.designerWorkspace.itinerary[
            activeTab
          ].dayScheduleStartTime.toDate()
    } catch (err) {
      console.log(err)
      return state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime
    }
  }

  const getSafeDaysStartTime = () => {
    try {
      return typeof state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime === "string"
        ? moment
            .utc(
              new Date(
                state.currentJobInfo.designerWorkspace.itinerary[
                  activeTab
                ].dayScheduleStartTime
              )
            )
            .format("hh:mm")
        : Intl.DateTimeFormat("en-US", options).format(
            state.currentJobInfo.designerWorkspace.itinerary[
              activeTab
            ].dayScheduleStartTime.toDate()
          )
    } catch (err) {
      console.log(err)
      return Intl.DateTimeFormat("en-US", options).format(
        state.currentJobInfo.designerWorkspace.itinerary[activeTab]
          .dayScheduleStartTime
      )
    }
  }

  const showActivities = () => {
    try {
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab] !==
        undefined
      ) {
        var itemCount =
          state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
            .length
        if (itemCount === 0) {
          //If no activities on a selected day
          return (
            // <div className="no-activities-image" style={{}}>
            <div className="" style={{ minHeight: "150px", marginTop: "40px" }}>
              {/* <img style={{}} src={noevents} alt="noevents" /> */}
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  // textTransform: "uppercase",
                  letterSpacing: "0.5px",
                }}
                align="center"
              >
                {`- No activities for
                ${retrieveDate(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .date
                )} -`}
              </p>
            </div>
          )
        } else {
          //Show activities for a selected day

          return (
            <>
              <p
                className="align-center"
                style={{ fontSize: "24px", fontWeight: "500" }}
              >
                {/* {`Itinerary for
                 ${retrieveDate(
                   state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                     .date
                 )}`} */}
              </p>
              {daysStartTimeInEditMode ? (
                <div style={{ maxWidth: "100%", marginLeft: "0px" }}>
                  <p>Pick activities start time:</p>
                  <TimePicker
                    value={
                      startTime === "" || startTime === null
                        ? getSafeStartTimeFormatted()
                        : startTime
                    }
                    onChange={time => {
                      console.log({ time })

                      // var res = time.split(":")
                      setStartTime(time)
                      // console.log({ res })

                      // dispatch({ type: "setSunriseTime", data: time })
                    }}
                  />
                  <div style={{ margin: "15px 0" }}>
                    <button
                      className="button"
                      onClick={() => setDaysStartTimeInEditMode(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="button is-warning"
                      style={{ marginLeft: "10px", float: "right" }}
                      onClick={() => {
                        if (startTime !== "" && startTime !== null) {
                          console.log(startTime)
                          var res = startTime.split(":")
                          var newTime = getSafeStartTime()
                          console.log(newTime)
                          newTime.setUTCHours(Number(res[0]), Number(res[1]))
                          console.log(newTime)
                          dispatch({
                            type: "updateDaysActivityStatTime",
                            index: activeTab,
                            data: newTime.toUTCString(),
                          })
                          dispatch({ type: "syncSchedule", data: true })
                          setDaysStartTimeInEditMode(false)
                        }
                      }}
                    >
                      Save time
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "40px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "700",
                    textAlign: "left",
                    //marginLeft: "-40px",
                  }}
                >
                  <p>
                    {getSafeDaysStartTime()}{" "}
                    <a
                      className=""
                      onClick={() => setDaysStartTimeInEditMode(true)}
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        textTransform: "uppercase",
                        // marginLeft: "-40px",
                      }}
                    >
                      Edit
                    </a>
                  </p>
                </div>
              )}

              <SortableList
                items={
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .activities
                }
                backupItems={
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .backupActivities
                }
                onSortEnd={onSortEnd}
                shouldCancelStart={shouldCancelStart}
                onRemove={onRemove}
                onMoveToBackup={onMoveToBackup}
                activeTab={activeTab}
                onEditActivity={onEditActivity}
                onEditCommute={onEditCommute}
                onAddCommute={onAddCommute}
                onRemoveCommute={onRemoveCommute}
                disableDrag={disableDrag}
              />
            </>
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const showBackupActivities = () => {
    try {
      // if (
      //   state.currentJobInfo.designerWorkspace.itinerary[activeTab] !==
      //   undefined
      // ) {

      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab] ===
          undefined ||
        state.currentJobInfo.designerWorkspace.itinerary[activeTab]
          .backupActivities.length === 0
      ) {
        //If no activities on a selected day
        return (
          <div className="" style={{}}>
            {/* <img style={{}} src={noevents} alt="noevents" /> */}
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                // textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
              align="center"
            >
              {`- No backup activities for
                ${retrieveDate(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .date
                )} -`}
            </p>
          </div>
        )
      } else {
        //Show activities for a selected day

        return (
          <>
            <SortableList
              items={
                state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                  .backupActivities
              }
              isSetAsBackup={true}
              onSortEnd={onSortEnd}
              shouldCancelStart={shouldCancelStart}
              onRemove={onRemove}
              onMoveToBackup={onMoveToBackup}
              onMoveToActive={onMoveToActive}
              activeTab={activeTab}
              onEditActivity={onEditActivity}
              onEditCommute={onEditCommute}
              onAddCommute={onAddCommute}
              onRemoveCommute={onRemoveCommute}
              disableDrag={disableDrag}
              onRemoveBackup={onRemoveBackup}
            />
          </>
        )
      }
      // }
    } catch (error) {
      console.log(error)
    }
  }

  // Note: This is the important for the <a> tag navigation to work inside the sortable list.
  const shouldCancelStart = e => {
    // Cancel sorting if the event target is an anchor tag (`a`)
    if (e.target.tagName.toLowerCase() === "a") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "button") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "input") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "textarea") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "select") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "i") {
      return true // Return true to cancel sorting
    }
  }
  // update the newly created activity
  const onActivityCreated = () => {
    console.log("onActivityCreated  called")
    dispatch({ type: "syncSchedule", data: true })
  }
  // update the newly created commute
  const onCommuteCreated = () => {
    console.log("onCommuteCreated  called")
    dispatch({ type: "syncSchedule", data: true })
  }

  // swap on drag end.
  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log({ oldIndex })
    console.log({ newIndex })
    if (oldIndex === newIndex) {
      console.log("no change in index")
      return
    }
    dispatch({
      type: "moveActivities",
      oldIndex: oldIndex,
      newIndex: newIndex,
      activeTab: activeTab,
    })
    dispatch({ type: "syncCommute", data: true })
    //dispatch({ type: "syncSchedule", data: true })
  }

  // clears the focus for the search results selection item.
  const clearFocus = () => {
    document.getElementById(
      state.searchResults[selectedItemIndex].activityUid
    ).className = ""
  }

  const syncDesignerWorkSpaceDB = params => {
    if (dbContext) {
      dbContext
        .firestore()
        .collection("jobs")
        .doc(`${authContext.uid}`)
        .collection("active-jobs")
        .doc(`${state.currentJobInfo.jobUid}`)
        .update({
          designerWorkspace: {
            itinerary: state.currentJobInfo.designerWorkspace.itinerary,
          },
        })
        .then(() => {
          console.log("document update successful")
          //update local state now
          //
        })
        .catch(e => console.log(e))
    }
  }

  // To handle the UP, DOWN and ENTER key press results in the search bar.
  const keyChanged = e => {
    console.log({ selectedItemIndex })
    console.log(e.keyCode)
    try {
      switch (e.keyCode) {
        case 27: // ESC
          setShowHits(false)
          return
        // case 38: // UP
        //   if (selectedItemIndex > 0) {
        //     clearFocus()
        //     var a = selectedItemIndex - 1
        //     setSelectedItemIndex(a)
        //   }
        //   break
        // case 40: // DOWN
        //   if (selectedItemIndex < state.searchResults.length - 1) {
        //     var a = selectedItemIndex + 1
        //     if (selectedItemIndex != -1) clearFocus()
        //     setSelectedItemIndex(a)
        //   }
        //   break
        // case 13: //ENTER
        //   if (selectedItemIndex != -1) {
        //     clearFocus()
        //     // Add to days activities list.
        //     dispatch({
        //       type: "addSearchedActivity",
        //       activeTab: activeTab,
        //       selectedItem: selectedItemIndex,
        //     })
        //     dispatch({ type: "updateSearchResults", data: [] })
        //     setSelectedItemIndex(-1)
        //     setSearchText("")
        //     //update cloud
        //     dispatch({ type: "syncSchedule", data: true })
        //     //setDesignerWorkSpaceModified(true)
        //   }
        //   break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Handles displaying search results and its formatting.
  const showSearchResults = () => {
    try {
      if (state.searchResults !== undefined)
        return state.searchResults.map(item => {
          return (
            <li
              key={item.activityUid}
              id={item.activityUid}
              onClick={e => {
                console.log(e.currentTarget.id)
                if (e.currentTarget.id != undefined) {
                  var i = state.searchResults.findIndex(
                    element => element.activityUid === e.currentTarget.id
                  )
                  console.log({ i })
                  if (i != -1) {
                    //clearFocus()
                    // Add to days activities list.
                    dispatch({
                      type: "addSearchedActivity",
                      activeTab: activeTab,
                      selectedItemIndex: i,
                      addCommute: true,
                    })
                    dispatch({ type: "updateSearchResults", data: [] })
                    setSelectedItemIndex(-1)
                    setSearchText("")
                    dispatch({ type: "syncSchedule", data: true })
                  }

                  //syncDesignerWorkSpaceDB()
                }
              }}
            >
              <p>{item.activityName}</p>
            </li>
          )
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getStartDateTime = params => {
    try {
      return state.currentJobInfo.designerWorkspace.itinerary[
        activeTab
      ].dayScheduleStartTime.toDate()
    } catch (error) {
      return new Date(
        state.currentJobInfo.designerWorkspace.itinerary[
          activeTab
        ].dayScheduleStartTime
      )
    }
  }
  const recalculateSchedule = () => {
    //fetch the day's schedule start time

    console.log(activeTab)
    try {
      var tempPreviousActivityTime = 0 // this stores the previous activity end time. This is picked form a temp variable as the state is not updated yet
      var startDateTime = getStartDateTime()
      for (
        let i = 0;
        i <
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
          .length;
        i++
      ) {
        // Now fetch each activities duration
        var currentActivityDurationHrs =
          state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .activities[i].activity.activityDurationHours === undefined
            ? 0
            : Number(
                state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                  .activities[i].activity.activityDurationHours
              )

        var currentActivityDurationMin =
          state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .activities[i].activity.activityDurationMinutes === undefined
            ? 0
            : Number(
                state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                  .activities[i].activity.activityDurationMinutes
              )
        // console.log({ currentActivityDurationHrs })
        // console.log({ currentActivityDurationMin })
        var totalMinutes =
          currentActivityDurationMin + currentActivityDurationHrs * 60

        //get the start time of this activity. It is going to be the end time of the previous activity.
        var activityStartTime =
          i === 0 ? startDateTime : tempPreviousActivityTime

        var activityEndTime = new Date(
          activityStartTime.getTime() + totalMinutes * 60000
        )

        // console.log({ startDateTime })
        // console.log({ totalMinutes })
        // console.log({ activityStartTime })
        // console.log({ activityEndTime })

        // // Dispatch a state update here.
        // console.log({ activityEndTime })
        dispatch({
          type: "updateActivityScheduleTimes",
          activeTab: activeTab,
          index: i,
          activityStartTime: activityStartTime.toUTCString(),
          activityEndTime: activityEndTime.toUTCString(),
        })
        tempPreviousActivityTime = activityEndTime
      }
    } catch (error) {
      console.log(error)
    }
  }

  const refreshCommute = () => {
    for (
      let i = 0;
      i <
      state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
        .length -
        1;
      i++
    ) {
      // if ( // for a case if two "add commute" card end up next to each other.
      //   state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
      //     i
      //   ].activity.activityType === "commute" &&
      //   state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
      //     i + 1
      //   ].activity.activityType === "commute"
      // ) {
      //   dispatch({
      //     type: "deleteAddCommute",
      //     activeTab: activeTab,
      //     index: i + 1,
      //   })
      // }
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityType !== "commute" &&
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i + 1
        ].activity.activityType !== "commute"
      ) {
        console.log("do dispatch")
        dispatch({
          type: "insertNewCommute",
          activeTab: activeTab,
          index: i + 1,
        })
      }
    }
  }

  const refreshDaysStats = () => {
    var totalSightseeingTime = 0
    var totalCommuteTime = 0
    var totalDiningTime = 0

    for (
      let i = 0;
      i <
      state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
        .length;
      i++
    ) {
      console.log({ i })
      var currentActivityDurationHrs =
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityDurationHours === undefined
          ? 0
          : Number(
              state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                .activities[i].activity.activityDurationHours
            )

      var currentActivityDurationMin =
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityDurationMinutes === undefined
          ? 0
          : Number(
              state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                .activities[i].activity.activityDurationMinutes
            )
      var totalMinutes =
        currentActivityDurationMin + currentActivityDurationHrs * 60
      switch (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityType
      ) {
        case "sightseeing":
        case "Sightseeing":
        case "souvenir":
        case "Souvenir":
        case "shopping":
        case "Shopping":
        case "distillery":
        case "Distillery":
        case "brewery":
        case "Brewery":
        case "Photo Shoot":
        case "Culture Walk":
        case "Cooking Class":
        case "Local Experience":
        case "River Cruise":
        case "Photo Shoot":
        case "Outdoor Activity":
        case "Guided Tour":
        case "Foodie Tour":
          totalSightseeingTime = totalSightseeingTime + totalMinutes
          break
        case "commute":
        case "Commute":
          totalCommuteTime = totalCommuteTime + totalMinutes
          break
        case "restaurant":
        case "Restaurant":
        case "coffee":
        case "Coffee":
        case "bar":
        case "Bar":
        case "Bar Crawl":
          totalDiningTime = totalDiningTime + totalMinutes
          break
        default:
          break
      }
    }

    dispatch({
      type: "updateDayStats",
      totalSightseeingTime: totalSightseeingTime,
      totalCommuteTime: totalCommuteTime,
      totalDiningTime: totalDiningTime,
      activeTab: activeTab,
    })
  }

  const launchActivityForm = () => {
    return (
      <div className="columns">
        <div className="column is-3-desktop is-1-tablet"></div>
        <div className="column is-6-desktop is-10-tablet">
          <div className="new-activity-form-container">
            <NewActivityForm
              onCancel={() => {
                setShowNewActivityEntry(false)
                setInActivityEditMode(false)
                dispatch({ type: "clearActivityScratchPad" })
              }}
              addToItinerary={true}
              activeTab={activeTab}
              onActivityCreated={onActivityCreated}
              inEditMode={inActivityEditMode}
            />
          </div>
        </div>
        <div className="column is-3-desktop is-1-tablet"></div>
      </div>
    )
  }

  const launchCommuteForm = () => {
    return (
      <div className="new-activity-form-container">
        <NewCommuteForm
          onCancel={() => {
            setInCommuteEditMode(false)
            setShowNewCommuteEntry(false)
            dispatch({ type: "clearActivityScratchPad" })
          }}
          activeTab={activeTab}
          onCommuteCreated={onCommuteCreated}
          //inCommuteEditMode={inCommuteEditMode}
          //    onAddCommute={onAddCommute}
        />
      </div>
    )
  }

  const timeInStringFromMinutes = minutes => {
    try {
      var t = Number(minutes)

      var h = Math.floor(t / 60)
      var m = Math.round(t % 60)
      if (t === 0 && h === 0) return "--"
      if (t < 60) {
        return `${m} Minutes`
      } else {
        var hrs = h === "1" ? "Hr" : "Hrs"
        if (m === 0) return `${h} ${hrs}`
        else return `${h} ${hrs} & ${m} Minutes`
      }
    } catch (err) {
      console.log(err)
      return "--"
    }
  }
  const formatDuration = (dHours, dMinutes) => {
    try {
      var hours = dHours
      var minutes = dMinutes

      var hrs = hours === "1" ? "Hr" : "Hrs"

      if (
        hours === undefined ||
        hours === "" ||
        hours === " " ||
        hours === "0"
      ) {
        return ` ${minutes} Min`
      } else {
        if (
          minutes === undefined ||
          minutes === "" ||
          minutes === " " ||
          minutes === "0"
        )
          return ` ${hours} ${hrs}`
        else {
          return ` ${hours} ${hrs} & ${minutes} Min`
        }
      }
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  function returnFormattedTime(startTime, endTime) {
    try {
      return (
        <>
          {" "}
          <div className="from-timeline">
            {startTime === undefined
              ? 0
              : `${addZeroBefore(getHours(startTime))}:${addZeroBefore(
                  getMinutes(startTime)
                )}`}
          </div>
          <div className="to-timeline">
            {" - "}
            {endTime === undefined
              ? 0
              : `${addZeroBefore(getHours(endTime))}:${addZeroBefore(
                  getMinutes(endTime)
                )}`}
          </div>
        </>
      )
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  const mapRef = useRef()
  const MyMarker = ({ id, activityId }) => (
    <div className="marker" id={`${id}`}>
      <ReactTooltip
        id="map-marker"
        type="light"
        backgroundColor="transparent"
        getContent={dataTip => {
          // console.log({ dataTip })
          if (!dataTip) return
          else {
            const [
              activityName,
              activityType,
              activityDurationHours,
              activityDurationMinutes,
              activityStartTime,
              activityEndTime,
            ] = dataTip.split("|")
            return (
              <div
                className="activity-card-type2"
                style={{
                  margin: "10px",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                {/* <div className="vertical-line"></div> */}
                <div className="activity-card-data">
                  {returnFormattedTime(activityStartTime, activityEndTime)}

                  {/* <div className="activity-card-warnings">
              <a className="is-small">
                <i className="fas fa-exclamation-triangle warning-icon-color"></i>
              </a>
            </div> */}
                  <div className="activity-card-name">
                    {dottedText(activityName ?? "", 27)}
                  </div>
                  <div
                    className="activity-card-type"
                    style={{
                      marginTop: "0px",
                      fontSize: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {titleCase(activityType)}
                  </div>
                  {formatDuration(
                    activityDurationHours ?? 0,
                    activityDurationMinutes
                  ) !== "" ? (
                    <div
                      className="activity-card-type"
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <i className="far fa-clock"></i>
                      {formatDuration(
                        activityDurationHours ?? 0,
                        activityDurationMinutes
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )
          }
        }}
        // <p>{`This little buddy is ${`}</p>}
      />
      <i
        className="fas fa-map-marker-alt fa-3x"
        style={{ color: "#4a4a4a", cursor: "pointer" }}
        onMouseOver={params => {}}
        id={`map-marker-${activityId}`}
        data-for="map-marker"
        data-tip={getActivity(activeTab, id)}
        // data-tip={"test"}
      ></i>
    </div>
  )
  const getActivity = (dayIndex, activityIndex) => {
    try {
      if (state) {
        // console.log({ dayIndex })
        if (
          state.currentJobInfo.designerWorkspace.itinerary[dayIndex] !==
          undefined
        ) {
          let dayPlan =
            state.currentJobInfo.designerWorkspace.itinerary[dayIndex]
          if (dayPlan) {
            let activities = dayPlan.activities
            // console.log({ activities })
            // return "yay!"
            let a = activities[activityIndex].activity
            let stringyResult = `${a.activityName}|${
              a.activityType
            }|${a.activityDurationHours ?? 0}|${a.activityDurationMinutes ??
              0}|${a.activityStartTime}|${a.activityEndTime}`
            // console.log({ stringyResult })
            return stringyResult
          }
        }
      } else {
        return ""
      }
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const geoCodeActivityAddress = useCallback(async dayIndex => {
    try {
      Geocode.setApiKey(`${process.env.GOOGLE_MAPS_API_KEY}`)
      // set response language. Defaults to english.
      Geocode.setLanguage("en")
      // set response region. Its optional.
      // A Geocoding request with region=es (Spain) will return the Spanish city.
      Geocode.setRegion("es")
      // set location_type filter . Its optional.
      // google geocoder returns more that one address for given lat/lng.
      // In some case we need one address as response for which google itself provides a location_type filter.
      // So we can easily parse the result for fetching address components
      // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
      // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
      Geocode.setLocationType("APPROXIMATE")
      // Enable or disable logs. Its optional.
      Geocode.enableDebug()
      var bounds = ""
      if (state) {
        console.log({ dayIndex })
        if (
          state.currentJobInfo.designerWorkspace.itinerary[dayIndex] !==
          undefined
        ) {
          let dayPlan =
            state.currentJobInfo.designerWorkspace.itinerary[dayIndex]
          if (dayPlan) {
            let activities = dayPlan.activities
            // console.log(activities)
            let coordinates = []
            const promises = activities.map(async (item, index) => {
              if (item) {
                if (item.activity.latlng) {
                  coordinates.push(item.activity.latlng)
                } else {
                  if (item.activity.activityAddress) {
                    // console.log(item.activity.activityAddress)
                    const response = await Geocode.fromAddress(
                      item.activity.activityAddress
                    )

                    if (response) {
                      const { lat, lng } = response.results[0].geometry.location
                      // console.log(item.activity.activityAddress, lat, lng)
                      let latlng = { lat, lng, index, aId: item.id }
                      coordinates.push(latlng)
                      return
                    }
                  }
                }
              }
            })
            const result = await Promise.all(promises)
            // console.log(result)
            setMarkerCoordinates(coordinates)

            /* eslint-disable no-undef */
            // for (let ii = 0; ii < coordinates.length; ii++) {
            //   const destination = coordinates[ii]
            //   const origin = coordinates[ii + 1]
            //   const DirectionsService = new google.maps.DirectionsService()
            //   const directionsRenderer = new google.maps.DirectionsRenderer()
            //   var request = {
            //     origin: origin,
            //     destination: destination,
            //     /* eslint-disable no-undef */
            //     travelMode: google.maps.TravelMode.DRIVING,
            //   }
            //   DirectionsService.route(request, function(response, status) {
            //     /* eslint-disable no-undef */
            //     if (status == google.maps.DirectionsStatus.OK) {
            //       directionsRenderer.setDirections(response)
            //       /* eslint-disable no-undef */
            //       // var map = new google.maps.Map(
            //       //   document.getElementById("map")
            //       // )
            //       directionsRenderer.setMap(mapRef.current)
            //       var myRoute = response.routes[0].legs[0]
            //       var lineSymbol = {
            //         path: "M 0,-1 0,1",
            //         strokeOpacity: 1,
            //         scale: 4,
            //       }
            //       /* eslint-disable no-undef */
            //       // var line = new google.maps.Polyline({
            //       //   path: [
            //       //     myRoute.steps[myRoute.steps.length - 1].end_point,
            //       //     to.geometry.location,
            //       //   ],
            //       //   strokeOpacity: 0,
            //       //   strokeColor: "#7d7d7d",
            //       //   icons: [
            //       //     {
            //       //       icon: lineSymbol,
            //       //       offset: "0",
            //       //       repeat: "20px",
            //       //     },
            //       //   ],
            //       //   map: map,
            //       // })
            //     }
            //   })
            // }
          }
        } else {
          setMarkerCoordinates([])
        }
      }
    } catch (error) {
      console.log(error)
    }
  })

  const createMapOptions = maps => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      // styles: [
      //   {
      //     stylers: [
      //       { saturation: -100 },
      //       { gamma: 0.8 },
      //       { lightness: 8 },
      //       { visibility: "on" },
      //     ],
      //   },
      // ],
      styles: [
        {
          featureType: "all",
          elementType: "geometry.fill",
          stylers: [
            {
              weight: "2.00",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#9c9c9c",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              color: "#f2f2f2",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7b7b7b",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              color: "#46bcec",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c8d7d4",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#070707",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
      ],
      // [
      //   { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      //   { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      //   { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      //   {
      //     featureType: "administrative.locality",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "poi",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "poi.park",
      //     elementType: "geometry",
      //     stylers: [{ color: "#263c3f" }],
      //   },
      //   {
      //     featureType: "poi.park",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#6b9a76" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "geometry",
      //     stylers: [{ color: "#38414e" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "geometry.stroke",
      //     stylers: [{ color: "#212a37" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#9ca5b3" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "geometry",
      //     stylers: [{ color: "#746855" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "geometry.stroke",
      //     stylers: [{ color: "#1f2835" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#f3d19c" }],
      //   },
      //   {
      //     featureType: "transit",
      //     elementType: "geometry",
      //     stylers: [{ color: "#2f3948" }],
      //   },
      //   {
      //     featureType: "transit.station",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "geometry",
      //     stylers: [{ color: "#17263c" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#515c6d" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "labels.text.stroke",
      //     stylers: [{ color: "#17263c" }],
      //   },
      // ],
    }
  }

  var bounds = ""
  var m = ""
  const displayMap = () => {
    return (
      <div
        style={{
          height: "600px",
          width: "100%",
          border: "1px solid #fec600",
          // borderRadius: "6px",
          marginTop: "10px",
          position: "sticky",
          // top: "100px",
        }}
        className="map-container"
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${process.env.GOOGLE_MAPS_API_KEY}`,
            language: "en",
            region: "US",
          }}
          // defaultCenter={{ lat: centerLat, lng: centerLng }}
          defaultCenter={{ lat: 36.1673, lng: -115.149 }}
          defaultZoom={12}
          // options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map
            m = maps
            console.log({ mapRef })
            console.log({ m })
            mapRef.current.setCenter(
              state.currentJobInfo.tripPrimaryDestination.latlng
            )
            /* eslint-disable no-undef */

            // console.log({ DirectionsService })
          }}
        >
          {markerCoordinates
            ? markerCoordinates.map((item, index) => {
                if (index === 0) {
                  if (window.google)
                    bounds = new window.google.maps.LatLngBounds()
                  if (mapRef.current) {
                    mapRef.current.setCenter({
                      lat: item ? item.lat : 0,
                      lng: item ? item.lng : 0,
                    })
                    mapRef.current.setZoom(12)
                  }
                }
                if (bounds !== "")
                  bounds.extend({
                    lat: item ? item.lat : 0,
                    lng: item ? item.lng : 0,
                  })
                if (index > 0 && mapRef.current)
                  mapRef.current.fitBounds(bounds)

                return (
                  <MyMarker
                    id={item.index}
                    key={index}
                    lat={item ? item.lat : 0}
                    lng={item ? item.lng : 0}
                    activityId={item.aId}
                  />
                )
              })
            : ""}
        </GoogleMapReact>
        {/* <Map
          google={props.google}
          zoom={8}
          //style={mapStyles}
          initialCenter={{ lat: centerLat, lng: centerLng }}
        /> */}
      </div>
    )
  }
  const displayBackupActivities = () => {
    try {
      return (
        <>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500",
              borderBottom: "1px solid #fec600",
              marginBottom: "20px",
              color: "rgba(74,74,74,0.8)",
            }}
          >
            Backup Activities
          </p>
          <p style={{ marginBottom: "15px", fontSize: "14px" }}>
            Note: Things don't always go as planned, so please add a few backup
            activities and places to eat for this day.
          </p>
          {showBackupActivities()}
        </>
      )
    } catch (err) {
      console.log(err)
      return ""
    }
  }

  const displayDaysStats = () => {
    try {
      return (
        <>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "700",
              borderBottom: "1px solid #fec600",
              marginBottom: "20px",
              color: "rgba(74,74,74,0.8)",
            }}
          >
            Days Statistics
          </p>
          <div className="stats-container" style={{ textAlign: "center" }}>
            {/* <div className="stats-header">Statistics</div> */}

            <div className="stats-header-2">
              Sightseeing/Experiences
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                {timeInStringFromMinutes(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .daysStats.totalSightseeingTime
                )}
              </p>
            </div>
            <div className="stats-header-2">
              Commute
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                {timeInStringFromMinutes(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .daysStats.totalCommuteTime
                )}
              </p>
            </div>

            <div className="stats-header-2">
              Wine & Dine
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                {timeInStringFromMinutes(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .daysStats.totalDiningTime
                )}
              </p>
            </div>
            <div className="stats-header-2">
              Total
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                {timeInStringFromMinutes(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .daysStats.totalActivitiesTime
                )}
              </p>
            </div>
          </div>
        </>
      )
    } catch (err) {
      console.log(err)
      return ""
    }
  }

  const showEnableEnableDisableDrag = () => {
    try {
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
          .length != 0
      ) {
        return (
          <div
            style={{
              maxWidth: "94px",
              marginLeft: "auto",
            }}
          >
            <button
              className="button is-warning is-small"
              onClick={() => {
                setDisableDrag(!disableDrag)
              }}
              style={{
                width: "100%",
              }}
            >
              {disableDrag ? "Enable Drag" : "Disable Drag"}
            </button>
          </div>
        )
      } else return ""
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const PoweredBy = ({ url }) => (
    <a href={url} style={{ color: "#4a4a4a", fontSize: "14px" }}>
      <img src={algoliaImg} alt="algolia" style={{ maxHeight: "18px" }} />
    </a>
  )
  const CustomPoweredBy = connectPoweredBy(PoweredBy)
  const Autocomplete = React.memo(({ currentRefinement, refine, hits }) => {
    console.log({ hits, refine, currentRefinement })
    //dispatch({ type: "updateSearchResults", data: hits })
    return (
      <div style={{ position: "relative" }}>
        <input
          // autoFocus
          id="search-bar"
          type="search"
          className="input"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
          onFocus={() => {
            try {
              setShowHits(true)
              setTimeout(() => {
                let element = document.getElementById("search-bar")
                if (element) element.focus()
              }, 500)
            } catch (err) {
              console.log(err)
            }
          }}
          //id="search-field"
          onBlur={() => {
            setTimeout(() => {
              console.log("blur")
              setShowHits(false)
            }, 500)
          }}
          onKeyDown={keyChanged}
        />
        <span
          style={{
            position: "absolute",
            right: "5px",
            top: "-20px",
            color: "#4a4a4a",
            zIndex: "10",
          }}
        >
          <CustomPoweredBy />
        </span>
        {showHits && (
          <ul style={{ zIndex: "99" }} className="results-container">
            {hits.map(hit => (
              // <li key={hit.objectID}>{hit.firstName}</li>
              <article
                key={hit.objectID}
                className="card result-cards"
                style={{ padding: "5px", cursor: "pointer" }}
                onClick={params => {
                  console.log({ hit })
                  dispatch({
                    type: "addSearchedActivity",
                    activeTab: activeTab,
                    selectedItem: hit,
                    addCommute: true,
                  })
                  setShowHits(false)
                  dispatch({ type: "syncSchedule", data: true })

                  // geoCodeActivityAddress()
                }}
              >
                <div className="media-content">
                  <div className="content">
                    <p>
                      <strong>{hit.activityName}</strong>{" "}
                      <small style={{ fontWeight: "300" }}>
                        {hit.activityType}
                      </small>{" "}
                      <br></br> <small>{hit.activityAddress}</small>
                      {/* <br></br>
                      {dottedText(hit.activityDescription, 100)} */}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </ul>
        )}

        {/* <ul>
        {hits != undefined
          ? hits.map(index => (
              <li key={index.index}>
                <h2>{index.index}</h2>
                <ul>
                  {index.hits.map(hit => (
                    <li key={hit.objectID}>{hit.name}</li>
                  ))}
                </ul>
              </li>
            ))
          : ""}
      </ul> */}
      </div>
    )
  })
  const getTripDestinations = () => {
    try {
      if (state.tripDetails.tripDestinations) {
        let d = state.tripDetails.tripDestinations.map((item, index) => {
          return { value: `${item.place}`, label: `${item.place}` }
        })
        return d
      }
    } catch (err) {
      return []
    }
  }
  const getTripDestination = () => {
    try {
      return state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .daysDestination
    } catch (err) {
      return []
    }
  }

  const formatDestinations = () => {
    try {
      var destinationString = ""
      state.currentJobInfo.designerWorkspace.itinerary[
        activeTab
      ].daysDestination.map((item, index) => {
        destinationString =
          destinationString +
          item.label +
          (state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .daysDestination.length ===
          index + 1
            ? ""
            : ", ")
      })
      console.log(destinationString)
      return destinationString
    } catch (err) {
      console.log(err)
      return ""
    }
  }

  const removeIsNew = data => {
    try {
      if (data) {
        let res = []
        data.map((val, index) => {
          res.push({ label: titleCase(val.label), value: val.value })
        })
        return res
      }
      return []
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const showDayDestination = () => {
    try {
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab] !==
        undefined
      ) {
        return state.currentJobInfo.designerWorkspace.itinerary[activeTab]
          .daysDestination === "" ||
          state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .daysDestination === undefined ||
          editDayDestination ? (
          <div style={{ margin: "15px 0px" }}>
            <p
              style={{
                marginBottom: "5px",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Which destination is this day being planned for?
            </p>
            <div className="field">
              <div className="control is-expanded" style={{ display: "flex" }}>
                <div style={{ flexGrow: "2", marginRight: "10px" }}>
                  <CreatableSelect
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    isMulti
                    className="basic-multi-select"
                    // isMulti
                    defaultValue={getTripDestination()}
                    onFocus={() => {
                      //  clearError("accommodation")
                      // setTripTypeError(false)
                    }}
                    // defaultValue={state.tripLogistics.tripType}
                    // defaultValue={getTripDestinations()}
                    // className="basic-select"
                    classNamePrefix="select"
                    onChange={(value, actionMeta) => {
                      setEditDayDestination(true)
                      console.log({ value })
                      if (value) {
                        let a = removeIsNew(value)
                        console.log({ a })
                        dispatch({
                          type: "updateDayDestination",
                          data: a,
                          activeTab: activeTab,
                        })
                        // dispatch({ type: "syncDB", data: true })
                      }
                    }}
                    isSearchable="true"
                    isClearable="false"
                    options={getTripDestinations()}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#fef0bf",
                        primary50: "#fee27f",
                        primary: "#fed43f",
                        //neutral0: "#424242",
                      },
                    })}
                  />
                  <p className="help">
                    pick all destination that apply to this day's itinerary
                  </p>
                </div>
                <button
                  className="button is-warning"
                  onClick={() => {
                    dispatch({ type: "syncDB", data: true })
                    setEditDayDestination(false)
                  }}
                >
                  Done
                </button>
                {/* {tripTypeError ? (
                    <p className="help is-danger">Please pick a trip type</p>
                  ) : (
                    ""
                  )} */}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "15px 0px",
            }}
          >
            <p
              style={{
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              <span
                style={{
                  marginRight: "7px",
                  fontWeight: "400",
                  fontSize: "14px",
                  // textTransform: "uppercase",
                }}
              >
                This day's itinerary covers:
              </span>
              {// state.currentJobInfo.designerWorkspace.itinerary[activeTab]
              //   .daysDestination
              formatDestinations()}
            </p>
            <a
              style={{
                textTransform: "uppercase",
                fontSize: "12px",
                lineHeight: "22px",
              }}
              className=""
              onClick={() => {
                setEditDayDestination(true)
                // dispatch({
                //   type: "updateDayDestination",
                //   data: "",
                //   activeTab: activeTab,
                // })
              }}
            >
              Edit
            </a>
          </div>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const CustomAutocomplete = connectAutoComplete(Autocomplete)

  const showRightComponent = () => {
    if (showNewActivityEntry === true) return launchActivityForm()
    else {
      if (showNewCommuteEntry === true) {
        return launchCommuteForm()
      } else
        return (
          <>
            <ReactTooltip />
            <ItineraryDateTabs onTabChange={onTabChange} />
            {/* <div>{showDaysPlan()}</div> */}
            {/* <hr className="hr-branded"></hr> */}
            {/* <button className="button is-info" onClick={recalculateSchedule}>
        recalculate
      </button> */}

            <hr
              className="hr-branded"
              style={{ backgroundColor: "white" }}
            ></hr>
            <div className="columns search-bar is-vcentered is-desktop">
              <div
                className="column is-2-desktop"
                style={{ backgroundColor: "", textAlign: "center" }}
              >
                <label className="label">Saved Activities:</label>
              </div>
              <div
                className="column is-half-desktop"
                style={{ backgroundColor: "" }}
              >
                <InstantSearch
                  refresh={true}
                  indexName={`activities-${authUser ? authUser.uid : ""}`}
                  searchClient={searchClient}
                >
                  <CustomAutocomplete />
                </InstantSearch>
                {/* <div className="field">
                  <div className="control has-icons-right">
                    <DebounceInput
                      minLength={2}
                      debounceTimeout={300}
                      id="search-field"
                      className="input"
                      value={searchText}
                      type="text"
                      placeholder="Search for your previously created activities here..."
                      style={{}}
                      onKeyDown={keyChanged}
                      onBlur={e => {
                        setTimeout(() => {
                          dispatch({ type: "updateSearchResults", data: [] })
                          setSelectedItemIndex(-1)
                          setSearchText("")
                        }, 1000)
                        // console.log("lost focus")
                        // // Initialize the selection index and the search results.
                      }}
                      onChange={e =>
                        setSearchText(e.target.value.toLowerCase())
                      }
                    />
                    <span className="icon is-small is-right">
                      <i className="fas fa-search"></i>
                    </span>
                    <div className="search-bar-results" style={{}}>
                      <ul>{showSearchResults()}</ul>
                    </div>
                  </div>
                </div> */}
              </div>
              <div
                className="column is-1-desktop"
                style={{ backgroundColor: "", textAlign: "center" }}
              >
                {/* <span style={{ marginLeft: "20px" }}>OR</span> */}
                <p alight="center">OR</p>
              </div>
              <div
                className="column is-2-desktop"
                style={{ backgroundColor: "", textAlign: "center" }}
              >
                <button
                  className="button is-warning"
                  style={{}}
                  onClick={() => {
                    setShowNewActivityEntry(true)
                  }}
                >
                  Create new activity
                </button>
              </div>
            </div>

            <hr
              className="hr-branded"
              style={{ backgroundColor: "white" }}
            ></hr>
            {/* <p style={{ fontSize: "26px", fontWeight: "700" }} align="center">
              {`Itinerary for
                ${retrieveDate(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .date
                )}`}
            </p> */}
            <div className="columns">
              {/* <div className="column is-0-desktop"></div> */}
              <div className="column is-5-desktop is-7-tablet">
                <div className="activity-list-container">
                  <>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        borderBottom: "1px solid #fec600",
                        marginBottom: "20px",
                        color: "rgba(74,74,74,0.8)",
                      }}
                    >
                      Days Itinerary
                    </p>
                    {showEnableEnableDisableDrag()}
                    {showDayDestination()}
                    {showActivities()}
                    <hr
                      className="hr-branded"
                      style={{ backgroundColor: "white" }}
                    ></hr>
                    {displayBackupActivities()}
                    <hr
                      className="hr-branded"
                      style={{ backgroundColor: "white" }}
                    ></hr>
                    {displayDaysStats()}
                  </>
                </div>
              </div>
              {/* <div className="column is-0-desktop is-0-tablet"></div> */}
              <div className="column is-7-desktop is-5-tablet">
                {/* {displayDaysStats()} */}
                <>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      borderBottom: "1px solid #fec600",
                      marginBottom: "20px",
                      color: "rgba(74,74,74,0.8)",
                    }}
                  >
                    Map View
                  </p>
                  {displayMap()}
                </>
              </div>
              {/* <div className="column is-0-desktop"></div> */}
            </div>

            {/* {showActivities()} */}
            <hr className="hr-branded"></hr>

            {/* {showActivitiesType2()} */}
            {/* <NewActivityForm /> */}
            {/* <ActivityCardTest />
      <ActivityCardTestInfo /> */}
          </>
        )
    }
  }

  return <>{showRightComponent()}</>
}

export default ItineraryCreator

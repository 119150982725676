import React from "react"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
// import emptyCommute from "../../images/emptyCommute.png"
export const NotificationModal = ({ title, text, onClose, showImage }) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className="box"
          style={{
            width: "400px",
            //height: "200px",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
          }}
        >
          <p style={{ fontSize: "18px", padding: "10px", fontWeight: "500" }}>
            {title}
          </p>

          <p style={{ fontSize: "16px", padding: "10px" }}>{text}</p>
          {showImage && (
            <img
              src={showImage}
              style={{ maxWidth: "50%", padding: "10px", fontWeight: "500" }}
              alt="Yikes!"
            />
          )}
          <br></br>
          <button
            className="button is-warning is-inverted"
            style={{ width: "160px", padding: "10px", margin: "10px" }}
            onClick={onClose}
          >
            OK
          </button>
        </div>
      )
    },
    afterClose: () => {
      onClose()
    },
  })
}

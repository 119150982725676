import React, { useEffect, useState, useContext } from "react"
import SearchDesigners from "../../Container/SearchDesigners/SearchDesigners"
import { motion } from "framer-motion"
import {
  useTripStateStore,
  TripStateProvider,
} from "../../State/trip-state/trip-state-store"
import Confirm from "./Confirm"
import { renderProfileImage, daysBetweenDates } from "../../utility/general"
import StarRating from "../StarRating/StarRating"
import { v4 as uuidv4 } from "uuid"
import { FirebaseContext } from "../firebase"
import AuthUserContext from "../session/context"
import { navigate } from "gatsby"
import * as ROUTES from "./../../constants/routes"
import { useDashboardStateStore } from "../../State/dashboard-state/dashboard-state"
import { useGlobalStateStore } from "../../State/global-state/global-state"
import { NotificationModal } from "../NotificationModal/NotificationModal"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { analytics } from "../firebase/firebase"
import PaymentFormNew from "../PaymentForm/PaymentFormNew"
import OfferModal from "../OfferModal/OfferModal"
const PickADesignerBase = ({ onNext, onPrevious, reselectDesigner }) => {
  const [state, dispatch] = useTripStateStore()
  const [dashboardState, dispatchDashboard] = useDashboardStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [designerSelected, setDesignerSelected] = useState(false)
  const [selectedDesigner, setSelectedDesigner] = useState(undefined)
  const [confirmDesignerSelection, setConfirmDesignerSelection] = useState(
    false
  )
  const [showOffer, setShowOffer] = useState(false)
  const [amountDue, setAmountDue] = useState(0)
  const baseAmountDue = 25.0
  useEffect(() => {
    window.scrollTo(0, 0)
    console.log({ state })
  }, [state])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowOffer(true)
  //   }, 3000)
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [])

  useEffect(() => {
    // var amount = tripDays * 20
    //Test Amount
    //setAmountDue(99)

    if (state.tripLogistics) {
      analyzeTravelerPreferences()
      if (state.tripLogistics.travelDuration) {
        setAmountDue(
          baseAmountDue.toFixed(2) * state.tripLogistics.travelDuration
        )
      }
    }
  }, [state])

  useEffect(() => {
    console.log({ dashboardState })
  }, [dashboardState])

  useEffect(() => {
    // if (setSelectedDesigner !== undefined) setDesignerSelected(true)
  }, [selectedDesigner])

  const onSelectDesigner = designerProfile => {
    console.log({ designerProfile })
    dispatch({ type: "setSelectedDesigner", data: designerProfile })
    setSelectedDesigner(designerProfile)
    setDesignerSelected(true)
    // setTimeout(() => {
    //   setShowOffer(true)
    // }, 3000)
  }

  const createJob = () => {
    try {
      if (dbContext) {
        var jobId = uuidv4()
        var roomId = uuidv4()
        var travelerUid = state.travelerDetails[0].uid
        var travelerName = state.travelerDetails[0].firstName
        var designerUid = selectedDesigner.uid
        var newJobObject = {
          createdOn: new Date(),
          tripUid: state.tripUid,
          tripReservations: [],
          tripPrimaryDestination: state.tripPrimaryDestination,
          tripChecklistStatus: "not-started",
          tripChecklistInProgress: "false",
          tripChecklist: [],
          travelerUid: travelerUid,
          travelDates: state.tripLogistics.travelDates,
          status: "pending",
          roomId: roomId,
          reservationsStatus: "not-started",
          reservationsInProgress: "false",
          messagedInProgress: false,
          tripChecklist: [],
          jobUid: jobId,
          itineraryStatus: "not-started",
          reviewStatus: "not-started",
          recommendationsStatus: "not-started",
          designerWorkspace: [],
        }
        var newChatRoomObject = {
          createdOn: new Date(),
          owners: [travelerUid, designerUid],
          recipientInfo: {
            name: travelerName,
            uid: travelerUid,
            roomId: roomId,
            profileUrl: authContext.photoURL,
          },
          senderInfo: {
            name: selectedDesigner.firstName,
            uid: designerUid,
            roomId: roomId,
            profileUrl: selectedDesigner.userProfileImage,
          },
        }
        console.log({ designerUid })
        console.log({ jobId })
        var b = { roomId: roomId }
        var a = { designerDetails: { ...b, ...state.designerDetails } }
        // var b = {...a,...state.designerDetails}

        const activeTripsRef = dbContext
          .firestore()
          .collection("trips")
          .doc(`${state.uid}`)
          .collection("active-trips")
          .doc(`${state.tripUid}`)

        const tripsRef = dbContext
          .firestore()
          .collection("trips")
          .doc(`${state.uid}`)

        const jobsRef = dbContext
          .firestore()
          .collection("jobs")
          .doc(`${designerUid}`)
          .collection("active-jobs")
          .doc(`${jobId}`)

        const chatRoomsRef = dbContext
          .firestore()
          .collection("chatRooms")
          .doc(`${roomId}`)

        const newTripPoolRef = dbContext
          .firestore()
          .collection("newTripsPool")
          .doc(`${state.tripUid}`)

        const batch = dbContext.firestore().batch()

        batch.set(activeTripsRef, { ...state, ...a })

        var itemIndex = dashboardState.travelManifest.findIndex(
          item => item.tripUid === state.tripUid
        )
        ///TODO: There is a bug in the way travel manifest is being written here. Needs to be fixed.
        if (itemIndex === -1) {
          //Check if the travel manifest entry is already there. This will happen when the trip has been declined byt the designer and the traveler has to select a new designer.
          batch.set(tripsRef, {
            travelManifest: dbContext.firestore.FieldValue.arrayUnion(
              {
                createdAt: new Date(),
                travelDates: state.tripLogistics.travelDates,
                tripName: state.tripName,
                tripPrimaryDestination: state.tripPrimaryDestination,
                tripUid: state.tripUid,
                cardPictureURL: "",
                status: "waiting-for-acceptance",
              },
              { merge: true }
            ),
          })
        } else {
          var oldManifest = [...dashboardState.travelManifest]
          var travelManifest = []
          console.log(state.tripName)
          oldManifest.map((item, index) => {
            if (item.tripUid == state.tripUid) {
              var updatedObj = {
                tripUid: item.tripUid,
                tripPrimaryDestination: state.tripPrimaryDestination,
                tripName: state.tripName,
                createdAt: item.createdAt,
                cardPictureURL: item.cardPictureURL,
                travelDates: state.tripLogistics.travelDates,
                status: item.status,
                status: "waiting-for-acceptance",
              }
              travelManifest.push(updatedObj)
            } else {
              travelManifest.push(item)
            }
          })
          //   var newObj = { travelManifest: { ...travelManifest } }
          batch.set(
            tripsRef,
            {
              travelManifest: [...travelManifest],
            },
            { merge: true }
          )
        }

        batch.set(jobsRef, {
          createdOn: new Date(),
          tripUid: state.tripUid,
          tripReservations: [],
          tripPrimaryDestination: state.tripPrimaryDestination,
          tripChecklistStatus: "not-started",
          tripChecklistInProgress: "false",
          tripChecklist: [],
          travelerUid: travelerUid,
          travelDates: state.tripLogistics.travelDates,
          status: "pending",
          roomId: roomId,
          reservationsStatus: "not-started",
          reservationsInProgress: "false",
          messagedInProgress: false,
          tripChecklist: [],
          jobUid: jobId,
          itineraryStatus: "not-started",
          reviewStatus: "not-started",
          recommendationsStatus: "not-started",
        })

        batch.set(chatRoomsRef, {
          createdOn: new Date(),
          owners: [travelerUid, designerUid],
          roomId: roomId,
          recipientInfo: {
            name: travelerName,
            uid: travelerUid,
            //To fix an issue where the profile image is null when user is using a non-gmail email
            // profileUrl: authContext.photoURL,
            profileUrl: globalState.currentUserData.userProfileImage,
          },
          senderInfo: {
            name: selectedDesigner.firstName,
            uid: designerUid,
            profileUrl: selectedDesigner.userProfileImage,
          },
        })

        batch.delete(newTripPoolRef)

        batch
          .commit()
          .then(() => {
            dispatch({ type: "updateRoomId", data: roomId })

            if (dispatchDashboard)
              dispatchDashboard({
                type: "setDesignerStateToPending",
                data: selectedDesigner,
              })
            console.log("New chat room created successfully!")
            console.log("Show success message now!")
            //Notify user
            NotificationModal({
              title: "",
              text:
                "Great! Your design task has been created and the travel designer has been notified. Hang tight! You will receive a notification with the next steps once the designer has accepted the design job.",
              onClose: close,
            })
          })
          .catch(error => {
            //console.log(error)
            console.log("Error creating new job! :", error)
            toast.error(
              "Error creating your travel design task, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            )
          })

        // dbContext
        //   .firestore()
        //   .collection("trips")
        //   .doc(`${state.uid}`)
        //   .collection("active-trips")
        //   .doc(`${state.tripUid}`)
        //   .set({ ...state, ...a })
        //   .then(function() {
        //     console.log("Trip details successfully updated!")
        //     var itemIndex = dashboardState.travelManifest.findIndex(
        //       item => item.tripUid === state.tripUid
        //     )
        //     if (itemIndex === -1) {
        //       //Check if the travel manifest entry is already there. This will happen when the trip has been declined byt the designer and the traveler has to select a new designer.
        //       dbContext
        //         .firestore()
        //         .collection("trips")
        //         .doc(`${state.uid}`)
        //         .update({
        //           travelManifest: dbContext.firestore.FieldValue.arrayUnion({
        //             createdAt: new Date(),
        //             travelDates: state.tripLogistics.travelDates,
        //             tripName: state.tripName,
        //             tripPrimaryDestination: state.tripPrimaryDestination,
        //             tripUid: state.tripUid,
        //             cardPictureURL: "",
        //             status: "design-in-progress",
        //           }),
        //         })
        //     } else {
        //       var oldManifest = [...dashboardState.travelManifest]
        //       var travelManifest = []
        //       console.log(state.tripName)
        //       oldManifest.map((item, index) => {
        //         if (item.tripUid == state.tripUid) {
        //           var updatedObj = {
        //             tripUid: item.tripUid,
        //             tripPrimaryDestination: state.tripPrimaryDestination,
        //             tripName: state.tripName,
        //             createdAt: item.createdAt,
        //             cardPictureURL: item.cardPictureURL,
        //             travelDates: state.tripLogistics.travelDates,
        //             status: item.status,
        //             status: "design-in-progress",
        //           }
        //           travelManifest.push(updatedObj)
        //         } else {
        //           travelManifest.push(item)
        //         }
        //       })
        //       //   var newObj = { travelManifest: { ...travelManifest } }
        //       dbContext
        //         .firestore()
        //         .collection("trips")
        //         .doc(`${authContext.uid}`)
        //         .update({
        //           travelManifest: [...travelManifest],
        //         })
        //         .then(() => {
        //           console.log("Travel manifest updated successfully!")
        //         })
        //     }
        //     dbContext
        //       .firestore()
        //       .collection("jobs")
        //       .doc(`${designerUid}`)
        //       .collection("active-jobs")
        //       .doc(`${jobId}`)
        //       .set({
        //         createdOn: new Date(),
        //         tripUid: state.tripUid,
        //         tripReservations: [],
        //         tripPrimaryDestination: state.tripPrimaryDestination,
        //         tripChecklistStatus: "not-started",
        //         tripChecklistInProgress: "false",
        //         tripChecklist: [],
        //         travelerUid: travelerUid,
        //         travelDates: state.tripLogistics.travelDates,
        //         status: "pending",
        //         roomId: roomId,
        //         reservationsStatus: "not-started",
        //         reservationsInProgress: "false",
        //         messagedInProgress: false,
        //         tripChecklist: [],
        //         jobUid: jobId,
        //         itineraryStatus: "not-started",
        //         reviewStatus: "not-started",
        //         recommendationsStatus: "not-started",
        //       })
        //       .then(function() {
        //         console.log("New job created successfully!")
        //         dbContext
        //           .firestore()
        //           .collection("chatRooms")
        //           .doc(`${roomId}`)
        //           .set({
        //             createdOn: new Date(),
        //             owners: [travelerUid, designerUid],
        //             roomId: roomId,
        //             recipientInfo: {
        //               name: travelerName,
        //               uid: travelerUid,
        //               //To fix an issue where the profile image is null when user is using a non-gmail email
        //               // profileUrl: authContext.photoURL,
        //               profileUrl: globalState.currentUserData.userProfileImage,
        //             },
        //             senderInfo: {
        //               name: selectedDesigner.firstName,
        //               uid: designerUid,
        //               profileUrl: selectedDesigner.userProfileImage,
        //             },
        //           })
        //           .then(function() {
        //             dispatch({ type: "updateRoomId", data: roomId })

        //             if (dispatchDashboard)
        //               dispatchDashboard({
        //                 type: "setDesignerStateToPending",
        //                 data: selectedDesigner,
        //               })
        //             console.log("New chat room created successfully!")
        //             console.log("Show success message now!")
        //             navigate(ROUTES.DASHBOARD)
        //           })
        //       })
        //   })
        //   .catch(error => {
        //     console.log("Error creating new job! :", error)
        //   })
      }
    } catch (err) {
      console.log({ err })
      toast.error(
        "Error creating your travel design task, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      )
    }
  }

  const getTripDays = () => {
    if (state.tripLogistics)
      if (state.tripLogistics.travelDuration) {
        return state.tripLogistics.travelDuration
      }
  }

  const close = params => {
    // All done successfully go back to dashboard now.
    navigate(ROUTES.DASHBOARD)
  }
  const renderNameField = () => {
    try {
      const fName =
        dashboardState.tripDetails.designerDetails.designerProfile.firstName
      const lName =
        dashboardState.tripDetails.designerDetails.designerProfile.lastName
      return <>{fName + " " + lName}</>
    } catch (error) {
      return <>"--"</>
    }
  }

  const getStartDates = () => {
    try {
      let a = `${state.tripLogistics.travelDates.start
        .toDate()
        .toLocaleDateString()}`
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return `${new Date(
        state.tripLogistics.travelDates.start
      ).toLocaleDateString()}`
    }
  }
  const getEndDates = () => {
    try {
      let a = `${state.tripLogistics.travelDates.end
        .toDate()
        .toLocaleDateString()} `
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return `${new Date(
        state.tripLogistics.travelDates.end
      ).toLocaleDateString()} `
    }
  }

  const onPaymentSuccessful = () => {
    createJob()
    analytics.logEvent("wizard_confirm", {
      userId: `${authContext.uid}`,
      tripId: `${state.tripUid}`,
    })
  }

  let skillsAffinityMatrix = {
    familyfun: 0,
    winelover: 0,
    whiskeylover: 0,
    beerlover: 0,
    foodie: 0,
    camping: 0,
    roadtrips: 0,
    adrenalinejunkie: 0,
    sports: 0,
    historybuff: 0,
    hiking: 0,
    clubbing: 0,
    arts: 0,
    handicap: 0,
    offroading: 0,
    coffee: 0,
    solo: 0,
    animal: 0,
    winter: 0,
    water: 0,
    climbing: 0,
    zipline: 0,
    rafting: 0,
    whiskeybars: 0,
    speakeasy: 0,
    localeats: 0,
    localdistilleries: 0,
    breweries: 0,
    craftcocktails: 0,
    camping: 0,
    coffee: 0,
  }

  const analyzeTravelerPreferences = params => {
    //calculate skill affinity from trip type
    // skillsAffinityMatrix.familyfun++
    if (state.tripLogistics.tripType) {
      switch (state.tripLogistics.tripType.value) {
        case "roadtrip":
          skillsAffinityMatrix.roadtrips++
          break
        case "solotrip":
          skillsAffinityMatrix.solo++
          break
        case "honeymoon":
          break
        case "familyvacay":
          // skillsAffinityMatrix.familyfun++
          break
        case "anniversary":
          break
        case "romantic":
          break
        case "adventure":
          skillsAffinityMatrix.adrenalinejunkie++
          break
        case "other":
          break

        default:
          break
      }
    }
    console.log({ skillsAffinityMatrix })
    if (state.tripPreferences.foodPreferences) {
      state.tripPreferences.foodPreferences.forEach(item => {
        switch (item.value) {
          case "Foodie":
            skillsAffinityMatrix.foodie++
            break
          case "trylocal":
            skillsAffinityMatrix.foodie++
            break
          case "notadventurous":
            break
          case "eattosurvive":
            break

          default:
            break
        }
      })
    }
    console.log({ skillsAffinityMatrix })
    if (state.tripPreferences.drinkPreferences) {
      state.tripPreferences.drinkPreferences.forEach(item => {
        switch (item.value) {
          case "dontdrink":
            break
          case "localwines":
            skillsAffinityMatrix.winelover++
            break
          case "localcraftbeers":
            skillsAffinityMatrix.beerlover++
            break
          case "whiskeybars":
            skillsAffinityMatrix.whiskeylover++
            break
          case "speakeasy":
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.beerlover++
            skillsAffinityMatrix.winelover++
            break
          case "craftcocktails":
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.beerlover++
            skillsAffinityMatrix.winelover++
            break
          case "nothingspecific":
            break
          default:
            break
        }
      })
    }
    console.log({ skillsAffinityMatrix })
    if (state.tripPreferences.preferredActivities) {
      state.tripPreferences.preferredActivities.forEach(item => {
        switch (item.value) {
          case "museums":
            skillsAffinityMatrix.historybuff++
            break
          case "hiking":
            skillsAffinityMatrix.hiking++
          case "camping":
            skillsAffinityMatrix.camping++
            // skillsAffinityMatrix.climbing++
            break
          case "aquarium":
            skillsAffinityMatrix.familyfun++
            break
          case "concerts":
            break
          case "coffee":
            skillsAffinityMatrix.coffee++
            break
          case "golf":
            skillsAffinityMatrix.sports++
            break
          case "surfing":
            skillsAffinityMatrix.adrenalinejunkie++
            skillsAffinityMatrix.water++
            break
          case "ski":
            skillsAffinityMatrix.adrenalinejunkie++
            skillsAffinityMatrix.winter++
            break
          case "snowboard":
            skillsAffinityMatrix.adrenalinejunkie++
            skillsAffinityMatrix.winter++
            break
          case "scuba":
            skillsAffinityMatrix.adrenalinejunkie++
            skillsAffinityMatrix.water++
            break
          case "spa":
            break
          case "offroading":
            skillsAffinityMatrix.adrenalinejunkie++
            break
          case "horseback":
            skillsAffinityMatrix.adrenalinejunkie++
            break
          case "hotairballoon":
            skillsAffinityMatrix.adrenalinejunkie++
            break
          case "fishing":
            skillsAffinityMatrix.adrenalinejunkie++
            break
          case "skydiving":
            skillsAffinityMatrix.adrenalinejunkie++
            break
          case "arts":
            skillsAffinityMatrix.arts++
            break
          case "historic":
            skillsAffinityMatrix.historybuff++
            break
          case "cultural":
            skillsAffinityMatrix.historybuff++
            break
          case "localtours":
            break
          case "localclasses":
            break
          case "biking":
            break
          case "beachactivity":
            skillsAffinityMatrix.water++
            break
          case "shopping":
            break

          case "localeats":
          case "foodtour":
            skillsAffinityMatrix.foodie++
            break
          case "pubcrawl":
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.beerlover++
            skillsAffinityMatrix.beerlover++
            skillsAffinityMatrix.winelover++
            break
          case "breweries":
            skillsAffinityMatrix.beerlover++

            break
          case "localdistilleries":
            skillsAffinityMatrix.whiskeylover++
            break
          case "craftcocktails":
          case "speakeasy":
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.beerlover++
            skillsAffinityMatrix.winelover++

            break
          case "winery":
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.beerlover++
            skillsAffinityMatrix.winelover++
            skillsAffinityMatrix.winelover++
            skillsAffinityMatrix.winelover++
            break
          case "danceclub":
            skillsAffinityMatrix.clubbing++
            break
          case "rockclimbing":
            skillsAffinityMatrix.climbing++
            break
          case "safari":
            skillsAffinityMatrix.familyfun++
            break
          case "touristspots":
            break
          case "whiskeybars":
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.whiskeylover++
            skillsAffinityMatrix.beerlover++
            skillsAffinityMatrix.winelover++
            break
          case "rafting":
          case "zipline":
            skillsAffinityMatrix.adrenalinejunkie++
          case "zoo":
            skillsAffinityMatrix.familyfun++
            break

          default:
            break
        }
      })
    }
    if (state.kidsTravelerCount > 0) {
      skillsAffinityMatrix.familyfun++
    }
    return skillsAffinityMatrix
  }

  return (
    <div>
      {designerSelected ? (
        <>
          {/* {showOffer && (
            <OfferModal>
              <div
                className="welcome-close"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowOffer(false)
                }}
              >
                <i className="fas fa-times-circle fa-2x"></i>
              </div>
            </OfferModal>
          )} */}

          <div className="columns is-multiline is-mobile">
            <div className="column is-3-desktop is-3-tablet is-hidden-mobile"></div>
            <div className="column is-6-desktop  is-6-tablet  is-12-mobile">
              <div
                className=""
                style={{
                  padding: "20px",
                  //border: "1px solid #ffc600",
                  borderRadius: "8px",
                }}
              >
                {selectedDesigner ? (
                  <>
                    <div className="profile-image" style={{ width: "128px" }}>
                      <figure className="image is-128x128">
                        {renderProfileImage(selectedDesigner.userProfileImage)}
                      </figure>
                    </div>
                    <div
                      className=""
                      style={{
                        marginLeft: "0px",
                        marginBottom: "5px",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          marginTop: "10px",
                          //marginBottom: "10px",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                        //  style={{ marginBottom: "0px", marginLeft: "auto" }}
                        // className="subtitle is-5 font-lato-600 data"
                      >
                        {" "}
                        {selectedDesigner.firstName} {selectedDesigner.lastName}
                      </p>
                      <StarRating
                        score={selectedDesigner.reviewRating}
                        reviewCount={selectedDesigner.reviewCount}
                      />
                    </div>
                  </>
                ) : (
                  <div>Nobody assigned yet</div>
                )}
              </div>
              <p
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  // //marginBottom: "10px",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "left",
                }}
              >
                You picked{" "}
                <strong>
                  {selectedDesigner.firstName} {selectedDesigner.lastName}
                </strong>{" "}
                as your travel plan designer.
              </p>
              {/* <p
                style={{
                  marginTop: "10px",
                  marginBottom: "30px",
                  // //marginBottom: "10px",
                  fontSize: "26px",
                  fontWeight: "800",
                  textAlign: "center",
                  color: "#fec600",
                }}
              >
                Total due: $199.00
              </p> */}
              <div
                style={{
                  // display: "none",
                  // maxWidth: "400px",
                  margin: "30px auto",
                  lineHeight: "1.8",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "right",
                    }}
                  >
                    {/* Test Amount */}
                    {/* Total Due: <strong>$99</strong> */}
                    Custom travel plan fee per day:{" "}
                  </p>
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "right",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      ${baseAmountDue}
                    </span>
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    {/* Test Amount */}
                    {/* Total Due: <strong>$99</strong> */}
                    Total itinerary days:
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        letterSpacing: "0.5px",
                        // color: "#fec600",
                      }}
                    >
                      <br></br> {getStartDates()} - {getEndDates()}
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "right",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#fec600",
                        marginRight: "15px",
                      }}
                    >
                      x
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {getTripDays()}
                    </span>
                  </p>
                </div>

                <p
                  style={{
                    marginTop: "5px",
                    marginBottom: "0px",
                    paddingTop: "5px",
                    // //marginBottom: "10px",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "right",
                    borderTop: "1px #fec600 solid",
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                  }}
                >
                  {/* Test Amount */}
                  {/* Total Due: <strong>$99</strong> */}
                  Total Due:{"    "}
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      // color: "#fec600",
                    }}
                  >
                    ${amountDue}
                  </span>
                </p>
              </div>
              <span
                // className="tag"
                style={{
                  // marginTop: "5px",
                  marginBottom: "50px",
                  display: "block",
                  fontWeight: "600",
                  fontSize: "16px",
                  textAlign: "left",
                  padding: "7px",
                  height: "35px",
                  borderRadius: "4px",
                  // border: "1px solid #fec600",
                  // textAlign: "center",

                  display: "none",
                }}
              >
                {" "}
                → Check your welcome email for a discount code! 🎉
              </span>
              {/* <p style={{ marginTop: "10px", marginBottom: "15px" }}>
                Note: You will be prompted to pay this amount once the travel
                designer accepts the task
              </p> */}
              {/* <PaymentFormNew
                tripDays={getTripDays()}
                tripUid={state.tripUid}
                tripPrimaryDestination={
                  state.tripPrimaryDestination.place +
                  "-" +
                  state.tripPrimaryDestination.country
                }
                tripName={state.tripName}
                onPaymentSuccessful={onPaymentSuccessful}
                //onCancel={onCancel}
              /> */}

              <p
                style={{
                  marginTop: "12px",
                  marginBottom: "15px",
                  textAlign: "left",
                  // display: "none",
                  fontSize: "12px",
                }}
              >
                <i
                  style={{ marginRight: "5px", color: "#fec600" }}
                  className="far fa-question-square"
                ></i>
                <strong>When do I pay?</strong>{" "}
                <span style={{ display: "inline-block" }}>
                  Once the travel designer accepts the design job you will be
                  notified to pay the amount due. Thats also when you can apply
                  any promo/discount codes.
                </span>
              </p>
              <div
                style={{
                  marginTop: "40px",
                  marginBottom: "20px",
                }}
              >
                <button
                  className="button"
                  onClick={() => {
                    setDesignerSelected(false)
                  }}
                >
                  Go back
                </button>
                <button
                  className="button is-warning"
                  onClick={() => {
                    createJob()
                    analytics.logEvent("wizard_confirm", {
                      userId: `${authContext.uid}`,
                      tripId: `${state.tripUid}`,
                    })
                  }}
                  style={{
                    float: "right",
                  }}
                >
                  Create Design Task
                </button>
              </div>
            </div>
            <div className="column is-3-desktop is-3-tablet is-hidden-mobile"></div>
          </div>
        </>
      ) : (
        <>
          {reselectDesigner ? (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Uh oh! {renderNameField()} wont be able to work on your travel
                plan, sorry!
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "500",
                  marginTop: "20px",
                }}
              >
                Please pick a new travel designer
              </p>
            </>
          ) : (
            <>
              {" "}
              {/* <h3 align="center" className="subtitle is-3 align-center">
                Pick your trip designer
              </h3> */}
              <div style={{ textAlign: "left", margin: "20px auto" }}>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "800",
                    color: "#fec600",
                  }}
                >
                  Pick your trip designer
                </p>
                {/* <p>This will help your trip designer better curate your activities</p> */}
              </div>
              {/* <progress
                className="progress is-warning is-thin"
                value={state.wizardProgress}
                max="100"
              >
                25%
              </progress> */}
            </>
          )}

          {/* <SearchDesigners
            onSelect={onSelectDesigner}
            destination={state.tripPrimaryDestination.country}
          /> */}

          <SearchDesigners
            onSelect={onSelectDesigner}
            destination={state.tripPrimaryDestination.country}
            skillsAffinityMatrix={analyzeTravelerPreferences()}
          />

          <hr className="hr-branded" />
          {!reselectDesigner && (
            <div className="columns">
              <div className="column is-6-desktop is-6-tablet is-6-mobile">
                {" "}
                <button
                  className="button"
                  onClick={e => {
                    if (onPrevious) onPrevious()
                  }}
                >
                  <i
                    className="fas fa-angle-left"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Summary{" "}
                </button>
              </div>
              <div className="column is-6-desktop is-6-tablet is-6-mobile"></div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const PickADesigner = ({ onNext, onPrevious, reselectDesigner }) => {
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="columns is-tablet is-multiline">
        <div className="column is-1-desktop is-12-tablet is-12-mobile"></div>
        <div className="column is-10-desktop is-12-tablet is-12-mobile">
          {" "}
          <PickADesignerBase
            onNext={onNext}
            onPrevious={onPrevious}
            reselectDesigner={reselectDesigner}
          />
        </div>
        <div className="column is-1-desktop is-12-tablet is-12-mobile"></div>
      </div>
    </>
  )
}
export default PickADesigner

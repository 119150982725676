import React, { useContext, useEffect, useState } from "react"
import StarRating from "../../../components/StarRating/StarRating"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import {
  renderProfileImage,
  fetchFromAPI,
  getSafeAddDays,
  daysRemaining,
} from "../../../utility/general"
import defaultUserImage from "../../../images/defaultUser.png"
import sampledesigner from "../../../images/sampledesigner.jpg"
import PickADesigner from "../../../components/TripCreationComponents/PickADesigner"
import swal from "sweetalert"
import { FirebaseContext } from "../../../components/firebase"
import AuthUserContext from "../../../components/session/context"
import { v4 as uuidv4 } from "uuid"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
import PaymentForm from "../../../components/PaymentForm/PaymentForm"
import { functions } from "../../../components/firebase/firebase"
import artistAtWork from "../../../images/artistAtWork.png"
import bonVoyayge from "../../../images/bon-voyayge.png"
import moment from "moment"
import { navigate } from "gatsby"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import loadable from "@loadable/component"
const ReactQuill = loadable(() => import("react-quill"))
function TripDesignerCardComponent() {
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripDispatch] = useTripStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [showReviewForm, setShowReviewForm] = useState(false)
  const [showPaymentForm, setShowPaymentForm] = useState(false)
  const [reviewScore, setReviewScore] = useState(0)
  const [reviewText, setReviewText] = useState("")
  const [reviewScoreValidationMsg, setReviewScoreValidationMsg] = useState("")
  const [reviewTextValidationMsg, setReviewTextValidationMsg] = useState("")
  const [processing, setProcessing] = useState(false)
  const [showInviteForm, setShowInviteForm] = useState(false)
  const [inviteeList, setInviteeList] = useState([{ name: "", email: "" }])
  const [busy, setBusy] = useState(false)
  const [submissionState, setSubmissionState] = useState("none")
  const [changes, setChanges] = useState("")
  const [showRequestAccept, setShowRequestAccept] = useState(true)
  const [isJobLate, setIsJobLate] = useState(false)
  const [
    offlineTravelPlanRequestState,
    setOfflineTravelPlanRequestState,
  ] = useState("none")
  useEffect(() => {
    console.log({ globalState })
  }, [globalState])

  useEffect(() => {
    console.log({ state })
  }, [state])

  useEffect(() => {
    if (isJobLate) {
      setShowInviteForm(false)
    }
  }, [isJobLate])

  const initiateReviewComplete = () => {
    console.log({ state })

    var designerUid = state.tripDetails.designerDetails.designerUid
    var jobId = state.currentJobInfo.jobUid
    if (dbContext)
      dbContext
        .firestore()
        .collection("jobs")
        .doc(`${designerUid}`)
        .collection("active-jobs")
        .doc(`${jobId}`)
        .update({
          status: "review-complete",
          reviewStatus: "complete",
        })
        .then(function() {
          console.log("Jobs details status updated to review complete state")
          dispatch({ type: "updateReviewCompleteForTraveler" })
          swal("Your travel plan designer has been notified.", {
            icon: "success",
          })
        })
        .catch(error => {
          console.log(
            "Error setting Jobs details status to review state:",
            error
          )
        })
  }

  const initiateRequestChanges = () => {
    console.log({ state })

    var designerUid = state.tripDetails.designerDetails.designerUid
    var jobId = state.currentJobInfo.jobUid
    if (dbContext)
      dbContext
        .firestore()
        .collection("jobs")
        .doc(`${designerUid}`)
        .collection("active-jobs")
        .doc(`${jobId}`)
        .update({
          status: "request-changes",
          reviewStatus: "changes-required",
          changesRequestedOn: new Date(),
          requestedChanges: dbContext.firestore.FieldValue.arrayUnion({
            changes: changes,
            dateTime: new Date(),
          }),
        })
        .then(function() {
          console.log("Jobs details status updated to request changes state")
          dispatch({ type: "updateRequestChangesForTraveler" })
          swal(
            "Your travel plan designer has been notified with the changes you requested",
            {
              icon: "success",
            }
          )
        })
        .catch(error => {
          console.log(
            "Error setting Jobs details status to review state:",
            error
          )
        })
  }

  const renderNameField = () => {
    try {
      const fName = state.tripDetails.designerDetails.designerProfile.firstName
      const lName = state.tripDetails.designerDetails.designerProfile.lastName
      return <>{fName + " " + lName}</>
    } catch (error) {
      return <>"--"</>
    }
  }
  const customNavigateTo = url => {
    window.open(url, "_blank")
  }
  const renderRating = params => {
    try {
      return state.tripDetails.designerDetails.designerProfile.reviewRating
    } catch (error) {
      return null
    }
  }
  const renderReviewCount = params => {
    try {
      return state.tripDetails.designerDetails.designerProfile.reviewCount
    } catch (error) {
      return null
    }
  }
  const validateAndSubmit = () => {
    if (reviewScore === 0)
      setReviewScoreValidationMsg("Star rating is required")
    if (reviewText === "") setReviewTextValidationMsg("Review is required")

    if (reviewScore !== 0 && reviewText !== "") submitReview()
  }
  const submitReview = () => {
    var reviewId = uuidv4()
    if (dbContext)
      dbContext
        .firestore()
        .collection("users")
        .doc(`${state.tripDetails.designerDetails.designerUid}`)
        .collection("publicProfile")
        .doc(`${state.tripDetails.designerDetails.designerUid}`)
        .collection("reviews")
        .doc(`${reviewId}`)
        .set({
          firstName:
            globalState.currentUserData.firstName === ""
              ? globalState.currentUserData.userName
              : globalState.currentUserData.firstName,
          rating: reviewScore,
          review: reviewText,
          reviewDate: new Date(),
          reviewerProfileImage: globalState.currentUserData.userProfileImage,
          uid: authContext.uid,
          reviewId: reviewId,
        })
        .then(() => {
          console.log("Review submitted!")
          dbContext
            .firestore()
            .collection("trips")
            .doc(`${authContext.uid}`)
            .collection("active-trips")
            .doc(`${state.currentJobInfo.tripUid}`)
            .update({ designerReviewed: true })
            .then(() => {
              console.log("Review status changed to submitted")
              dispatch({ type: "reviewSubmitted" })
              swal("Your review has been submitted. Thank you!", {
                icon: "success",
              })
            })
        })
        .catch(error => console.log(error))
  }

  const reviewComplete = () => {
    if (
      state.tripDetails.designerReviewed === false ||
      state.tripDetails.designerReviewed === undefined
    ) {
      return false
    } else return true
  }
  const onCancel = e => {
    setShowPaymentForm(false)
  }

  const showTripDestinations = () => {
    if (state.tripDetails.tripDestinations) {
      var destinationString = ""
      state.tripDetails.tripDestinations.map((item, index) => {
        console.log(state.tripDetails.tripDestinations.length, index)
        destinationString =
          destinationString +
          item.place +
          "-" +
          item.country +
          (state.tripDetails.tripDestinations.length === index + 1 ? "" : ", ")
      })
      return destinationString
    }
  }

  const showRightScreen = () => {
    switch (submissionState) {
      case "none":
        return (
          <>
            <div style={{ maxWidth: "600px", margin: "0 auto" }}>
              {displayInviteListForm()}
              <div style={{ maxWidth: "600px", margin: "10px auto" }}>
                <button
                  className="button is-dark is-outlined"
                  style={{ width: "100%" }}
                  onClick={handleAddClick}
                >
                  Add more
                  {/* <span className="icon is-small">
              <i className="fas fa-plus"></i>
            </span> */}
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{ marginTop: "10px", marginRight: "10px" }}
                  className="button"
                  onClick={() => {
                    setShowInviteForm(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ marginTop: "10px" }}
                  className={`${
                    busy
                      ? "button is-warning is-pulled-right is-loading"
                      : "button is-warning is-pulled-right"
                  }`}
                  onClick={() => {
                    sendInvitation()
                    //setShowInviteForm(false)
                  }}
                >
                  Send request
                </button>
              </div>
            </div>
          </>
        )
        break
      case "error":
        return (
          <p
            style={{
              fontSize: "20px",
              margin: "15px auto",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            Uh oh!, there was an error sending the invites. Please try again in
            sometime. Sorry! <br></br>{" "}
            <small>
              If the issue persists please email to{" "}
              <a href="mailto: support@voyayge.com">support@voyayge.com</a>
            </small>
          </p>
        )
        break
      case "success":
        return (
          <p
            style={{
              fontSize: "20px",
              margin: "15px auto",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            We have successfully sent your recommendation requests!
          </p>
        )
        break

      default:
        break
    }
  }

  const sendInvitation = () => {
    try {
      setBusy(true)
      let data = {
        invitees: inviteeList,

        inviter:
          globalState.currentUserData.firstName === ""
            ? globalState.currentUserData.userName
            : globalState.currentUserData.firstName +
              " " +
              globalState.currentUserData.lastName,
        destinations: state.tripDetails.tripDestinations,
        tripUid: state.tripDetails.tripUid,
        jobUid: state.tripDetails.designerDetails.jobUid,
        designerUid: state.tripDetails.designerDetails.designerUid,
        travelerUid: state.tripDetails.uid,
      }
      var sendInvite = functions.httpsCallable("sendInviteForRecommendations")
      sendInvite(data).then(function(result) {
        // Read result of the Cloud Function.
        setBusy(false)
        console.log({ result })
        console.log(result.data.text)
        if (result.data.success) {
          setSubmissionState("success")
        } else {
          setSubmissionState("error")
        }
        // ...
      })
    } catch (err) {
      setBusy(false)
      setSubmissionState("error")
    }
  }

  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      // ["clean"],
    ],
  }
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    //  "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
  ]
  const downloadOfflinePlan = async () => {
    setProcessing(true)
    try {
      var getPlanPDF = functions.httpsCallable("generatePDF")
      getPlanPDF({
        tripUid: state.currentJobInfo.tripUid,
        jobUid:
          tripState.type === "sample-for-traveler"
            ? state.currentJobInfo.tripUid
            : state.currentJobInfo.jobUid,
      }).then(result => {
        setProcessing(false)
        setOfflineTravelPlanRequestState("done")
        console.log(result)
        // if (result.data.success) {
        //   var a = document.createElement("a")
        //   a.href = "data:application/pdf;base64," + result.data.success
        //   a.download = "Travel-Plan.pdf"
        //   document.body.appendChild(a)
        //   setProcessing(false)
        //   a.click()
        //   setTimeout(function() {
        //     document.body.removeChild(a)
        //   }, 0)
        // }
      })
    } catch (err) {
      console.log(err)
      setProcessing(false)
    }
  }
  const downloadDraftOfflinePlan = async () => {
    setProcessing(true)
    try {
      var getPlanPDF = functions.httpsCallable("generatePDFForReview")
      getPlanPDF({
        tripUid: state.currentJobInfo.tripUid,
        jobUid:
          tripState.type === "sample-for-traveler"
            ? state.currentJobInfo.tripUid
            : state.currentJobInfo.jobUid,
      }).then(result => {
        setProcessing(false)
        setOfflineTravelPlanRequestState("done")
        console.log(result)
        // if (result.data.success) {
        //   var a = document.createElement("a")
        //   a.href = "data:application/pdf;base64," + result.data.success
        //   a.download = "Travel-Plan.pdf"
        //   document.body.appendChild(a)
        //   setProcessing(false)
        //   a.click()
        //   setTimeout(function() {
        //     document.body.removeChild(a)
        //   }, 0)
        // }
      })
    } catch (err) {
      console.log(err)
      setProcessing(false)
    }
  }

  const handleAddClick = () => {
    setInviteeList([...inviteeList, { name: "", email: "" }])
  }

  const handleRemoveClick = (e, index) => {
    const list = [...inviteeList]
    list.splice(index, 1)
    setInviteeList(list)
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inviteeList]
    list[index][name] = value
    setInviteeList(list)
  }

  const displayInviteListForm = () => {
    return inviteeList.map((item, i) => {
      return (
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px auto",
          }}
        >
          <input
            name="name"
            type="text"
            placeholder="Name"
            className="input"
            style={{ marginRight: "5px" }}
            value={item.name}
            onChange={e => handleInputChange(e, i)}
          />
          <input
            name="email"
            type="text"
            placeholder="Email address"
            className="input"
            style={{ marginLeft: "5px" }}
            value={item.email}
            onChange={e => handleInputChange(e, i)}
            style={{ marginRight: "5px" }}
          />

          <button
            className="button is-warning"
            onClick={e => handleRemoveClick(e, i)}
          >
            <span className="icon is-small">
              <i className="fas fa-times"></i>
            </span>
          </button>
        </div>
      )
    })
  }

  const isReviewLate = () => {
    if (state.tripDetails.paymentCompletedOn !== undefined) {
      let days = daysRemaining(state.tripDetails.paymentCompletedOn, 7)
      if (days < 0) return true
      // Give a grace of 1 day before providing an option to cancel
      else return false
    }

    return false
  }

  const areChangesRequestedLate = () => {
    if (state.tripDetails.paymentCompletedOn !== undefined) {
      let days = daysRemaining(state.tripDetails.paymentCompletedOn, 7)
      if (days < 0) return true
      // Give a grace of 1 day before providing an option to cancel
      else return false
    }

    return false
  }

  const onConfirm = () => {
    console.log("Job Canceled")
  }

  const renderRightMessage = () => {
    try {
      switch (state.tripDetails.designerDetails.status) {
        case "pending":
          return (
            <p
              style={{
                //marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Designer is yet to accept the job
            </p>
          )
          break
        case "review-complete":
          return (
            <p
              style={{
                //marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Travel plan review is now complete. Your designer is now working
              on finalizing the plan. You should hear back from us shortly.
            </p>
          )
          break
        case "request-changes":
          return (
            <>
              <p
                style={{
                  //marginBottom: "10px",
                  fontSize: "18px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Your requested changes have been sent to your travel designer.
                Thank you!
              </p>
              <p
                style={{
                  //marginTop: "10px",
                  marginTop: "20px",
                  // //marginBottom: "10px",
                  fontSize: "14px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                If you do not receive your requested within the 4 days, please
                reach out to{" "}
                <a
                  href={`mailto: support@voyayge.com?subject=Requested Changes Delay #${state.tripDetails.designerDetails.jobUid}`}
                >
                  support@voyayge.com
                </a>
              </p>
            </>
          )
          break
        case "payment-pending":
          return (
            <>
              {showPaymentForm ? (
                <PaymentForm
                  tripDays={getTripDays()}
                  tripUid={state.tripDetails.tripUid}
                  tripPrimaryDestination={
                    state.tripDetails.tripPrimaryDestination.place +
                    "-" +
                    state.tripDetails.tripPrimaryDestination.country
                  }
                  tripName={state.tripDetails.tripName}
                  //onCancel={onCancel}
                />
              ) : (
                <>
                  <p style={{ textAlign: "center" }}>
                    {
                      state.tripDetails.designerDetails.designerProfile
                        .firstName
                    }
                    {"  "}has accepted your job. Please proceed to payment.
                  </p>
                  <div
                    style={{
                      maxWidth: "175px",
                      margin: "0 auto",
                    }}
                  >
                    <button
                      style={{ marginTop: "15px" }}
                      className="button is-warning"
                      onClick={() => {
                        setShowPaymentForm(true)
                        // swal({
                        //   title: "Confirmation",
                        //   text: "Are you sure?",
                        //   //  icon: "question",
                        //   buttons: true,
                        //   dangerMode: true,
                        //   buttons: ["No, cancel it!", "Yes, I am sure!"],
                        // }).then(doReviewComplete => {
                        //   if (doReviewComplete) {
                        //     initiateReviewComplete()
                        //   }
                        // })
                      }}
                    >
                      Proceed to payment
                    </button>
                  </div>
                </>
              )}
            </>
          )
          break
        case "review":
          return (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Travel plan available for review
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "400",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                Please review the travel plan available under the "Itinerary",
                "Other Info" and "Checklist" tabs above and pick an appropriate
                action.
              </p>
              {showRequestAccept ? (
                <div
                  style={{
                    flexWrap: "wrap",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15px",
                    // maxWidth: "200px",
                    margin: "0 auto",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      marginTop: "15px",
                      maxWidth: "200px",
                      margin: "0 auto",
                    }}
                  >
                    <button
                      style={{
                        marginTop: "15px",
                        width: "100%",
                      }}
                      className="button is-light"
                      onClick={() => {
                        setShowRequestAccept(false)
                        // swal({
                        //   title: "Confirmation",
                        //   text: "Are you sure?",
                        //   //  icon: "question",
                        //   buttons: true,
                        //   dangerMode: true,
                        //   buttons: ["No, cancel it!", "Yes, I am sure!"],
                        // }).then(doRequestChanges => {
                        //   if (doRequestChanges) {
                        //     initiateRequestChanges()
                        //   }
                        // })
                      }}
                    >
                      Request Changes
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: "15px",
                      maxWidth: "200px",
                      margin: "0 auto",
                    }}
                  >
                    <button
                      style={{
                        marginTop: "15px",
                        width: "100%",
                      }}
                      className="button is-warning"
                      onClick={() => {
                        swal({
                          title: "Confirmation",
                          text:
                            "Are you sure you want to finalize this travel plan?",
                          //  icon: "question",
                          buttons: true,
                          dangerMode: true,
                          buttons: ["No", "Yes, I am sure!"],
                        }).then(doReviewComplete => {
                          if (doReviewComplete) {
                            initiateReviewComplete()
                          }
                        })
                      }}
                    >
                      Finalize Travel Plan
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "25px",
                    // maxWidth: "200px",
                    margin: "0 auto",
                  }}
                >
                  <div className="reco-quill" style={{ minHeight: "400px" }}>
                    <ReactQuill
                      theme="snow"
                      value={changes}
                      onChange={e => {
                        setChanges(e)
                      }}
                      placeholder="Please type the requested changes here..."
                      rows="15"
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "25px",
                      // maxWidth: "200px",
                      marginLeft: "auto",
                    }}
                  >
                    <div
                      className="field is-grouped"
                      style={{
                        maxWidth: "177px",
                        // maxWidth: "200px",
                        marginLeft: "auto",
                      }}
                    >
                      <p className="control">
                        <button
                          className="button"
                          onClick={() => {
                            setShowRequestAccept(true)
                          }}
                        >
                          Cancel
                        </button>
                      </p>
                      <p className="control">
                        <button
                          className="button is-warning"
                          onClick={() => {
                            initiateRequestChanges()
                            setShowRequestAccept(true)
                          }}
                        >
                          Submit
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ maxWidth: "247px", margin: "0 auto" }}>
                {offlineTravelPlanRequestState === "none" && authContext && (
                  <button
                    style={{
                      display: "none",
                      //cursor: "pointer",
                      marginTop: "15px",
                      // width: "240px",
                      // //marginBottom: "10px",
                      fontSize: "11px",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      // textAlign: "center",
                      // float: "right",
                    }}
                    onClick={downloadDraftOfflinePlan}
                    className={`${
                      processing
                        ? "button  is-warning is-small is-loading"
                        : "button  is-warning is-small "
                    }`}
                  >
                    <i
                      className="fas fa-inbox-in"
                      style={{ paddingRight: "10px" }}
                    ></i>
                    {/* Request travel plan in PDF format for offline access. */}
                    Request my offline travel plan
                    {/* <i
                    className="fal fa-file-pdf fa-2x"
                    style={{ color: "#fec600" }}
                  ></i> */}
                  </button>
                )}
                {offlineTravelPlanRequestState === "done" && (
                  <p
                    style={{
                      //cursor: "pointer",
                      marginTop: "15px",
                      // width: "240px",
                      // //marginBottom: "10px",
                      fontSize: "14px",
                      fontWeight: "500",
                      // textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      textAlign: "center",
                      float: "right",
                    }}
                  >
                    {/* <i
                          className="far fa-check"
                          // style={{ paddingRight: "px" }}
                        ></i>{" "} */}
                    Success! You should receive an email with the plan shortly.
                  </p>
                )}
              </div>
            </>
          )
          break
        case "complete":
          return (
            <>
              {reviewComplete() ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        //marginTop: "10px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "22px",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      Design complete
                    </p>
                    <div style={{ maxWidth: "255px", margin: "0 auto" }}>
                      <img
                        src={bonVoyayge}
                        style={{
                          padding: "10px",
                          width: "100%",
                        }}
                      />
                    </div>
                    <p
                      style={{
                        marginTop: "20px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {/* Download your offline travel plan: */}
                    </p>
                    {offlineTravelPlanRequestState === "none" && authContext && (
                      <button
                        style={{
                          //cursor: "pointer",
                          marginTop: "15px",
                          // width: "240px",
                          // //marginBottom: "10px",
                          fontSize: "11px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                          letterSpacing: "0.5px",
                          // textAlign: "center",
                          // float: "right",
                        }}
                        onClick={downloadOfflinePlan}
                        className={`${
                          processing
                            ? "button  is-warning is-small is-loading"
                            : "button  is-warning is-small "
                        }`}
                      >
                        <i
                          className="fas fa-inbox-in"
                          style={{ paddingRight: "10px" }}
                        ></i>
                        {/* Request travel plan in PDF format for offline access. */}
                        Request my offline travel plan
                        {/* <i
                  className="fal fa-file-pdf fa-2x"
                  style={{ color: "#fec600" }}
                ></i> */}
                      </button>
                    )}
                    {offlineTravelPlanRequestState === "done" && (
                      <p
                        style={{
                          //cursor: "pointer",
                          marginTop: "15px",
                          // width: "240px",
                          // //marginBottom: "10px",
                          fontSize: "14px",
                          fontWeight: "500",
                          // textTransform: "uppercase",
                          letterSpacing: "0.5px",
                          textAlign: "center",
                          float: "right",
                        }}
                      >
                        {/* <i
                          className="far fa-check"
                          // style={{ paddingRight: "px" }}
                        ></i>{" "} */}
                        Success! You should receive an email with the plan
                        shortly.
                      </p>
                    )}
                  </div>
                </>
              ) : showReviewForm ? (
                <div className="columns">
                  <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
                  <div className="column is-10-desktop is-10-tablet">
                    {/* <p style={{ textAlign: "left" }}> */}
                    <label className="label" style={{ textAlign: "left" }}>
                      How was your experience with your trip designer,{" "}
                      {
                        state.tripDetails.designerDetails.designerProfile
                          .firstName
                      }
                      ?
                      <p className="help is-danger">
                        {reviewScoreValidationMsg}
                      </p>
                    </label>
                    {/* </p> */}

                    <div
                      style={{
                        display: "flex",
                        marginTop: "25px",
                        marginBottom: "25px",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ margin: "10px" }}>
                        <div style={{ maxWidth: "36px", margin: "0 auto" }}>
                          <i
                            className={`${
                              reviewScore >= 1
                                ? `fas fa-star fa-2x`
                                : `fal fa-star fa-2x `
                            }`}
                            style={{
                              color: "#ffc600",
                              cursor: "pointer",
                              width: "100%",
                            }}
                            onClick={() => {
                              setReviewScore(1)
                              setReviewScoreValidationMsg("")
                            }}
                          ></i>
                        </div>
                        <p style={{ textAlign: "center", marginTop: "5px" }}>
                          Terrible
                        </p>
                      </div>
                      <div style={{ margin: "10px" }}>
                        <i
                          style={{ marginRight: "5px" }}
                          className={`${
                            reviewScore >= 2
                              ? `fas fa-star fa-2x`
                              : `fal fa-star fa-2x `
                          }`}
                          style={{ color: "#ffc600", cursor: "pointer" }}
                          onClick={() => {
                            setReviewScore(2)
                            setReviewScoreValidationMsg("")
                          }}
                        ></i>
                        <p style={{ textAlign: "center", marginTop: "5px" }}>
                          Bad
                        </p>
                      </div>
                      <div style={{ margin: "10px" }}>
                        <i
                          style={{ marginRight: "5px" }}
                          className={`${
                            reviewScore >= 3
                              ? `fas fa-star fa-2x`
                              : `fal fa-star fa-2x `
                          }`}
                          style={{ color: "#ffc600", cursor: "pointer" }}
                          onClick={() => {
                            setReviewScore(3)
                            setReviewScoreValidationMsg("")
                          }}
                        ></i>
                        <p style={{ textAlign: "center", marginTop: "5px" }}>
                          Okay
                        </p>
                      </div>
                      <div style={{ margin: "10px" }}>
                        <i
                          style={{ marginRight: "5px" }}
                          className={`${
                            reviewScore >= 4
                              ? `fas fa-star fa-2x`
                              : `fal fa-star fa-2x `
                          }`}
                          style={{ color: "#ffc600", cursor: "pointer" }}
                          onClick={() => {
                            setReviewScore(4)
                            setReviewScoreValidationMsg("")
                          }}
                        ></i>
                        <p style={{ textAlign: "center", marginTop: "5px" }}>
                          Good
                        </p>
                      </div>
                      <div style={{ margin: "10px" }}>
                        <i
                          style={{ marginRight: "5px" }}
                          className={`${
                            reviewScore >= 5
                              ? `fas fa-star fa-2x`
                              : `fal fa-star fa-2x `
                          }`}
                          style={{ color: "#ffc600", cursor: "pointer" }}
                          onClick={() => {
                            setReviewScore(5)
                            setReviewScoreValidationMsg("")
                          }}
                        ></i>
                        <p style={{ textAlign: "center", marginTop: "5px" }}>
                          Great
                        </p>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" style={{ textAlign: "left" }}>
                        Your review:{" "}
                        <span style={{ fontSize: "12px", fontWeight: "300" }}>
                          (This will be displayed on designer's profile)
                        </span>
                        <p className="help is-danger">
                          {reviewTextValidationMsg}
                        </p>
                      </label>
                      <textarea
                        value={reviewText}
                        className="textarea"
                        rows="5"
                        maxLength="700"
                        placeholder="What did you like or didnt like about this trip designer's service? Please share as many details as you can to help other travelers make right decision."
                        onChange={e => {
                          setReviewTextValidationMsg("")
                          setReviewText(e.currentTarget.value)
                        }}
                      ></textarea>
                    </div>
                    <div style={{ float: "" }}>
                      <button
                        className="button is-light"
                        style={{ marginRight: "15px" }}
                        onClick={() => {
                          setShowReviewForm(false)
                          setReviewTextValidationMsg("")
                          setReviewScoreValidationMsg("")
                          setReviewText("")
                          setReviewScore(0)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="button is-warning"
                        onClick={() => {
                          validateAndSubmit()
                        }}
                      >
                        Submit Review
                      </button>
                    </div>
                  </div>
                  <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
                </div>
              ) : (
                <>
                  <p style={{ textAlign: "center", fontSize: "18px" }}>
                    <strong>Please provide a review</strong>
                  </p>
                  <p style={{ textAlign: "center", marginTop: "12px" }}>
                    Leaving a review and providing feedback helps{" "}
                    {
                      state.tripDetails.designerDetails.designerProfile
                        .firstName
                    }{" "}
                    get better. It also helps other <strong>voyaygers</strong>{" "}
                    make better decisions, Thank you!
                  </p>
                  <p style={{ textAlign: "left" }}></p>

                  <div
                    style={{
                      marginTop: "15px",
                      maxWidth: "200px",
                      margin: "0 auto",
                    }}
                  >
                    <button
                      style={{ marginTop: "15px", width: "100%" }}
                      className="button is-warning"
                      onClick={() => {
                        setShowReviewForm(true)
                      }}
                    >
                      Review
                    </button>
                  </div>
                </>
              )}
            </>
          )
          break
        case "active":
          return (
            <>
              {state.tripDetails.paymentCompletedOn !== undefined && (
                <nav className="level">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Payment completed on</p>
                      <p className="title" style={{ fontSize: "20px" }}>
                        {" "}
                        {state.tripDetails.paymentCompletedOn !== undefined
                          ? getSafeAddDays(
                              state.tripDetails.paymentCompletedOn,
                              0
                            )
                          : "--"}
                      </p>
                    </div>
                  </div>
                  {/* <div className="level-item has-text-centered">
                  <div>
                    <p className="heading"></p>
                    <p className="title">Late</p>
                  </div>
                </div> */}
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Due by</p>
                      <p
                        className={`${isReviewLate() ? "title" : "title "}`}
                        style={{ fontSize: "20px" }}
                      >
                        {state.tripDetails.paymentCompletedOn !== undefined
                          ? getSafeAddDays(
                              state.tripDetails.paymentCompletedOn,
                              7
                            )
                          : "--"}
                      </p>
                      {isReviewLate() && (
                        <p className="is-help has-text-danger">(Past Due)</p>
                      )}
                    </div>
                  </div>
                </nav>
              )}
              <div
                style={{ maxWidth: "275px", margin: "0 auto" }}
                id="send-invite"
              >
                <img
                  src={artistAtWork}
                  style={{
                    padding: "10px",
                    width: "100%",
                  }}
                />
              </div>
              <p
                style={{
                  //marginTop: "10px",
                  marginBottom: "22px",
                  // //marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "500",
                  textAlign: "center",
                  // lineHeight: "5px",
                }}
              >
                {/* Payment complete!  */}
                <br></br> Your itinerary design is in progress.
                <br></br>
                {/* {isLate() && "Late"} */}
              </p>
              {isReviewLate() && (
                <>
                  <p
                    style={{
                      //marginTop: "10px",
                      marginTop: "20px",
                      // //marginBottom: "10px",
                      fontSize: "18px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    Uh oh! your design job is past due. We apologize for the
                    inconvenience. Please reach out to{" "}
                    <a
                      href={`mailto: support@voyayge.com?subject=Past due #${state.tripDetails.designerDetails.jobUid}`}
                    >
                      support@voyayge.com
                    </a>
                  </p>
                  {/* <div style={{ maxWidth: "200px", margin: "0 auto" }}>
                    <button
                      style={{ width: "100%", marginTop: "10px" }}
                      className="button is-warning"
                      onClick={() => {
                        // setShowInviteForm(true)
                        ConfirmationModal({
                          onConfirm,
                          title: "Confirm",
                          text: `Are you sure you want to cancel the job?`,
                        })
                      }}
                    >
                      Cancel Design Job
                    </button>
                  </div> */}
                </>
              )}
              {!showInviteForm ? (
                isReviewLate() ? (
                  ""
                ) : (
                  <>
                    <p
                      style={{
                        //marginTop: "10px",
                        marginTop: "45px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Know someone thats been to your trip destination?{" "}
                      <br></br>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        Invite them to get their recommendations. <br></br>Your
                        designer will make every attempt to include their
                        suggestions in your plan if it matches your
                        requirements.
                      </span>
                    </p>
                    <div style={{ maxWidth: "200px", margin: "0 auto" }}>
                      <button
                        style={{ width: "100%", marginTop: "10px" }}
                        className="button is-warning"
                        onClick={() => {
                          setShowInviteForm(true)
                        }}
                      >
                        Invite
                      </button>
                    </div>
                  </>
                )
              ) : (
                showRightScreen()
              )}
            </>
          )

          break
        case "declined":
          return (
            <>
              Uh oh! {renderNameField()} wont be able to work on your travel
              plan
            </>
          )
          break

        default:
          break
      }
    } catch (err) {
      return ""
    }
  }
  const renderProfileImage = () => {
    try {
      return tripState.type === "sample-for-traveler" ? (
        <img
          className="is-rounded"
          style={{ borderRadius: "5px" }}
          src={sampledesigner}
          alt="designer profile"
        />
      ) : (
        <img
          className="is-rounded"
          style={{ borderRadius: "5px" }}
          src={
            state.tripDetails.designerDetails.designerProfile.userProfileImage
          }
          alt="designer profile"
        />
      )
    } catch (error) {
      return (
        <img
          className="is-rounded"
          src={defaultUserImage}
          alt="designer profile"
        />
      )
    }
  }

  const tripDesignerDeclined = () => {
    try {
      if (state.tripDetails.designerDetails.status === "declined") {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const getTripDays = () => {
    try {
      console.log(state.tripDetails.tripLogistics.travelDates.end.toDate())
      console.log(state.tripDetails.tripLogistics.travelDates.start.toDate())
      var totalTravelDays = Math.round(
        (state.tripDetails.tripLogistics.travelDates.end
          .toDate()
          .toUTCString() -
          state.tripDetails.tripLogistics.travelDates.start
            .toDate()
            .toUTCString()) /
          (24 * 60 * 60 * 1000) +
          1
      )
      console.log("Total travel days: ", totalTravelDays)
      return totalTravelDays
    } catch (err) {
      // console.log(
      //   moment.utc(state.tripDetails.tripLogistics.travelDates.end).toDate()
      // )
      // console.log(
      //   moment.utc(state.tripDetails.tripLogistics.travelDates.start).toDate()
      // )
      // var totalTravelDays = Math.round(
      //   (new Date(state.tripDetails.tripLogistics.travelDates.end) -
      //     new Date(state.tripDetails.tripLogistics.travelDates.start)) /
      //     (24 * 60 * 60 * 1000)
      // )
      var totalTravelDays = Math.round(
        (moment.utc(state.tripDetails.tripLogistics.travelDates.end).toDate() -
          moment
            .utc(state.tripDetails.tripLogistics.travelDates.start)
            .toDate()) /
          (24 * 60 * 60 * 1000) +
          1
      )
      console.log("Total travel days: ", totalTravelDays)
      return totalTravelDays
    }
  }

  const showSendMessage = () => {
    if (state.tripDetails) {
      if (state.tripDetails.designerDetails) {
        if (state.tripDetails.designerDetails.roomId) {
          return (
            <div
              className="link is-small is-warning"
              style={{
                textAlign: "center",
                margin: "10px",
                fontSize: "16px",
              }}
              onClick={() => {
                if (state.tripDetails.designerDetails.roomId)
                  navigate(
                    `/app/messageactivity/${state.tripDetails.designerDetails.roomId}/${state.tripDetails.designerDetails.designerUid}`
                    // "_blank"
                  )
              }}
            >
              Send a message
            </div>
          )
        }
      }
    }
  }
  return (
    <>
      <div className="columns is-centered branded-background">
        <div className="column">
          {/* <hr className="hr-branded"></hr> */}
          {tripDesignerDeclined() ? (
            <div className="columns is-vcentered">
              <div className="column is-0-desktop"></div>
              <div className="column is-12-desktop  is-6-tablet">
                {/* <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  Uh oh! {renderNameField()} wont be able to work on your travel
                  plan, sorry!
                </p> */}
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "500",
                    marginTop: "20px",
                  }}
                >
                  Please pick a new travel designer
                </p>

                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {/* <button className="button is-warning">
                  Pick a new designer
                </button> */}
                  <PickADesigner />
                </div>
              </div>
              <div className="column is-0-desktop"></div>
            </div>
          ) : (
            <div className="columns is-vcentered is-centered">
              <div className="column is-5-desktop  is-6-tablet">
                <h3
                  style={{ marginTop: "20px", textAlign: "center" }}
                  className="subtitle is-4"
                  style={{
                    marginTop: "10px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    marginTop: "20px",
                    textAlign: "center",
                    fontSize: "20px",
                    letterSpacing: ".5px",
                  }}
                >
                  Your Trip Designer
                </h3>
                <div
                  className=""
                  style={{
                    padding: "20px",
                    // border: "1px solid #ffc600",
                    borderRadius: "5px",
                  }}
                >
                  {state.tripDetails.designerDetails ? (
                    <>
                      <div className="profile-image" style={{ width: "64px" }}>
                        <figure className="image">
                          {renderProfileImage()}
                        </figure>
                      </div>
                      <div
                        className=""
                        style={{
                          marginLeft: "0px",
                          marginBottom: "5px",
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            marginTop: "10px",
                            marginBottom: "15px",
                            fontSize: "18px",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                          //  style={{ marginBottom: "0px", marginLeft: "auto" }}
                          //className="subtitle is-5 font-lato-600 data"
                        >
                          {tripState.type === "sample-for-traveler"
                            ? "Kate Haynes"
                            : renderNameField()}
                        </p>
                        <div
                          style={{
                            marginBottom: "15px",
                            // textAlign: "center",
                          }}
                        >
                          <StarRating
                            score={
                              tripState.type === "sample-for-traveler"
                                ? 5
                                : renderRating()
                            }
                            reviewCount={
                              tripState.type === "sample-for-traveler"
                                ? 34
                                : renderReviewCount()
                            }
                          />
                        </div>
                        {/* <a
              style={{ marginTop: "10px" }}
              className="button is-warning is-small is-pulled-right"
              href={`/app/userprofiles/${state.tripDetails.designerDetails.designerUid}`}
              target="_blank"
            >
              More info
            </a> */}
                      </div>
                      <div
                        style={{
                          // width: "250px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tripState.type !== "sample-for-traveler" && (
                          <div
                            className="link is-small is-warning"
                            style={{
                              textAlign: "center",
                              margin: "10px",
                              fontSize: "16px",
                            }}
                            onClick={() => {
                              window.open(
                                `/app/userprofiles/${state.tripDetails.designerDetails.designerUid}`
                                // "_blank"
                              )
                            }}
                          >
                            View Profile
                          </div>
                        )}
                        {showSendMessage()}
                      </div>
                    </>
                  ) : (
                    <div>Nobody assigned yet</div>
                  )}
                </div>
              </div>
              {/* <div className="column is-1-desktop is-1-tablet "></div> */}

              {/* <div className="column is-4-desktop is-hidden-tablet "></div> */}
            </div>
          )}
        </div>
      </div>
      <hr className="hr-branded"></hr>
      <div className="columns is-centered">
        <div
          className="column is-12-desktop is-12-tablet"
          style={{ position: "relative" }}
        >
          <h3
            style={{
              marginTop: "28px",
              textTransform: "uppercase",
              fontWeight: "600",
              marginTop: "20px",
              textAlign: "center",
              fontSize: "20px",
              letterSpacing: ".5px",
            }}
            className="subtitle is-4"
          >
            Status
          </h3>

          <div
            style={{
              padding: "20px",
              // border: "1px solid #ffc600",
              borderRadius: "5px",
            }}
          >
            {showPaymentForm ? (
              <div
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "60px",
                  cursor: "pointer",
                }}
              >
                <i
                  className="fas fa-times-circle fa-lg"
                  style={{ color: "#fec600" }}
                  onClick={() => setShowPaymentForm(false)}
                ></i>
              </div>
            ) : (
              ""
            )}
            {renderRightMessage()}
          </div>
        </div>
      </div>
    </>
  )
}

export default TripDesignerCardComponent

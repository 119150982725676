import arrayMove from "array-move"

export function tripStateReducer(draft, action) {
  switch (action.type) {
    case "updateRoomId":
      draft.designerDetails.roomId = action.data
      return
    case "setSelectedDesigner":
      // draft.selectedDesigner = action.data
      draft.designerDetails.status = "pending"
      draft.designerDetails.designerUid = action.data.uid
      draft.designerDetails.designerProfile = action.data
      return

    case "deleteActivity":
      console.log(action.itemId)
      console.log(action.dayId)
      var itemIndex = draft.itinerary[action.dayId].activities.findIndex(
        element =>
          //console.log("element.id action.itemId", element.id)
          element.id === Number(action.itemId)
      )
      console.log({ itemIndex })
      draft.itinerary[action.dayId].activities.splice(itemIndex, 1)
      return
    case "moveActivities":
      draft.itinerary[action.activeTab].activities = arrayMove(
        draft.itinerary[action.activeTab].activities,
        action.oldIndex,
        action.newIndex
      )
      return
    case "addActivity":
      //draft.itinerary[action.activeTab].activities = []
      draft.itinerary[action.activeTab].activities.push({
        id: draft.itinerary[action.activeTab].activities.length,
        dayId: action.activeTab,
        activity: `activity-${action.activeTab}-${action.data}`,
      })
      return
    case "initializeItineraries":
      var s = action.startDate
      var e = action.endDate

      for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
        a.push({ date: new Date(d), activities: [] })
      }
      draft.itinerary = a

      return

    case "setWizardProgress":
      draft.wizardProgress = action.data
      return
    case "updateTripState":
      return action.data
    case "updateTripName":
      draft.tripName = action.data
      return
    case "updateTripUid":
      draft.tripUid = action.data
      return
    case "updatePrimaryTravelerDetails":
      draft.uid = action.uid
      draft.roles = { [action.uid]: { role: "owner" } }
      draft.travelerDetails[0].uid = action.uid
      draft.travelerDetails[0].primary = true
      if (action.data.firstName === "" && action.data.lastName === "") {
        draft.travelerDetails[0].firstName = action.data.username
        draft.travelerDetails[0].lastName = ""
      } else {
        draft.travelerDetails[0].firstName = action.data.firstName
        draft.travelerDetails[0].lastName = action.data.lastName
      }

      draft.travelerDetails[0].gender = action.data.gender
      return
    case "addTravelerDetails":
      draft.travelerDetails.push(action.data)
      return
    case "setAdultTravelerCount":
      draft.adultTravelerCount = action.data
      return
    case "setKidsTravelerCount":
      draft.kidsTravelerCount = action.data
      return
    case "setTravelerAdditionalInfo":
      draft.travelerAdditionalInfo = action.data
      return
    case "updateTravelerDetails":
      const i = action.data.index
      draft.travelerDetails[i].firstName = action.data.firstName
      draft.travelerDetails[i].lastName = action.data.lastName
      draft.travelerDetails[i].gender = action.data.gender
      draft.travelerDetails[i].diet = action.data.diet
      draft.travelerDetails[i].age = action.data.age
      draft.travelerDetails[i].wheelchair = action.data.wheelchair
      draft.travelerDetails[i].additionalTravelerDetails =
        action.data.additionalTravelerDetails
      return
    case "deleteCoTravelerEntry":
      console.log("deleteCoTravelerEntry", action.data)
      draft.travelerDetails.splice(
        draft.travelerDetails.findIndex(e => e.index === action.data),
        1
      )
      return

    case "updateTripPurpose":
      draft.tripLogistics.tripsPurpose = action.data
      return
    case "removeDestination":
      draft.tripDestinations.splice(Number(action.data), 1)
      if (draft.tripDestinations.length > 0)
        draft.tripPrimaryDestination = draft.tripDestinations[0]
      else draft.tripPrimaryDestination = ""
      return
    case "updateDestination":
      // let _darray = []
      // if (action.data.value) {
      //   Object.values(action.data.value).map((val, index) => {
      //     if (val !== undefined) _darray.push(val.label)
      //   })
      //   draft.tripDestinations = _darray
      // }
      // draft.tripPrimaryDestination = draft.tripDestinations[0]
      if (
        draft.tripDestinations &&
        draft.tripDestinations.length < action.maxDestinationsCount
      ) {
        draft.tripDestinations.push(action.data)
        draft.tripPrimaryDestination = draft.tripDestinations[0]
        // draft.tripName = `Trip to ${draft.tripDestinations[0].place}`
      }
      return
    case "updateTripDestinations":
      if (draft.tripDestinations) {
        draft.tripDestinations = action.data
        draft.tripPrimaryDestination = action.data[0]
      }
      return
    case "updateTravelDates":
      draft.tripLogistics.travelDates = action.data
      return
    case "updateTravelStartDate":
      draft.tripLogistics.travelDates.start = action.data
      return
    case "updateTravelEndDate":
      draft.tripLogistics.travelDates.end = action.data
      return
    case "updateTravelDuration":
      draft.tripLogistics.travelDuration = action.data
      return
    case "updateFlexibility":
      draft.tripLogistics.travelDatesFlexible = action.data
      return
    case "updateNoSpecificDates":
      draft.tripLogistics.noSpecificTravelDatesChecked = action.data
      return
    case "decrementAdultCount":
      draft.tripLogistics.adultTravelerCount =
        draft.tripLogistics.adultTravelerCount === 1
          ? 1
          : draft.tripLogistics.adultTravelerCount - 1
      return
    case "incrementAdultCount":
      draft.tripLogistics.adultTravelerCount =
        draft.tripLogistics.adultTravelerCount + 1
      return
    case "decrementChildCount":
      draft.tripLogistics.childrenTravelerCount =
        draft.tripLogistics.childrenTravelerCount === 0
          ? 0
          : draft.tripLogistics.childrenTravelerCount - 1
      return
    case "incrementChildCount":
      draft.tripLogistics.childrenTravelerCount =
        draft.tripLogistics.childrenTravelerCount + 1
      return
    case "updateMinBudget":
      draft.tripLogistics.totalTripBudgetRange.minBudget = action.data
      return
    case "updateMaxBudget":
      draft.tripLogistics.totalTripBudgetRange.maxBudget = action.data
      return
    case "updateAccommodationPreference":
      draft.tripLogistics.accommodationPreference = action.data
      return
    case "appendAccommodationPreference":
      if (!draft.tripLogistics.accommodationPreference) {
        draft.tripLogistics.accommodationPreference = []
      }
      draft.tripLogistics.accommodationPreference.push(action.data)
      return
    case "removeAccommodationPreference":
      draft.tripLogistics.accommodationPreference.splice(action.data, 1)

      return
    case "setIsAccommodationNotRequired":
      draft.tripLogistics.isAccommodationNotRequired = action.data

      return
    case "updateTripType":
      draft.tripLogistics.tripType = action.data
      return
    case "updateAccommodationDetails":
      draft.tripLogistics.accommodationDetails = action.data
      return
    case "updateTripPacing":
      draft.tripPreferences.pacing = action.data
      return
    case "updateFoodPreference":
      draft.tripPreferences.foodPreferences = action.data
      return
    case "updateCuisinePreference":
      draft.tripPreferences.preferredCuisines = action.data
      return
    case "updateDrinkPreference":
      draft.tripPreferences.drinkPreferences = action.data
      return
    case "updateVacationActivities":
      draft.tripPreferences.preferredActivities = action.data
      return
    case "addVacationActivities":
      if (!draft.tripPreferences.preferredActivities) {
        draft.tripPreferences.preferredActivities = []
      }
      draft.tripPreferences.preferredActivities.push(action.data)
      return
    case "removeVacationActivities":
      if (!draft.tripPreferences.preferredActivities) {
        return
      }
      var itemIndex = draft.tripPreferences.preferredActivities.findIndex(
        element =>
          //console.log("element.id action.itemId", element.id)
          element.value === action.data.value
      )
      if (itemIndex !== -1)
        draft.tripPreferences.preferredActivities.splice(itemIndex, 1)
      return
    case "updateOtherTripPreferenceDetails":
      draft.tripPreferences.otherTripPreferenceDetails = action.data
      return
    case "updateTripCreatedDateTime":
      draft.tripCreatedDateTime = action.data
      return

    default:
      return
  }
}

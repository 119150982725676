import React, { useState, useEffect, useContext } from "react"
import { loadStripe } from "@stripe/stripe-js"
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js"
import { fetchFromAPI } from "../../utility/general"
import AuthUserContext from "../session/context"
import { useDashboardStateStore } from "../../State/dashboard-state/dashboard-state"
import { useGlobalStateStore } from "../../State/global-state/global-state"
import { FirebaseContext } from "../firebase"
import { analytics } from "../firebase/firebase"
import "./PaymentForm.scss"
import { useTripStateStore } from "../../State/trip-state/trip-state-store"
const key = process.env.STRIPE_PUBLISH_KEY
// console.log({ key })
const stripePromise = loadStripe(key)

function PaymentFormNewBase({
  tripDays,
  tripUid,
  tripName,
  tripPrimaryDestination,
}) {
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripDispatch] = useTripStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const stripe = useStripe()
  const elements = useElements()

  const [amountDue, setAmountDue] = useState(0)
  const [processing, setProcessing] = useState(false)
  const [clientSecret, setClientSecret] = useState("")
  const [paymentState, setPaymentState] = useState("pay")
  const [paymentErrorResponse, setPaymentErrorResponse] = useState("")
  const [promoCode, setPromoCode] = useState("")
  const [promoCodeState, setPromoCodeState] = useState("none")
  const [processingPromoCode, setProcessingPromoCode] = useState(false)
  const [promoCodeResponseMessage, setPromoCodeResponseMessage] = useState("")
  const [activePromoCodes, setActivePromoCodes] = useState([])
  const [discountRate, setDiscountRate] = useState(0)
  const [codeVerified, setCodeVerified] = useState(false)
  const [discountType, setDiscountType] = useState("")
  const [freeTravelPlan, setFreeTravelPlan] = useState(false)
  const [readyToPay, setReadyToPay] = useState(false)
  // const [amountDue, setAmountDue] = useState(0)

  const baseAmountDue = 25.0

  useEffect(() => {
    // var amount = tripDays * 20
    //Test Amount
    //setAmountDue(99)
    setAmountDue(baseAmountDue.toFixed(2))
  }, [])

  useEffect(() => {
    console.log({ state })

    if (tripState.tripLogistics) {
      if (tripState.tripLogistics.travelDuration) {
        setAmountDue(
          baseAmountDue.toFixed(2) * tripState.tripLogistics.travelDuration
        )
      }
    }
  }, [state])
  useEffect(() => {
    console.log({ elements })
  }, [elements])
  useEffect(() => {
    // console.log({ activePromoCodes })
  }, [activePromoCodes])

  const fetchSecret = async () => {
    if (tripDays > 0 && tripUid) {
      const token = await authContext.getIdToken()
      console.log({ token })
      const response = await fetchFromAPI(
        "POST",
        "api/secret",
        {
          body: {
            tripDays: tripDays,
            tripUid: tripUid,
            tripName: tripName,
            tripPrimaryDestination: tripPrimaryDestination,
            promoCode: codeVerified ? promoCode.toLowerCase() : "",
          },
        },
        token
      )
      return response
    }
  }

  const validatePromoCode = async () => {
    if (tripDays > 0 && tripUid) {
      const token = await authContext.getIdToken()
      console.log({ token })
      const response = await fetchFromAPI(
        "POST",
        "api/validatepromocode",
        {
          body: {
            promoCode: promoCode.toLowerCase(),
          },
        },
        token
      )
      return response
    }
  }
  const clearPromoCodeVariables = params => {
    setPromoCodeState("none")
    setPromoCodeResponseMessage("")
  }

  const renderPromoCodeResponse = params => {
    switch (promoCodeState) {
      case "none":
        return ""

      case "error":
        return (
          <p className="has-text-danger">Error: {promoCodeResponseMessage}</p>
        )
      case "success":
        return (
          <p className="">
            <i
              className="far fa-check fa-lg"
              style={{ color: "#fec600", marginRight: "5px" }}
            ></i>
            {promoCodeResponseMessage}
          </p>
        )

      default:
        break
    }
  }

  const handlePromoCodeSubmit = async event => {
    event.preventDefault()
    setProcessingPromoCode(true)
    if (promoCode === "" || promoCode.length < 3 || promoCode.length > 50) {
      // console.log("error")
      setProcessingPromoCode(false)
      setPromoCodeState("error")
      setPromoCodeResponseMessage("Invalid promo code")
      return
    }
    console.log("submit promo code")
    console.log({ promoCode })

    var res = await validatePromoCode()
    if (res.error) {
      console.log(res.error)
      setProcessingPromoCode(false)
      setPromoCodeResponseMessage(res.error)
      setPromoCodeState("error")
    } else {
      console.log(res)
      analytics.logEvent("promo_applied", {
        userId: `${authContext.uid}`,
        code: `${promoCode}`,
      })
      if (res.discountRate && res.type) {
        console.log("valid response")
        setDiscountRate(res.discountRate)
        setDiscountType(res.type)
        setCodeVerified(true)
        if (res.type === "quantity") {
          setPromoCodeResponseMessage(
            `Itinerary ${
              res.discountRate === 1 ? "day" : "days"
            } discount applied`
          )
          let newTripDaysAfterPromoCode = 0
          if (res.discountRate > getTripDays()) {
            // setProcessingPromoCode(false)
            // setPromoCodeState("error")
            // setPromoCodeResponseMessage("Invalid promo code")

            // return
            newTripDaysAfterPromoCode = 0
          } else {
            newTripDaysAfterPromoCode = getTripDays() - res.discountRate
          }

          let aDue = baseAmountDue * newTripDaysAfterPromoCode
          if (aDue === 0) {
            //free
            setFreeTravelPlan(true)
          } else {
            setFreeTravelPlan(false)
          }
          setAmountDue(aDue.toFixed(2))
          setPromoCodeState("success")
          setActivePromoCodes([`${promoCode}`])
        } else {
          if (res.type === "amount") {
            setPromoCodeResponseMessage(
              `${res.discountRate * 100}% discount applied`
            )
            let aDue = baseAmountDue * getTripDays()
            setAmountDue((aDue - aDue * res.discountRate).toFixed(2))
            setPromoCodeState("success")
            setActivePromoCodes([`${promoCode}`])
          } else {
            setProcessingPromoCode(false)
            setPromoCodeState("error")
            setPromoCodeResponseMessage("Invalid promo code")
          }
        }

        // setActivePromoCodes([...activePromoCodes, promoCode])
      } else {
        setPromoCodeState("error")
        setPromoCodeResponseMessage("Invalid promo code")
      }
      setProcessingPromoCode(false)
    }
    // setProcessing(false)

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardElement),
    // })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (!stripe || !elements) {
      return
    }
    console.log("submit")
    console.log({ stripe })
    console.log({ elements })
    var res = await fetchSecret()
    if (res.error || res === "error") {
      // Show error to your customer (e.g., insufficient funds)
      analytics.logEvent("payment_failed", {
        userId: `${authContext.uid}`,
        code: `${promoCode}`,
        amount: `${amountDue}`,
      })
      console.log(res.error)
      setPaymentState("error")
    } else {
      console.log(res)
      console.log(res.client_secret)
      console.log(res.tripDays)
      console.log(res.amountDue)
      console.log(res.discountRate)
      console.log(res.promoCode)
      if (res.client_secret && res.tripDays && res.amountDue) {
        console.log("valid response")
        if (amountDue * 100 === res.amountDue) {
          // double check the numbers
          const result = await stripe.confirmCardPayment(res.client_secret, {
            payment_method: { card: elements.getElement(CardElement) },
          })
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            analytics.logEvent("payment_failed", {
              userId: `${authContext.uid}`,
              code: `${promoCode}`,
              amount: `${amountDue}`,
            })
            console.log(result.error.message)
            setPaymentErrorResponse(result.error.message)
            setPaymentState("error")
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded") {
              analytics.logEvent("payment_successful", {
                userId: `${authContext.uid}`,
                code: `${promoCode}`,
                amount: `${amountDue}`,
              })
              console.log("success!")
              updateSaleToDB()
              setPaymentState("success")
              window.dataLayer.push({ event: "TRAVEL_PLAN_PURCHASED" })
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
            }
          }
        } else {
          console.log("amounts don't match")
          setPaymentState("error")
        }
      }
    }
    setProcessing(false)

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardElement),
    // })
  }
  const iframeStyles = {
    base: {
      //backgroundColor: "#fec600",
      padding: "10px",
      border: "1px solid #fec600",
      fontSmoothing: "antialiased",
      color: "#32325d",
      fontSize: "16px",
      fontStyle: "Lato",
      iconColor: "#32325d",
      fontWeight: "500",
      "::placeholder": {
        color: "#aab7c4",
        fontStyle: "Lato",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
    complete: {
      iconColor: "#cbf4c9",
    },
  }

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: false,
  }

  const updateDBFreePlan = params => {
    try {
      if (dbContext)
        dbContext
          .firestore()
          .collection("free-plans")
          .doc(`${tripState.designerDetails.jobUid}`)
          .set({
            designerUid: `${tripState.designerDetails.designerUid}`,
            tripUid: `${tripState.tripUid}`,
            initiatedOn: new Date(),
            travelerUid: `${authContext.uid}`,
            code: `${promoCode}`,
            amount: `${amountDue}`,
            tripDays: `${getTripDays()}`,
          })
          .then(() => {
            console.log("status updated successfully")
          })
          .catch(error => console.log({ error }))
    } catch (error) {
      console.log({ error })
    }
  }

  const updateSaleToDB = params => {
    try {
      if (dbContext)
        dbContext
          .firestore()
          .collection("plan-sales")
          .doc(`${tripState.designerDetails.jobUid}`)
          .set({
            designerUid: `${tripState.designerDetails.designerUid}`,
            tripUid: `${tripState.tripUid}`,
            transactionDate: new Date(),
            travelerUid: `${authContext.uid}`,
            code: `${promoCode}`,
            amount: `${amountDue}`,
            tripDays: `${getTripDays()}`,
          })
          .then(() => {
            console.log("status updated successfully")
          })
          .catch(error => console.log({ error }))
    } catch (error) {
      console.log({ error })
    }
  }
  const updateDB = params => {
    try {
      if (dbContext)
        dbContext
          .firestore()
          .collection("jobs")
          .doc(`${tripState.designerDetails.designerUid}`)
          .collection("active-jobs")
          .doc(`${tripState.designerDetails.jobUid}`)
          .update({ status: "active", paymentCompletedOn: new Date() })
          .then(() => {
            dbContext
              .firestore()
              .collection("trips")
              .doc(`${authContext.uid}`)
              .collection("active-trips")
              .doc(`${tripState.tripUid}`)
              .update({
                "designerDetails.status": "active",
                paymentCompletedOn: new Date(),
              })
              .then(() => console.log("status updated successfully"))
          })
          .catch(error => console.log({ error }))
    } catch (error) {
      console.log({ error })
    }
  }

  const removePromoCode = e => {
    setActivePromoCodes([])
    setAmountDue(baseAmountDue * getTripDays())
    clearPromoCodeVariables()
    setDiscountRate(0)
    setPromoCode("")
    setFreeTravelPlan(false)
  }

  const displayActivePromoCodes = () => {
    return (
      <>
        {activePromoCodes.length > 0 && (
          <p style={{ margin: "5px 0" }}>Active promo code:</p>
        )}
        <div>
          {activePromoCodes.map((item, index) => {
            return (
              <div className="control">
                <div className="tags has-addons">
                  <a className="tag is-warning">{item}</a>
                  <a
                    id={index}
                    className="tag is-delete"
                    onClick={removePromoCode}
                  ></a>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  const renderRightContent = () => {
    switch (paymentState) {
      case "pay":
        return (
          <>
            <div style={{ maxWidth: "400px", margin: "0 auto" }}>
              <p className="">Do you have a promo code?</p>
              <div className="field is-grouped">
                <div className="control is-expanded">
                  <input
                    className="input"
                    onChange={e => {
                      clearPromoCodeVariables()
                      if (e.currentTarget.value)
                        setPromoCode(e.currentTarget.value)
                      else setPromoCode("")
                    }}
                    value={promoCode}
                  />
                </div>
                <div className="control">
                  <button
                    // className="button is-warning is-loading"
                    className={`${
                      processingPromoCode
                        ? "button is-warning is-loading"
                        : "button is-warning"
                    }`}
                    onClick={handlePromoCodeSubmit}
                  >
                    Validate Code
                  </button>
                </div>
              </div>
              <div style={{ marginBottom: "20px" }}>
                {renderPromoCodeResponse()}
              </div>

              {displayActivePromoCodes()}
              <hr className="hr-branded"></hr>
            </div>

            {freeTravelPlan ? (
              <div style={{ maxWidth: "200px", margin: "0 auto" }}>
                <button
                  className="button is-warning"
                  style={{ width: "100%" }}
                  onClick={() => {
                    updateDBFreePlan()
                    setPaymentState("success")
                  }}
                >
                  Get my free travel plan!
                </button>
              </div>
            ) : (
              // <div style={{ maxWidth: "400px", margin: "0 auto" }}>
              //   <button className="button is-warning">
              //     Get my free travel plan!
              //   </button>
              // </div>
              <form onSubmit={handleSubmit} style={{ marginTop: "0px" }}>
                <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                  <p className="" style={{ marginBottom: "10px" }}>
                    Enter your card details:
                  </p>
                  {/* <CardNumberElement
                    options={cardElementOpts}
                    className="card-element"
                  /> */}
                  <CardElement
                    options={cardElementOpts}
                    className="card-element"
                    onChange={event => {
                      if (event && event.complete) {
                        console.log("All Fields Entered")
                        setReadyToPay(true)
                      } else {
                        setReadyToPay(false)
                      }
                    }}
                  />
                </div>
                <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                  <button
                    type="submit"
                    style={{ marginTop: "10px", width: "100%" }}
                    // disabled={!stripe & !readyToPay}
                    className={`${
                      processing
                        ? "button is-warning is-loading"
                        : "button is-warning"
                    }`}
                    disabled={!readyToPay}
                    onClick={() => {
                      setProcessing(true)
                      // fetchSecret()
                    }}
                  >
                    Pay ${amountDue}
                  </button>
                  <p className="help">
                    {" "}
                    <i className="far fa-exclamation-circle"></i> Enter card
                    details to enable Pay button
                  </p>
                </div>
              </form>
            )}
          </>
        )
        break
      case "error":
        return (
          <>
            <div style={{ maxWidth: "48px", margin: "0 auto" }}>
              <i
                className="fal fa-times-circle fa-3x"
                style={{
                  color: "rgba(255, 34, 0, 0.7)",
                  textAlign: "center",
                }}
              ></i>
            </div>
            <p
              style={{
                marginTop: "10px",
                marginBottom: "0px",
                // //marginBottom: "10px",
                fontSize: "18px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Payment Error!
            </p>
            <p
              style={{
                marginTop: "8px",
                marginBottom: "0px",
                // //marginBottom: "10px",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              {paymentErrorResponse === "" ? (
                <>
                  Please try again in sometime. If the issue persists, please
                  reach out to{" "}
                  <a href="mailto: support@voyayge.com">support@voyayge.com</a>
                </>
              ) : (
                paymentErrorResponse
              )}
            </p>
            {/* <p
              style={{
                marginTop: "5px",
                marginBottom: "0px",
                // //marginBottom: "10px",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >

            </p> */}
          </>
        )
        break
      case "success":
        updateDB()
        return (
          <>
            <div style={{ maxWidth: "40px", margin: "0 auto" }}>
              <i
                className="fal fa-check-circle fa-3x"
                style={{ color: "#00b8a9", textAlign: "center" }}
              ></i>
            </div>
            <p
              style={{
                marginTop: "10px",
                marginBottom: "0px",
                // //marginBottom: "10px",
                fontSize: "20px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Thank you!
            </p>
            {!freeTravelPlan && (
              <p
                style={{
                  marginTop: "7px",
                  marginBottom: "0px",
                  // //marginBottom: "10px",
                  fontSize: "18px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Payment Successful!
              </p>
            )}
            <div
              style={{ marginTop: "10px", maxWidth: "140px", margin: "0 auto" }}
            >
              <button
                className="button is-warning"
                style={{ width: "100%" }}
                onClick={() => {
                  dispatch({ type: "payment-complete" })
                }}
              >
                Close
              </button>
            </div>
          </>
        )
        break

      default:
        break
    }
  }
  const getTripDays = () => {
    if (tripState.tripLogistics)
      if (tripState.tripLogistics.travelDuration) {
        return tripState.tripLogistics.travelDuration
      }
  }

  const getStartDates = () => {
    try {
      let a = `${tripState.tripLogistics.travelDates.start
        .toDate()
        .toLocaleDateString()}`
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return `${new Date(
        tripState.tripLogistics.travelDates.start
      ).toLocaleDateString()}`
    }
  }
  const getEndDates = () => {
    try {
      let a = `${tripState.tripLogistics.travelDates.end
        .toDate()
        .toLocaleDateString()} `
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return `${new Date(
        tripState.tripLogistics.travelDates.end
      ).toLocaleDateString()} `
    }
  }
  return (
    <div>
      {tripDays > 0 ? (
        <>
          <div className="columns is-vcentered is-desktop">
            <div className="column is-5-desktop is-12-tablet">
              <div
                style={{
                  maxWidth: "400px",
                  margin: "0px auto",
                  lineHeight: "1.8",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "right",
                    }}
                  >
                    {/* Test Amount */}
                    {/* Total Due: <strong>$99</strong> */}
                    Custom travel plan fee per day:{" "}
                  </p>
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "right",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      ${baseAmountDue}
                    </span>
                  </p>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    {/* Test Amount */}
                    {/* Total Due: <strong>$99</strong> */}
                    Total itinerary days:
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                      }}
                    >
                      <br></br>
                      {getStartDates()} - {getEndDates()}
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "right",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#fec600",
                        marginRight: "15px",
                      }}
                    >
                      x
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {getTripDays()}
                    </span>
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      // //marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "right",
                    }}
                  >
                    {/* Test Amount */}
                    {/* Total Due: <strong>$99</strong> */}
                    Total for {getTripDays()} days:{"    "}
                  </p>
                  <p
                    style={{
                      // //marginBottom: "10px",
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "right",
                      borderTop: "1px #fec600 solid",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        // color: "#fec600",
                      }}
                    >
                      ${baseAmountDue.toFixed(2) * getTripDays()}
                    </span>
                  </p>
                </div>

                {discountRate > 0 && discountType === "amount" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      {/* Test Amount */}
                      {/* Total Due: <strong>$99</strong> */}
                      {discountRate * 100}% discount applied:
                    </p>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      - $
                      {(
                        baseAmountDue.toFixed(2) *
                        getTripDays() *
                        discountRate
                      ).toFixed(2)}
                    </p>
                  </div>
                )}
                {discountRate > 0 &&
                  discountType === "quantity" &&
                  discountRate < getTripDays() && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          marginTop: "0px",
                          marginBottom: "0px",
                          // //marginBottom: "10px",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {/* Test Amount */}
                        {/* Total Due: <strong>$99</strong> */}
                        {discountRate} {discountRate === 1 ? "day" : "days"}{" "}
                        discount applied:
                      </p>
                      <p
                        style={{
                          marginTop: "0px",
                          marginBottom: "0px",
                          // //marginBottom: "10px",
                          fontSize: "16px",
                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        - ${baseAmountDue * discountRate}
                      </p>
                    </div>
                  )}

                <p
                  style={{
                    marginTop: "7px",
                    marginBottom: "0px",
                    paddingTop: "7px",
                    // //marginBottom: "10px",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "right",
                    borderTop: "1px #fec600 solid",
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                  }}
                >
                  {/* Test Amount */}
                  {/* Total Due: <strong>$99</strong> */}
                  Total Amount Due:{"    "}
                  <span
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // //marginBottom: "10px",
                      fontSize: "18px",
                      fontWeight: "600",
                      textAlign: "right",
                    }}
                  >
                    ${amountDue}
                  </span>
                </p>
                {/* <div className="columns is-multiline is-mobile">
                  <div className="column is-6">
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Total itinerary days:
                    </p>
                  </div>
                  <div className="column is-6">
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      {tripDays}
                    </p>
                  </div>
                  <div className="column is-6">
                    {" "}
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Cost per day:
                    </p>
                  </div>
                  <div className="column is-6">
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      $20
                    </p>
                  </div>
                  <div
                    className="column is-6"
                    style={{ borderTop: "1px solid #fec600" }}
                  >
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        textAlign: "left",
                      }}
                    >
                      Total:
                    </p>
                  </div>
                  <div
                    className="column is-6"
                    style={{ borderTop: "1px solid #fec600" }}
                  >
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // //marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        textAlign: "left",
                      }}
                    >

                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="column is-1"></div> */}
            <div className="column is-7-desktop is-12-tablet">
              {renderRightContent()}
            </div>
          </div>
        </>
      ) : (
        "Error!"
      )}
      {freeTravelPlan ? "" : ""}
    </div>
  )
}

const PaymentFormNew = ({
  tripDays,
  tripUid,
  tripPrimaryDestination,
  tripName,
}) => (
  <Elements stripe={stripePromise}>
    <PaymentFormNewBase
      tripDays={tripDays}
      tripUid={tripUid}
      tripPrimaryDestination={tripPrimaryDestination}
      tripName={tripName}
    />
  </Elements>
)

export default PaymentFormNew

import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"

import "./Messages.scss"
import { v4 as uuidv4 } from "uuid"
import MessageActivityComponent from "./MessageActivityComponent"

function MessageActivityContainer() {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()

  useEffect(() => {
    dispatch({ type: "initChatMsgStore" })
  }, [])

  const getRecipientUid = () => {
    if (authContext)
      if (authContext.uid === state.tripDetails.uid) {
        //user is the traveler
        return state.tripDetails.designerDetails.designerUid
      } else return state.tripDetails.uid
  }

  return (
    <div>
      {/* {state.currentJobInfo.roomId !== undefined ? ( */}
      {state.tripDetails.designerDetails.roomId !== undefined ? (
        <MessageActivityComponent
          roomId={state.currentJobInfo.roomId}
          roomId={state.tripDetails.designerDetails.roomId}
          recipientUid={getRecipientUid()}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default MessageActivityContainer

import { dbUpdate } from "../../utility/dbCRUD"
import React, { useState, useEffect, useContext } from "react"
import "./EditableItems.scss"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import AvatarEditorWrapper from "../../components/AvatarEditor/AvatarEditorWrapper"

const EditableProfileImageField = ({ profileImageURL, doUpdateImage }) => {
  const [avatarEdit, setAvatarEdit] = useState(false)
  const [dbStorageURL, setdbStorageURL] = useState("")

  const [userPhotoURL, setUserPhotoURL] = useState("")
  const authUser = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)

  useEffect(() => {
    if (dbStorageURL !== "") {
      var _data = { userProfileImage: dbStorageURL }

      try {
        dbUpdate(dbContext, "users", authUser.uid, _data)
      } catch (e) {
        console.log(e)
      }
    }
  }, [dbStorageURL])

  //Avatar image editor closed/canceled
  let onCancel = () => {
    setAvatarEdit(false)
  }
  //Avatar image updated
  let onSave = newUserProfileImageURL => {
    try {
      setUserPhotoURL(newUserProfileImageURL)
      console.log({ newUserProfileImageURL })

      setAvatarEdit(false)
      var file = newUserProfileImageURL
      const regexp = /\/(([\s\S]*?));/gm
      var result = regexp.exec(file)
      console.log({ result })
      if (
        (result !== null && result[1] === "png") ||
        result[1] === "jpg" ||
        result[1] === "jpeg"
      ) {
        var storageRef = dbContext.storage().ref()
        var uploadTask = storageRef
          .child(`user-data/${authUser.uid}/profile/profileImage.` + result[1])
          .putString(file, "data_url")

        uploadTask.on(
          dbContext.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log("Upload is " + progress + "% done")
            switch (snapshot.state) {
              case dbContext.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused")
                break
              case dbContext.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running")
                break
            }
          },
          function(error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break
              case "storage/canceled":
                // User canceled the upload
                break
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break
            }
          },
          function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                console.log("File available at", downloadURL)
                if (doUpdateImage) doUpdateImage(downloadURL)
                //setdbStorageURL(downloadURL)
              })
              .catch(function(error) {
                console.log("error updating picture", error)
              })
          }
        )
      } else {
        console.log()
      }
    } catch (err) {
      console.log(err)
    }
    //Push the new user profile image back to remote db
    //    authUser.updateProfile({photo})
  }

  useEffect(() => {
    // if (profileImageURL.profileImageURL !== "") {
    //   console.log({ profileImageURL })
    //   var reader = new FileReader()
    //   reader.addEventListener("load", () => {
    //     setUserPhotoURL(reader.result)
    //     console.log(reader.result)
    //   })
    //   reader.readAsDataURL(profileImageURL.profileImageURL)
    //   console.log({ profileImageURL })
    // }
    // var canvas = document.getElementById("voyage-avatar")
    // console.log({ canvas })
    // var dataUrl = canvas.toDataURL()
    // setUserPhotoURL(dataUrl)
    // console.log({ dataUrl })
    console.log({ profileImageURL })
    setUserPhotoURL(profileImageURL)
  }, [profileImageURL])

  useEffect(() => {}, [avatarEdit])

  return (
    <>
      {avatarEdit && userPhotoURL ? (
        <div className="columns">
          <div className="column is-1-desktop is-4-tablet is-hidden-mobile "></div>
          <div className="column is-10-desktop is-4-tablet ">
            {" "}
            <AvatarEditorWrapper
              defaultImage={userPhotoURL}
              onCancel={onCancel}
              onSave={onSave}
            />
          </div>
          <div className="column is-1-desktop is-4-tablet is-hidden-mobile "></div>
        </div>
      ) : (
        <>
          <div className="columns">
            <div className="column is-1-desktop is-4-tablet is-hidden-mobile "></div>
            <div className="column is-10-desktop is-4-tablet ">
              <figure className="image profile-image  is-vertical-center push-bottom-margin">
                <a onClick={() => setAvatarEdit(true)}>
                  <img
                    id="voyage-avatar"
                    style={{ marginBottom: "5px", borderRadius: "5px" }}
                    className="is-rounded profile-image"
                    src={userPhotoURL}
                  />
                  {/* <span className="editIcon-outer-line"></span> */}
                  {/* <img className="is-rounded editIcon" src={editIcon} /> */}
                  <a
                    align="center"
                    style={{
                      margin: "5px",
                      width: "auto",
                      fontSize: "12px",
                      // textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      // float: "right",
                    }}
                    // className="button is-warning has-text-weight-medium is-small"
                    className="link"
                  >
                    <>Edit Picture</>
                  </a>
                </a>
              </figure>
            </div>
            <div className="column is-1-desktop is-4-tablet is-hidden-mobile "></div>
          </div>
        </>
      )}
    </>
  )
}

export default EditableProfileImageField

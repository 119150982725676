import React, { useState, useEffect, useRef } from "react"
import Select from "react-select"
import { dietaryPreferences } from "../../constants/data/general.js"
import { useTripStateStore } from "../../State/trip-state/trip-state-store"

const EditDetails = ({ setEditCancel, EditIndex }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [age, setAge] = useState("")
  const [gender, setGender] = useState("")
  const [diet, setDiet] = useState("")
  const [defaultDiet, setDefaultDiet] = useState("")
  const [wheelchair, setWheelchair] = useState("No")
  const [additionalDetails, setAdditionalDetails] = useState("")
  const [showForm, setShowForm] = useState(false)
  const [state, dispatch] = useTripStateStore()

  const clearAndClose = () => {
    setFirstName("")
    setLastName("")
    setAge("")
    setGender("")
    setDiet("")
    setWheelchair("No")
    setAdditionalDetails("")
    setEditCancel()
  }

  const updateState = () => {
    let _tDetails = {
      firstName: firstName,
      lastName: lastName,
      index: EditIndex,
      gender: gender,
      diet: diet,
      age: age,
      wheelchair: wheelchair,
      additionalTravelerDetails: additionalDetails,
    }
    console.log({ _tDetails })

    dispatch({ type: "updateTravelerDetails", data: _tDetails })
    clearAndClose()
  }

  const editDetailsRef = useRef(null)

  const scrollToRef = ref => {
    try {
      window.scrollTo(0, ref.current.offsetTop)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    console.log("%cTrip-State", "color: #ffc600; font-size: 18px", { state })
  }, [state])

  useEffect(() => {
    if (showForm === true) scrollToRef(editDetailsRef)
  }, [showForm])

  useEffect(() => {
    console.log({ EditIndex })
    if (EditIndex >= 0) {
      const {
        firstName,
        lastName,
        gender,
        diet,
        age,
        wheelchair,
        additionalTravelerDetails,
      } = state.travelerDetails[EditIndex]
      setFirstName(firstName)
      setLastName(lastName)
      setAge(age)
      setGender(gender)
      setDiet(diet)
      setDefaultDiet(dietaryPreferences.find(element => element.label === diet))
      setWheelchair(wheelchair)
      setAdditionalDetails(additionalTravelerDetails)
      setShowForm(true)
    }
  }, [EditIndex])

  const flexibleButton = (
    <>
      <button
        name="Yes"
        onClick={() => {
          setWheelchair("Yes")
        }}
        className={`button ${wheelchair === "Yes" ? "is-warning" : ""}`}
      >
        Yes
      </button>{" "}
      <button
        name="No"
        onClick={() => {
          setWheelchair("No")
        }}
        className={`button ${wheelchair === "Yes" ? "" : "is-warning"}`}
      >
        No
      </button>{" "}
    </>
  )

  return (
    <>
      {showForm && (
        <div className="columns">
          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
          <div className="column is-8-desktop is-10-tablet is-12-mobile">
            <div className="columns is-multiline">
              <div
                className="column is-6-desktop is-6-tablet"
                ref={editDetailsRef}
              >
                <div className="field">
                  <label className="label">First name:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={e => {
                        setFirstName(e.currentTarget.value)
                      }}
                      disabled={EditIndex === "0" ? true : false}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-6-desktop is-6-tablet">
                <div className="field">
                  <label className="label">Last name:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={e => {
                        setLastName(e.currentTarget.value)
                      }}
                      disabled={EditIndex === "0" ? true : false}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-3-desktop is-3-tablet">
                <div className="field">
                  <label className="label">Age:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="number"
                      //placeholder="Enter traveler's age"
                      value={age}
                      onChange={e => {
                        setAge(e.currentTarget.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-3-desktop is-3-tablet">
                <div className="field">
                  <label className="label">Gender:</label>
                  <div className="control is-expanded">
                    <Select
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                      }}
                      defaultValue={{
                        value: `${gender}`,
                        label: `${gender}`,
                      }}
                      className="basic-single"
                      menuPortalTarget={document.body}
                      classNamePrefix="select"
                      isSearchable="true"
                      //isClearable="true"
                      onChange={(value, actionMeta) => {
                        if (value) setGender(value.label)
                      }}
                      options={[
                        { value: "male", label: "Male" },
                        { value: "female", label: "Female" },
                        { value: "nonbinary", label: "Non-binary" },
                        {
                          value: "declinetoanswer",
                          label: "Decline to answer",
                        },
                      ]}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#fef0bf",
                          primary: "#fed43f",
                          //neutral0: "#424242",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-6-desktop is-6-tablet">
                {" "}
                <div className="field">
                  <label className="label">Dietary Restrictions:</label>

                  <div className="control is-expanded">
                    <Select
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                      }}
                      defaultValue={dietaryPreferences.find(
                        element => element.label === diet
                      )}
                      className="basic-single"
                      menuPortalTarget={document.body}
                      classNamePrefix="select"
                      isSearchable="true"
                      isClearable="true"
                      onChange={(value, actionMeta) => {
                        if (value) setDiet(value.label)
                      }}
                      options={dietaryPreferences}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#fef0bf",
                          primary: "#fed43f",
                          //neutral0: "#424242",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-12-desktop is-12-tablet">
                <div className="field">
                  <label className="label">Wheelchair Access:</label>
                  <div className="control is-expanded">
                    <div className="buttons has-addons are-small">
                      {flexibleButton}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="column is-12-desktop is-12-tablet">
                <hr className="hr-branded-half"></hr>
              </div> */}
              <div className="column is-12-desktop is-12-tablet">
                {" "}
                <p>
                  Any other relevant details you would like to provide us below
                  for this traveler?
                </p>
                <textarea
                  value={additionalDetails}
                  className="textarea"
                  placeholder="Additional details..."
                  onChange={e => {
                    setAdditionalDetails(e.currentTarget.value)
                  }}
                ></textarea>
              </div>
              <div className="column is-12-desktop is-12-tablet">
                <div className="columns is-mobile">
                  <div className="column is-6-desktop is-6-tablet  is-6-mobile">
                    <button
                      onClick={() => {
                        setEditCancel()
                      }}
                      className="button is-light"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="column is-6-desktop is-6-tablet  is-6-mobile">
                    <button
                      onClick={() => {
                        updateState()
                      }}
                      className="button is-warning"
                      style={{ float: "right" }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>

              <div className="column is-three-quarters">
                <div className="control margin-top-20px">
                  <div className="field is-grouped is-grouped-right">
                    <p className="control"></p>
                    <p className="control"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        </div>
      )}
    </>
  )
}

export default EditDetails

import React from "react"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

export const ConfirmationModal = ({
  onConfirm,
  title,
  text,
  sendBackValue,
  cancelText,
  confirmationText,
  onExit,
  hideCancel = false,
}) => {
  console.log({ onConfirm })
  console.log({ cancelText })
  console.log({ confirmationText })

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className="box"
          style={{
            width: "400px",
            //height: "200px",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
          }}
        >
          <p style={{ fontSize: "20px", padding: "10px", fontWeight: "500" }}>
            {title}
          </p>
          <p style={{ fontSize: "16px", padding: "10px" }}>{text}</p>
          {!hideCancel && (
            <button
              className="button is-warning is-inverted"
              style={{ width: "160px", padding: "10px", margin: "10px" }}
              onClick={() => {
                if (onExit) onExit()
                onClose()
              }}
            >
              {cancelText ? cancelText : "Cancel"}
            </button>
          )}
          <button
            className="button is-warning"
            style={{ width: "160px", padding: "10px", margin: "10px" }}
            onClick={() => {
              onConfirm(sendBackValue)
              onClose()
            }}
          >
            {confirmationText ? confirmationText : "Yes"}
          </button>
        </div>
      )
    },
  })
}

const setInputValue = newValue => {
  try {
    document.getElementById("ConfirmationModalWithInputField").value = newValue
  } catch (error) {}
}

export const ConfirmationModalWithInputField = ({
  onConfirm,
  title,
  text,
  sendBackValue,
  cancelText,
  confirmationText,
  onExit,
  inputFieldValue,
  hideCancel = false,
}) => {
  console.log({ onConfirm })
  console.log({ cancelText })
  console.log({ inputFieldValue })
  let newValue = ""
  setTimeout(() => {
    setInputValue(inputFieldValue)
  }, 500)

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className="box"
          style={{
            width: "400px",
            //height: "200px",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
          }}
        >
          <p style={{ fontSize: "20px", padding: "10px", fontWeight: "500" }}>
            {title}
          </p>
          <p style={{ fontSize: "16px", padding: "10px" }}>{text}</p>
          <input
            className="input"
            type="text"
            id="ConfirmationModalWithInputField"
            // value={newValue}
            style={{ marginTop: "5px", marginBottom: "10px" }}
            onChange={e => {
              console.log(e.currentTarget.value)
              newValue = e.currentTarget.value
              console.log({ newValue })
              setInputValue(newValue)
            }}
          />
          {!hideCancel && (
            <button
              className="button is-warning is-inverted"
              style={{ width: "160px", padding: "10px", margin: "10px" }}
              onClick={() => {
                if (onExit) onExit()
                onClose()
              }}
            >
              {cancelText ? cancelText : "Cancel"}
            </button>
          )}
          <button
            className="button is-warning"
            style={{ width: "160px", padding: "10px", margin: "10px" }}
            onClick={() => {
              onConfirm(sendBackValue, newValue)
              onClose()
            }}
          >
            {confirmationText ? confirmationText : "Yes"}
          </button>
        </div>
      )
    },
  })
}

import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"

export const ChecklistCard = ({ data, onRemove, canEdit }) => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  const [inEditMode, setInEditMode] = useState(true)
  const [toggleStar, setToggleStar] = useState(false)
  const [toggleCheck, setToggleCheck] = useState(false)
  const [checkListItems, setCheckListItems] = useState("")

  useEffect(() => {
    console.log({ data })
  }, [data])

  const keyChanged = (e, hits) => {
    console.log(e.keyCode)
    try {
      switch (e.keyCode) {
        case 27: // ESC
          dispatch({
            type: "setChecklistItemInEditMode",
            index: e.currentTarget.id,
            data: false,
          })
          return

        case 13: //ENTER
        case 9:
          dispatch({
            type: "saveChecklistItem",
            index: e.currentTarget.id,
          })
          dispatch({ type: "tripChecklistSyncRequired", data: true })
          break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className="checklist-container">
      {data.inEditMode ? (
        <>
          <input
            style={{
              fontSize: "14px",
              boxShadow: "none",
              border: "none",
              borderBottom: "1px solid rgba(78,78,78,0.2",
              borderRadius: "0px",
              paddingLeft: "0px",
            }}
            value={
              state.currentJobInfo.tripChecklist[data.index]
                ? state.currentJobInfo.tripChecklist[data.index].item
                : ""
            }
            id={data.index}
            type="text"
            className="input"
            placeholder="Type your task here..."
            onKeyDown={keyChanged}
            onChange={e => {
              dispatch({
                type: "checklistItemData",
                data: e.currentTarget.value,
                index: e.currentTarget.id,
              })
            }}
          />
          <button
            id={data.index}
            className="button is-text"
            style={{ fontSize: "12px" }}
            onClick={e => {
              dispatch({
                type: "setChecklistItemInEditMode",
                index: e.currentTarget.id,
                data: false,
              })
            }}
          >
            Cancel
          </button>
          <button
            id={data.index}
            className="button is-warning"
            style={{ fontSize: "12px" }}
            onClick={e => {
              dispatch({
                type: "saveChecklistItem",
                index: e.currentTarget.id,
              })
              dispatch({ type: "tripChecklistSyncRequired", data: true })
            }}
          >
            Save
          </button>
        </>
      ) : (
        <>
          {/* <i
      className="far fa-check-square fa-2x"
      style={{ color: "#ffc600" }}
    ></i> */}
          <div>
            {canEdit ? (
              ""
            ) : (
              <a
                className="no-decoration"
                onClick={e => setToggleCheck(!toggleCheck)}
              >
                <i
                  className={`${
                    toggleCheck
                      ? "far fa-check-square fa-lg"
                      : "far fa-square fa-lg"
                  }`}
                  style={{ color: "#ffc600" }}
                ></i>
              </a>
            )}
            {/* <i className="far fa-square fa-1x" style={{ color: "#ffc600" }}></i> */}
          </div>
          <div className="checklist-item">
            <p style={{ fontSize: "14px", float: "left" }}>{`${data.item}`}</p>
            {canEdit ? (
              <>
                <a
                  id={data.index}
                  className="is-pulled-right"
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                    fontSize: "14px",
                  }}
                  onClick={e => {
                    dispatch({
                      type: "setChecklistItemInEditMode",
                      index: e.currentTarget.id,
                      data: true,
                    })
                  }}
                >
                  Edit
                </a>
                <a
                  id={data.index}
                  className="is-pulled-right"
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                    fontSize: "14px",
                  }}
                  onClick={onRemove}
                >
                  Remove
                </a>
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            {canEdit ? (
              ""
            ) : (
              <a
                className="no-decoration"
                onClick={e => setToggleStar(!toggleStar)}
              >
                <i
                  className={`${
                    toggleStar
                      ? "fas fa-star red-star fa-1x"
                      : "far fa-star fa-1x"
                  }`}
                  style={{ color: "#ffc600" }}
                ></i>
              </a>
            )}
          </div>
        </>
      )}
    </div>
  )
}

import arrayMove from "array-move"
import { v4 as uuidv4 } from "uuid"
import { splitStringCombos } from "../../utility/general"
import { sortBy } from "lodash"
const findItemIndex = (collection, index) => {
  return collection.findIndex(item => item.index === Number(index))
}

export function dashboardStateReducer(draft, action) {
  switch (action.type) {
    case "payment-complete":
      draft.tripDetails.designerDetails.status = "active"
      return

    case "reviewSubmitted":
      draft.tripDetails.designerReviewed = true
      return
    case "setSunriseTime":
      draft.tempRecommendations.sunriseTime = action.data
      return
    case "setSunsetTime":
      draft.tempRecommendations.sunsetTime = action.data
      return
    case "setHighTemp":
      draft.tempRecommendations.tempHigh = action.data
      return
    case "setLowTemp":
      draft.tempRecommendations.tempLow = action.data
      return
    case "setPrecipitation":
      draft.tempRecommendations.precipitation = action.data
      return
    case "updateAccommodation1":
      draft.tempRecommendations.accommodation1 = action.data
      return
    case "updateAccommodation2":
      draft.tempRecommendations.accommodation2 = action.data
      return
    case "updateAccommodation3":
      draft.tempRecommendations.accommodation3 = action.data
      return
    case "updateAccommodation4":
      draft.tempRecommendations.accommodation4 = action.data
      return
    case "updateAccommodation5":
      draft.tempRecommendations.accommodation5 = action.data
      return
    case "updateAccommodation6":
      draft.tempRecommendations.accommodation6 = action.data
      return
    case "updateAccommodationDetails":
      draft.tempRecommendations[`accommodationDetails${action.index}`] =
        action.data
      return

    case "updateAccommodationCount":
      draft.tempRecommendations.accommodationCount = action.data
      return

    case "updateTravelDates":
      draft.tripDetails.travelDates = {
        start: action.startDate,
        end: action.endDate,
      }
      draft.tripDetails.tripLogistics.travelDates = {
        start: action.startDate,
        end: action.endDate,
      }
      return

    case "initializeTempRecommendations":
      if (draft.currentJobInfo.recommendations !== undefined) {
        draft.tempRecommendations = draft.currentJobInfo.recommendations
      } else {
        draft.tempRecommendations = {
          generalInfo: " ",
          sunriseTime: "",
          sunsetTime: "",
          accommodation1: "",
          accommodation2: "",
          accommodation3: "",
          accommodation4: "",
          accommodation5: "",
          accommodation6: "",
        }
      }
      draft.recommendationRequiresSync = false
      return
    case "recommendationsSyncTemp":
      draft.currentJobInfo.recommendations = draft.tempRecommendations
      return
    case "updateGeneralInfo":
      draft.tempRecommendations.generalInfo = action.data
      return
    case "recommendationsInProgress":
      draft.currentJobInfo.recommendationsStatus = "in-progress"
      return
    case "recommendationsComplete":
      draft.currentJobInfo.recommendationsStatus = "complete"
      return
    case "recommendationsDBSyncRequired":
      draft.recommendationRequiresSync = action.data
      return

    case "updateRecommendations":
      draft.currentJobInfo.recommendations = action.data
      draft.tempRecommendations = action.data
      return

    //Notification viewer reducers
    case "deleteNotification":
      var itemIndex = draft.notifications.findIndex(
        item => item.messageId === action.data
      )
      if (itemIndex !== -1) draft.notifications.splice(itemIndex, 1)
      return
    case "updateNotifications":
      draft.notifications.push(action.data)
      return
    case "initNotifications":
      draft.notifications = []
      return
    //Message viewer reducers

    case "updateMessages":
      console.log("Data: ", action.data)
      console.log("Meta: ", action.meta)
      draft.messagesViewSnapshot.push({ ...action.data, ...action.meta })
      draft.messagesViewSnapshot.sort((a, b) => {
        if (a.sentAt < b.sentAt) return 1
        if (a.sentAt > b.sentAt) return -1
      })
      return

    case "initMessages":
      draft.messagesViewSnapshot = []
      return
    //Messages Tab Reducers

    case "updateChatMsgPush":
      draft.messageStore.push(action.data)
      return
    case "updateChatMsgUnShift":
      draft.messageStore.unshift(action.data)
      //draft.messageStore.push(action.data)
      return
    case "initChatMsgStore":
      draft.messageStore = []
      return
    // Reservation Tab reducers

    case "setReservationTempForEdit":
      var itemIndex = findItemIndex(
        draft.currentJobInfo.tripReservations,
        action.index
      )
      if (itemIndex !== -1) {
        draft.currentJobInfo.tripReservationTemp =
          draft.currentJobInfo.tripReservations[itemIndex]
        draft.currentJobInfo.tripReservationEditExisting = true
      }
      return
    case "deleteReservation":
      var itemIndex = findItemIndex(
        draft.currentJobInfo.tripReservations,
        action.index
      )
      if (itemIndex !== -1) {
        draft.currentJobInfo.tripReservations.splice(itemIndex, 1)
      }
      return
    case "saveReservationChanges":
      var itemIndex = findItemIndex(
        draft.currentJobInfo.tripReservations,
        action.index
      )
      if (itemIndex !== -1) {
        draft.currentJobInfo.tripReservations[itemIndex] =
          draft.currentJobInfo.tripReservationTemp
      }
      //It is important to clear the Temp location here for useEffect to observe the changes on next edit press.
      draft.currentJobInfo.tripReservationTemp = ""
      return

    case "addReservation":
      //set the index value and push to the list
      draft.currentJobInfo.tripReservationTemp.index =
        draft.currentJobInfo.tripReservations.length
      draft.currentJobInfo.tripReservations.push(
        draft.currentJobInfo.tripReservationTemp
      )
      //It is important to clear the Temp location here for useEffect to observe the changes on next edit press.
      draft.currentJobInfo.tripReservationTemp = ""
      return

    case "updateReservationDate":
      draft.currentJobInfo.tripReservationTemp.reservationDate = action.data
      return
    case "updateReservationTime":
      draft.currentJobInfo.tripReservationTemp.reservationTime = action.data
      return
    case "updateReservationDateTime":
      draft.currentJobInfo.tripReservationTemp.dateTime = action.data
      return
    case "updateAdditionalNote":
      draft.currentJobInfo.tripReservationTemp.additionalNote = action.data
      return
    case "updateConfirmationNumber":
      draft.currentJobInfo.tripReservationTemp.confirmationNumber = action.data
      return
    case "updateVenueName":
      draft.currentJobInfo.tripReservationTemp.name = action.data
      return
    case "clearReservationTempItem":
      draft.currentJobInfo.tripReservationTemp = ""
      return
    case "initializeReservationTempItem":
      draft.currentJobInfo.tripReservationTemp = {
        index: draft.currentJobInfo.tripReservations.length,
        name: "  ",
        dateTime: new Date(),
        starred: false,
        complete: false,
        additionalNote: "  ",
        inEditMode: true,
        confirmationNumber: "",
        externalUrl: "",
      }
      draft.currentJobInfo.tripReservationEditExisting = false
      return

    case "reservationsComplete":
      draft.currentJobInfo.reservationsStatus = "complete"
      return
    case "reservationsInProgress":
      draft.currentJobInfo.reservationsStatus = "in-progress"
      return

    case "tripReservationSyncRequired":
      draft.currentJobInfo.tripReservationSyncRequired = action.data
      return
    case "flightDetailsSyncRequired":
      draft.currentJobInfo.flightDetailsSyncRequired = action.data
      return
    case "accommodationDetailsSyncRequired":
      draft.currentJobInfo.accommodationDetailsSyncRequired = action.data
      return
    case "rentalCarDetailsSyncRequired":
      draft.currentJobInfo.rentalCarDetailsSyncRequired = action.data
      return
    case "tasksSyncRequired":
      draft.currentJobInfo.tasksSyncRequired = action.data
      return

    // Traveler Checklist Tab reducers
    case "tripChecklistInProgress":
      draft.currentJobInfo.tripChecklistStatus = "in-progress"
      draft.currentJobInfo.tripChecklist = []
      return
    case "tripChecklistComplete":
      draft.currentJobInfo.tripChecklistStatus = "complete"
      return
    case "tripChecklistSyncRequired":
      draft.currentJobInfo.tripChecklistSyncRequired = action.data
      return
    case "deleteChecklistItem":
      // console.log(.)
      var itemIndex = findItemIndex(
        draft.currentJobInfo.tripChecklist,
        action.index
      )
      if (itemIndex !== -1) {
        draft.currentJobInfo.tripChecklist.splice(itemIndex, 1)
      }
      return
    case "saveChecklistItem":
      var itemIndex = findItemIndex(
        draft.currentJobInfo.tripChecklist,
        action.index
      )
      if (itemIndex !== -1) {
        draft.currentJobInfo.tripChecklist[itemIndex].inEditMode = false
      }
      return
    case "setChecklistItemInEditMode":
      var itemIndex = findItemIndex(
        draft.currentJobInfo.tripChecklist,
        action.index
      )
      if (itemIndex !== -1) {
        draft.currentJobInfo.tripChecklist[itemIndex].inEditMode = action.data
      }
      return
    case "checklistItemData":
      var itemIndex = findItemIndex(
        draft.currentJobInfo.tripChecklist,
        action.index
      )
      if (itemIndex !== -1) {
        draft.currentJobInfo.tripChecklist[itemIndex].item = action.data
      }
      return
    case "addChecklistItem":
      draft.currentJobInfo.tripChecklist.push({
        index: draft.currentJobInfo.tripChecklist.length,
        item: "",
        starred: false,
        complete: false,
        additionalNote: "  ",
        inEditMode: true,
      })
      return
    case "setActiveDay":
      try {
        draft.activeDay = action.data
      } catch (err) {}
      break
    case "updateDayStats":
      draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].daysStats = {
        totalSightseeingTime: action.totalSightseeingTime,
        totalCommuteTime: action.totalCommuteTime,
        totalDiningTime: action.totalDiningTime,
        totalActivitiesTime:
          action.totalSightseeingTime +
          action.totalCommuteTime +
          action.totalDiningTime,
      }
      return
    case "updateDayDestination":
      draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].daysDestination = action.data
      break
    case "deleteAddCommute":
      // Delete node. Skip delete is the commute node is populated and let the user delete it manually.
      if (
        /// Check if the last node is an "Add Commute" button, if so delete.
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .activities[action.index - 1].activity.commuteMode !== "" ||
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .activities[action.index].activity.commuteMode !== ""
      ) {
      } else {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.splice(action.index, 1)
      }
      return
    case "deleteBlock":
      try {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.splice(action.index, 1)
      } catch (err) {
        console.log(err)
      }
      return
    case "insertNewCommute":
      // //Get the length
      // var a =
      //   draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
      //     .activities.length

      // /// Check if the first node if its a "Add Commute" button, if so delete and adjust the index
      // if (
      //   draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
      //     .activities[0].activity.activityType === "commute" &&
      //   draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
      //     .activities[0].activity.commuteMode === ""
      // ) {
      //   console.log(
      //     "@$%^@#$@#%@#$%@#$%@#%^ Found rough one at the beginning!, deleting it!"
      //   )
      //   draft.currentJobInfo.designerWorkspace.itinerary[
      //     action.activeTab
      //   ].activities.splice(0, 1)
      //   //Adjust index to compensate for the deleted item.
      //   action.index = action.index - 1
      // } else {
      //   if (
      //     /// Check if the last node is an "Add Commute" button, if so delete.
      //     draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
      //       .activities[a - 1].activity.activityType === "commute" &&
      //     draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
      //       .activities[a - 1].activity.commuteMode === ""
      //   ) {
      //     console.log(
      //       "@$%^@#$@#%@#$%@#$%@#%^ Found rough one at the end!, deleting it!"
      //     )
      //     draft.currentJobInfo.designerWorkspace.itinerary[
      //       action.activeTab
      //     ].activities.splice(a - 1, 1)
      //   }
      // }
      // Add the node the required.
      draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].activities.splice(action.index, 0, {
        activity: {
          activityType: "commute",
          commuteMode: "",
          commuteDurationHours: 0,
          commuteDurationMinutes: 0,
          activityUid: uuidv4(),
        },
        dayId: action.activeTab,
        id: uuidv4(),
        // draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
        //   .activities.length,
      })

      return
    case "updateActivityScheduleTimes":
      draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].activities[action.index].activity.activityStartTime =
        action.activityStartTime

      draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].activities[action.index].activity.activityEndTime =
        action.activityEndTime

      return
    case "prepareActivityScratchPad":
      draft.activityScratchPad = action.data
      return
    case "syncSchedule":
      try {
        if (draft.currentJobInfo.designerWorkspace === undefined) {
          draft.currentJobInfo.designerWorkspace = {}
        }
        draft.currentJobInfo.designerWorkspace.updateRequired = action.data
      } catch (err) {}
      return
    case "syncCommute":
      draft.currentJobInfo.designerWorkspace.updateCommute = action.data
      return
    case "syncDB":
      draft.currentJobInfo.designerWorkspace.updateDB = action.data
      return
    case "syncStats":
      draft.currentJobInfo.designerWorkspace.updateStats = action.data
      return
    case "updateSearchResults":
      draft.searchResults = []
      draft.searchResults = action.data
      return
    case "createActivityUid":
      draft.activityScratchPad.activityUid = uuidv4()
      return
    case "createActivityUidBYOT":
      let id = `${uuidv4()}-byot`
      draft.activityScratchPad.activityUid = id
      return
    case "setActivityTypeBYOT":
      draft.activityScratchPad.byot = true
      return
    case "updateActivityScratchPad":
      draft.activityScratchPad = action.data
      return
    case "duplicateActivityAndUpdateScratchPad":
      draft.activityScratchPad = action.data
      let newId = uuidv4()
      draft.activityScratchPad.activityUid = newId
      draft.activityScratchPad.objectID = ""
      // draft.activityScratchPad.activityName = `Copy of ${draft.activityScratchPad.activityName}`
      draft.activityScratchPad.activityName = `` // Delete the activity name to prevent creation of same activities

      return
    case "clearActivityScratchPad":
      draft.activityScratchPad = {}
      return
    case "updateActivityEntryFee":
      draft.activityScratchPad.activityEntryFee = action.data
      return
    case "updateActivityMenuURL":
      draft.activityScratchPad.activityMenuURL = action.data
      return
    case "updateActivityDescription":
      draft.activityScratchPad.activityDescription = action.data
      return
    // case "updateActivityMenuURL":
    //   draft.activityScratchPad.menuURL = action.data
    //   return

    case "updateActivityType":
      draft.activityScratchPad.activityType = action.data
      return
    case "updateActivityImage":
      draft.activityScratchPad.activityImageData = action.data.image
      draft.activityScratchPad.activityImageId = action.data.imageId
      draft.activityScratchPad.activityImageSource = action.data.imageSource
      return

    case "updateCommuteMode":
      draft.activityScratchPad.commuteMode = action.data
      return
    case "updateActivityEntryFee":
      draft.activityScratchPad.activityEntryFee = action.data
      return
    case "updateActivityName":
      draft.activityScratchPad.activityName = action.data
      //disabling this as this is no longer needed
      // draft.activityScratchPad.activityNameSplitString = splitStringCombos(
      //   action.data
      // )
      return
    case "updateActivityTags":
      let _darray = []
      if (action.data.value) {
        Object.values(action.data.value).map((val, index) => {
          console.log({ val })
          if (val.label !== undefined) _darray.push(val.label)
        })
        draft.activityScratchPad.activityTags = _darray
      } else {
        draft.activityScratchPad.activityTags = []
      }
      return
    case "updateActivityAddress":
      draft.activityScratchPad.activityAddress = action.data
      return
    case "updateActivityPhone":
      draft.activityScratchPad.activityPhone = action.data
      return
    case "updateActivityDurationHours":
      draft.activityScratchPad.activityDurationHours = action.data
      return
    case "updateActivityDurationMinutes":
      draft.activityScratchPad.activityDurationMinutes = action.data
      return
    case "updateCommuteAdditionalInfo":
      draft.activityScratchPad.commuteAdditionalInfo = action.data
      return
    case "updateActivityPersonalPoints":
      draft.activityScratchPad.personalPoints = action.data
      return
    case "updateActivityURL1":
      draft.activityScratchPad.activityURL1 = action.data
      return
    case "updateActivityURL2":
      draft.activityScratchPad.activityURL2 = action.data
      return
    case "updateActivityURL3":
      draft.activityScratchPad.activityURL3 = action.data
      return
    case "updateActivityURL4":
      draft.activityScratchPad.activityURL4 = action.data
      return
    case "updateDaysActivityStatTime":
      {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.index
        ].dayScheduleStartTime = action.data
      }
      return

    case "deleteBackupActivity":
      draft.currentJobInfo.designerWorkspace.itinerary[
        action.dayId
      ].backupActivities.splice(action.itemIndex, 1)
      return
    case "toggleSelectionForItineraryItem":
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
          .activities[action.itemIndex].activity.selected === undefined
      )
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayId
        ].activities[action.itemIndex].activity.selected = true
      draft.currentJobInfo.designerWorkspace.itinerary[action.dayId].activities[
        action.itemIndex
      ].activity.selected = !draft.currentJobInfo.designerWorkspace.itinerary[
        action.dayId
      ].activities[action.itemIndex].activity.selected
      return

    case "moveActivity":
      var itemIndex = draft.currentJobInfo.designerWorkspace.itinerary[
        action.fromDayId
      ].activities.findIndex(element => element.id === action.itemId)
      console.log({ itemIndex })
      if (itemIndex === -1) {
        console.log("Item not found!")
        return // Uid not found - error case
      }

      let activityToMove =
        draft.currentJobInfo.designerWorkspace.itinerary[action.fromDayId]
          .activities[itemIndex].activity

      draft.currentJobInfo.designerWorkspace.itinerary[
        action.toDayId
      ].activities.push({
        id: uuidv4(),
        //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
        //              .activities.length,
        dayId: action.toDayId,
        activity: activityToMove,
      })

      return
    case "setSyncScheduleForTab":
      console.log("syncScheduleForTab")
      console.log(action.data)
      draft.currentJobInfo.designerWorkspace.syncScheduleForTab = action.data
      return

    case "deleteActivity":
      var itemIndex = draft.currentJobInfo.designerWorkspace.itinerary[
        action.dayId
      ].activities.findIndex(element => element.id === action.itemId)
      console.log({ itemIndex })
      if (itemIndex === -1) {
        console.log("Item not found!")
        return // Uid not found - error case
      }
      //Check if the activity to be deleted is of type commute
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
          .activities[itemIndex].activity.activityType === "commute"
      ) {
        console.log("delete-type-commute")
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayId
        ].activities.splice(itemIndex, 1)
        return
      }

      if (itemIndex === 0) {
        // Check if first activity is being deleted. If so then delete the first item and then the following commute card node.

        //Commenting this 01-19-2022 as we are removing automatic commute blocks
        if (action.withCommute) {
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.dayId
          ].activities.splice(itemIndex + 1, 1)
        }
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayId
        ].activities.splice(itemIndex, 1)
      } else {
        // Check if last activity is being deleted. If so then delete the last item and then the previous commute card node.
        if (
          itemIndex ===
          draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
            .activities.length -
            1
        ) {
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.dayId
          ].activities.splice(itemIndex, 1)

          //Commenting this 01-19-2022 as we are removing automatic commute blocks
          if (action.withCommute) {
            draft.currentJobInfo.designerWorkspace.itinerary[
              action.dayId
            ].activities.splice(itemIndex - 1, 1)
          }
        } else {
          // A middle activity is being deleted. If so then reset the previous commute node, delete the activity and the following commute nodelast item and then the previous commute card node.

          //Commenting this 01-19-2022 as we are removing automatic commute blocks
          if (action.withCommute) {
            draft.currentJobInfo.designerWorkspace.itinerary[
              action.dayId
            ].activities[itemIndex - 1].activity = {
              activityType: "commute",
              commuteMode: "",
              commuteDurationHours: 0,
              commuteDurationMinutes: 0,
              activityUid: uuidv4(),
            }
            draft.currentJobInfo.designerWorkspace.itinerary[
              action.dayId
            ].activities.splice(itemIndex + 1, 1)
          }
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.dayId
          ].activities.splice(itemIndex, 1)
        }
      }

      //Remove the commute item
      try {
        if (itemIndex === 0) {
          if (
            draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
              .commute &&
            draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
              .commute[0]
          )
            draft.currentJobInfo.designerWorkspace.itinerary[
              action.dayId
            ].commute.splice(0, 1)
        } else {
          if (
            draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
              .commute &&
            draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
              .commute[itemIndex - 1]
          )
            draft.currentJobInfo.designerWorkspace.itinerary[
              action.dayId
            ].commute.splice(itemIndex - 1, 1)
        }
      } catch (err) {
        console.log(err)
      }

      return
    case "moveActivityToBackup":
      var itemIndex = draft.currentJobInfo.designerWorkspace.itinerary[
        action.dayId
      ].activities.findIndex(element => element.id === action.itemId)
      console.log({ itemIndex })
      if (itemIndex === -1) {
        console.log("Item not found!")
        return // Uid not found - error case
      }
      //Check if the activity to be deleted is of type commute
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
          .activities[itemIndex].activity.activityType === "commute"
      ) {
        console.log("delete-type-commute")
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayId
        ].activities.splice(itemIndex, 1)
        return
      }
      let itemToMove =
        draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
          .activities[itemIndex].activity

      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
          .backupActivities === undefined
      ) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayId
        ].backupActivities = []
      }
      console.log(itemToMove.activityUid)
      var backupItemIndex = draft.currentJobInfo.designerWorkspace.itinerary[
        action.dayId
      ].backupActivities.findIndex(
        element => element.activity.activityUid === itemToMove.activityUid
      )

      if (backupItemIndex === -1) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayId
        ].backupActivities.push({
          id: uuidv4(),
          //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //              .activities.length,
          dayId: action.dayId,
          activity: itemToMove,
        })
      }

      if (itemIndex === 0) {
        // Check if first activity is being deleted. If so then delete the first item and then the following commute card node.
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayId
        ].activities.splice(itemIndex + 1, 1)
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayId
        ].activities.splice(itemIndex, 1)
      } else {
        // Check if last activity is being deleted. If so then delete the last item and then the previous commute card node.
        if (
          itemIndex ===
          draft.currentJobInfo.designerWorkspace.itinerary[action.dayId]
            .activities.length -
            1
        ) {
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.dayId
          ].activities.splice(itemIndex, 1)
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.dayId
          ].activities.splice(itemIndex - 1, 1)
        } else {
          // A middle activity is being deleted. If so then reset the previous commute node, delete the activity and the following commute nodelast item and then the previous commute card node.
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.dayId
          ].activities[itemIndex - 1].activity = {
            activityType: "commute",
            commuteMode: "",
            commuteDurationHours: 0,
            commuteDurationMinutes: 0,
            activityUid: uuidv4(),
          }
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.dayId
          ].activities.splice(itemIndex + 1, 1)
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.dayId
          ].activities.splice(itemIndex, 1)
        }
      }

      return
    case "moveActivities":
      draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].activities = arrayMove(
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .activities,
        action.oldIndex,
        action.newIndex
      )
      draft.currentJobInfo.designerWorkspace.updateCommute = true
      return
    case "addSearchedActivity":
      //draft.itinerary[action.activeTab].activities = []
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .activities.length === 0
      ) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.push({
          id: uuidv4(),
          //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //              .activities.length,
          dayId: action.activeTab,
          activity: action.selectedItem,
        })
      } else {
        //Commenting this 01-19-2022 as we are removing automatic commute blocks
        if (action.addCommute) {
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.activeTab
          ].activities.push({
            id: uuidv4(),
            //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab].activities.length,
            dayId: action.activeTab,
            activity: {
              activityType: "commute",
              commuteMode: "",
              commuteDurationHours: 0,
              commuteDurationMinutes: 0,
              activityUid: uuidv4(),
            },
          })
        }
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.push({
          id: uuidv4(),
          //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //              .activities.length,
          dayId: action.activeTab,
          activity: action.selectedItem,
        })
      }
      return
    // case "addSearchedActivity":
    // //draft.itinerary[action.activeTab].activities = []
    // if (
    //   draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
    //     .activities.length === 0
    // ) {
    //   draft.currentJobInfo.designerWorkspace.itinerary[
    //     action.activeTab
    //   ].activities.push({
    //     id: uuidv4(),
    //     //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
    //     //              .activities.length,
    //     dayId: action.activeTab,
    //     activity: draft.searchResults[action.selectedItemIndex],
    //   })
    // } else {
    //   draft.currentJobInfo.designerWorkspace.itinerary[
    //     action.activeTab
    //   ].activities.push({
    //     id: uuidv4(),
    //     //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab].activities.length,
    //     dayId: action.activeTab,
    //     activity: {
    //       activityType: "commute",
    //       commuteMode: "",
    //       commuteDurationHours: 0,
    //       commuteDurationMinutes: 0,
    //       activityUid: uuidv4(),
    //     },
    //   })
    //   draft.currentJobInfo.designerWorkspace.itinerary[
    //     action.activeTab
    //   ].activities.push({
    //     id: uuidv4(),
    //     //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
    //     //              .activities.length,
    //     dayId: action.activeTab,
    //     activity: draft.searchResults[action.selectedItemIndex],
    //   })
    // }
    // return
    case "updateActivity":
      //Find the item that needs to be updated
      var index = draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].activities.findIndex(
        element => element.activity.activityUid === action.activityUid
      )
      console.log(index, action.activityUid)
      if (index != -1) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities[index].activity = draft.activityScratchPad
      }
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .backupActivities === undefined
      ) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].backupActivities = []
      }
      var backupItemIndex = draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].backupActivities.findIndex(
        element => element.activity.activityUid === action.activityUid
      )
      console.log(backupItemIndex, action.activityUid)
      if (backupItemIndex != -1) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].backupActivities[backupItemIndex].activity = draft.activityScratchPad
      }
      return
    case "addNewActivity":
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .activities.length === 0
      ) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.push({
          id: uuidv4(),
          // draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //  .activities.length,
          dayId: action.activeTab,
          activity: draft.activityScratchPad,
        })
      } else {
        if (action.addCommute) {
          draft.currentJobInfo.designerWorkspace.itinerary[
            action.activeTab
          ].activities.push({
            id: uuidv4(),
            //  draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
            //    .activities.length,
            dayId: action.activeTab,
            activity: {
              activityType: "commute",
              commuteMode: "",
              commuteDurationHours: 0,
              commuteDurationMinutes: 0,
              activityUid: uuidv4(),
            },
          })
        }
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.push({
          id: uuidv4(),
          // draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //   .activities.length,
          dayId: action.activeTab,
          activity: draft.activityScratchPad,
        })
      }
      //draft.itinerary[action.activeTab].activities = []

      return
    case "addNewActivityWithData":
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .activities.length === 0
      ) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.push({
          id: uuidv4(),
          // draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //  .activities.length,
          dayId: action.activeTab,
          activity: action.data,
        })
        draft.currentJobInfo.designerWorkspace.updateRequired = true
        draft.currentJobInfo.designerWorkspace.fetchAndUpdateCommute = true
      } else {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.push({
          id: uuidv4(),
          // draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //   .activities.length,
          dayId: action.activeTab,
          activity: action.data,
        })
        draft.currentJobInfo.designerWorkspace.updateRequired = true
        draft.currentJobInfo.designerWorkspace.fetchAndUpdateCommute = true
      }
      //draft.itinerary[action.activeTab].activities = []

      return
    case "initializeDesignerWorkspaceForItineraries":
      if (draft.currentJobInfo.designerWorkspace != undefined)
        draft.currentJobInfo.designerWorkspace.itinerary = action.data
      else {
        draft.currentJobInfo.designerWorkspace = { itinerary: [] }
        draft.currentJobInfo.designerWorkspace.itinerary = action.data
      }
      return

    case "jobComplete":
      draft.currentJobInfo.status = "complete"
      return

    case "itineraryComplete":
      draft.currentJobInfo.itineraryStatus = "complete"
      return
    case "itineraryInProgress":
      draft.currentJobInfo.itineraryStatus = "in-progress"
      return
    case "reviewComplete":
      draft.currentJobInfo.reviewStatus = "complete"
      return
    case "reviewInProgress":
      draft.currentJobInfo.reviewStatus = "in-progress"
      return

    case "updateReviewCompleteForTraveler":
      draft.tripDetails.designerDetails.status = "review-complete"
      return

    case "updateRequestChangesForTraveler":
      draft.tripDetails.designerDetails.status = "request-changes"
      return

    case "updateCurrentJobInfo":
      draft.currentJobInfo = action.data
      return

    case "syncTripDetailsAndTripState":
      draft.tripDetails = action.data
      return

    case "addNewManifestEntry":
      if (draft.travelManifest === undefined) {
        draft.travelManifest = []
      }
      draft.travelManifest.push(action.data)
      return
    case "updateTripManifest":
      if (draft.travelManifest === undefined) {
        draft.travelManifest = []
      }
      if (action.data === undefined) draft.travelManifest = []
      else draft.travelManifest = action.data.travelManifest
      return
    case "appendToTripManifest":
      console.log(action.data)
      if (draft.travelManifest === undefined) {
        draft.travelManifest = []
      }
      if (action.data === undefined) return
      else draft.travelManifest.push(action.data)
      return
    case "removeManifestEntry":
      var itemIndex = draft.travelManifest.findIndex(
        item => item.tripUid === action.data
      )
      if (itemIndex !== -1) {
        draft.travelManifest.splice(itemIndex, 1)
      }
      return
    case "setActiveTrips":
      if (
        !draft.activeTrips.find(
          element => element.tripUid === action.data.tripUid
        )
      )
        draft.activeTrips.push(action.data)
      return
    case "setDesignerStateToPending":
      if (draft.tripDetails.designerDetails !== undefined) {
        draft.tripDetails.designerDetails.status = "pending"
        draft.tripDetails.designerDetails.designerUid = action.data.uid
        draft.tripDetails.designerDetails.designerProfile = action.data
      }
      return
    case "updateDesignerPublicProfile":
      draft.tripDetails.designerDetails.designerProfile = action.data
      return
    case "setActiveTripDetailsUid":
      draft.tripDetails = draft.activeTrips.find(
        element => element.tripUid === action.data
      )
      return
    case "updateJobDetails":
      draft.tripDetails = action.data
      return
    case "updateJob":
      const fPending = draft.jobs.findIndex(
        element => element.tripUid === action.data.tripUid
      )
      if (fPending >= 0) {
        draft.jobs[fPending] = action.data
      }
      return

    case "addNewJob":
      draft.jobs.push(action.data)
      return
    case "deleteJob":
      draft.jobs.splice(
        draft.jobs.findIndex(e => e.tripUid === action.data.tripUid),
        1
      )
      return
    case "setActiveTab":
      draft.activeTab = action.data
      return
    case "toggleLogisticsEditMode":
      draft.editLogistics = !draft.editLogistics
      return
    case "toggleTravelerDetailsEditMode":
      draft.editTravelerDetails = !draft.editTravelerDetails
      return

    case "toggleTravelerPreferencesEditMode":
      draft.editTravelerPreferences = !draft.editTravelerPreferences
      return

    case "removeNewRoleEntry":
      let a = draft.tripDetails.roles
      delete a[action.data]
      draft.tripDetails.roles = a

      return
    case "addNewRoleEntry":
      draft.tripDetails.roles = action.data
      return
    case "addNewFlightDetail":
      if (draft.currentJobInfo.flightDetails === undefined) {
        draft.currentJobInfo.flightDetails = []
      }
      draft.currentJobInfo.flightDetails.push(action.data)
      return

    case "saveFlightEdits":
      try {
        draft.currentJobInfo.flightDetails[action.index] = action.data
      } catch (err) {}
      return
    case "deleteFlightDetailEntry":
      try {
        draft.currentJobInfo.flightDetails.splice(action.index, 1)
      } catch (err) {
        console.log(err)
      }
      return

    case "addNewAccommodationDetail":
      if (draft.currentJobInfo.accommodationDetails === undefined) {
        draft.currentJobInfo.accommodationDetails = []
      }
      draft.currentJobInfo.accommodationDetails.push(action.data)
      return

    case "saveAccommodationEdits":
      try {
        draft.currentJobInfo.accommodationDetails[action.index] = action.data
      } catch (err) {}
      return
    case "deleteAccommodationDetailEntry":
      try {
        draft.currentJobInfo.accommodationDetails.splice(action.index, 1)
      } catch (err) {
        console.log(err)
      }
      return

    case "addNewTask":
      if (draft.currentJobInfo.tasks === undefined) {
        draft.currentJobInfo.tasks = []
      }
      draft.currentJobInfo.tasks.push(action.data)
      draft.currentJobInfo.tasksSyncRequired = true
      return
    case "saveTaskEdits":
      try {
        draft.currentJobInfo.tasks[action.index] = action.data
        draft.currentJobInfo.tasksSyncRequired = true
      } catch (err) {}
      return
    case "tasksSortRequired":
      try {
        if (
          draft.currentJobInfo.tasks &&
          draft.currentJobInfo.tasks.length > 0
        ) {
          var sArray = sortBy(draft.currentJobInfo.tasks, [
            function(o) {
              return o.taskStarred
            },
          ]).reverse()
          draft.currentJobInfo.tasks = [...sArray]
        }
      } catch (err) {}
      return

    case "deleteTask":
      try {
        draft.currentJobInfo.tasks.splice(action.index, 1)
      } catch (err) {
        console.log(err)
      }
      return

    case "addNewRentalCarDetail":
      if (draft.currentJobInfo.rentalCarDetails === undefined) {
        draft.currentJobInfo.rentalCarDetails = []
      }
      draft.currentJobInfo.rentalCarDetails.push(action.data)
      return

    case "saveRentalCarEdits":
      try {
        draft.currentJobInfo.rentalCarDetails[action.index] = action.data
      } catch (err) {}
      return
    case "deleteRentalCarDetailEntry":
      try {
        draft.currentJobInfo.rentalCarDetails.splice(action.index, 1)
      } catch (err) {
        console.log(err)
      }
      return

    case "updateTripNotes":
      try {
        if (draft.currentJobInfo.tripNotes === undefined)
          draft.currentJobInfo.tripNotes = ""

        draft.currentJobInfo.tripNotes = action.data
      } catch (err) {
        console.log(err)
      }
      return
    case "updateTripDayNotes":
      try {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].dayNotes = action.data
      } catch (err) {
        console.log(err)
      }
      return
    case "addNewWishListItem":
      try {
        if (draft.currentJobInfo.tripWishlist === undefined) {
          draft.currentJobInfo.tripWishlist = []
        }

        draft.currentJobInfo.tripWishlist.push(draft.activityScratchPad)
      } catch (error) {
        console.log(error)
      }
      return
    case "addNewWishListItemFromSearch":
      try {
        if (draft.currentJobInfo.tripWishlist === undefined) {
          draft.currentJobInfo.tripWishlist = []
        }

        draft.currentJobInfo.tripWishlist.push(action.data)
      } catch (error) {
        console.log(error)
      }
      return
    case "addNewTripDocument":
      try {
        if (draft.currentJobInfo.tripDocuments === undefined) {
          draft.currentJobInfo.tripDocuments = []
        }

        draft.currentJobInfo.tripDocuments.push(action.data)
        draft.currentJobInfo.tripDocumentsSyncRequired = true
      } catch (error) {
        console.log(error)
      }
      return
    case "deleteTripDocument":
      try {
        if (draft.currentJobInfo.tripDocuments === undefined) {
          return
        }
        draft.currentJobInfo.tripDocuments.splice(action.data, 1)
        draft.currentJobInfo.tripDocumentsSyncRequired = true
      } catch (error) {
        console.log(error)
      }
      return
    case "updateTripDocumentFileNameAlias":
      try {
        if (draft.currentJobInfo.tripDocuments === undefined) {
          return
        }
        draft.currentJobInfo.tripDocuments[action.index].fileNameAlias =
          action.data
        draft.currentJobInfo.tripDocumentsSyncRequired = true
      } catch (error) {
        console.log(error)
      }
      return
    case "toggleDocumentStar":
      try {
        if (draft.currentJobInfo.tripDocuments === undefined) {
          return
        }

        var sArray = [...draft.currentJobInfo.tripDocuments]

        sArray[action.data].docStarred = !sArray[action.data].docStarred

        let fArray = sortBy(sArray, [
          function(o) {
            return o.docStarred
          },
        ]).reverse()
        draft.currentJobInfo.tripDocuments = [...fArray]
        console.log({ sArray })
        console.log({ fArray })
        draft.currentJobInfo.tripDocumentsSyncRequired = true
      } catch (error) {}
      return
    case "moveToWishList":
      try {
        if (draft.currentJobInfo.tripWishlist === undefined) {
          draft.currentJobInfo.tripWishlist = []
        }
        if (action.overwrite) {
          draft.currentJobInfo.tripWishlist[action.existingIndex] = action.item
        } else {
          console.log(action.overwrite)
          console.log(action.item)
          draft.currentJobInfo.tripWishlist.push(action.item)
        }
      } catch (error) {
        console.log(error)
      }
      return
    case "updateWishlistItem":
      try {
        if (draft.currentJobInfo.tripWishlist && action.activityUid) {
          let itemIndex = draft.currentJobInfo.tripWishlist.findIndex(
            i => i.activityUid === action.activityUid
          )
          if (itemIndex != -1) {
            draft.currentJobInfo.tripWishlist[itemIndex] =
              draft.activityScratchPad
          }
        }
      } catch (error) {
        console.log(error)
      }
      return
    case "wishlistSyncRequired":
      draft.currentJobInfo.wishlistSyncRequired = action.data
      return

    case "tripDocumentsSyncRequired":
      draft.currentJobInfo.tripDocumentsSyncRequired = action.data
      return

    case "wishlistItinerarySyncRequired":
      draft.currentJobInfo.wishlistItinerarySyncRequired = action.data
      return
    case "deleteWishListItem":
      try {
        if (draft.currentJobInfo.tripWishlist === undefined) {
          return
        }
        draft.currentJobInfo.tripWishlist.splice(action.index, 1)
        draft.currentJobInfo.wishlistSyncRequired = true
      } catch (error) {
        console.log(error)
      }
      return
    case "deleteSelectedWishListItem":
      try {
        if (draft.currentJobInfo.tripWishlist === undefined) {
          return
        }
        let temp = []
        draft.currentJobInfo.tripWishlist.map((item, index) => {
          if (!item.selected) {
            temp.push(item)
          }
        })
        draft.currentJobInfo.tripWishlist = temp
        draft.currentJobInfo.wishlistSyncRequired = true
      } catch (error) {
        console.log(error)
      }
      return

    case "ToggleWishlistItem":
      try {
        if (draft.currentJobInfo.tripWishlist[action.selectedItem].selected)
          draft.currentJobInfo.tripWishlist[
            action.selectedItem
          ].selected = !draft.currentJobInfo.tripWishlist[action.selectedItem]
            .selected
        else
          draft.currentJobInfo.tripWishlist[action.selectedItem].selected = true
        return
      } catch (error) {
        console.log(error)
      }
      return

    case "FromWishListToItinerarySelected":
      try {
        draft.currentJobInfo.tripWishlist.map((item, index) => {
          if (item.selected) {
            draft.currentJobInfo.designerWorkspace.itinerary[
              action.activeTab
            ].activities.push({
              id: uuidv4(),
              //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
              //              .activities.length,
              dayId: action.activeTab,
              activity: item,
            })

            if (action.actionType === "move") {
              draft.currentJobInfo.tripWishlist.splice(
                action.wishlistItemIndex,
                1
              )
            }
            draft.currentJobInfo.tripWishlist[index].selected = false
            // draft.currentJobInfo.designerWorkspace.updateRequired = true
            draft.currentJobInfo.designerWorkspace.updateCommute = true
            draft.currentJobInfo.wishlistItinerarySyncRequired = true
          }
        })

        return
      } catch (error) {
        console.log(error)
      }
      return
    case "ResetWishlistSelections":
      try {
        draft.currentJobInfo.tripWishlist.map((item, index) => {
          if (item.selected) {
            draft.currentJobInfo.tripWishlist[index].selected = false
          }
        })

        return
      } catch (error) {
        console.log(error)
      }
      return
    case "ResetWishlistSelectionsWithDBSync":
      try {
        draft.currentJobInfo.tripWishlist.map((item, index) => {
          if (item.selected) {
            draft.currentJobInfo.tripWishlist[index].selected = false
          }
        })
        draft.currentJobInfo.wishlistSyncRequired = true
        return
      } catch (error) {
        console.log(error)
      }
      return
    case "FromWishListToItinerary":
      try {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.push({
          id: uuidv4(),
          //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //              .activities.length,
          dayId: action.activeTab,
          activity: action.selectedItem,
        })

        if (action.actionType === "move") {
          draft.currentJobInfo.tripWishlist.splice(action.wishlistItemIndex, 1)
        }
        // draft.currentJobInfo.designerWorkspace.updateRequired = true
        draft.currentJobInfo.designerWorkspace.updateCommute = true
        draft.currentJobInfo.wishlistItinerarySyncRequired = true

        return
      } catch (error) {
        console.log(error)
      }
      return
    // case "FromWishListToItinerary": // This one adds an additional default commute block
    //   try {
    //     if (
    //       draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
    //         .activities.length === 0
    //     ) {
    //       draft.currentJobInfo.designerWorkspace.itinerary[
    //         action.activeTab
    //       ].activities.push({
    //         id: uuidv4(),
    //         //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
    //         //              .activities.length,
    //         dayId: action.activeTab,
    //         activity: action.selectedItem,
    //       })
    //     } else {
    //       draft.currentJobInfo.designerWorkspace.itinerary[
    //         action.activeTab
    //       ].activities.push({
    //         id: uuidv4(),
    //         //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab].activities.length,
    //         dayId: action.activeTab,
    //         activity: {
    //           activityType: "commute",
    //           commuteMode: "",
    //           commuteDurationHours: 0,
    //           commuteDurationMinutes: 0,
    //           activityUid: uuidv4(),
    //         },
    //       })
    //       draft.currentJobInfo.designerWorkspace.itinerary[
    //         action.activeTab
    //       ].activities.push({
    //         id: uuidv4(),
    //         //draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
    //         //              .activities.length,
    //         dayId: action.activeTab,
    //         activity: action.selectedItem,
    //       })
    //     }
    //     if (action.actionType === "move") {
    //       draft.currentJobInfo.tripWishlist.splice(action.wishlistItemIndex, 1)
    //     }
    //     // draft.currentJobInfo.designerWorkspace.updateRequired = true
    //     draft.currentJobInfo.designerWorkspace.updateCommute = true
    //     draft.currentJobInfo.wishlistItinerarySyncRequired = true
    //     return
    //   } catch (error) {
    //     console.log(error)
    //   }
    //   return

    //   {
    //     "activityUid": "39743894-2b64-4244-a24a-36b9dfa97f59",
    //     "activityStartTime": "Tue, 14 Dec 2021 08:00:00 GMT",
    //     "activityType": "commute",
    //     "commuteDurationHours": 0,
    //     "commuteDurationMinutes": 0,
    //     "activityEndTime": "Tue, 14 Dec 2021 08:20:00 GMT",
    //     "commuteMode": "driving",
    //     "activityDurationHours": "0",
    //     "activityDurationMinutes": "20"
    // }

    case "updateCommute":
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .activities.length < 2
      )
        return
      let commuteActivity = {
        activityUid: uuidv4(),
        activityType: "commute",
        commuteDurationHours: action.commuteDurationHours,
        commuteDurationMinutes: action.commuteDurationMinutes,
        commuteDistanceMeters: action.commuteDistanceMeters,
        commuteMode: action.commuteMode,
        activityDurationHours: `${action.commuteDurationHours}`,
        activityDurationMinutes: `${action.commuteDurationMinutes}`,
        origin: action.origin,
        destination: action.destination,
      }
      draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].activities[action.itemIndex].activity = { ...commuteActivity }
      draft.currentJobInfo.designerWorkspace.updateRequired =
        action.updateRequired
      return
    case "insertCommute":
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          .activities.length < 2
      )
        return
      let newCommuteActivity = {
        activityUid: uuidv4(),
        activityType: "commute",
        commuteDurationHours: action.commuteDurationHours,
        commuteDurationMinutes: action.commuteDurationMinutes,
        commuteDistanceMeters: action.commuteDistanceMeters,
        commuteMode: action.commuteMode,
        activityDurationHours: `${action.commuteDurationHours}`,
        activityDurationMinutes: `${action.commuteDurationMinutes}`,
        origin: action.origin,
        destination: action.destination,
      }
      draft.currentJobInfo.designerWorkspace.itinerary[
        action.activeTab
      ].activities.splice(action.itemIndex, 0, {
        activity: newCommuteActivity,
        dayId: action.activeTab,
        id: uuidv4(),
      })
      draft.currentJobInfo.designerWorkspace.updateRequired =
        action.updateRequired
      return
    case "updateCollaboratorData":
      if (draft.collaborators !== undefined) {
        var itemIndex = draft.collaborators.findIndex(
          item => item.uid === action.uid
        )
        // console.log({ itemIndex })
        if (itemIndex === -1)
          draft.collaborators.push({
            profileImage: action.data.userProfileImage,
            uid: action.data.uid,
            userName: action.data.username
              ? action.data.username
              : action.data.firstName + " " + action.data.lastName,
          })
        // else {
        //   if (
        //     draft.collaborators[itemIndex] ===
        //     {
        //       profileImage: action.data.userProfileImage,
        //       uid: action.data.uid,
        //       userName: action.data.username
        //         ? action.data.username
        //         : action.data.firstName + " " + action.data.lastName,
        //     }
        //   )
        //     return
        //   else {
        //     draft.collaborators[itemIndex] = {
        //       profileImage: action.data.userProfileImage,
        //       uid: action.data.uid,
        //       userName: action.data.username
        //         ? action.data.username
        //         : action.data.firstName + " " + action.data.lastName,
        //     }
        //   }
        // }
      } else {
        draft.collaborators = []
        draft.collaborators.push({
          profileImage: action.data.userProfileImage,
          uid: action.data.uid,
          userName: action.data.username
            ? action.data.username
            : action.data.firstName + " " + action.data.lastName,
        })
      }
      return

    case "updateComputedCommuteDetails":
      if (
        draft.currentJobInfo.designerWorkspace.itinerary[action.dayIndex]
          .commute === undefined
      ) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayIndex
        ].commute = []
      }

      draft.currentJobInfo.designerWorkspace.itinerary[action.dayIndex].commute[
        action.activityIndex
      ] = action.commuteData
      return
    case "applyOptimizedRoute":
      if (action.activities !== []) {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.dayIndex
        ].activities = action.activities
      }
      draft.currentJobInfo.designerWorkspace.fetchAndUpdateCommute = true
      return
    case "setFetchAndUpdateCommute":
      draft.currentJobInfo.designerWorkspace.fetchAndUpdateCommute =
        action.value
      return

    case "addAccommodationToItinerary":
      console.log("addAccommodationToItinerary")
      console.log(action.data)
      if (action.actionType === "end") {
        draft.currentJobInfo.designerWorkspace.itinerary[
          action.activeTab
        ].activities.push({
          id: uuidv4(),
          // draft.currentJobInfo.designerWorkspace.itinerary[action.activeTab]
          //   .activities.length,
          dayId: action.activeTab,
          activity: {
            activityName: action.data.accommodationName,
            activityAddress: action.data.accommodationAddress,
            activityLatLng: action.data.accommodationAddressLatLng,
            activityUid: action.data.accommodationUid,
            activityImageData: action.imageInfo,
            activityImageId: action.imageInfo.imageId,
            activityType: "Accommodation",
          },
        })
      }

      return
    default:
      return
  }
}

import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"
// import reservations from "./../../../images/reservations/reservations.svg"
import ReservationCard from "./../ReservationTab/ReservationCard"
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table"
import DateTimePicker from "react-datetime-picker"
import moment from "moment"
import is from "is_js"
import { SingleDatePicker } from "react-dates"
import Cleave from "cleave.js/react"
import Select from "react-select"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
const ReservationCreator = ({ canEdit = false }) => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [inEditMode, setInEditMode] = useState(false)
  const [reservationDateFocussed, setReservationDateFocussed] = useState(false)
  const addReservationItem = () => {
    dispatch({ type: "initializeReservationTempItem" })
  }

  useEffect(() => {
    try {
      if (state.currentJobInfo.tripReservationSyncRequired) {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${tripState.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({ tripReservations: state.currentJobInfo.tripReservations })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => console.log(e))
        }

        dispatch({ type: "tripReservationSyncRequired", data: false })
      }
    } catch (error) {
      console.log(error)
    }
  }, [state.currentJobInfo.tripReservationSyncRequired])

  useEffect(() => {
    if (state.currentJobInfo.tripReservationTemp) setInEditMode(true)
  }, [state.currentJobInfo.tripReservationTemp])

  const formattedDate = d => {
    try {
      return new Date(d.setMinutes(d.getMinutes() - d.getTimezoneOffset()))
    } catch (error) {
      console.log(error)
      let a = new Date(d)
      return new Date(a.setMinutes(a.getMinutes() - a.getTimezoneOffset()))
    }
  }

  const pickRightDateFormat = () => {
    try {
      // var dt = new moment.utc()
      // var offset = dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset())
      //console.log(offset)
      return state.currentJobInfo.tripReservationTemp.dateTime === ""
        ? new Date()
        : typeof state.currentJobInfo.tripReservationTemp.dateTime === "string"
        ? new Date(state.currentJobInfo.tripReservationTemp.dateTime)
        : state.currentJobInfo.tripReservationTemp.dateTime.toDate()
    } catch (error) {
      console.log(error)
      return state.currentJobInfo.tripReservationTemp.dateTime === ""
        ? new Date()
        : state.currentJobInfo.tripReservationTemp.dateTime
    }
  }
  const onEdit = e => {
    dispatch({ type: "setReservationTempForEdit", index: e.currentTarget.id })
  }

  const showReservationEntryForm = () => {
    return (
      <div className="columns">
        <div className="column is-3 is-desktop"></div>
        <div className="column is-6 is-desktop">
          <hr className="hr-branded"></hr>
          <div style={{ marginTop: "20px" }}>
            <div className="field">
              <label className="label">Venue Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  tabIndex="1"
                  autoFocus
                  value={
                    state.currentJobInfo.tripReservationTemp
                      ? state.currentJobInfo.tripReservationTemp.name || "--"
                      : "--"
                  }
                  onChange={e =>
                    dispatch({
                      type: "updateVenueName",
                      data: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="control"></div>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div
                className="field"
                style={{ marginRight: "8px", width: "50%" }}
              >
                <label className="label">Reservation Date</label>
                <div className="control">
                  {/* <DateTimePicker
                  onChange={value => {
                    dispatch({
                      type: "updateReservationDateTime",
                      // data: formattedDate(value).toUTCString(),
                      data: value,
                    })
                  }}
                  value={pickRightDateFormat()}
                  disableClock={true}
                /> */}
                  <SingleDatePicker
                    date={
                      state.currentJobInfo.tripReservationTemp
                        .reservationDate != ""
                        ? moment(
                            state.currentJobInfo.tripReservationTemp
                              .reservationDate
                          )
                        : null
                    } // momentPropTypes.momentObj or null
                    numberOfMonths={1}
                    onDateChange={date => {
                      try {
                        console.log(date)

                        if (moment.isMoment(date)) {
                          dispatch({
                            type: "updateReservationDate",

                            data: moment(date).format("L"),
                          })
                        }
                      } catch (err) {
                        console.log(err)
                      }
                    }} // PropTypes.func.isRequired
                    readOnly={is.mobile() ? true : false}
                    focused={reservationDateFocussed} // PropTypes.bool
                    onFocusChange={({ focused }) => {
                      setReservationDateFocussed(focused)
                    }} // PropTypes.func.isRequired
                    id="your_unique_1" // PropTypes.string.isRequired,
                  />
                </div>
              </div>
              <div
                className="field"
                style={{ marginRight: "8px", width: "50%" }}
              >
                <label className="label">Time</label>
                <div className="control">
                  <Cleave
                    className="input"
                    value={
                      state.currentJobInfo.tripReservationTemp.reservationTime
                    }
                    style={{ textAlign: "center" }}
                    placeholder="Time - 24hr"
                    options={{ time: true, timePattern: ["h", "m"] }}
                    onChange={e => {
                      dispatch({
                        type: "updateReservationTime",

                        data: e.currentTarget.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Confirmation/Ticker Number:</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={
                    state.currentJobInfo.tripReservationTemp.confirmationNumber
                  }
                  onChange={e =>
                    dispatch({
                      type: "updateConfirmationNumber",
                      data: e.currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Additional Info: </label>
              <div className="control">
                <textarea
                  className="textarea"
                  rows="3"
                  value={
                    state.currentJobInfo.tripReservationTemp.additionalNote
                  }
                  placeholder="E.g. I have reserved a table for three, i.e., two adults and a child for dinner."
                  onChange={e =>
                    dispatch({
                      type: "updateAdditionalNote",
                      data: e.currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field is-grouped is-grouped-right">
              <p className="control">
                <a
                  className="button is-light"
                  onClick={e => {
                    dispatch({ type: "clearReservationTempItem" })
                    setInEditMode(false)
                  }}
                >
                  Cancel
                </a>
              </p>
              <p className="control">
                <a
                  className="button is-warning"
                  onClick={e => {
                    dispatch({
                      type: "updateReservationDateTime",
                      data: formattedDate(
                        state.currentJobInfo.tripReservationTemp.dateTime
                      ).toUTCString(),
                      // data: value,
                    })
                    state.currentJobInfo.tripReservationEditExisting
                      ? //If editing an existing reservation
                        dispatch({
                          type: "saveReservationChanges",
                          index: state.currentJobInfo.tripReservationTemp.index,
                        })
                      : // Adding a new reservation.
                        dispatch({
                          type: "addReservation",
                        })
                    // Required to notify a cloud DB update
                    dispatch({
                      type: "tripReservationSyncRequired",
                      data: true,
                    })
                    //Close reservation entry form
                    setInEditMode(false)
                  }}
                >
                  {state.currentJobInfo.tripReservationEditExisting
                    ? "Save Changes"
                    : "Add Reservation"}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="column is-3 is-desktop"></div>
      </div>
    )
  }
  const compareReservationStartDate = (a, b) => {
    if (a.dateTime.seconds > b.dateTime.seconds) return 1
    if (a.dateTime.seconds < b.dateTime.seconds) return -1
    return 0
  }
  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
  }
  const formatTimeAlt = data => {
    try {
      return typeof data.dateTime === "string"
        ? moment.utc(data.dateTime).format("HH:mm")
        : new Intl.DateTimeFormat("en-US", timeOptions).format(
            data.dateTime.toDate()
          )
    } catch (error) {
      console.log(error)
      return new Intl.DateTimeFormat("en-US", timeOptions).format(data.dateTime)
    }
  }
  const formatDateAlt = data => {
    try {
      return typeof data.dateTime === "string"
        ? moment.utc(new Date(data.dateTime)).format("MM/DD/YYYY")
        : new Intl.DateTimeFormat("en-US", dateOptions).format(
            data.dateTime.toDate()
          )
    } catch (error) {
      console.log(error)
      return new Intl.DateTimeFormat("en-US", dateOptions).format(data.dateTime)
    }
  }
  const showReservations = () => {
    if (state.currentJobInfo.tripReservations.length > 0) {
      var reservationsList = state.currentJobInfo.tripReservations.slice()
      reservationsList.sort(compareReservationStartDate)
      if (reservationsList)
        return reservationsList.map((item, index) => {
          console.log({ item })
          return (
            <div key={index} style={{ margin: "10px auto" }}>
              {canEdit && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#fec600",
                  }}
                >
                  <div
                    style={{ marginRight: "20px", cursor: "pointer" }}
                    onClick={() => {
                      dispatch({
                        type: "setReservationTempForEdit",
                        index: index,
                      })
                    }}
                  >
                    <i className="far fa-pen"></i>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      ConfirmationModal({
                        onConfirm: i => {
                          console.log(i)
                          dispatch({
                            type: "deleteReservation",
                            index: i,
                          })
                          dispatch({
                            type: "tripReservationSyncRequired",
                            data: true,
                          })
                        },
                        sendBackValue: index,
                        title: "Confirmation",
                        text: `Are you sure you want to permanently delete this entry?`,
                      })
                    }}
                  >
                    <i className="far fa-trash-alt"></i>
                  </div>
                </div>
              )}
              <div className="accommodation-details">
                <div className="accommodation-name">{item.name}</div>
                {/* <div className="accommodation-address">
                      <i
                        style={{ color: "#fec600", marginRight: "5px" }}
                        className="far fa-map-marker-alt"
                      ></i>
                      <a
                        href={googleMapsURI(
                          item.accommodationName + item.accommodationAddress
                        )}
                        target="_blank"
                      >
                        {item.accommodationAddress}
                      </a>
                    </div> */}
                <div className="accommodation-dates-container">
                  <div
                    className="accommodation-dates"
                    style={{ marginRight: "15px" }}
                  >
                    <div
                      style={{
                        fontWeight: "300",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        fontSize: "10px",
                      }}
                    >
                      date
                    </div>
                    {item.reservationDate}
                  </div>
                  <div className="accommodation-dates">
                    <div
                      style={{
                        fontWeight: "300",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        fontSize: "10px",
                      }}
                    >
                      time
                    </div>
                    {item.reservationTime}
                  </div>
                  {/* <i
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      color: "#fec600",
                      margin: "0px 20px",
                    }}
                    className="fal fa-arrows-h"
                  ></i> */}
                  {/* <div className="accommodation-dates">
                    <div
                      style={{
                        fontWeight: "300",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        fontSize: "10px",
                      }}
                    >
                      check-out
                    </div>
                    {item.toDate}
                  </div> */}
                </div>

                <div style={{ display: "flex" }}>
                  <div
                    className="confirm-no"
                    style={{ marginRight: "20px", minWidth: "100px" }}
                  >
                    <span
                      style={{
                        fontWeight: "300",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        fontSize: "10px",
                      }}
                    >
                      Confirmation #{" "}
                    </span>
                    <div style={{ fontWeight: "400", fontSize: "14px" }}>
                      {item.confirmationNumber === ""
                        ? "--"
                        : item.confirmationNumber}
                    </div>
                  </div>
                  <div className="additional-details">
                    {" "}
                    <span
                      style={{
                        fontWeight: "300",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        fontSize: "10px",
                      }}
                    >
                      Notes{" "}
                    </span>
                    <div style={{ fontWeight: "400", fontSize: "14px" }}>
                      {item.additionalNote === "" ? "--" : item.additionalNote}
                    </div>
                  </div>
                </div>
                <hr className="hr-branded"></hr>
              </div>
            </div>
            // <ReservationCard
            //   key={index}
            //   data={item}
            //   onEdit={onEdit}
            //   canEdit={true}
            // />
          )
        })
      // <Table>
      //   <Thead>
      //     <Tr>
      //       <Th className="" style={{ paddingBottom: "10px" }}>
      //         Reservation Date
      //       </Th>
      //       <Th className="" style={{ paddingBottom: "10px" }}>
      //         Reservation Time
      //       </Th>
      //       <Th className="" style={{ paddingBottom: "10px" }}>
      //         Confirmation No.{" "}
      //       </Th>
      //       <Th className="" style={{ paddingBottom: "10px" }}>
      //         Venue Name
      //       </Th>
      //       <Th className="" style={{ paddingBottom: "10px" }}>
      //         Additional Info
      //       </Th>
      //     </Tr>
      //   </Thead>
      //   <Tbody>
      //     {state.currentJobInfo.tripReservations.map((item, index) => {
      //       console.log({ item })
      //       return (
      //         <ReservationCard
      //           key={index}
      //           data={item}
      //           onEdit={onEdit}
      //           canEdit={true}
      //         />
      //       )
      //     })}
      //   </Tbody>
      // </Table>
    } else
      return (
        <>
          {!canEdit && (
            <p
              style={{
                // fontStyle: "italic",
                fontSize: "14px",
                fontWeight: "300",
                textAlign: "center",
              }}
            >
              - No items added yet -
            </p>
          )}
        </>
      )
  }

  return (
    <>
      <p
        // style={{
        //   fontSize: "34px",
        //   fontWeight: "800",
        //   color: "rgba(74,74,74,0.8)",
        //   margin: "20px auto",
        //   lineHeight: "1.4",
        //   textAlign: "center",
        // }}
        className="tab-header"
      >
        Reservation Details<span style={{ color: "#fec600" }}>.</span>
      </p>
      <p className="tab-tagline">
        All your restaurant reservations, tour bookings, show tickets etc.
        <span style={{ fontWeight: "500" }}> TIP:</span> These events will be
        displayed on your itinerary.
      </p>
      {inEditMode ? (
        showReservationEntryForm()
      ) : (
        <>
          <div className="columns">
            <div className="column is-2-desktop is-0-tablet is-hidden-mobile"></div>
            <div className="column is-8-desktop is-12-tablet is-12-mobile">
              <div
                style={{
                  // marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
                className=""
              >
                <div
                  style={
                    {
                      // margin: "30px auto",
                      // marginBottom: "20px",
                    }
                  }
                >
                  {canEdit && (
                    <a
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        textDecorationColor: "#fec600",
                        color: "rgba(74, 74, 74, 0.8)",
                        textDecoration: "none",
                      }}
                      onClick={addReservationItem}
                    >
                      <i
                        className="far fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>{" "}
                      Add a reservation
                    </a>
                  )}

                  {/* <button className="button is-warning" onClick={addReservationItem}>
              <span className="icon is-small">
                <i className="fal fa-plus"></i>
              </span>
              <span>Add a new reservation</span>
            </button> */}
                </div>
                <hr className="hr-branded"></hr>
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  {showReservations()}
                </div>

                {/* <div style={{ zIndex: "-5", position: "absolute", top: "50px" }}>
            <img
              src={reservations}
              style={{ maxWidth: "400px", marginTop: "80px" }}
            ></img>
          </div> */}
              </div>
            </div>

            <div className="column is-2-desktop is-0-tablet is-hidden-mobile"></div>
          </div>
        </>
      )}
    </>
  )
}

export default ReservationCreator
